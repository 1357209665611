import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { useEffect } from "react";
import { setLanguage } from "@brikl/localization";
import { useStore } from "@brikl/store";
import { getURLParams } from "@brikl/studio-utils";
export var useSetStoreLanguage = function() {
    var urlParams = getURLParams();
    var langCode = (urlParams === null || urlParams === void 0 ? void 0 : urlParams.get("language")) || (urlParams === null || urlParams === void 0 ? void 0 : urlParams.get("l"));
    var ref = _sliced_to_array(useStore(), 1), store = ref[0];
    useEffect(function() {
        setLanguage(langCode !== null && langCode !== void 0 ? langCode : store.langCode);
    }, [
        langCode,
        store.langCode
    ]);
};
