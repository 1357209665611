import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { put, takeLatest } from "typed-redux-saga";
import { actions } from "../slice";
export function onInitialDesignTemplates(action) {
    var _payload, templates, selectedTemplateId, _tmp;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _payload = action.payload, templates = _payload.templates, selectedTemplateId = _payload.selectedTemplateId;
                console.log("[Template] Selected template:", templates, selectedTemplateId);
                // update selected template
                return [
                    5,
                    _ts_values(put(actions.selectTemplate(selectedTemplateId)))
                ];
            case 1:
                _state.sent();
                _tmp = {};
                return [
                    5,
                    _ts_values(put(actions.loadTemplatesCompleted((_tmp.templates = templates, _tmp.selectedTemplateId = selectedTemplateId, _tmp))))
                ];
            case 2:
                _state.sent();
                return [
                    5,
                    _ts_values(put(actions.selectTemplateCompleted()))
                ];
            case 3:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
export function watchLoadTemplateSaga() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(takeLatest(actions.loadTemplates.type, onInitialDesignTemplates))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
