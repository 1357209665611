import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import findIndex from "lodash/findIndex";
import omit from "lodash/omit";
export var selectPlaceholderGroup = function(state, action) {
    state.selectedPlaceholderGroup = action.payload;
};
export var selectPlaceholder = function(state, action) {
    state.selectedPlaceholderId = action.payload;
};
export var updateSelectedPrintingTechniques = function(state, action) {
    var existingIndex = findIndex(state.selectedPrintingTechniques, omit(action.payload, [
        "printingTechniqueId"
    ]));
    if (existingIndex !== -1) {
        state.selectedPrintingTechniques[existingIndex] = action.payload;
    } else {
        state.selectedPrintingTechniques = _to_consumable_array(state.selectedPrintingTechniques).concat([
            action.payload, 
        ]);
    }
};
export var uploadPlaceholderImagesDone = function(state) {
    state.isUploading = false;
};
export var uploadPlaceholderImages = function(state, action) {
    state.isUploading = true;
};
export var placePlaceholderImage = function(state, action) {
// no reducer required
};
