export var StudioActions;
(function(StudioActions) {
    StudioActions["TOGGLE_DESIGNROOM"] = "TOGGLE_DESIGNROOM";
    StudioActions["TOGGLE_MOUSE_TRACKING"] = "TOGGLE_MOUSE_TRACKING";
    StudioActions["INITIALIZE"] = "INITIALIZE";
    StudioActions["TOGGLE_DEBUG"] = "TOGGLE_DEBUG";
    StudioActions["TOGGLE_BACKGROUND"] = "TOGGLE_BACKGROUND";
    StudioActions["TOGGLE_AUTO_ROTATE"] = "TOGGLE_AUTO_ROTATE";
    StudioActions["TOGGLE_RECENTER_3D_CAMERA"] = "TOGGLE_RECENTER_3D_CAMERA";
    StudioActions["TOGGLE_3D_ZOOM"] = "TOGGLE_3D_ZOOM";
    StudioActions["TOGGLE_2D_VISIBILITY"] = "TOGGLE_2D_VISIBILITY";
    StudioActions["TOGGLE_3D_VISIBILITY"] = "TOGGLE_3D_VISIBILITY";
    StudioActions["CANVAS_READY"] = "CANVAS_READY";
    StudioActions["LAYERS_READY"] = "LAYERS_READY";
    StudioActions["THREE_READY"] = "THREE_READY";
    StudioActions["RESET_DEFAULTS"] = "RESET_DEFAULTS";
    StudioActions["SELECT_BACKGROUND"] = "SELECT_BACKGROUND";
    StudioActions["APPLY_PLACEHOLDER_IMAGE"] = "APPLY_PLACEHOLDER_IMAGE";
    StudioActions["APPLY_PLACEHOLDER_TEXT"] = "APPLY_PLACEHOLDER_TEXT";
    StudioActions["REMOVE_PLACEHOLDER"] = "REMOVE_PLACEHOLDER";
    StudioActions["SHOW_REQUEST_INDICATOR"] = "SHOW_REQUEST_INDICATOR";
    StudioActions["HIDE_REQUEST_INDICATOR"] = "HIDE_REQUEST_INDICATOR";
    StudioActions["CAPTURE_3D_PREVIEW"] = "CAPTURE_3D_PREVIEW";
    StudioActions["SELECT_PLACEHOLDER"] = "SELECT_PLACEHOLDER";
    StudioActions["CLEAR_SELECT_PLACEHOLDER"] = "CLEAR_SELECT_PLACEHOLDER";
    StudioActions["UPLOAD_PATTERN"] = "UPLOAD_PATTERN";
    StudioActions["ADD_PATTERN_IN_CANVAS"] = "ADD_PATTERN_IN_CANVAS";
    StudioActions["DELETE_UPLOAD_PATTERN"] = "DELETE_UPLOAD_PATTERN";
    StudioActions["DELETE_CANVAS_PATTERN"] = "DELETE_CANVAS_PATTERN";
    StudioActions["SELECTED_PATTERN"] = "SELECTED_PATTERN";
    StudioActions["MODIFY_PATTERN_IN_CANVAS"] = "MODIFY_PATTERN_IN_CANVAS";
    StudioActions["LOADING_STATUS"] = "LOADING_STATUS";
    StudioActions["CAPTURE_3D_REQUEST"] = "CAPTURE_3D_REQUEST";
    StudioActions["CAPTURE_3D_ACTION"] = "CAPTURE_3D_ACTION";
    StudioActions["CAPTURE_3D"] = "CAPTURE_3D";
    StudioActions["TOGGLE_GRID_HELPER"] = "TOGGLE_GRID_HELPER";
    StudioActions["HAS_GRID_HELPER"] = "HAS_GRID_HELPER";
    StudioActions["CURRENT_2D_ZOOM"] = "CURRENT_2D_ZOOM";
    StudioActions["SET_QUALITY_VALUE"] = "SET_QUALITY_VALUE";
})(StudioActions || (StudioActions = {}));
