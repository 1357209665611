import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import React, { useState } from "react";
import { useFabricCanvas } from "@brikl/studio";
import { toggleMultipleCapture } from "../../_three/reconciler";
import { createGridImages, loadBase64Process } from "../../_three/utils";
var MultiViewExports;
(function(MultiViewExports) {
    MultiViewExports["FRONT"] = "FRONT";
    MultiViewExports["BACK"] = "BACK";
    MultiViewExports["LEFT"] = "LEFT";
    MultiViewExports["RIGHT"] = "RIGHT";
})(MultiViewExports || (MultiViewExports = {}));
var defaultSides = [
    MultiViewExports.FRONT,
    MultiViewExports.BACK,
    MultiViewExports.LEFT,
    MultiViewExports.RIGHT, 
];
var defaultOptions = {
    shouldCapture: true,
    shouldDownload: false,
    sides: defaultSides,
    is2dOnly: false
};
export var useCapturePreview = function(callback) {
    var options = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : defaultOptions;
    var ref = Object.assign({}, defaultOptions, options), shouldCapture = ref.shouldCapture, shouldDownload = ref.shouldDownload, sides = ref.sides;
    var ref1 = useState(false), isCapture = ref1[0], setCapture = ref1[1];
    var fabricCanvas = useFabricCanvas();
    var onDeActiveFabricObject = function() {
        fabricCanvas === null || fabricCanvas === void 0 ? void 0 : fabricCanvas.discardActiveObject();
        fabricCanvas === null || fabricCanvas === void 0 ? void 0 : fabricCanvas.renderAll();
    };
    var createProductPreviewImageFile = function(dataURL) {
        var buffImage = Buffer.from(dataURL.replace(/^data:image\/\w+;base64,/, ""), "base64");
        var file = new File([
            buffImage
        ], "".concat(new Date().getTime(), "_preview.png"), {
            type: "image/png",
            lastModified: new Date().getTime()
        });
        return file;
    };
    React.useEffect(function() {
        if (!shouldCapture) return;
        if (options.is2dOnly) {
            try {
                var file = createProductPreviewImageFile(fabricCanvas.toDataURL());
                callback(file, null);
            } catch (e) {
                callback(null, e);
            } finally{
                setCapture(false);
            }
        } else {
            setCapture(true);
            toggleMultipleCapture(shouldCapture, sides, null, shouldDownload);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        shouldCapture
    ]);
    /**
   * Listen to capturing event
   */ React.useEffect(function() {
        if (!isCapture) return;
        onDeActiveFabricObject();
        var canvas = document.createElement("canvas");
        var canvasContext = canvas.getContext("2d");
        var handleCapture = function(event) {
            window.removeEventListener("captureEvent", handleCapture, false);
            var captureSets = event.detail;
            var images = captureSets.map(function(captureBase64) {
                return loadBase64Process(captureBase64);
            });
            try {
                Promise.all(images).then(function() {
                    var _ref = _async_to_generator(function(image) {
                        var canvasGrid, dataURL, file;
                        return _ts_generator(this, function(_state) {
                            if (!isCapture) return [
                                2
                            ];
                            canvasGrid = createGridImages(canvas, canvasContext, image);
                            dataURL = canvasGrid.toDataURL("image/png");
                            file = createProductPreviewImageFile(dataURL);
                            callback(file, null);
                            setCapture(false);
                            return [
                                2
                            ];
                        });
                    });
                    return function(image) {
                        return _ref.apply(this, arguments);
                    };
                }());
            } catch (e) {
                callback(null, e);
            }
        };
        window.addEventListener("captureEvent", handleCapture, false);
        return function() {
            return window.removeEventListener("captureEvent", handleCapture, false);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isCapture
    ]);
};
