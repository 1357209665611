import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { createSlice } from "../../@redux";
import { initialState } from "./constants";
export var slice = createSlice({
    name: "template",
    initialState: initialState,
    reducers: {
        resetData: function() {
            return initialState;
        },
        reset: function(state) {
            state.selectedTemplateId = null;
            state.templates = [];
        },
        selectTemplate: function(state, param) {
            var payload = param.payload;
            state.selectedTemplateId = payload;
            state.isTemplateReady = false;
        },
        selectTemplateCompleted: function(state) {
            state.isTemplateReady = true;
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        loadTemplates: function(state, action) {},
        loadTemplatesCompleted: function(state, param) {
            var payload = param.payload;
            state.selectedTemplateId = payload.selectedTemplateId;
            // remove colorZones from template array to avoid confusion
            // because colorZones has been moved to colorFill for appropriate purpose
            state.templates = payload.templates.map(function(template) {
                return _object_spread_props(_object_spread({}, template), {
                    // empty this object to avoid confusion, to make colorZones more sense it will be stored in colorFill engines only
                    colorZones: []
                });
            });
        }
    }
});
export var actions = slice.actions, reducer = slice.reducer, name = slice.name;
