import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import * as THREE from "three";
import { LoadingManager } from "three";
import { fixCDN } from "@brikl/studio-utils";
import { isBrowser } from "../constants";
/**
 * check `PrintPattern` material path
 * @param  {THREE.Material} materialInfo
 * @param  {string} prop
 */ var isNotPrintPatternAndNoUrl = function(materialInfo, prop) {
    return materialInfo[prop] && materialInfo[prop].indexOf("https") === -1 && materialInfo[prop].indexOf("PrintPattern") === -1;
};
/**
 * https://threejs.org/docs/#examples/en/loaders/MTLLoader
 * MTL is material & texture infomation of OBJ
 * We load MTL file here with MTLLoader then it will transform string into MTL format that threejs can understand
 * @param  {string} mtlUrl
 */ export var loadMaterialAsync = function(mtlUrl) {
    return new Promise(function(resolve, reject) {
        var materialResult = null;
        if (!isBrowser) {
            reject();
            return;
        }
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        var MTLLoader = require("three/examples/jsm/loaders/MTLLoader").MTLLoader;
        var loadingManager = new LoadingManager();
        loadingManager.onStart = function(mtlUrl) {
            console.log("loadingManager", "start = ", mtlUrl);
        };
        // in case of loading large texture we need to add loading manager to ensure that texture fully loaded
        loadingManager.onLoad = function() {
            if (!materialResult) {
                console.warn("mtl-loading-manager: cannot load material url");
            }
            console.log("loadingManager", "mtlComplete");
            resolve(materialResult);
        };
        loadingManager.onError = function(error) {
            console.warn("mtl-loading-manager: ", error);
        // resolve(materialResult)
        };
        var mtlLoader = new MTLLoader(loadingManager);
        // mtlLoader.crossOrigin = 'anonymous'
        mtlLoader.load(fixCDN(mtlUrl), function(material) {
            // After load MTL will transform text file to Object in callback
            var properties = [
                "map_bump",
                "map_ka",
                "map_kd",
                "map_kn"
            ] // Name of material & texture url
            ;
            var duo_properties_1 = [
                "map_bump",
                "map_kn"
            ] // Name of material & texture url
            ;
            var duo_properties_2 = [
                "map_ka",
                "map_kd"
            ] // Name of material & texture url
            ;
            var printPattern = [] // Collect print pattern (design template) material
            ;
            var materialUrl = [] // collect material url (1 material can have many properties)
            ;
            // Perform fix cdn for prevent CORS policy (Just update url of texture map).
            // We do update here not in threejs structure becuase after preload the broken url will ignore by threejs and it turn material texture to undefine
            // "material.materialsInfo" is contain raw information of material such as texture location(url), geometry map coordinate, initial color ....
            Object.keys(material.materialsInfo).forEach(function(key) {
                var materialInfo = material.materialsInfo[key];
                properties.forEach(function(prop) {
                    if (materialInfo[prop]) {
                        materialInfo[prop] = fixCDN(materialInfo[prop]);
                        if (materialInfo[prop].indexOf("PrintPattern") !== -1) {
                            // This is 2D design template maping material naming // We use this naming becuase this material will map again with fabricjs (canvas)
                            printPattern.push(key);
                        }
                        if (materialInfo[prop].indexOf("https") !== -1) {
                            // This is other texture file with url
                            materialUrl.push({
                                key: key,
                                url: materialInfo[prop]
                            });
                        }
                    }
                });
            });
            var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
            try {
                // DOUBLE CHECK URL ASSIGN, SOMETIME IT MISSING URL FROM BACKEND MTL
                // 1 material will have 1 texture mapping, so i double check this for prevent threejs reject this texture
                // forexample the material have info of 2 texture property but 1 of them missing url then this will fix that
                for(var _iterator = materialUrl[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                    var info = _step.value;
                    var materialInfo = material.materialsInfo[info.key];
                    var _iteratorNormalCompletion1 = true, _didIteratorError1 = false, _iteratorError1 = undefined;
                    try {
                        for(var _iterator1 = duo_properties_1[Symbol.iterator](), _step1; !(_iteratorNormalCompletion1 = (_step1 = _iterator1.next()).done); _iteratorNormalCompletion1 = true){
                            var prop = _step1.value;
                            if (isNotPrintPatternAndNoUrl(materialInfo, prop)) {
                                materialInfo[prop] = info.url;
                            }
                        }
                    } catch (err) {
                        _didIteratorError1 = true;
                        _iteratorError1 = err;
                    } finally{
                        try {
                            if (!_iteratorNormalCompletion1 && _iterator1.return != null) {
                                _iterator1.return();
                            }
                        } finally{
                            if (_didIteratorError1) {
                                throw _iteratorError1;
                            }
                        }
                    }
                    var _iteratorNormalCompletion2 = true, _didIteratorError2 = false, _iteratorError2 = undefined;
                    try {
                        for(var _iterator2 = duo_properties_2[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true){
                            var prop1 = _step2.value;
                            if (isNotPrintPatternAndNoUrl(materialInfo, prop1)) {
                                materialInfo[prop1] = info.url;
                            }
                        }
                    } catch (err) {
                        _didIteratorError2 = true;
                        _iteratorError2 = err;
                    } finally{
                        try {
                            if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
                                _iterator2.return();
                            }
                        } finally{
                            if (_didIteratorError2) {
                                throw _iteratorError2;
                            }
                        }
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally{
                try {
                    if (!_iteratorNormalCompletion && _iterator.return != null) {
                        _iterator.return();
                    }
                } finally{
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }
            // This is MTL compiler, It gonna take "material.materialsInfo" then compile them to threejs material (mesh)
            // After compile it will save into "material.materials"
            material.preload();
            // I setup this for use in "_three/util/updateObjectMaterialsWithCanvas"
            // Because only this material need to maping texture with fabricjs (canvas)
            printPattern.forEach(function(key) {
                material.materials[key].userData.PrintPattern = true;
            });
            // Alert when model missing "PrintPattern" prefix because without this prefix will make model invisible
            // Sometime they add wrong or missing prefix name, so this can notice user when upload obj & mtl
            if (printPattern.length <= 0) {
                alert('MTL format missing "PrintPattern" prefix, Please contact administrator.');
            }
            materialResult = material;
        }, null, function(error) {
            return resolve(error);
        });
    });
};
/**
 * load obj url with material
 * @param  {string} objectStringContent
 * @param  {THREE.Material[]} materials
 */ var loadObjAsync = function(objectStringContent, materials) {
    return new Promise(function(resolve, reject) {
        if (isBrowser) {
            // https://threejs.org/docs/#examples/en/loaders/OBJLoader
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            var OBJLoader = require("three/examples/jsm/loaders/OBJLoader").OBJLoader;
            var objLoader = new OBJLoader(new LoadingManager());
            objLoader.crossOrigin = "anonymous";
            objLoader.setMaterials(materials);
            var obj = objLoader.parse(objectStringContent) // Parse obj string
            ;
            resolve(obj);
        } else {
            reject();
        }
    });
};
/**
 * main function to load obj and mtl file url
 */ export var create3DLoader = function() {
    return {
        load: function() {
            var _ref = _async_to_generator(function(param) {
                var mtlUrl, objUrl, ref, materials, objectStringContent, object, _tmp, err;
                return _ts_generator(this, function(_state) {
                    switch(_state.label){
                        case 0:
                            mtlUrl = param.mtlUrl, objUrl = param.objUrl;
                            _state.label = 1;
                        case 1:
                            _state.trys.push([
                                1,
                                4,
                                ,
                                5
                            ]);
                            return [
                                4,
                                Promise.all([
                                    loadMaterialAsync(mtlUrl),
                                    loadObjToStringAsync(objUrl)
                                ])
                            ];
                        case 2:
                            ref = _sliced_to_array.apply(void 0, [
                                _state.sent(),
                                2
                            ]), materials = ref[0], objectStringContent = ref[1];
                            return [
                                4,
                                loadObjAsync(objectStringContent, materials)
                            ];
                        case 3:
                            object = _state.sent();
                            object.traverse(function(object) {
                                if (_instanceof(object, THREE.Mesh)) {
                                    object.castShadow = true;
                                    object.receiveShadow = true;
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    if (object.material.map) object.material.map.anisotropy = 16;
                                }
                            });
                            _tmp = {};
                            return [
                                2,
                                (_tmp.materials = materials, _tmp.object = object, _tmp)
                            ];
                        case 4:
                            err = _state.sent();
                            console.log("Error on loading 3d", err);
                            return [
                                3,
                                5
                            ];
                        case 5:
                            return [
                                2
                            ];
                    }
                });
            });
            return function(_) {
                return _ref.apply(this, arguments);
            };
        }()
    };
};
/**
 * Load OBJ with string return for customize purpose
 * We discover that obj format from Blender have some auto element call "Line Segment" which is break geometry processing
 * So This function will remove uneccesary string from obj file
 * @param  {string} objUrl
 */ var loadObjToStringAsync = function(objUrl) {
    return new Promise(function(resolve, reject) {
        if (isBrowser) {
            var req = new XMLHttpRequest();
            var lineSegmentPattern = /^l\s.*/gm;
            req.onload = function() {
                var response = req.responseText;
                var checkLineSegment = response.match(lineSegmentPattern);
                console.log("Line Segment Detected !!", checkLineSegment);
                var cleanLineSegment = response.replace(lineSegmentPattern, "");
                resolve(cleanLineSegment);
            };
            req.open("GET", fixCDN(objUrl));
            req.send(null);
        } else {
            reject();
        }
    });
};
