import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";
import { getCanvasColorZoneId } from "@brikl/studio";
function getColorZoneId(svgIds) {
    return getCanvasColorZoneId(first(svgIds));
}
export function removeDuplicateColorZones(colorZones) {
    var colorzoneIds = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : [];
    return uniqBy(colorZones, function(zone) {
        return getColorZoneId(zone.svgIds);
    }).filter(function(zone) {
        // in case of array of color zone ids empty, we don't need to filter it
        if (isEmpty(colorzoneIds)) return true;
        return colorzoneIds.includes(getColorZoneId(zone.svgIds));
    });
}
