import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _object_without_properties from "@swc/helpers/src/_object_without_properties.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import flatMap from "lodash/flatMap";
import isEmpty from "lodash/isEmpty";
import last from "lodash/last";
import { isImageCustomizationMode } from "@brikl/studio-utils";
import { fixCDN } from "~/libs/studio-utils/src/CDNHelper";
export var svgObjectVisualizePlacementProperties = {
    hasBorders: false,
    hasRotatingPoint: false,
    hasControls: false,
    perPixelTargetFind: true,
    lockMovementX: true,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    lockUniScaling: true,
    lockRotation: true,
    evented: false
};
export var loadSVGFromURL = function() {
    var _ref = _async_to_generator(function(url) {
        return _ts_generator(this, function(_state) {
            return [
                2,
                new Promise(function(resolve, reject) {
                    if (!url) reject();
                    var originalMatch = String.prototype.match;
                    // fabric.getCSSRule string match function doesn't have null check which throw error
                    // eslint-disable-next-line no-extend-native
                    String.prototype.match = function(regExp) {
                        return originalMatch.call(this, regExp) || [];
                    };
                    fabric.loadSVGFromURL(url, function(svgObjects, options) {
                        // eslint-disable-next-line no-extend-native
                        String.prototype.match = originalMatch;
                        if (!options) {
                            return reject("Failed to load svg  ".concat(url));
                        }
                        resolve({
                            svgObjects: svgObjects,
                            svgDimensions: {
                                width: options.width,
                                height: options.height
                            },
                            svgOption: options
                        });
                    });
                })
            ];
        });
    });
    return function loadSVGFromURL(url) {
        return _ref.apply(this, arguments);
    };
}();
export var loadImageFromURL = function() {
    var _ref = _async_to_generator(function(url) {
        return _ts_generator(this, function(_state) {
            return [
                2,
                new Promise(function(resolve) {
                    fabric.Image.fromURL(url, function(image) {
                        return resolve(image);
                    }, {
                        crossOrigin: "anonymous"
                    });
                })
            ];
        });
    });
    return function loadImageFromURL(url) {
        return _ref.apply(this, arguments);
    };
}();
export var convertSvgToObject = function(url, option) {
    return new Promise(function(resolve) {
        fabric.loadSVGFromURL(url, function(svgObjects, options) {
            if (option && option === "UNGROUP") {
                resolve(svgObjects);
            } else {
                var svgObjectGroup = fabric.util.groupSVGElements(svgObjects, options);
                resolve(svgObjectGroup);
            }
        });
    });
};
export var downloadableFromLink = function() {
    var _ref = _async_to_generator(function(url, fileName) {
        var response, blob, blobUrl, a;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        fetch(url)
                    ];
                case 1:
                    response = _state.sent();
                    return [
                        4,
                        response.blob()
                    ];
                case 2:
                    blob = _state.sent();
                    blobUrl = window.URL.createObjectURL(blob);
                    a = document.createElement("a");
                    a.href = blobUrl;
                    a.download = fileName;
                    document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
                    ;
                    a.click();
                    a.remove();
                    return [
                        2
                    ];
            }
        });
    });
    return function downloadableFromLink(url, fileName) {
        return _ref.apply(this, arguments);
    };
}();
export var convertSvgToBase64 = function(url) {
    return new Promise(function(resolve) {
        fabric.loadSVGFromURL(url, function(svgObjects, options) {
            var svgObjectGroup = fabric.util.groupSVGElements(svgObjects, options);
            var base64Png = svgObjectGroup.toDataURL({
                format: "png"
            });
            resolve({
                base64: base64Png
            });
        });
    });
};
export var convertImageToBase64 = function(url) {
    return new Promise(function(resolve) {
        fabric.Image.fromURL(url, function(image) {
            var base64 = image.toDataURL({
                format: "png"
            });
            fabric.Image.fromURL(base64, function(image) {
                return resolve(image);
            }, {
                crossOrigin: "anonymous"
            });
        }, {
            crossOrigin: "anonymous"
        });
    });
};
export var convertImageUrlToBase64 = function(url, option) {
    return new Promise(function(resolve) {
        fabric.Image.fromURL(url, function(image) {
            var base64 = image.toDataURL({
                format: "png"
            });
            if (option && option === "INCLUDE_DIMENSION") {
                resolve({
                    base64: base64,
                    width: image.getScaledWidth(),
                    height: image.getScaledHeight()
                });
            }
            resolve(base64);
        }, {
            crossOrigin: "anonymous"
        });
    });
};
export var convertImageUrlToFabricImage = function(url) {
    return new Promise(function(resolve) {
        fabric.Image.fromURL(url, function(image) {
            resolve(image);
        }, {
            crossOrigin: "anonymous"
        });
    });
};
export var convertImageUrlToImageElement = function(url) {
    return new Promise(function(resolve) {
        var image = new Image();
        image.crossOrigin = "anonymous";
        image.onload = function() {
            resolve(image);
        };
        image.src = fixCDN(url);
    });
};
export function handle2DZoom(param) {
    var fabricRef = param.fabricRef, zoomFactor = param.zoomFactor, current2DZoom = param.current2DZoom, width = param.width, height = param.height;
    var zoom = fabricRef.getZoom();
    if (current2DZoom) {
        zoom = current2DZoom;
    }
    // RETURN BASE DIMENSION
    var baseDimension = {
        width: fabricRef.getWidth() / zoom,
        height: fabricRef.getHeight() / zoom
    };
    if (zoomFactor === "+") {
        // ZOMM IN
        zoom += 0.01;
    } else if (zoomFactor === "-") {
        // ZOOM OUT
        zoom -= 0.01;
    } else if (zoomFactor === "fit-width" && width) {
        // Fit 2D design to width of screen canvas size (some template too hight)
        zoom = width / baseDimension.width;
    } else if (zoomFactor === "fit-height" && height) {
        // Fit 2D design to height of screen canvas size (some template too width)
        zoom = height / baseDimension.height;
    }
    fabricRef.setDimensions({
        width: baseDimension.width * zoom,
        height: baseDimension.height * zoom
    });
    console.log("zoom level", zoom);
    fabricRef.setZoom(zoom);
    fabricRef.requestRenderAll();
    adjustIconSize(zoom);
    return zoom;
}
export function adjustIconSize(zoom) {
    var settings = {
        cornerSize: 60 * zoom,
        cornerPadding: 30 * zoom
    };
    var isCustomImage = isImageCustomizationMode();
    if (isCustomImage) {
        settings["cornerSize"] = 50 * zoom;
        settings["cornerPadding"] = 30 * zoom;
    }
    // This use for adjust cornor icon size to matching what 2D zoom
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    fabric.Object.prototype.customizeCornerIcons({
        settings: settings
    });
}
export var listSvgDimensionQuality = [
    {
        quality: "HIGHEST",
        value: 10000
    },
    {
        quality: "HIGH",
        value: 7000
    },
    {
        quality: "MEDIUM",
        value: 4000
    },
    {
        quality: "LOW",
        value: 2500
    }, 
];
export function getQualityList(fabricRef) {
    if (!fabricRef) return;
    var currentZoom = fabricRef.getZoom();
    var baseDimension = {
        width: fabricRef.getWidth() / currentZoom,
        height: fabricRef.getHeight() / currentZoom
    };
    var largerDimension = baseDimension.width > baseDimension.height ? baseDimension.width : baseDimension.height;
    var qualityList = listSvgDimensionQuality.filter(function(quality) {
        return quality.value < largerDimension;
    });
    return qualityList || [
        last(listSvgDimensionQuality)
    ] // some products have less than 2500px, it should be show lowest dimension
    ;
}
// Some template dimension too big, it slow down overall performance
// But we can tricky by adjust zoom factor to control canvas dimension limitation
export function getZoomForBetterPerformance(svgDimensions, optionType) {
    var largerDimension = svgDimensions.width > svgDimensions.height ? svgDimensions.width : svgDimensions.height;
    var qualityByPixel = 5000 // Standard quality
    ;
    var zoomLevel = 1;
    if (optionType) {
        if (optionType === "ORIGINAL") {
            qualityByPixel = largerDimension;
        } else {
            var ref;
            qualityByPixel = (ref = listSvgDimensionQuality.find(function(param) {
                var quality = param.quality;
                return quality === optionType;
            })) === null || ref === void 0 ? void 0 : ref.value;
        }
    }
    if (largerDimension > qualityByPixel) zoomLevel = qualityByPixel / largerDimension;
    console.log("getZoomForBetterPerformance", parseFloat(zoomLevel.toFixed(1)));
    return parseFloat(zoomLevel.toFixed(1));
}
export function setQualityByZoomLevel(param) {
    var zoomLevel = param.zoomLevel, fabricCanvas = param.fabricCanvas;
    console.trace("setQualityByZoomLevel", zoomLevel);
    var currentZoom = fabricCanvas.getZoom();
    var baseDimension = {
        width: fabricCanvas.getWidth() / currentZoom,
        height: fabricCanvas.getHeight() / currentZoom
    };
    fabricCanvas.zoomLevel = zoomLevel;
    fabricCanvas.setZoom(zoomLevel) // return global zoom level
    ;
    fabricCanvas.setDimensions({
        width: baseDimension.width * zoomLevel,
        height: baseDimension.height * zoomLevel
    });
    adjustIconSize(zoomLevel);
    fabricCanvas.requestRenderAll();
}
export function setQualityControl(param) {
    var fabricCanvas = param.fabricCanvas, option = param.option;
    var currentZoom = fabricCanvas.getZoom();
    var baseDimension = {
        width: fabricCanvas.getWidth() / currentZoom,
        height: fabricCanvas.getHeight() / currentZoom
    };
    var zoomLevel = getZoomForBetterPerformance(baseDimension, option);
    setQualityByZoomLevel({
        zoomLevel: zoomLevel,
        fabricCanvas: fabricCanvas
    });
}
// Toogle stroke of placeholder area when user using personalization menu
export function togglePlaceholderArea(param) {
    var selectedPlaceholderSvgId = param.selectedPlaceholderSvgId, outlineColor = param.outlineColor, fabricCanvas = param.fabricCanvas;
    if (!fabricCanvas) return;
    var svgObjects = fabricCanvas === null || fabricCanvas === void 0 ? void 0 : fabricCanvas.getObjects();
    svgObjects === null || svgObjects === void 0 ? void 0 : svgObjects.forEach(function(phCanvas) {
        var ref;
        // Toogle stroke of placeholder to 0 (invisible stroke)
        // Only PLACEHOLDER type will perform this feature, so we specific only this once, because another element might have stroke as well
        if ((phCanvas === null || phCanvas === void 0 ? void 0 : (ref = phCanvas.data) === null || ref === void 0 ? void 0 : ref.type) === "PLACEHOLDER") phCanvas.set({
            strokeWidth: 0
        });
        // Active current placeholder stroke
        if (selectedPlaceholderSvgId && phCanvas.id === selectedPlaceholderSvgId) {
            phCanvas.set({
                strokeWidth: 1,
                stroke: outlineColor
            });
        }
    });
    fabricCanvas.requestRenderAll();
}
export function getDPI(param) {
    var fabricCanvas = param.fabricCanvas, pxToCmDimension = param.pxToCmDimension;
    if (fabricCanvas) {
        var ref, ref1;
        var activeObject = fabricCanvas.getActiveObject();
        // console.log('getDPI', pxToCmDimension, activeObject)
        if ((activeObject === null || activeObject === void 0 ? void 0 : (ref = activeObject.data) === null || ref === void 0 ? void 0 : ref.type) === "Text" || (activeObject === null || activeObject === void 0 ? void 0 : (ref1 = activeObject.data) === null || ref1 === void 0 ? void 0 : ref1.type) === "Image") {
            var ref2;
            var DPI_WIDTH = null;
            var DPI_HEIGHT = null;
            if ((activeObject === null || activeObject === void 0 ? void 0 : (ref2 = activeObject.data) === null || ref2 === void 0 ? void 0 : ref2.type) === "Image") {
                // DPI CALCULATION ONLY IMAGE
                var approxWidth = activeObject.getScaledWidth() * pxToCmDimension;
                var approxHeight = activeObject.getScaledHeight() * pxToCmDimension;
                DPI_WIDTH = activeObject.width / (approxWidth / 2.54);
                DPI_HEIGHT = activeObject.height / (approxHeight / 2.54);
            }
            var dimensionInfo = {
                x: activeObject._getLeftTopCoords().x,
                y: activeObject._getLeftTopCoords().y,
                width: activeObject.getScaledWidth() * pxToCmDimension,
                height: activeObject.getScaledHeight() * pxToCmDimension,
                angle: activeObject.angle,
                pxToCmDimension: pxToCmDimension,
                pxWidth: activeObject.getScaledWidth(),
                pxHeight: activeObject.getScaledHeight(),
                originalWidth: activeObject.width,
                originalHeight: activeObject.height,
                DPI_WIDTH: DPI_WIDTH,
                DPI_HEIGHT: DPI_HEIGHT
            };
            return dimensionInfo;
        }
        return null;
    }
}
export var placeholderAction;
(function(placeholderAction) {
    placeholderAction["modified"] = "modified";
    placeholderAction["scaling"] = "scaling";
    placeholderAction["moving"] = "moving";
    placeholderAction["rotating"] = "rotating";
})(placeholderAction || (placeholderAction = {}));
function getCanvasObjectById(param) {
    var id = param.id, fabricCanvas = param.fabricCanvas;
    var svgObjects = fabricCanvas === null || fabricCanvas === void 0 ? void 0 : fabricCanvas.getObjects();
    return svgObjects === null || svgObjects === void 0 ? void 0 : svgObjects.find(function(obj) {
        return obj.id === id;
    });
}
export function placeholderLimitAreaCheck(param) {
    var object = param.object, action = param.action, fabricCanvas = param.fabricCanvas;
    var ref;
    if (object === null || object === void 0 ? void 0 : (ref = object.data) === null || ref === void 0 ? void 0 : ref.placeholderSvgId) {
        // get current placeholder
        var placeholderObject = getCanvasObjectById({
            id: object.data.placeholderSvgId,
            fabricCanvas: fabricCanvas
        });
        if (placeholderObject) {
            var placeholderCoord = placeholderObject.getBoundingRect();
            var lastedObjectTransform = {
                left: object.left,
                top: object.top,
                scale: object.scaleX
            };
            if (action === "moving" || action === "scaling" || action === "modified") {
                object.setCoords();
                var isInBoundingBox = constrainObjectInPlaceholder(object, placeholderObject, lastedObjectTransform);
                if (!isInBoundingBox) return;
            }
            var objectCoord = object.getBoundingRect();
            if (action === "scaling" || action === "modified") {
                object.setCoords();
                if (objectCoord.width > placeholderCoord.width) {
                    object.scaleToWidth(placeholderCoord.width);
                    constrainObjectInPlaceholder(object, placeholderObject, lastedObjectTransform);
                }
                if (objectCoord.height > placeholderCoord.height) {
                    object.scaleToHeight(placeholderCoord.height);
                    constrainObjectInPlaceholder(object, placeholderObject, lastedObjectTransform);
                }
            }
            fabricCanvas.renderAll();
        }
    }
}
var constrainObjectInPlaceholder = function(object, placeholderObject, lastedObjectTransform) {
    var objectCoord = object.getBoundingRect();
    var placeholderCoord = placeholderObject.getBoundingRect();
    // CHECK IF OBJECT STILL IN PLACEHOLDER OR NOT
    // RECORD LASTED COORD
    var isContainedWithinObject = object.isContainedWithinObject(placeholderObject);
    if (!isContainedWithinObject) {
        var ref;
        if ((object === null || object === void 0 ? void 0 : (ref = object.data) === null || ref === void 0 ? void 0 : ref.lastedObjectTransform) || lastedObjectTransform) {
            var ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8;
            // SET LASTED COORD IF IT OUTSIDE PLACEHOLDER
            object.left = (object === null || object === void 0 ? void 0 : (ref1 = object.data) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.lastedObjectTransform) === null || ref2 === void 0 ? void 0 : ref2.left) || lastedObjectTransform.left;
            object.top = (object === null || object === void 0 ? void 0 : (ref3 = object.data) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.lastedObjectTransform) === null || ref4 === void 0 ? void 0 : ref4.top) || lastedObjectTransform.top;
            object.scaleX = (object === null || object === void 0 ? void 0 : (ref5 = object.data) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.lastedObjectTransform) === null || ref6 === void 0 ? void 0 : ref6.scale) || lastedObjectTransform.scale;
            object.scaleY = (object === null || object === void 0 ? void 0 : (ref7 = object.data) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.lastedObjectTransform) === null || ref8 === void 0 ? void 0 : ref8.scale) || lastedObjectTransform.scale;
            object.setCoords();
            return false;
        }
    } else {
        object.data = _object_spread_props(_object_spread({}, object.data), {
            lastedObjectTransform: lastedObjectTransform
        });
    }
    // CHECK IF OBJECT STILL IN PLACEHOLDER OR NOT
    // RECORD LASTED COORD
    object.setCoords();
    var widthOffset = objectCoord.width / 2;
    var heightOffset = objectCoord.height / 2;
    if (objectCoord.left < placeholderCoord.left) {
        object.left = placeholderCoord.left + widthOffset;
    } else if (objectCoord.left + objectCoord.width > placeholderCoord.left + placeholderCoord.width) {
        object.left = placeholderCoord.left + placeholderCoord.width - widthOffset;
    }
    if (objectCoord.top < placeholderCoord.top) {
        object.top = placeholderCoord.top + heightOffset;
    } else if (objectCoord.top + objectCoord.height > placeholderCoord.top + placeholderCoord.height) {
        object.top = placeholderCoord.top + placeholderCoord.height - heightOffset;
    }
    object.setCoords();
    return true;
};
export function checkObjectDuplicate(param) {
    var fabricCanvas = param.fabricCanvas, object = param.object;
    var objects = fabricCanvas.getObjects();
    var checkDup = objects.find(function(obj) {
        var ref, ref1;
        return (obj === null || obj === void 0 ? void 0 : (ref = obj.data) === null || ref === void 0 ? void 0 : ref.id) === (object === null || object === void 0 ? void 0 : (ref1 = object.data) === null || ref1 === void 0 ? void 0 : ref1.id);
    });
    return checkDup;
}
export function rotateSnap(param) {
    var selectedObjectId = param.selectedObjectId, fabricCanvas = param.fabricCanvas;
    var objects = fabricCanvas.getObjects();
    var object = objects.find(function(obj) {
        var ref;
        return (obj === null || obj === void 0 ? void 0 : (ref = obj.data) === null || ref === void 0 ? void 0 : ref.id) === selectedObjectId;
    });
    var snapAngle = getRightAngle(object);
    object.set({
        angle: snapAngle
    });
    fabricRequestRender(fabricCanvas);
}
export function getRightAngle(object) {
    return (object.angle + 90) % 360;
}
export function centerInPlaceholder(param) {
    var fabricCanvas = param.fabricCanvas, selectedObjectId = param.selectedObjectId;
    var objects = fabricCanvas.getObjects();
    var object = objects.find(function(obj) {
        var ref;
        return (obj === null || obj === void 0 ? void 0 : (ref = obj.data) === null || ref === void 0 ? void 0 : ref.id) === selectedObjectId;
    });
    var placeholderObject = objects.find(function(obj) {
        var ref;
        return (obj === null || obj === void 0 ? void 0 : obj.id) === (object === null || object === void 0 ? void 0 : (ref = object.data) === null || ref === void 0 ? void 0 : ref.placeholderSvgId);
    });
    if (object && placeholderObject) {
        object.set({
            left: placeholderObject.getCenterPoint().x,
            top: placeholderObject.getCenterPoint().y
        });
        object.setCoords();
        fabricRequestRender(fabricCanvas);
    }
}
/** Check if the fabric object can be layered on other elements. */ export var isFabricObjectLayerable = function(obj) {
    var ref;
    if (!(obj === null || obj === void 0 ? void 0 : (ref = obj.data) === null || ref === void 0 ? void 0 : ref.type)) return false;
    return [
        "Text",
        "Image",
        "Pattern"
    ].includes(obj.data.type);
};
/** Get the fabric objects that can be layered on other elements. */ export function getLayerableFabricObjects(fabricCanvas) {
    if (!fabricCanvas) return [];
    return fabricCanvas.getObjects().filter(isFabricObjectLayerable);
}
/** Re-generate the order sequences when we bring forward, send backward & add new objects. */ export function generateOrderSEQ(fabricCanvas) {
    getLayerableFabricObjects(fabricCanvas).forEach(function(obj, idx) {
        return obj.data.orderSEQ = idx;
    });
    console.log("[Layer] Re-generating Order Sequences.");
}
// GET PIXEL XY THAT NOT TRANSPARENT PIXEL
export function getPixelCoord(imageData, canvasWidth) {
    var collectXY = [];
    for(var i = 0; i < imageData.data.length; i += 4){
        var transparent = imageData.data[i + 3];
        var x = i / 4 % canvasWidth;
        var y = Math.floor(i / 4 / canvasWidth);
        if (transparent !== 0) {
            collectXY.push({
                x: x,
                y: y
            });
        }
    }
    return collectXY;
}
// FIND MIN MIX OF X & Y AND WIDTH HEIGHT FROM MIN MAX COORD
export function getMinMaxPixelCoord(collectXY) {
    var ref, ref1, ref2, ref3;
    var ref4, ref5, ref6, ref7;
    // sometimes on mobile devices cannot get first element of pixel when click add to cart
    var minMaxCoord = {
        maxX: (ref4 = (ref = collectXY[0]) === null || ref === void 0 ? void 0 : ref.x) !== null && ref4 !== void 0 ? ref4 : 0,
        minX: (ref5 = (ref1 = collectXY[0]) === null || ref1 === void 0 ? void 0 : ref1.x) !== null && ref5 !== void 0 ? ref5 : 0,
        maxY: (ref6 = (ref2 = collectXY[0]) === null || ref2 === void 0 ? void 0 : ref2.y) !== null && ref6 !== void 0 ? ref6 : 0,
        minY: (ref7 = (ref3 = collectXY[0]) === null || ref3 === void 0 ? void 0 : ref3.y) !== null && ref7 !== void 0 ? ref7 : 0,
        width: 0,
        height: 0
    };
    for(var i = 0; i < collectXY.length; i++){
        if (collectXY[i].x > minMaxCoord.maxX) {
            minMaxCoord.maxX = collectXY[i].x;
        }
        if (collectXY[i].x < minMaxCoord.minX) {
            minMaxCoord.minX = collectXY[i].x;
        }
        if (collectXY[i].y > minMaxCoord.maxY) {
            minMaxCoord.maxY = collectXY[i].y;
        }
        if (collectXY[i].y < minMaxCoord.minY) {
            minMaxCoord.minY = collectXY[i].y;
        }
    }
    minMaxCoord.width = minMaxCoord.maxX - minMaxCoord.minX;
    minMaxCoord.height = minMaxCoord.maxY - minMaxCoord.minY;
    return minMaxCoord;
}
export function fabricRequestRender(fabricCanvas, option) {
    try {
        if (!fabricCanvas) {
            return;
        }
        if (option && option === "renderAll") {
            fabricCanvas.renderAll() // immediately render call, this mosltly use for render object in scene and need some data after render object. (Such as coordinate)
            ;
        } else {
            fabricCanvas.requestRenderAll() // More performance and reduce laging because this will render according with FPS (Frame per second) with user machine.
            ;
        }
    } catch (error) {
        console.error("_fabric.util.fabricRequestRender.error", error);
    }
}
/** Debug function: logs the object's layering information */ export function debugLogObjectsLayer(objects) {
    var layers = objects.filter(function(o) {
        return !o.data;
    }).map(function(o) {
        var ref, ref1, ref2;
        var _data = o.data, type = _data.type, orderSEQ = _data.orderSEQ, id = _data.id, base64 = _data.base64;
        var meta = id;
        if (type === "Image") meta = base64;
        if (type === "Text") meta = (ref = o) === null || ref === void 0 ? void 0 : ref.text;
        if (type === "Pattern") meta = (ref1 = o) === null || ref1 === void 0 ? void 0 : (ref2 = ref1._element) === null || ref2 === void 0 ? void 0 : ref2.currentSrc;
        return "(".concat(type, ", ").concat(orderSEQ, ", ").concat(meta, ")");
    });
    console.log("[Layer] layer data:\n" + layers.join("\n"));
}
export function mapColorCMYK(allColors, id, hex) {
    if (!allColors) return;
    var ref;
    var listCmykColors = (ref = flatMap(allColors.map(function(color) {
        return color.node.colors;
    }))) !== null && ref !== void 0 ? ref : [];
    var cmykColorById = listCmykColors.find(function(color) {
        return color.id === id;
    });
    if (cmykColorById) return cmykColorById;
    var cmykColorByHex = listCmykColors.find(function(color) {
        return color.hex === hex;
    });
    return cmykColorByHex;
}
export function mapSvgElementWithCMYK(fabricImage, fabrics) {
    // THIS MODULE WILL LOOP THROUHT SVG OBJECT (CHILD) THEN COMPARE HEX WITH COLOR LIBRARY AND ATTACH CMYK + COLOR INFO
    var assignCMYK = function(object) {
        var hex = object.fill;
        if (!hex) return;
        var colorInfo = mapColorCMYK(fabrics, null, hex);
        if (!colorInfo) return;
        object.set({
            data: _object_spread_props(_object_spread({}, object.data), {
                CMYK: colorInfo === null || colorInfo === void 0 ? void 0 : colorInfo.CMYK,
                colorInfo: colorInfo
            })
        });
    };
    if (fabricImage.getObjects) {
        fabricImage.getObjects().forEach(function(object) {
            assignCMYK(object);
        });
    } else {
        assignCMYK(fabricImage);
    }
}
export function initAssignFilterColorImage(svgObject) {
    // If colorzone is images type, then this function will call 1 time when first loaded to apply cache of filter image color
    try {
        svgObject.filters = [];
        svgObject.filters.push(new fabric.Image.filters.BlendColor({
            color: "#fff",
            mode: "tint",
            alpha: 0
        }));
        svgObject.applyFilters();
    } catch (error) {
        console.log("error.initAssignFilterColorImage", error, svgObject);
    }
}
export function getObjectsByType(param) {
    var type = param.type, fabricCanvas = param.fabricCanvas;
    if (!fabricCanvas) return [];
    var objects = fabricCanvas.getObjects().filter(function(obj) {
        var ref;
        return ((ref = obj.data) === null || ref === void 0 ? void 0 : ref.type) === type;
    });
    return objects;
}
export function addImageToCanvas(_param) {
    var image = _param.image, xAxis = _param.xAxis, yAxis = _param.yAxis, height = _param.height, width = _param.width, rest = _object_without_properties(_param, [
        "image",
        "xAxis",
        "yAxis",
        "height",
        "width"
    ]);
    var canvasToWork = "canvas" in rest ? rest.canvas : document.createElement("canvas");
    if (!("canvas" in rest)) {
        canvasToWork.height = rest.canvasHeight;
        canvasToWork.width = rest.canvasWidth;
    }
    var ctx = canvasToWork.getContext("2d");
    if (height && width) {
        ctx.drawImage(image, xAxis, yAxis, width, height);
        return canvasToWork;
    }
    ctx.drawImage(image, xAxis, yAxis);
    return canvasToWork;
}
export function getBlobFromCanvas(canvas) {
    return new Promise(function(resolve) {
        canvas.toBlob(function(blob) {
            resolve(blob);
        }, "image/png");
    });
}
export function getOriginalSvgElement() {
    return document.querySelector("#originalSVG");
}
export function getBaseSvgElement() {
    return document.querySelector("#baseSVG");
}
// DEBUGING ONLY
export function debugDot(fabricCanvas) {
    if (!fabricCanvas) return [];
    var dot = new fabric.Rect({
        name: "DOT",
        width: 10,
        height: 10,
        fill: "#f00"
    });
    fabricCanvas.add(dot);
}
export function updateDot(param) {
    var x = param.x, y = param.y, fabricCanvas = param.fabricCanvas;
    if (!fabricCanvas) return [];
    var dot = fabricCanvas.getObjects().find(function(obj) {
        return obj.name === "DOT";
    });
    dot.set({
        left: x,
        top: y
    });
    console.log("DOT", x, y);
    fabricCanvas.requestRenderAll();
}
export var getFilename = function(param) {
    var customNameOption = param.customNameOption, defaultName = param.defaultName, fileType = param.fileType, _variant = param.variant, variant = _variant === void 0 ? "" : _variant;
    var hasCustomName = !isEmpty(customNameOption);
    var variantName = variant !== "" ? "_".concat(variant) : "";
    var customName = hasCustomName ? "_".concat(customNameOption) : "";
    if (hasCustomName) {
        return "".concat(defaultName).concat(variantName).concat(customName, ".").concat(fileType);
    }
    return "".concat(defaultName).concat(variantName, ".").concat(fileType);
};
