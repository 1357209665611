import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { createSlice } from "../../@redux";
// Normal image actions
import { initialState } from "./constants";
import * as deleteImageActions from "./slices/delete-image.actions";
import * as loadImageActions from "./slices/load-image.actions";
import * as placeImageActions from "./slices/place-image.actions";
import * as updateImageActions from "./slices/update-image.actions";
import * as uploadImageActions from "./slices/upload-image.actions";
// Image engine actions
export var slice = createSlice({
    name: "image",
    initialState: initialState,
    reducers: _object_spread({
        resetData: function() {
            return initialState;
        }
    }, uploadImageActions, placeImageActions, deleteImageActions, loadImageActions, updateImageActions)
});
export var actions = slice.actions, reducer = slice.reducer, name = slice.name;
