import { isImageCustomizationMode } from "@brikl/studio-utils";
import { addControlsExtensionToFabricModule } from "./UNSAFE_controls";
export var initCustomControlsExtension = function(param) {
    var _brandColor = param.brandColor, brandColor = _brandColor === void 0 ? "black" : _brandColor;
    // to prevent duplicate add control
    // @ts-ignore
    if (fabric.Canvas.prototype.customiseControls) return;
    // enable to customiseControls function in fabtric
    if ("object" !== "undefined") {
        addControlsExtensionToFabricModule(fabric);
    }
    // @ts-ignore
    // fabric.Object.prototype.setControlsVisibility({
    //   mtr: false,
    //   ml: false,
    //   mr: false,
    //   mt: true,
    //   mb: true,
    //   tr: true,
    //   tl: true,
    //   br: true,
    //   bl: true
    // })
    // @ts-ignore
    fabric.Canvas.prototype.customiseControls({
        tl: {
            action: "rotate",
            cursor: "pointer"
        },
        tr: {
            action: "scale"
        },
        bl: {
            action: "remove",
            cursor: "pointer"
        },
        br: {
            action: function action(e, target) {
                target.lockMovementX = !target.lockMovementX;
                target.lockMovementY = !target.lockMovementY;
                target.lockScalingX = !target.lockScalingX;
                target.lockScalingY = !target.lockScalingY;
                target.lockUniScaling = !target.lockUniScaling;
                target.lockRotation = !target.lockRotation;
            },
            cursor: "pointer"
        },
        mt: {
            action: "moveUp",
            cursor: "pointer"
        },
        mb: {
            action: "moveDown",
            cursor: "pointer"
        }
    }, function() {
    // renderAll
    });
    var isCustomProductImage = isImageCustomizationMode();
    var cornerSize = isCustomProductImage ? 20 : 60;
    var cornerPadding = isCustomProductImage ? 10 : 30;
    // @ts-ignore
    fabric.Object.prototype.customizeCornerIcons({
        settings: {
            borderColor: brandColor,
            cornerSize: cornerSize,
            cornerShape: "rect",
            cornerBackgroundColor: brandColor,
            cornerPadding: cornerPadding
        },
        tl: {
            icon: "https://dev.cdn.mybrikl.com/common/fabricjs/rotate.svg"
        },
        tr: {
            icon: "https://dev.cdn.mybrikl.com/common/fabricjs/resize.svg"
        },
        bl: {
            icon: "https://dev.cdn.mybrikl.com/common/fabricjs/remove.svg"
        },
        br: {
            icon: "https://dev.cdn.mybrikl.com/common/fabricjs/lock.svg"
        },
        mt: {
            icon: "https://dev.cdn.mybrikl.com/common/fabricjs/up.svg"
        },
        mb: {
            icon: "https://dev.cdn.mybrikl.com/common/fabricjs/down.svg"
        }
    }, function() {
    // renderAll
    });
};
