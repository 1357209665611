import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { createSlice } from "../../@redux";
import { initialState } from "./constants";
import { updateAop, updateAopTransform } from "./slices/apply-aop-transform.actions";
import { deleteAop, focusAop, selectAop } from "./slices/select-aop.actions";
import { aopUploadComplete, uploadAop } from "./slices/upload-aop.actions";
export var slice = createSlice({
    name: "aop",
    initialState: initialState,
    reducers: {
        resetData: function() {
            return initialState;
        },
        resetSelectedAop: function(state) {
            state.selectedAopId = null;
        },
        resetCustomAop: function(state, action) {
            state.customTemplateAops = state.customTemplateAops.filter(function(aop) {
                return aop.templateId === action.payload;
            });
        },
        deleteByZoneId: function(state, param) {
            var payload = param.payload;
            state.customTemplateAops = state.customTemplateAops.filter(function(aop) {
                return aop.zoneId !== payload.zoneId;
            });
        },
        setIsApplying: function(state, action) {
            state.isApplyingAop = action.payload;
        },
        setIsAllAopApplied: function(state) {
            state.isAllAopApplied = true;
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        ifSwitchZoneAop: function(state, payload) {},
        focusAop: focusAop,
        selectAop: selectAop,
        uploadAop: uploadAop,
        aopUploadComplete: aopUploadComplete,
        updateAop: updateAop,
        updateAopTransform: updateAopTransform,
        deleteAop: deleteAop,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        initializeAop: function(state, action) {},
        initializeAopCompleted: function(state, param) {
            var payload = param.payload;
            return _object_spread_props(_object_spread({}, state, payload), {
                isReady: true
            });
        },
        setShowMobilePanel: function setShowMobilePanel(state, param) {
            var payload = param.payload;
            state.isShowingMobilePanel = payload;
        },
        setMobileControl: function setMobileControl(state, param) {
            var payload = param.payload;
            state.selectedMobileControl = payload;
        }
    }
});
export var actions = slice.actions, reducer = slice.reducer, name = slice.name;
