import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { selectAndTransformPlacedPatterns, selectAndTransformUploadedPatterns } from "../sagas/pattern-helpers/selectors";
export var loadPatterns = function(state, param) {
    var payload = param.payload;
    state.isLoading = true;
};
export var loadPatternsSuccess = function(state, param) {
    var payload = param.payload;
    var logoDesigns = payload;
    var loaded = function(pattern) {
        return _object_spread_props(_object_spread({}, pattern), {
            isLoading: false
        });
    };
    state.placedPatterns = selectAndTransformPlacedPatterns(logoDesigns);
    state.uploadedPatterns = selectAndTransformUploadedPatterns(logoDesigns).map(loaded);
    state.isLoading = false;
    state.isReady = true;
};
