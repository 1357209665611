import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { createSlice } from "../../@redux";
// Normal image actions
import { initialState } from "./constants";
import * as loadPlaceholderActions from "./slices/load-placeholder.actions";
import * as updatePlaceholderActions from "./slices/update-placeholder.actions";
// Image engine actions
export var slice = createSlice({
    name: "placeholder",
    initialState: initialState,
    reducers: _object_spread_props(_object_spread({}, loadPlaceholderActions, updatePlaceholderActions), {
        resetData: function() {
            return initialState;
        },
        clearSelection: function(state, action) {
            state.selectedPlaceholderGroup = initialState.selectedPlaceholderGroup;
            state.selectedPlaceholderId = initialState.selectedPlaceholderId;
        }
    })
});
export var actions = slice.actions, reducer = slice.reducer, name = slice.name;
