import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _define_property from "@swc/helpers/src/_define_property.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _object_without_properties from "@swc/helpers/src/_object_without_properties.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import isEqual from "lodash/isEqual";
import { fixCDN, getBase64ImageByURL, isImageCustomizationMode } from "@brikl/studio-utils";
import { OBJECT_PLACEHOLDER_VISIBILITY_PROPERTIES, OBJECT_SINGLE_LAYER_VISIBILITY_PROPERTIES, OBJECT_VISIBILITY_PROPERTIES } from "./config";
import { centerInPlaceholder, checkObjectDuplicate, convertSvgToObject, fabricRequestRender, loadImageFromURL, mapSvgElementWithCMYK } from "./util";
var placeholderProperties = {
    evented: false,
    hasBorders: false,
    hasRotatingPoint: false,
    hasControls: false,
    perPixelTargetFind: true,
    lockMovementX: true,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    lockUniScaling: true,
    lockRotation: true,
    hoverCursor: "pointer"
};
var placeholderFreePositioningProperties = {
    lockRotation: true,
    hoverCursor: "pointer"
};
export var IMAGE_INITIAL_DATA = {
    scale: 0,
    rotate: 0,
    visible: true,
    deleted: false,
    lock: false,
    base64: null,
    srcUrl: null
};
export var addImage = function() {
    var _ref = _async_to_generator(function(param) {
        var fabricCanvas, imageData, fabrics, base64, imageProps, fabricImage, placeholderAutoLock, svgObjectCheck, imageBase64, _tmp, _tmp1, _flipX, _flipY, _opacity, reCenterFlag, placeholderCanvas, svgObjects, ref, ref1, ref2, ref3, scaledWidth, scaledHeight, placeholderLeft, placeholderTop, _tmp2, placeholderOffset, _tmp3, lockValue, _tmp4, error;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    fabricCanvas = param.fabricCanvas, imageData = param.imageData, fabrics = param.fabrics;
                    base64 = imageData.base64, imageProps = _object_without_properties(imageData, [
                        "base64"
                    ]);
                    if (!base64) {
                        console.error("image source is required!");
                        return [
                            2
                        ];
                    }
                    svgObjectCheck = false;
                    _state.label = 1;
                case 1:
                    _state.trys.push([
                        1,
                        9,
                        ,
                        10
                    ]);
                    if (!(base64.indexOf("image/svg") !== -1 || base64.indexOf(".svg") !== -1)) return [
                        3,
                        3
                    ];
                    return [
                        4,
                        convertSvgToObject(fixCDN(base64))
                    ];
                case 2:
                    // CONVERT SVG TO IMAGE
                    fabricImage = _state.sent();
                    svgObjectCheck = true;
                    return [
                        3,
                        8
                    ];
                case 3:
                    if (!(base64.indexOf("http") !== -1)) return [
                        3,
                        6
                    ];
                    return [
                        4,
                        getBase64ImageByURL(fixCDN(base64)) // convert url to base64 to prevent CORS
                    ];
                case 4:
                    imageBase64 = _state.sent();
                    return [
                        4,
                        loadImageFromURL(imageBase64) // url image should convert to base64 (Export reason)
                    ];
                case 5:
                    fabricImage = _state.sent();
                    return [
                        3,
                        8
                    ];
                case 6:
                    return [
                        4,
                        loadImageFromURL(fixCDN(base64)) // already base64
                    ];
                case 7:
                    fabricImage = _state.sent();
                    _state.label = 8;
                case 8:
                    _tmp = {};
                    _tmp1 = {};
                    fabricImage.set("data", _object_spread_props(_object_spread((_tmp.id = imageData.id, _tmp), imageData), (_tmp1.type = "Image", _tmp1)));
                    fabricImage.set("flipX", (_flipX = imageData.flipX) !== null && _flipX !== void 0 ? _flipX : false);
                    fabricImage.set("flipY", (_flipY = imageData.flipY) !== null && _flipY !== void 0 ? _flipY : false);
                    fabricImage.set("opacity", (_opacity = imageData.opacity) !== null && _opacity !== void 0 ? _opacity : 1);
                    if (svgObjectCheck === true) {
                        // MAP CMYK WITH HEX (ONLY FOR SVG IMAGE)
                        mapSvgElementWithCMYK(fabricImage, fabrics);
                    }
                    reCenterFlag = false // SOMETIME PLACEHOLDER COORD ISSUE, THEN WE RECENTER TO MAKE SURE IT IN SIDE PLACEHOLDER
                    ;
                    placeholderCanvas = null;
                    _tmp2 = {};
                    if (imageProps.placeholderSvgId) {
                        svgObjects = fabricCanvas === null || fabricCanvas === void 0 ? void 0 : fabricCanvas.getObjects();
                        // placeholder are
                        placeholderCanvas = svgObjects.find(function(phCanvas) {
                            return phCanvas.id === imageProps.placeholderSvgId;
                        });
                        if (!placeholderCanvas) return [
                            2
                        ]; // NO PLACEHOLDER NO ADD
                        if (placeholderCanvas) {
                            ;
                            scaledWidth = placeholderCanvas.width * placeholderCanvas.scaleX;
                            scaledHeight = placeholderCanvas.height * placeholderCanvas.scaleY;
                            placeholderLeft = placeholderCanvas.getCenterPoint().x;
                            placeholderTop = placeholderCanvas.getCenterPoint().y;
                            fabricImage.data.placeholderSvgId = placeholderCanvas.id;
                            fabricImage.setControlsVisibility(OBJECT_PLACEHOLDER_VISIBILITY_PROPERTIES) // ALL ICON CONTROL DISABLE
                            ;
                            if (placeholderCanvas === null || placeholderCanvas === void 0 ? void 0 : (ref = placeholderCanvas.data) === null || ref === void 0 ? void 0 : ref.hasFreePositioning) {
                                fabricImage.set(placeholderFreePositioningProperties);
                                fabricImage.setControlsVisibility((_tmp2.tr = true, _tmp2)) // Active scale control
                                ;
                            } else {
                                fabricImage.set(placeholderProperties) // AUTO LOCK ALL
                                ;
                                placeholderAutoLock = true;
                            }
                            placeholderOffset = 2;
                            if (!!imageProps.left && (placeholderCanvas === null || placeholderCanvas === void 0 ? void 0 : (ref1 = placeholderCanvas.data) === null || ref1 === void 0 ? void 0 : ref1.hasFreePositioning)) {
                                fabricImage.set("left", imageProps.left);
                            } else {
                                fabricImage.set("left", placeholderLeft + placeholderOffset);
                                reCenterFlag = true;
                            }
                            if (!!imageProps.top && (placeholderCanvas === null || placeholderCanvas === void 0 ? void 0 : (ref2 = placeholderCanvas.data) === null || ref2 === void 0 ? void 0 : ref2.hasFreePositioning)) {
                                fabricImage.set("top", imageProps.top);
                            } else {
                                fabricImage.set("top", placeholderTop + placeholderOffset);
                                reCenterFlag = true;
                            }
                            if (imageProps.scale && (placeholderCanvas === null || placeholderCanvas === void 0 ? void 0 : (ref3 = placeholderCanvas.data) === null || ref3 === void 0 ? void 0 : ref3.hasFreePositioning)) {
                                fabricImage.set("scaleX", imageProps.scale || IMAGE_INITIAL_DATA.scale || 1);
                                fabricImage.set("scaleY", imageProps.scale || IMAGE_INITIAL_DATA.scale || 1);
                            } else {
                                // LOGIC FOR ADJUST SIZE IN PLACEHOLDER
                                fabricImage.scaleToWidth(scaledWidth - placeholderOffset);
                                if (fabricImage.height * fabricImage.scaleX > scaledHeight) {
                                    fabricImage.scaleToHeight(scaledHeight - placeholderOffset);
                                }
                            }
                        }
                    } else {
                        // NORMAL
                        fabricImage.set("left", imageProps.left || IMAGE_INITIAL_DATA.left);
                        fabricImage.set("top", imageProps.top || IMAGE_INITIAL_DATA.top);
                        fabricImage.set("scaleX", imageProps.scale || IMAGE_INITIAL_DATA.scale || 1);
                        fabricImage.set("scaleY", imageProps.scale || IMAGE_INITIAL_DATA.scale || 1);
                        if (!imageProps.scale) {
                            fabricImage.scaleToWidth(500);
                        }
                        fabricImage.setControlsVisibility(OBJECT_VISIBILITY_PROPERTIES);
                        // hide move upper layer and move to bottom layer controls
                        if (isImageCustomizationMode()) {
                            fabricImage.setControlsVisibility(OBJECT_SINGLE_LAYER_VISIBILITY_PROPERTIES);
                        }
                    }
                    fabricImage.set("angle", imageProps.rotate || (placeholderCanvas === null || placeholderCanvas === void 0 ? void 0 : placeholderCanvas.angle) || IMAGE_INITIAL_DATA.rotate);
                    fabricImage.set("centeredScaling", true);
                    fabricImage.set("centeredRotation", true);
                    fabricImage.set("originX", "center");
                    fabricImage.set("originY", "center");
                    fabricImage.setCoords();
                    _tmp3 = {};
                    if (!checkObjectDuplicate((_tmp3.object = fabricImage, _tmp3.fabricCanvas = fabricCanvas, _tmp3))) {
                        fabricCanvas.add(fabricImage);
                    }
                    lockValue = imageProps.lock || placeholderAutoLock || IMAGE_INITIAL_DATA.lock;
                    fabricImage.set("lockMovementX", lockValue);
                    fabricImage.set("lockMovementY", lockValue);
                    fabricImage.set("lockScalingX", lockValue);
                    fabricImage.set("lockScalingY", lockValue);
                    fabricImage.set("lockUniScaling", lockValue);
                    fabricImage.set("lockRotation", lockValue);
                    // fabricImage.bringToFront()
                    fabricRequestRender(fabricCanvas);
                    _tmp4 = {};
                    fabricCanvas.trigger("object:modified", (_tmp4.target = fabricImage, _tmp4));
                    if (reCenterFlag) {
                        centerInPlaceholder(fabricImage.data.id);
                    }
                    return [
                        3,
                        10
                    ];
                case 9:
                    error = _state.sent();
                    console.error("Failed to add image", error);
                    return [
                        3,
                        10
                    ];
                case 10:
                    return [
                        2
                    ];
            }
        });
    });
    return function addImage(_) {
        return _ref.apply(this, arguments);
    };
}();
export var updateImage = function(param) {
    var fabricCanvas = param.fabricCanvas, imageId = param.imageId, transform = param.transform;
    var imageObject = fabricCanvas.getObjects().find(function(obj) {
        var ref;
        return ((ref = obj.data) === null || ref === void 0 ? void 0 : ref.id) === imageId;
    });
    if (!imageObject) {
        console.warn("Image Object not found", imageId);
        return;
    }
    var setProperty = function(transformProp, imageObjectProp) {
        if (!!transform[transformProp] && !isEqual(imageObject[imageObjectProp], transform[transformProp])) {
            imageObject.set(imageObjectProp, transform[transformProp]);
        }
    };
    setProperty("rotate", "angle");
    setProperty("scale", "scaleX");
    setProperty("scale", "scaleY");
    setProperty("opacity", "opacity");
    // Hack: when reset rotation to 0 straigthen the image
    if ("rotate" in transform && Number(transform["rotate"]) === 0) {
        imageObject.straighten();
    }
    imageObject.setCoords();
    fabricRequestRender(fabricCanvas);
    fabricCanvas.trigger("object:modified", {
        target: imageObject
    });
};
export var mirrorItem = function(param) {
    var fabricCanvas = param.fabricCanvas, itemId = param.itemId, mirrorType = param.mirrorType;
    var object = fabricCanvas.getObjects().find(function(obj) {
        var ref;
        return ((ref = obj.data) === null || ref === void 0 ? void 0 : ref.id) === itemId;
    });
    if (object) {
        // set object data to save flip information
        var objectData = _object_spread_props(_object_spread({}, object.data), _define_property({}, mirrorType, Boolean(!object[mirrorType])));
        object.set("data", objectData);
        object.toggle(mirrorType);
        fabricRequestRender(fabricCanvas);
        fabricCanvas.trigger("object:modified", {
            target: object
        });
    }
};
export var toggleVisible = function(param) {
    var fabricCanvas = param.fabricCanvas, itemId = param.itemId;
    var object = fabricCanvas.getObjects().find(function(obj) {
        var ref;
        return ((ref = obj.data) === null || ref === void 0 ? void 0 : ref.id) === itemId;
    });
    var visibleData = Boolean(!object.visible);
    object.set("visible", visibleData);
    fabricCanvas.discardActiveObject();
    fabricRequestRender(fabricCanvas);
    fabricCanvas.trigger("object:modified", {
        target: object
    });
    return object.visible;
};
export var toggleLock = function(param) {
    var fabricCanvas = param.fabricCanvas, itemId = param.itemId;
    var object = fabricCanvas.getObjects().find(function(obj) {
        var ref;
        return ((ref = obj.data) === null || ref === void 0 ? void 0 : ref.id) === itemId;
    });
    var lockData = {
        lockMovementX: Boolean(!object["lockMovementX"]),
        lockMovementY: Boolean(!object["lockMovementY"]),
        lockScalingX: Boolean(!object["lockScalingX"]),
        lockScalingY: Boolean(!object["lockScalingY"]),
        lockUniScaling: Boolean(!object["lockUniScaling"]),
        lockRotation: Boolean(!object["lockRotation"])
    };
    // const objectData = Object.assign({ ...object.data }, lockData)
    object.set(lockData);
    // object.set('data', objectData)
    fabricCanvas.discardActiveObject();
    fabricCanvas.targetControl = null;
    fabricCanvas.targetObject = null;
    fabricCanvas.targetObjectPos = {
        left: 0,
        top: 0
    };
    fabricRequestRender(fabricCanvas);
    fabricCanvas.trigger("object:modified", {
        target: object
    });
    return object.lockMovementX;
};
