import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectOrderSequences } from "@brikl/studio-engine";
import { useFabricCanvas } from "./useFabricCanvas";
import { fabricRequestRender, isFabricObjectLayerable } from "./util";
function useFabricCanvasUtil() {
    var getLayerableFabricObjects = function getLayerableFabricObjects() {
        if (!fabricCanvas) return [];
        return fabricCanvas.getObjects().filter(isFabricObjectLayerable);
    };
    var setOrderSequences = /** Set the order sequences on the fabric object based on the input. */ function setOrderSequences() {
        var objects = getLayerableFabricObjects();
        if ((orderSequences === null || orderSequences === void 0 ? void 0 : orderSequences.length) < 1) return;
        objects.forEach(function(obj) {
            var orderSEQ = orderSequences.indexOf(obj.data.id);
            if (!orderSEQ) {
                obj.data.orderSEQ = 0;
                return;
            }
            if (obj.data.orderSEQ) return;
            obj.data.orderSEQ = orderSEQ;
        });
        // Re-calculate the order of the fabric objects.
        relayerObjects();
    };
    var relayerObjects = /**
   * Re-assign the layering order to each fabric objects
   * @returns void
   */ function relayerObjects() {
        if (!fabricCanvas) return;
        /**
     * Sort the object by the order sequences.
     * Move objects without orderSEQ forward,
     * and move patterns backward.
     **/ var sortedObjects = getLayerableFabricObjects().sort(function(a, b) {
            return a.data.orderSEQ < b.data.orderSEQ ? -1 : 1;
        }).sort(function(a) {
            return a.data.type === "Pattern" ? -1 : 1;
        });
        function bringToFront(obj, index) {
            setTimeout(function() {
                fabricCanvas.bringToFront(obj);
                // Re-render the scene in the next animation frame.
                fabricRequestRender(fabricCanvas);
            }, index);
        }
        sortedObjects.forEach(bringToFront);
    };
    var getSVGObjectCenter = /**
   * get center position of 2d object by using svg id
   * @param  {IFabricCanvas} fabricCanvas
   * @param  {string} svgId from fabricjs which is used to get center position(x,y)
   * @returns {Point} (x,y) position in 2d coordinate system
   */ function getSVGObjectCenter() {
        var svgId = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "COLORZONE-1";
        if (!fabricCanvas) return null;
        var objects = fabricCanvas.getObjects();
        // COLORZONE-1_2_ || COLORZONE-1 || COLORZONE-1_1
        var object = objects.find(function(obj) {
            var ref;
            return (obj === null || obj === void 0 ? void 0 : obj.id) === svgId || (obj === null || obj === void 0 ? void 0 : (ref = obj.id) === null || ref === void 0 ? void 0 : ref.startsWith(svgId + "_"));
        });
        return object === null || object === void 0 ? void 0 : object.getCenterPoint();
    };
    var fabricCanvas = useFabricCanvas();
    var orderSequences = useSelector(selectOrderSequences);
    return useMemo(function() {
        return {
            getSVGObjectCenter: getSVGObjectCenter,
            relayerObjects: relayerObjects,
            setOrderSequences: setOrderSequences
        };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
        fabricCanvas
    ]);
}
export default useFabricCanvasUtil;
