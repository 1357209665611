/**
 * Combine all reducers in this file and export the combined reducers.
 */ import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { combineReducers } from "@reduxjs/toolkit";
import { engines } from "./engines";
var engineReducers = Object.fromEntries(Object.entries(engines).map(function(param) {
    var _param = _sliced_to_array(param, 2), key = _param[0], config = _param[1];
    return [
        key,
        config.reducer
    ];
}));
/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */ function createReducer() {
    var injectedReducers = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    // Initially we don't have any injected reducers, so return identity function to avoid the error
    if (Object.keys(injectedReducers).length === 0) return function(state) {
        return state;
    };
    return combineReducers(_object_spread({}, injectedReducers, engineReducers));
}
export default createReducer;
