import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect, useState } from "react";
import { reportError } from "@brikl/shared-utils";
import { create3DLoader } from "./create3DLoader";
export function use3DLoader(params) {
    var loader = create3DLoader();
    var ref = useState({
        loading: true,
        data: null,
        error: null
    }), state = ref[0], setState = ref[1];
    useEffect(function() {
        if (params.only2D) {
            setState(function(prev) {
                return _object_spread_props(_object_spread({}, prev), {
                    loading: false
                });
            });
            return;
        }
        loader.load(params).then(function(data) {
            setState(function() {
                return {
                    data: data,
                    error: null,
                    loading: false
                };
            });
        }).catch(function(err) {
            reportError(err, {
                module: "Studio",
                action: "load-model"
            });
            setState(function() {
                return {
                    data: null,
                    loading: false,
                    error: {
                        message: "Error loading model: ".concat(err.currentTarget.responseURL)
                    }
                };
            });
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        params.mtlUrl,
        params.objUrl,
        params.only2D
    ]);
    return state;
}
