import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { call, select, takeLatest } from "typed-redux-saga";
import { getMutationParams } from "../../../utils/saga-helpers";
import { updateProductDesignRevision } from "../../../utils/update-product-design-revision";
import { selectOrderSequences } from "../selectors";
import { actions } from "../slice";
/** Persist the order sequences to the backend. */ export function saveOrderSequences() {
    var ref, apollo, meta, orderSequences, input, _tmp, result, _tmp1;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(getMutationParams())
                ];
            case 1:
                ref = _state.sent(), apollo = ref.apollo, meta = ref.meta;
                if (!meta) return [
                    2
                ];
                if (meta.viewOnly) return [
                    2
                ];
                return [
                    5,
                    _ts_values(select(selectOrderSequences))
                ];
            case 2:
                orderSequences = _state.sent();
                _tmp = {};
                input = (_tmp.orderSequences = orderSequences, _tmp);
                return [
                    5,
                    _ts_values(call(updateProductDesignRevision, input, apollo, meta))
                ];
            case 3:
                result = _state.sent();
                _tmp1 = {};
                console.log("[Layer] Order Sequence Saved:", (_tmp1.input = input, _tmp1.result = result, _tmp1));
                return [
                    2
                ];
        }
    });
}
export function watchUpdateLayer() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(takeLatest(actions.saveLayeringOrder.type, saveOrderSequences))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
