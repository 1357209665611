import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { all } from "typed-redux-saga";
import { watchTextDelete } from "./sagas/delete-text.saga";
import { watchLoadPlacedTexts } from "./sagas/load-text.saga";
import { watchPlaceText } from "./sagas/place-text.saga";
import { watchSelectText } from "./sagas/select.text.saga";
import { watchTextUpdate } from "./sagas/update-text.saga";
/**
 * Root saga manages watcher lifecycle
 */ export default function rootSaga() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    all([
                        watchLoadPlacedTexts(),
                        watchPlaceText(),
                        watchTextUpdate(),
                        watchTextDelete(),
                        watchSelectText()
                    ])
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
};
