import { useMemo } from "react";
import once from "lodash/once";
export function useScriptLoader(src) {
    return useMemo(function() {
        var loadScript = once(function(callback) {
            if (!src) {
                throw new Error("Please provide valid src. " + src);
            }
            var existingScript = document.getElementById(src);
            if (!existingScript) {
                var script = document.createElement("script");
                script.src = src;
                script.id = src;
                document.body.appendChild(script);
                script.onload = function() {
                    if (callback) callback();
                };
            }
            if (existingScript && callback) callback();
        });
        return {
            loadScript: loadScript
        };
    }, [
        src
    ]);
}
