import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { call, put, select, takeLatest } from "typed-redux-saga";
import { reportError } from "@brikl/shared-utils";
import { actions as aopEngineActions } from "../../AopEngine/slice";
import { selectSelectedZone3DElementId, selectSelectedZoneId } from "../../SceneEngine/selectors";
import { actions } from "../slice";
import { onUpdateColorZone } from "./update-color-2d-elements";
import { onUpdateColorZone3DElement } from "./update-color-3d-elements";
function onSelectColor(action) {
    var _tmp, selectedColorId, selectedZone3DElementId, selectedZone2dId, _tmp1, error, _tmp2, _tmp3;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    9,
                    ,
                    11
                ]);
                _tmp = {};
                return [
                    5,
                    _ts_values(put(actions.setErrorForTemplateColor((_tmp.error = null, _tmp))))
                ];
            case 1:
                _state.sent();
                selectedColorId = action.payload;
                if (!selectedColorId) return [
                    2
                ];
                return [
                    5,
                    _ts_values(select(selectSelectedZone3DElementId))
                ];
            case 2:
                selectedZone3DElementId = _state.sent();
                return [
                    5,
                    _ts_values(select(selectSelectedZoneId))
                ];
            case 3:
                selectedZone2dId = _state.sent();
                if (!selectedZone3DElementId) return [
                    3,
                    5
                ];
                // select color from 3d element list
                return [
                    5,
                    _ts_values(call(onUpdateColorZone3DElement, action))
                ];
            case 4:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 5:
                // select color from template color zones
                return [
                    5,
                    _ts_values(call(onUpdateColorZone, action))
                ];
            case 6:
                _state.sent();
                _state.label = 7;
            case 7:
                _tmp1 = {};
                // TODO: Move to AOP engine to keep workflow self-container and consistent
                return [
                    5,
                    _ts_values(put(aopEngineActions.deleteByZoneId((_tmp1.zoneId = selectedZone3DElementId !== null && selectedZone3DElementId !== void 0 ? selectedZone3DElementId : selectedZone2dId, _tmp1))))
                ];
            case 8:
                _state.sent();
                return [
                    3,
                    11
                ];
            case 9:
                error = _state.sent();
                _tmp2 = {};
                return [
                    5,
                    _ts_values(put(actions.setErrorForTemplateColor((_tmp2.error = error.message, _tmp2))))
                ];
            case 10:
                _state.sent();
                _tmp3 = {};
                reportError(error, (_tmp3.module = "selectColorSaga", _tmp3));
                return [
                    3,
                    11
                ];
            case 11:
                return [
                    2
                ];
        }
    });
}
export function watchSelectColor() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(takeLatest(actions.selectColor.type, onSelectColor))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
