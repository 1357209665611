import { createSelector, createStructuredSelector } from "reselect";
var selectDomain = function(state) {
    return state.metadata;
};
export var makeSelectLangCode = createSelector(selectDomain, function(state) {
    return (state === null || state === void 0 ? void 0 : state.langCode) || null;
});
export var makeSelectCurrency = createSelector(selectDomain, function(state) {
    return (state === null || state === void 0 ? void 0 : state.currency) || null;
});
export var makeSelectAllFabrics = createSelector(selectDomain, function(state) {
    return (state === null || state === void 0 ? void 0 : state.fabrics) || [];
});
export var selectAllMeta = createSelector(selectDomain, function(state) {
    return state;
});
export var selectDisclaimerUrl = createSelector(selectDomain, function(state) {
    return state === null || state === void 0 ? void 0 : state.disclaimerUrl;
});
export var selectThankYouSlug = createSelector(selectDomain, function(state) {
    return state === null || state === void 0 ? void 0 : state.thankYouSlug;
});
export var metaDataSelector = createStructuredSelector({
    langCode: makeSelectLangCode,
    currency: makeSelectCurrency,
    fabrics: makeSelectAllFabrics,
    disclaimerUrl: selectDisclaimerUrl,
    thankYouSlug: selectThankYouSlug
});
