import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect, useRef } from "react";
import { useApolloClient } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { useGlobalEngineActions } from "./hooks/useEngineActions";
/**
 * Initializes the initial state of the studio engine from GraphQL product query..
 */ export function useInitializeStudioEngine(config) {
    var actions = useGlobalEngineActions();
    // persist product id and design id to check before init data
    var persistProductIdAndDesignId = useRef({
        productId: null,
        productDesignId: null
    });
    var shouldCreateNewZoneCustomization = useRef(false);
    var productDesignId = config.productDesignId, productId = config.productId, isDesignRoom = config.isDesignRoom;
    var dispatch = useDispatch();
    var apolloClient = useApolloClient();
    var globalState = useSelector(function(state) {
        return state.global;
    });
    /**
   * setup engine store
   */ useEffect(function() {
        if (!(productDesignId && productId)) return;
        var _current = persistProductIdAndDesignId.current, persistProductId = _current.productId, persistDesignId = _current.productDesignId;
        persistProductIdAndDesignId.current = {
            productId: productId,
            productDesignId: productDesignId
        };
        dispatch(actions.initial(_object_spread_props(_object_spread({}, config), {
            apolloClient: apolloClient
        })));
        if (persistDesignId === productDesignId && persistProductId === productId) {
            shouldCreateNewZoneCustomization.current = false;
            return;
        }
        shouldCreateNewZoneCustomization.current = true;
    // when productDesign and product has changed by selecting new product on design room and studio-editor
    }, [
        productDesignId,
        productId,
        isDesignRoom
    ]);
    return globalState;
}
