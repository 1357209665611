import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { call, put, select } from "typed-redux-saga";
import { v4 } from "uuid";
import { selectSelectedTemplate } from "../../TemplateEngine/selectors";
import { selectSelectedColorZone, selectSelectedCustomTemplateColor } from "../selectors";
import { actions } from "../slice";
import { getSelectedColorOnCurrentZone } from "../transforms/get-custom-template-colors";
import { onUpdateColorFill } from "./update-color";
/**
 * Selects the specified color fill.
 *
 * This is used to apply a color in the Color Fill UI.
 */ export function onUpdateColorZone(action) {
    var selectedColorId, selectedColorZone, ref, selectedTemplateId, selectedCustomTemplateColor, selectedColor, _tmp, newCustomTemplateColor, zone, _tmp1, id, zone1, _tmp2, error, _tmp3;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    6,
                    ,
                    7
                ]);
                selectedColorId = action.payload;
                if (!selectedColorId) return [
                    2
                ];
                return [
                    5,
                    _ts_values(select(selectSelectedColorZone))
                ];
            case 1:
                selectedColorZone = _state.sent();
                return [
                    5,
                    _ts_values(select(selectSelectedTemplate))
                ];
            case 2:
                ref = _state.sent(), selectedTemplateId = ref.id;
                return [
                    5,
                    _ts_values(select(selectSelectedCustomTemplateColor))
                ];
            case 3:
                selectedCustomTemplateColor = _state.sent();
                _tmp = {};
                selectedColor = getSelectedColorOnCurrentZone((_tmp.colorZone = selectedColorZone, _tmp.colorId = selectedColorId, _tmp));
                if (!selectedColor) return [
                    2
                ];
                newCustomTemplateColor = null;
                _tmp1 = {};
                _tmp2 = {};
                // then create new custom color
                if (!selectedCustomTemplateColor) {
                    zone = selectedColorZone.zone;
                    newCustomTemplateColor = {
                        id: v4(),
                        colorId: selectedColor.id,
                        zoneId: zone.zoneId,
                        templateId: selectedTemplateId
                    };
                    console.log("[Color Fill] Assigning to new color zone:", (_tmp1.newCustomTemplateColor = newCustomTemplateColor, _tmp1));
                } else {
                    id = selectedCustomTemplateColor.id, zone1 = selectedCustomTemplateColor.zone;
                    newCustomTemplateColor = {
                        id: id,
                        colorId: selectedColor.id,
                        zoneId: zone1 === null || zone1 === void 0 ? void 0 : zone1.zoneId,
                        templateId: selectedTemplateId
                    };
                    console.log("[Color Fill] Assigning to existing color zone:", (_tmp2.newCustomTemplateColor = newCustomTemplateColor, _tmp2));
                }
                return [
                    5,
                    _ts_values(call(onUpdateColorFill, newCustomTemplateColor, selectedColor.hex))
                ];
            case 4:
                _state.sent();
                return [
                    5,
                    _ts_values(put(actions.setTemplateColor(newCustomTemplateColor)))
                ];
            case 5:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 6:
                error = _state.sent();
                _tmp3 = {};
                console.error("[Color Fill] Update Custom Color error:", (_tmp3.error = error, _tmp3));
                throw error;
            case 7:
                return [
                    2
                ];
        }
    });
}
