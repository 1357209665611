export var DEFAULT_INITIAL_STATE = {
    qualityValue: null,
    productData: null,
    shopId: null,
    productId: null,
    productDesignId: null,
    ownedByOrganisationId: null,
    initialized: false,
    canvasSvgIds: [],
    canvas: null,
    isSendingRequest: false,
    isCanvasReady: false,
    isThreeReady: false,
    isLayersReady: false,
    isDesignRoom: false,
    enable2D: false,
    enable3D: true,
    only2D: false,
    isDebugEnabled: false,
    isAutoRotateEnabled: false,
    isRecentering3DCamera: false,
    isMouseTrackingEnabled: false,
    is3DZoomEnabled: true,
    is2DVisible: false,
    is3DVisible: true,
    isBackgroundEnabled: false,
    threeMouseState: null,
    availableAreas: [],
    availableBackgrounds: [],
    availablePlaceholders: [],
    uploadedImages: [],
    uploadedPattern: [],
    selectedImages: [],
    selectedTextId: null,
    canvasPatterns: [],
    selectedBackground: null,
    selectedPlaceholderSvgId: null,
    // defaults: null,
    prevState: null,
    prevAction: null,
    placeholderCanvas: [],
    applyPlaceholder: null,
    removePlaceholder: null,
    capturedAt: null,
    normalMapConfig: [],
    // TODO we may split that to a context outside the state
    theme: {
        threeControlColor: "#000000",
        menuPlacement: "left",
        menuHeight: "auto",
        menuItemStyle: "icon",
        menuPanelBackgroundColor: "#FFF",
        menuBackgroundColor: "#FFF",
        menuHoverColor: "#FEFEFE",
        menuBorderColor: "#FFF",
        button: {
            variant: "solid",
            borderRadius: "0"
        },
        colorStep: {
            swatch: {
                borderRadius: "0"
            }
        },
        aopStep: {
            swatch: {
                borderRadius: "0"
            }
        }
    },
    fontList: [
        "Not in use"
    ],
    isLoading: true,
    capture3D: false,
    capture3DRequest: false,
    pxToCmDimension: 0,
    environment: "dev",
    gridHelper: false,
    hasGridHelper: false,
    current2DZoom: 0
};
