import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
export var productDesignVariables = function(shopId, ownedByOrganisationId, fabricImageData) {
    if (!fabricImageData) {
        return {
            isVisible: true,
            logoDesign: {
                ownedByOrganisationId: ownedByOrganisationId,
                designAttributes: null,
                orderSeq: fabricImageData.orderSEQ
            }
        };
    }
    var flipX = fabricImageData.flipX, flipY = fabricImageData.flipY, left = fabricImageData.left, lock = fabricImageData.lock, rotate = fabricImageData.rotate, scale = fabricImageData.scale, top = fabricImageData.top, visible = fabricImageData.visible, placeholderId = fabricImageData.placeholderId, printingTechniqueId = fabricImageData.printingTechniqueId, isPlaceholderDefault = fabricImageData.isPlaceholderDefault, defaultImageId = fabricImageData.defaultImageId, width = fabricImageData.width, height = fabricImageData.height, orderSEQ = fabricImageData.orderSEQ, opacity = fabricImageData.opacity;
    return {
        isVisible: visible,
        placeholderId: placeholderId,
        printingTechniqueId: printingTechniqueId,
        defaultImageId: defaultImageId,
        logoDesign: {
            orderSeq: orderSEQ,
            ownedByOrganisationId: isPlaceholderDefault ? shopId + "-ADMIN" : ownedByOrganisationId,
            designAttributes: {
                top: top,
                left: left,
                scaleX: scale,
                scaleY: scale,
                angle: rotate,
                flipX: flipX,
                flipY: flipY,
                lock: lock,
                width: width,
                height: height,
                opacity: opacity
            }
        }
    };
};
export var transformOrderSequence = function(logoDesignRevision, orderSequences) {
    return logoDesignRevision.logoDesign.orderSeq ? logoDesignRevision.logoDesign.orderSeq : orderSequences ? orderSequences.indexOf(logoDesignRevision.id) + 1 : 0;
};
export var transformPlacedImages = function(logoDesignRevisions, placeholders, orderSequences) {
    return logoDesignRevisions.map(function(logoDesignRevision) {
        var ref;
        return _object_spread_props(_object_spread({}, transformImage(logoDesignRevision)), {
            orderSEQ: transformOrderSequence(logoDesignRevision, orderSequences),
            placeholderSvgId: placeholders && logoDesignRevision.placeholderId && ((ref = placeholders === null || placeholders === void 0 ? void 0 : placeholders.find(function(placeholder) {
                return placeholder.id === logoDesignRevision.placeholderId;
            })) === null || ref === void 0 ? void 0 : ref.svgId)
        });
    });
};
var _opacity;
export var transformImage = function(param) {
    var id = param.id, _logoDesign = param.logoDesign, designAttributes = _logoDesign.designAttributes, srcUrl = _logoDesign.src, defaultImageId = param.defaultImageId, placeholderId = param.placeholderId, printingTechniqueId = param.printingTechniqueId;
    return {
        base64: srcUrl,
        id: id,
        left: designAttributes.left,
        lock: designAttributes.lock,
        rotate: designAttributes.angle,
        scale: designAttributes.scaleX || designAttributes.scaleY,
        top: designAttributes.top,
        flipX: designAttributes.flipX,
        flipY: designAttributes.flipY,
        visible: true,
        defaultImageId: defaultImageId,
        placeholderId: placeholderId,
        printingTechniqueId: printingTechniqueId,
        width: designAttributes.width,
        height: designAttributes.height,
        opacity: (_opacity = designAttributes.opacity) !== null && _opacity !== void 0 ? _opacity : 1
    };
};
