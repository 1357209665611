import { LanguageCode } from "@brikl/codegen";
import { mapZoneToColors } from "@brikl/store";
import { getTextFromLanguage } from "~/libs/store/src/util/text";
export var mapColorZones = function(template, lang) {
    var ref;
    return template === null || template === void 0 ? void 0 : (ref = template.zones) === null || ref === void 0 ? void 0 : ref.flatMap(function(zone) {
        var ref, ref1;
        return {
            zone: {
                zoneId: zone.id,
                svgIds: zone.svgIds,
                name: ((ref = zone.title) === null || ref === void 0 ? void 0 : ref.text) ? getTextFromLanguage({
                    fallbackLanguage: LanguageCode.EnUk,
                    targetLanguage: lang,
                    textObject: (ref1 = zone.title) === null || ref1 === void 0 ? void 0 : ref1.text
                }) : zone.defaultName
            },
            defaultColor: {
                hex: zone.defaultColorHex,
                id: zone.defaultColorId
            },
            colors: mapZoneToColors(zone),
            templateId: template === null || template === void 0 ? void 0 : template.id
        };
    });
};
