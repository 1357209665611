import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import React from "react";
import * as Sentry from "@sentry/browser";
import FontFaceObserver from "fontfaceobserver";
/**
 * we have to skip these fonts because they're system fonts,
 * FontfaceObserver will take long time to load then error
 * because they're not found
 */ var excludeFonts = [
    "Arial"
];
var allCachedFonts = new Map();
export var useInitialFontFaceObserver = function() {
    var fonts = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [];
    var fontCachingObserver = function() {
        var _ref = _async_to_generator(function(fontName) {
            var fontFaceObserver, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        // skip loading excluded fonts
                        if (excludeFonts.includes(fontName)) {
                            allCachedFonts.set(fontName, true);
                            return [
                                2
                            ];
                        }
                        // skip cached font
                        if (allCachedFonts.get(fontName)) return [
                            2
                        ];
                        fontFaceObserver = new FontFaceObserver(fontName);
                        return [
                            4,
                            fontFaceObserver.load(null, 5000)
                        ];
                    case 1:
                        _state.sent();
                        allCachedFonts.set(fontName, true);
                        return [
                            3,
                            3
                        ];
                    case 2:
                        error = _state.sent();
                        console.warn("[useInitialFontFaceObserver] unable to load font family ".concat(fontName), error);
                        Sentry.captureException(error);
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function fontCachingObserver(fontName) {
            return _ref.apply(this, arguments);
        };
    }();
    React.useEffect(function() {
        fonts.map(fontCachingObserver);
    }, [
        fonts
    ]);
};
