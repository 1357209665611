import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import maxBy from "lodash/maxBy";
import { all, put, select, takeLatest } from "typed-redux-saga";
import { IMAGE_INITIAL_DATA } from "@brikl/studio";
// DesignStepEngine
import { selectSelectedDesignStep } from "../../DesignStepEngine/selectors";
// ImageEngine
import { onUploadImages } from "../../ImageEngine/sagas/upload-image.saga";
import { makeSelectPlacedImages } from "../../ImageEngine/selectors";
import { actions as imageEngineActions } from "../../ImageEngine/slice";
import { selectPlacedTexts } from "../../TextEngine/selectors";
import { actions as TextEngineActions } from "../../TextEngine/slice";
import { makeSelectAvailablePlaceholders, makeSelectSelectedPlaceholder } from "../selectors";
import { actions } from "../slice";
function onPlacePlaceholderImage(action) {
    var ref, deletedPlacedImages, newImage, selectPlaceholder, selectedDesignStep, selectedPlaceholderGroup, placedTexts, allPlacedImages, ref1, orderSEQ, _placeholderGroupId, _placeholderId, _placeholderSvgId, newPlacedImage, _tmp, _tmp1, placedGroupPlacedImages, placedGroupPlacedTexts, isDeletePlaceTextsInSameGroup, e;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    9,
                    ,
                    10
                ]);
                deletedPlacedImages = [];
                newImage = action.payload;
                return [
                    5,
                    _ts_values(select(makeSelectSelectedPlaceholder))
                ];
            case 1:
                selectPlaceholder = _state.sent();
                return [
                    5,
                    _ts_values(select(selectSelectedDesignStep))
                ];
            case 2:
                selectedDesignStep = _state.sent();
                selectedPlaceholderGroup = selectedDesignStep.designPlaceholderGroups.find(function(group) {
                    return group.designPlaceholderIds.includes(selectPlaceholder.id);
                });
                return [
                    5,
                    _ts_values(select(selectPlacedTexts))
                ];
            case 3:
                placedTexts = _state.sent();
                if (!selectedPlaceholderGroup || !selectPlaceholder) {
                    new Error("[PlaceholderEngine] onPlacePlaceholderImage => selecedPlaceholderGroup & selectPlaceholder are empty");
                }
                return [
                    5,
                    _ts_values(select(makeSelectPlacedImages))
                ];
            case 4:
                allPlacedImages = _state.sent();
                orderSEQ = ((ref1 = (ref = maxBy(allPlacedImages, function(placehodler) {
                    return placehodler.orderSEQ;
                })) === null || ref === void 0 ? void 0 : ref.orderSEQ) !== null && ref1 !== void 0 ? ref1 : 0) + 1;
                _tmp = {};
                _tmp1 = {};
                newPlacedImage = _object_spread_props(_object_spread(_tmp, newImage), (_tmp1.placeholderGroupId = (_placeholderGroupId = newImage.placeholderGroupId) !== null && _placeholderGroupId !== void 0 ? _placeholderGroupId : selectedPlaceholderGroup.id, _tmp1.placeholderId = (_placeholderId = newImage.placeholderId) !== null && _placeholderId !== void 0 ? _placeholderId : selectPlaceholder.id, _tmp1.placeholderSvgId = (_placeholderSvgId = newImage.placeholderSvgId) !== null && _placeholderSvgId !== void 0 ? _placeholderSvgId : selectPlaceholder.svgId, _tmp1.orderSEQ = orderSEQ, _tmp1));
                placedGroupPlacedImages = allPlacedImages.filter(function(image) {
                    return selectedPlaceholderGroup.designPlaceholderIds.includes(image.placeholderId) || image.placeholderGroupId === selectedPlaceholderGroup.id;
                });
                placedGroupPlacedTexts = placedTexts.filter(function(image) {
                    return selectedPlaceholderGroup.designPlaceholderIds.includes(image.placeholderId) || image.placeholderGroupId === selectedPlaceholderGroup.id;
                });
                // Start business logic case for warrix
                placedGroupPlacedImages.forEach(function(placedImage) {
                    var ref;
                    var toDelete = false;
                    //if placeholder is taken and maximum is 1 we replace
                    var isExceedMaximumItem = selectPlaceholder.maximumItems === 1 || selectPlaceholder.maximumItems === null;
                    if (isExceedMaximumItem && selectPlaceholder.id === placedImage.placeholderId) {
                        toDelete = true;
                    }
                    if (placedImage.id === newPlacedImage.id) {
                        toDelete = false;
                    }
                    // in case of in another placeholder to prevent combination logic is "SINGLE" remove image in the same placeholder.
                    var isInAnotherPlaceholder = placedImage.placeholderId !== newPlacedImage.placeholderId;
                    // if a placeholder group is single we clear all placeholders in the group and add new
                    // delete placeholder image in another placeholder of the same group
                    if (selectedPlaceholderGroup.combinationLogic === "SINGLE" && ((ref = selectedPlaceholderGroup.designPlaceholderIds) === null || ref === void 0 ? void 0 : ref.includes(placedImage.placeholderId)) && isInAnotherPlaceholder) {
                        toDelete = true;
                    }
                    if (toDelete) {
                        deletedPlacedImages.push(placedImage.id);
                    }
                });
                return [
                    5,
                    _ts_values(all(deletedPlacedImages.map(function(imageId) {
                        return put(imageEngineActions.deletePlacedImage({
                            id: imageId
                        }));
                    })))
                ];
            case 5:
                _state.sent();
                isDeletePlaceTextsInSameGroup = selectedPlaceholderGroup.combinationLogic === "SINGLE" && placedGroupPlacedTexts.length !== 0;
                if (!isDeletePlaceTextsInSameGroup) return [
                    3,
                    7
                ];
                return [
                    5,
                    _ts_values(all(placedGroupPlacedTexts.map(function(text) {
                        return put(TextEngineActions.deleteText({
                            id: text.id
                        }));
                    })))
                ];
            case 6:
                _state.sent();
                _state.label = 7;
            case 7:
                return [
                    5,
                    _ts_values(put(imageEngineActions.placeImage(newPlacedImage)))
                ];
            case 8:
                _state.sent();
                return [
                    3,
                    10
                ];
            case 9:
                e = _state.sent();
                console.error("[PlaceholderEngine] onPlacePlaceholderImage => error ", e);
                return [
                    3,
                    10
                ];
            case 10:
                return [
                    2
                ];
        }
    });
}
// TODO: simplify this workflow
function onUploadPlaceholderImageSuccess(param) {
    var image, selectedDesignStep, availablePlaceholders, allowTypes, shouldRenderPlaceholder, newImage, _tmp, _tmp1, error;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                image = param.payload;
                _state.label = 1;
            case 1:
                _state.trys.push([
                    1,
                    6,
                    ,
                    7
                ]);
                return [
                    5,
                    _ts_values(select(selectSelectedDesignStep))
                ];
            case 2:
                selectedDesignStep = _state.sent();
                return [
                    5,
                    _ts_values(select(makeSelectAvailablePlaceholders))
                ];
            case 3:
                availablePlaceholders = _state.sent();
                allowTypes = [
                    "PERSONALIZATION",
                    "PLACEHOLDER"
                ];
                shouldRenderPlaceholder = availablePlaceholders.length !== 0 && allowTypes.includes(selectedDesignStep.type);
                if (!shouldRenderPlaceholder) return [
                    2
                ];
                _tmp = {};
                _tmp1 = {};
                newImage = _object_spread_props(_object_spread(_tmp, IMAGE_INITIAL_DATA, image), (_tmp1.// default image id is really important to reference to its origin uploaded image
                defaultImageId = image.id, _tmp1));
                return [
                    4,
                    put(actions.uploadPlaceholderImagesDone())
                ];
            case 4:
                _state.sent();
                return [
                    4,
                    put(actions.placePlaceholderImage(newImage))
                ];
            case 5:
                _state.sent();
                console.log("[PlaceholderEngine] onUploadPlaceholderImageSuccess", newImage);
                return [
                    3,
                    7
                ];
            case 6:
                error = _state.sent();
                console.error("[PlaceholderEngine] onUploadPlaceholderImageSuccess error", error);
                return [
                    3,
                    7
                ];
            case 7:
                return [
                    2
                ];
        }
    });
}
/**
 * Watch image placeholder
 */ export function watchUploadPlaceholderImage() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(all([
                        // Watch when start to upload placeholder images
                        takeLatest(actions.uploadPlaceholderImages.type, onUploadImages),
                        // Watch when completed upload placeholder images
                        takeLatest(imageEngineActions.uploadImageSuccess.type, onUploadPlaceholderImageSuccess),
                        // Watch placing placeholder image
                        takeLatest(actions.placePlaceholderImage.type, onPlacePlaceholderImage)
                    ]))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
