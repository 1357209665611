import { createSelector, createStructuredSelector } from "reselect";
import { initialState } from "./constants";
var withoutCartStep = function(step) {
    return step.type !== "ADD_TO_CART";
};
export var stateDomain = function(state) {
    return state.designStep || initialState;
};
export var selectAllDesignSteps = createSelector(stateDomain, function(state) {
    return state.designSteps.filter(withoutCartStep);
});
var ref;
export var selectSelectedDesignStep = createSelector(stateDomain, function(state) {
    return (ref = state.designSteps.find(function(designStep) {
        return designStep.id === state.selectedDesignStepId;
    })) !== null && ref !== void 0 ? ref : state.designSteps[0];
});
export var selectAopStep = createSelector(selectAllDesignSteps, function(designSteps) {
    return designSteps.filter(function(designStep) {
        return designStep.type === "AOP";
    }) || null;
});
export var selectAddToCart = createSelector(stateDomain, function(state) {
    return state.designSteps.find(function(designStep) {
        return designStep.type === "ADD_TO_CART";
    }) || null;
});
export var designStepSelector = createStructuredSelector({
    designSteps: selectAllDesignSteps,
    cartStep: selectAddToCart,
    selectedDesignStep: selectSelectedDesignStep
});
