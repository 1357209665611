import uniqBy from "lodash/uniqBy";
import { createSelector, createStructuredSelector } from "reselect";
import { initialState } from "./constants";
var selectDomain = function(state) {
    return state.text || initialState;
};
export var selectPlacedTexts = createSelector([
    selectDomain
], function(textState) {
    return uniqBy(textState === null || textState === void 0 ? void 0 : textState.placedTexts, "id") || [];
});
export var selectedDeletedPlacedTexts = createSelector([
    selectDomain
], function(textState) {
    return (textState === null || textState === void 0 ? void 0 : textState.deletedPlacedTexts) || [];
});
export var selectAllFonts = createSelector([
    selectDomain
], function(textState) {
    return (textState === null || textState === void 0 ? void 0 : textState.fonts) || [];
});
export var selectSelectedTextId = createSelector([
    selectDomain
], function(textState) {
    return (textState === null || textState === void 0 ? void 0 : textState.selectedTextId) || null;
});
export var selectSelectedText = createSelector([
    selectSelectedTextId,
    selectPlacedTexts
], function(selectedTextId, placedTexts) {
    return placedTexts.find(function(placedText) {
        return placedText.id === selectedTextId;
    }) || null;
});
export var textSelector = createStructuredSelector({
    placedTexts: selectPlacedTexts,
    deletedPlacedTexts: selectedDeletedPlacedTexts,
    selectedTextId: selectSelectedTextId,
    selectedText: selectSelectedText,
    fonts: selectAllFonts
});
