export function get3DElements(latestElements) {
    var transform = /** Transforms the ProductElement type from backend into IElement3D, with color information attached. */ function transform(element) {
        var _name;
        var colors = (element === null || element === void 0 ? void 0 : element.fabricCompositions.flatMap(function(fabricComposition) {
            return fabricComposition.colors.map(function(color) {
                return {
                    id: color.id,
                    name: (_name = color.name) !== null && _name !== void 0 ? _name : color.code,
                    hex: color.hex
                };
            });
        })) || [];
        // Constructs the output IElement3D object.
        var id = element.id, name = element.name, defaultName = element.defaultName, fullCode = element.fullCode;
        return {
            id: "".concat(id, "_ELM3D"),
            name: name,
            defaultName: defaultName,
            fullCode: fullCode,
            availableColors: colors
        };
    };
    var ref;
    return (ref = latestElements === null || latestElements === void 0 ? void 0 : latestElements.filter(function(elm) {
        return elm.is3DOnly;
    }).map(transform)) !== null && ref !== void 0 ? ref : [];
}
