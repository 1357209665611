import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _define_property from "@swc/helpers/src/_define_property.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import isEqual from "lodash/isEqual";
import { OBJECT_PLACEHOLDER_VISIBILITY_PROPERTIES, OBJECT_VISIBILITY_PROPERTIES } from "./config";
import { checkObjectDuplicate, fabricRequestRender, getMinMaxPixelCoord, getPixelCoord, mapColorCMYK } from "./util";
var placeholderProperties = {
    evented: false,
    hasBorders: false,
    hasRotatingPoint: false,
    hasControls: false,
    perPixelTargetFind: true,
    lockMovementX: true,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    lockUniScaling: true,
    lockRotation: true,
    hoverCursor: "pointer"
};
var placeholderFreePositioningProperties = {
    lockRotation: true,
    hoverCursor: "pointer"
};
export var TEXT_INITIAL_DATA = {
    left: 1370,
    top: 1552,
    fontSize: 60,
    fontFamily: "Arial",
    scale: 2,
    fillColor: "black",
    outlineColor: "#000",
    outline: 0,
    charSpacing: 0,
    rotate: 0,
    flipX: false,
    flipY: false,
    lock: false,
    visible: true,
    opacity: 1
};
export var mapTextObjectToFabricTextData = function(textObject) {
    return {
        // main
        id: textObject.data.id,
        step: textObject.data.step,
        text: textObject.text,
        // position
        top: textObject.top,
        left: textObject.left,
        // color
        fillColor: textObject.fill,
        outlineColor: textObject.stroke,
        // font
        fontFamily: textObject.fontFamily,
        fontSize: textObject.fontSize,
        // transform
        charSpacing: textObject.charSpacing,
        scale: textObject.scaleX,
        rotate: textObject.angle,
        outline: textObject.strokeWidth,
        // controls
        flipX: textObject.flipX,
        flipY: textObject.flipY,
        // lock
        // we only need one property from lock since all of them
        // are the same boolean value
        lock: textObject.lockMovementX,
        width: textObject.width,
        height: textObject.height,
        opacity: textObject.opacity
    };
};
export var addText = function(param) {
    var fabricCanvas = param.fabricCanvas, textData = param.textData;
    try {
        if (!textData.text) return;
        var _flipX, _flipY;
        var text = {
            data: {
                id: textData.id,
                type: "Text",
                step: textData.step,
                placeholderSvgId: textData.placeholderSvgId,
                placeholderGroupId: textData.placeholderGroupId,
                placeholderId: textData.placeholderId,
                isPlaceholderDefault: textData.isPlaceholderDefault,
                printingTechniqueId: textData.printingTechniqueId
            },
            left: textData.left || TEXT_INITIAL_DATA.left,
            top: textData.top || TEXT_INITIAL_DATA.top,
            fontSize: textData.fontSize || TEXT_INITIAL_DATA.fontSize,
            fontFamily: textData.fontFamily || TEXT_INITIAL_DATA.fontFamily,
            scaleX: textData.scale || TEXT_INITIAL_DATA.scale,
            scaleY: textData.scale || TEXT_INITIAL_DATA.scale,
            fill: textData.fillColor || TEXT_INITIAL_DATA.fillColor,
            stroke: textData.outlineColor || TEXT_INITIAL_DATA.outlineColor,
            strokeWidth: textData.outline || TEXT_INITIAL_DATA.outline,
            angle: textData.rotate || TEXT_INITIAL_DATA.rotate,
            charSpacing: textData.charSpacing || TEXT_INITIAL_DATA.charSpacing,
            flipX: (_flipX = textData.flipX) !== null && _flipX !== void 0 ? _flipX : TEXT_INITIAL_DATA.flipX,
            flipY: (_flipY = textData.flipY) !== null && _flipY !== void 0 ? _flipY : TEXT_INITIAL_DATA.flipY,
            lockMovementX: textData.lock || TEXT_INITIAL_DATA.lock,
            lockMovementY: textData.lock || TEXT_INITIAL_DATA.lock,
            lockScalingX: textData.lock || TEXT_INITIAL_DATA.lock,
            lockScalingY: textData.lock || TEXT_INITIAL_DATA.lock,
            lockUniScaling: textData.lock || TEXT_INITIAL_DATA.lock,
            lockRotation: textData.lock || TEXT_INITIAL_DATA.lock,
            opacity: textData.opacity || TEXT_INITIAL_DATA.opacity,
            originX: "center",
            originY: "center",
            centeredScaling: true,
            centeredRotation: true,
            paintFirst: "fill",
            minScaleLimit: 0.1,
            lockScalingFlip: true,
            visible: true,
            textAlign: "center"
        };
        var fabricText = new fabric.IText(textData.text, text);
        fabricText.set("opacity", text.opacity);
        if (textData.placeholderSvgId) {
            var ref;
            // PLACEHOLDER
            // GET DIMENSION COORD FROM BASE SVG PLACEHOLDER
            var svgObjects = fabricCanvas === null || fabricCanvas === void 0 ? void 0 : fabricCanvas.getObjects();
            var placeholderCanvas = svgObjects.find(function(phCanvas) {
                return phCanvas.id === textData.placeholderSvgId;
            });
            if (!placeholderCanvas) {
                return;
            }
            var scaledWidth = placeholderCanvas.width * placeholderCanvas.scaleX;
            var scaledHeight = placeholderCanvas.height * placeholderCanvas.scaleY;
            var ph_left = placeholderCanvas.left + scaledWidth / 2;
            var ph_top = placeholderCanvas.top + scaledHeight / 2;
            var hasFreePositioning = !!(placeholderCanvas === null || placeholderCanvas === void 0 ? void 0 : (ref = placeholderCanvas.data) === null || ref === void 0 ? void 0 : ref.hasFreePositioning);
            fabricText.data.placeholderId = placeholderCanvas.id;
            fabricText.setControlsVisibility(OBJECT_PLACEHOLDER_VISIBILITY_PROPERTIES) // ALL ICON CONTROL DISABLE
            ;
            if (hasFreePositioning && textData.lock !== true // VIEW MODE RE-CHECK
            ) {
                fabricText.set(placeholderFreePositioningProperties);
                fabricText.set("angle", placeholderCanvas.angle);
                fabricText.setControlsVisibility({
                    tr: true
                }) // Active scale control
                ;
            } else {
                fabricText.set(placeholderProperties) // AUTO LOCK ALL
                ;
                fabricText.set("angle", placeholderCanvas.angle);
            }
            var placeholderOffset = 2;
            if (!!textData.left && hasFreePositioning) {
                fabricText.set("left", textData.left);
            } else {
                fabricText.set("left", ph_left + placeholderOffset);
            }
            if (!!textData.top && hasFreePositioning) {
                fabricText.set("top", textData.top);
            } else {
                fabricText.set("top", ph_top + placeholderOffset);
            }
            if (textData.scale && hasFreePositioning) {
                fabricText.set("scaleX", textData.scale || TEXT_INITIAL_DATA.scale || 1);
                fabricText.set("scaleY", textData.scale || TEXT_INITIAL_DATA.scale || 1);
            } else {
                // LOGIC FOR ADJUST SIZE IN PLACEHOLDER
                fabricText.scaleToWidth(scaledWidth - placeholderOffset);
                if (fabricText.height * fabricText.scaleX > scaledHeight) {
                    fabricText.scaleToHeight(scaledHeight - placeholderOffset);
                }
            }
        } else {
            // NORMAL ADD TEXT STEP
            fabricText.setControlsVisibility(OBJECT_VISIBILITY_PROPERTIES);
        }
        fabricText.setCoords();
        if (!checkObjectDuplicate({
            object: fabricText,
            fabricCanvas: fabricCanvas
        })) {
            fabricCanvas.add(fabricText);
        }
        fabric.util.clearFabricFontCache();
        fabricRequestRender(fabricCanvas);
        var loadAndUse = createLoadAndUse(fabricCanvas, fabricText);
        fabricText.dirty = false;
        loadAndUse(textData.fontFamily).then(function() {
            fabricRequestRender(fabricCanvas);
            fabricText.dirty = true;
        });
        if (fabricText) fabricCanvas.trigger("object:modified", {
            target: fabricText
        });
    } catch (error) {
        console.error("Failed to add text", error);
    }
};
export var adjustPlaceholder = function(param) {
    var // Adjust placeholder after fontFamily or any change that might effect shape
    fabricCanvas = param.fabricCanvas, placeholderSvgId = param.placeholderSvgId, fabricText = param.fabricText;
    if (!placeholderSvgId) return;
    // PLACEHOLDER
    // GET DIMENSION COORD FROM BASE SVG PLACEHOLDER
    var svgObjects = fabricCanvas === null || fabricCanvas === void 0 ? void 0 : fabricCanvas.getObjects();
    var placeholderCanvas = svgObjects.find(function(phCanvas) {
        return phCanvas.id === placeholderSvgId;
    });
    if (placeholderCanvas) {
        var scaledWidth = placeholderCanvas.width * placeholderCanvas.scaleX;
        var scaledHeight = placeholderCanvas.height * placeholderCanvas.scaleY;
        var ph_left = placeholderCanvas.left + scaledWidth / 2;
        var ph_top = placeholderCanvas.top + scaledHeight / 2;
        fabricText.data.placeholderId = placeholderCanvas.id;
        fabricText.set("left", ph_left);
        fabricText.set("top", ph_top);
        // LOGIC FOR ADJUST SIZE IN PLACEHOLDER
        fabricText.scaleToWidth(scaledWidth);
        if (fabricText.height * fabricText.scaleX > scaledHeight) {
            fabricText.scaleToHeight(scaledHeight);
        }
        fabricText.setCoords();
    }
};
export var updateText = function(param) {
    var fabricCanvas = param.fabricCanvas, transform = param.transform, textId = param.textId, _triggerElementModified = param.triggerElementModified, triggerElementModified = _triggerElementModified === void 0 ? true : _triggerElementModified, fabrics = param.fabrics, text = param.text;
    try {
        var textObjects = fabricCanvas.getObjects();
        var placeholderSvgId = (text === null || text === void 0 ? void 0 : text.placeholderSvgId) || null;
        var isPlaceholderDefault = (text === null || text === void 0 ? void 0 : text.isPlaceholderDefault) || null;
        var placeholderId = (text === null || text === void 0 ? void 0 : text.placeholderId) || null;
        var fontFamily = text === null || text === void 0 ? void 0 : text.fontFamily;
        var textObject = textObjects.find(function(obj) {
            var ref;
            return ((ref = obj.data) === null || ref === void 0 ? void 0 : ref.id) === textId;
        });
        if (!textObject) {
            console.warn("Text Object not found", textId);
            return;
        }
        var loadAndUse = createLoadAndUse(fabricCanvas, textObject);
        var currentFontFamily = textObject.fontFamily;
        var currentFill = textObject.fill;
        var setProperty = function(transformProp, textObjectProp) {
            // For font family (particularly), we must preload the font using FontFaceObserver instead.
            if (textObjectProp === "fontFamily") return;
            if (transform[transformProp] === undefined) return;
            if (!isEqual(textObject[textObjectProp], transform[transformProp])) {
                textObject.set(textObjectProp, transform[transformProp]);
            }
            if (fabrics && textObjectProp === "fill") {
                // UPDATE CMYK COLORSET
                // MAP CMYK INFO
                var colorInfo = mapColorCMYK(fabrics, null, transform[transformProp]);
                textObject.set({
                    data: _object_spread_props(_object_spread({}, textObject.data), {
                        CMYK: colorInfo === null || colorInfo === void 0 ? void 0 : colorInfo.CMYK,
                        colorInfo: colorInfo
                    })
                });
            }
        };
        setProperty("rotate", "angle");
        setProperty("outline", "strokeWidth");
        setProperty("charSpacing", "charSpacing");
        setProperty("scale", "scaleX");
        setProperty("scale", "scaleY");
        setProperty("text", "text");
        setProperty("outlineColor", "stroke");
        setProperty("fillColor", "fill");
        setProperty("top", "top");
        setProperty("left", "left");
        setProperty("opacity", "opacity");
        textObject.setCoords();
        fabricRequestRender(fabricCanvas);
        if (currentFontFamily !== fontFamily || currentFill !== transform.fillColor) {
            // No need to delay because we switched to Promise instead.
            textObject.dirty = false;
            loadAndUse(fontFamily).then(function() {
                var ref;
                textObject.dirty = true;
                var svgObjects = fabricCanvas === null || fabricCanvas === void 0 ? void 0 : fabricCanvas.getObjects();
                var placeholderCanvas = svgObjects.find(function(phCanvas) {
                    return phCanvas.id === placeholderSvgId;
                });
                if ((placeholderCanvas === null || placeholderCanvas === void 0 ? void 0 : (ref = placeholderCanvas.data) === null || ref === void 0 ? void 0 : ref.hasFreePositioning) !== true) {
                    adjustPlaceholder({
                        fabricCanvas: fabricCanvas,
                        placeholderSvgId: placeholderSvgId,
                        isPlaceholderDefault: isPlaceholderDefault,
                        placeholderId: placeholderId,
                        fabricText: textObject
                    });
                    textToImage(fabricCanvas, textObject);
                }
                fabricRequestRender(fabricCanvas);
            });
        }
        if (triggerElementModified) {
            fabricCanvas.trigger("object:modified", {
                target: textObject
            });
        }
    } catch (e) {
        console.error("updateText error", e);
    }
};
var createLoadAndUse = function(canvas, textObject) {
    return function() {
        var _ref = _async_to_generator(function(font) {
            return _ts_generator(this, function(_state) {
                if (!font) return [
                    2
                ];
                textObject.set("fontFamily", font);
                canvas.requestRenderAll();
                return [
                    2
                ];
            });
        });
        return function(font) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var mirrorText = function(param) {
    var fabricCanvas = param.fabricCanvas, textId = param.textId, mirrorType = param.mirrorType;
    var textObject = fabricCanvas.getObjects().find(function(obj) {
        var ref;
        return ((ref = obj.data) === null || ref === void 0 ? void 0 : ref.id) === textId;
    });
    if (!textObject) {
        return;
    }
    var mirrorData = Boolean(!textObject[mirrorType]);
    textObject.set(mirrorType, mirrorData);
    var objectData = Object.assign(_object_spread({}, textObject.data), _define_property({}, mirrorType, mirrorData));
    textObject.set("data", objectData);
    textObject.setCoords();
    fabricRequestRender(fabricCanvas);
    fabricCanvas.trigger("object:modified", {
        target: textObject
    });
};
export var toggleLockText = function(param) {
    var fabricCanvas = param.fabricCanvas, textId = param.textId;
    var textObject = fabricCanvas.getObjects().find(function(obj) {
        var ref;
        return ((ref = obj.data) === null || ref === void 0 ? void 0 : ref.id) === textId;
    });
    if (!textObject) {
        return;
    }
    var lockData = {
        lockMovementX: Boolean(!textObject["lockMovementX"]),
        lockMovementY: Boolean(!textObject["lockMovementY"]),
        lockScalingX: Boolean(!textObject["lockScalingX"]),
        lockScalingY: Boolean(!textObject["lockScalingY"]),
        lockUniScaling: Boolean(!textObject["lockUniScaling"]),
        lockRotation: Boolean(!textObject["lockRotation"])
    };
    // const objectData = Object.assign({ ...textObject.data }, lockData)
    textObject.set(lockData);
    // textObject.set('data', objectData)
    fabricCanvas.discardActiveObject();
    fabricCanvas.targetControl = null;
    fabricCanvas.targetObject = null;
    fabricCanvas.targetObjectPos = {
        left: 0,
        top: 0
    };
    fabricRequestRender(fabricCanvas);
    fabricCanvas.trigger("object:modified", {
        target: textObject
    });
    return textObject.lockMovementX;
};
export var toggleVisibleText = function(param) {
    var fabricCanvas = param.fabricCanvas, textId = param.textId;
    var textObject = fabricCanvas.getObjects().find(function(obj) {
        var ref;
        return ((ref = obj.data) === null || ref === void 0 ? void 0 : ref.id) === textId;
    });
    textObject.set("visible", Boolean(!textObject.visible));
    fabricCanvas.discardActiveObject();
    fabricRequestRender(fabricCanvas);
    fabricCanvas.trigger("object:modified", {
        target: textObject
    });
    return textObject.visible;
};
export var changeFontFamily = function(param) {
    var fabricCanvas = param.fabricCanvas, textId = param.textId, fontFamily = param.fontFamily;
    var textObject = fabricCanvas.getObjects().find(function(obj) {
        var ref;
        return ((ref = obj.data) === null || ref === void 0 ? void 0 : ref.id) === textId;
    });
    textObject.set("fontFamily", fontFamily);
    textObject.setCoords();
    fabricCanvas.trigger("object:modified", {
        target: textObject
    });
};
// Convert fabric Text to image for fitting text bounding in to placeholder area
export function textToImage(fabricCanvas, textObject) {
    var ref, ref1;
    if (!(textObject === null || textObject === void 0 ? void 0 : (ref = textObject.data) === null || ref === void 0 ? void 0 : ref.placeholderSvgId)) {
        return;
    }
    var objects = fabricCanvas === null || fabricCanvas === void 0 ? void 0 : fabricCanvas.getObjects();
    var placeholderObject = objects.find(function(phCanvas) {
        var ref;
        return phCanvas.id === (textObject === null || textObject === void 0 ? void 0 : (ref = textObject.data) === null || ref === void 0 ? void 0 : ref.placeholderSvgId);
    });
    var data = placeholderObject.data;
    if (!data) throw new Error("no data");
    var hasFreePositioning = data.hasFreePositioning;
    // ONLY NON FREE POSITIONING ALLOW
    if (hasFreePositioning) return;
    // REMOVE PREV ONCE
    removeTextImage(fabricCanvas, textObject === null || textObject === void 0 ? void 0 : (ref1 = textObject.data) === null || ref1 === void 0 ? void 0 : ref1.id);
    // TOGGLE FABRIC TEXT VISIBLE FOR CAPTURE TO CANVAS ELEMENT
    textObject.set({
        visible: true,
        angle: 0
    }) // RETURN ANGLE TO 0 to PROPER SET FIT DIMENSION
    ;
    textObject.scaleToWidth(1000) // Text image quality control (visualize only)
    ;
    var textCanvas = textObject.toCanvasElement();
    textObject.set({
        visible: false
    });
    var context_text = textCanvas.getContext("2d");
    var canvasWidth = textCanvas.width;
    var canvasHeight = textCanvas.height;
    var imageData = context_text.getImageData(0, 0, canvasWidth, canvasHeight);
    var collectXY = getPixelCoord(imageData, canvasWidth);
    if (!collectXY) {
        // NO COORD RECEIVE THEN STOP PROCESS AND TOGGLE FABRIC TEXT BACK
        textObject.set({
            visible: true
        });
        return;
    } else {
        textObject.set({
            excludeFromExport: true
        });
    }
    var minMaxCoord = getMinMaxPixelCoord(collectXY);
    var newWidth = minMaxCoord.width;
    var newHeight = minMaxCoord.height;
    var newCanvas = document.createElement("canvas");
    newCanvas.width = newWidth;
    newCanvas.height = newHeight;
    var new_context = newCanvas.getContext("2d");
    new_context.clearRect(0, 0, newWidth, newHeight);
    new_context.drawImage(textCanvas, minMaxCoord.minX, minMaxCoord.minY, minMaxCoord.width, minMaxCoord.height, 0, 0, minMaxCoord.width, minMaxCoord.height);
    fabric.Image.fromURL(newCanvas.toDataURL(), function(imageText) {
        imageText.set({
            data: {
                refId: "REF_".concat(textObject.data.id),
                type: "TextImage"
            },
            originX: "center",
            originY: "center",
            left: placeholderObject.getCenterPoint().x,
            top: placeholderObject.getCenterPoint().y,
            evented: false
        });
        var scaledWidth = placeholderObject.width * placeholderObject.scaleX;
        var scaledHeight = placeholderObject.height * placeholderObject.scaleY;
        // LOGIC FOR ADJUST SIZE IN PLACEHOLDER
        imageText.scaleToWidth(scaledWidth);
        if (imageText.height * imageText.scaleX > scaledHeight) {
            imageText.scaleToHeight(scaledHeight);
        }
        imageText.setCoords();
        imageText.setControlsVisibility(OBJECT_PLACEHOLDER_VISIBILITY_PROPERTIES) // ALL ICON CONTROL DISABLE
        ;
        imageText.set(placeholderProperties) // AUTO LOCK ALL
        ;
        imageText.set({
            angle: placeholderObject.angle
        }) // SET CURRENT ANGLE BASE ON PLACEHOLDER ANGLE
        ;
        fabricCanvas.add(imageText);
        fabricRequestRender(fabricCanvas);
    });
}
export function removeTextImage(fabricCanvas, textId) {
    if (!textId) {
        return;
    }
    var objects = fabricCanvas === null || fabricCanvas === void 0 ? void 0 : fabricCanvas.getObjects();
    // REMOVE EXIST TEXT_IMAGE
    var currentTextImage = objects.find(function(obj) {
        var ref;
        return (obj === null || obj === void 0 ? void 0 : (ref = obj.data) === null || ref === void 0 ? void 0 : ref.refId) === "REF_" + textId;
    });
    if (currentTextImage) {
        fabricCanvas.remove(currentTextImage);
    }
// REMOVE EXIST TEXT_IMAGE
}
