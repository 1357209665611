import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { downloadFileToUser, fixCDN } from "@brikl/studio-utils";
import { getMinMaxPixelCoord, getPixelCoord } from "../../_fabric/util";
import { embedTextAndLogoToCapture, mergeCaptureWithProductInfo } from "../reconciler";
/**
 * tranform image in base64 format to canvas element
 * @param  {string} base64 image in base64 format
 */ export function loadBase64Process(base64) {
    return new Promise(function(resolve, reject) {
        var imageBase64 = new Image();
        imageBase64.crossOrigin = "anonymous";
        imageBase64.onload = function() {
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            canvas.width = imageBase64.width;
            canvas.height = imageBase64.height;
            ctx.drawImage(imageBase64, 0, 0, canvas.width, canvas.height);
            var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            // TRIM PROCESS
            canvas.remove();
            canvas = null;
            var imageTrim = trimImage({
                imageBase64: imageBase64,
                imageData: imageData,
                width: imageBase64.width,
                height: imageBase64.height
            });
            // TRIM PROCESS
            resolve({
                width: imageTrim.img_canvas.width,
                height: imageTrim.img_canvas.height,
                img_dom: imageTrim.img_dom,
                img_canvas: imageTrim.img_canvas
            });
        };
        imageBase64.onerror = reject;
        imageBase64.src = base64;
    });
}
/**
 * trim image with specified width and height value
 * @param  {ITrimImageOptions}
 * @returns dataURL
 */ export function trimImage(param) {
    var imageBase64 = param.imageBase64, imageData = param.imageData, width = param.width, height = param.height;
    var collectXY = getPixelCoord(imageData, width);
    var minMaxCoord = getMinMaxPixelCoord(collectXY);
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    canvas.width = minMaxCoord.width;
    canvas.height = minMaxCoord.height;
    ctx.drawImage(imageBase64, -minMaxCoord.minX, -minMaxCoord.minY, width, height);
    var trimmedCanvas = document.createElement("canvas");
    var ctx2 = trimmedCanvas.getContext("2d");
    trimmedCanvas.width = width;
    trimmedCanvas.height = height;
    ctx2.drawImage(canvas, trimmedCanvas.width / 2 - canvas.width / 2, trimmedCanvas.height / 2 - canvas.height / 2);
    var dataURL = trimmedCanvas.toDataURL("image/png");
    canvas.remove();
    canvas = null;
    return {
        img_canvas: trimmedCanvas,
        img_dom: dataURL
    };
}
/**
 * Create grid of image from 2d texture to multiple view of model
 * @param  {HTMLCanvasElement} canvas
 * @param  {CanvasRenderingContext2D} ctx
 * @param  {any[]} imgSET
 */ export var createGridImages = function(canvas, ctx, imageSets) {
    var _imageSets = _sliced_to_array(imageSets, 4), imageOne = _imageSets[0], imageTwo = _imageSets[1], imageThree = _imageSets[2], imageFour = _imageSets[3];
    var base_width = imageOne.width;
    var base_height = imageOne.height;
    canvas.width = imageOne.width * 2;
    if (imageSets.length === 2) {
        canvas.height = imageOne.height;
        ctx.drawImage(imageOne.img_canvas, 0, 0, base_width, base_height);
        ctx.drawImage(imageTwo.img_canvas, base_width, 0, base_width, base_height);
    } else if (imageSets.length === 3) {
        canvas.height = imageOne.height * 2;
        // item one
        ctx.drawImage(imageOne.img_canvas, 0, 0, base_width, base_height);
        // item two
        ctx.drawImage(imageTwo.img_canvas, imageTwo.width, 0, base_width, base_height);
        // item three
        ctx.drawImage(imageThree.img_canvas, 0, base_height, base_width, base_height);
    } else if (imageSets.length === 4) {
        canvas.height = imageOne.height * 2;
        ctx.drawImage(imageOne.img_canvas, 0, 0, base_width, base_height);
        ctx.drawImage(imageTwo.img_canvas, imageOne.width, 0, base_width, base_height);
        ctx.drawImage(imageThree.img_canvas, 0, base_height, base_width, base_height);
        ctx.drawImage(imageFour.img_canvas, base_width, base_height, base_width, base_height);
    }
    return canvas;
};
/**
 * create multiple view from 3d model to image file
 *
 * @param  {string[]} captureSets list of images in base64 format
 * @param  {IProductData} productData product data to export
 * @param  {boolean} shouldDownload flag to auto download file when export
 * @param  {string} productTitle product title
 * @param  {EmbedCapturePosition | undefined } productTitlePosition position of title on export image
 * @param  {EmbedCapturePosition | undefined } productLogoPosition position of logo on export image
 * @param  {string | undefined} productLogoData product logo image
 */ export function createMultipleCapture(_) {
    return _createMultipleCapture.apply(this, arguments);
}
function _createMultipleCapture() {
    _createMultipleCapture = _async_to_generator(function(param) {
        var captureSets, fabricCanvas, productData, productLogoData, productLogoPosition, productTitle, productTitlePosition, shouldDownload, captureEvent, _tmp, images, canvas, context;
        return _ts_generator(this, function(_state) {
            captureSets = param.captureSets, fabricCanvas = param.fabricCanvas, productData = param.productData, productLogoData = param.productLogoData, productLogoPosition = param.productLogoPosition, productTitle = param.productTitle, productTitlePosition = param.productTitlePosition, shouldDownload = param.shouldDownload;
            if (captureSets.length === 0) throw new Error("captureSets is required");
            _tmp = {};
            captureEvent = new CustomEvent("captureEvent", (_tmp.bubbles = true, _tmp.detail = captureSets, _tmp));
            images = captureSets.map(function(captureBase64) {
                return loadBase64Process(captureBase64);
            });
            canvas = document.createElement("canvas");
            context = canvas.getContext("2d");
            if (!canvas || !context) throw new Error("canvas and 2d are not ready!");
            Promise.all(images).then(function() {
                var _ref = _async_to_generator(function(image) {
                    var canvasGrid, dataURL, embedData, downloadOptions, _tmp;
                    function getEmbedData() {
                        return _getEmbedData.apply(this, arguments);
                    }
                    function _getEmbedData() {
                        _getEmbedData = _async_to_generator(function() {
                            var _tmp;
                            return _ts_generator(this, function(_state) {
                                switch(_state.label){
                                    case 0:
                                        if (!(productTitle || productLogoData)) return [
                                            3,
                                            2
                                        ];
                                        _tmp = {};
                                        return [
                                            4,
                                            embedTextAndLogoToCapture((_tmp.dataURL = dataURL, _tmp.productLogoData = productLogoData, _tmp.productTitle = productTitle, _tmp.productLogoPosition = productLogoPosition, _tmp.productTitlePosition = productTitlePosition, _tmp))
                                        ];
                                    case 1:
                                        return [
                                            2,
                                            _state.sent()
                                        ];
                                    case 2:
                                        return [
                                            2,
                                            dataURL
                                        ];
                                }
                            });
                        });
                        return _getEmbedData.apply(this, arguments);
                    }
                    return _ts_generator(this, function(_state) {
                        switch(_state.label){
                            case 0:
                                canvasGrid = createGridImages(canvas, context, image);
                                dataURL = canvasGrid.toDataURL("image/png");
                                if (productData) {
                                    mergeCaptureWithProductInfo(dataURL, productData, "BRIKL-3D-MODEL-MULTIPLE-VIEW.png", "3D", fabricCanvas);
                                }
                                if (!shouldDownload) return [
                                    3,
                                    2
                                ];
                                return [
                                    4,
                                    getEmbedData()
                                ];
                            case 1:
                                embedData = _state.sent();
                                _tmp = {};
                                downloadOptions = (_tmp.attributes = [
                                    {
                                        qualifyName: "id",
                                        value: "capture-preview"
                                    }, 
                                ], _tmp.filename = "BRIKL-3D-MODEL-MULTIPLE-VIEW.png", _tmp.embedData = embedData, _tmp);
                                downloadFileToUser(downloadOptions);
                                _state.label = 2;
                            case 2:
                                window.dispatchEvent(captureEvent);
                                return [
                                    2
                                ];
                        }
                    });
                });
                return function(image) {
                    return _ref.apply(this, arguments);
                };
            }());
            return [
                2
            ];
        });
    });
    return _createMultipleCapture.apply(this, arguments);
}
export function downloadFileWithoutCache(_) {
    return _downloadFileWithoutCache.apply(this, arguments);
}
function _downloadFileWithoutCache() {
    _downloadFileWithoutCache = _async_to_generator(function(param) {
        var filename, fileUrl, headers, blobData, _tmp, embedData, _tmp1;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    filename = param.filename, fileUrl = param.fileUrl;
                    headers = new Headers();
                    headers.append("pragma", "no-cache");
                    headers.append("cache-control", "no-cache");
                    _tmp = {};
                    return [
                        4,
                        fetch(fixCDN(fileUrl), (_tmp.method = "GET", _tmp.headers = headers, _tmp))
                    ];
                case 1:
                    return [
                        4,
                        _state.sent().blob()
                    ];
                case 2:
                    blobData = _state.sent();
                    embedData = URL.createObjectURL(blobData);
                    _tmp1 = {};
                    downloadFileToUser((_tmp1.filename = filename, _tmp1.embedData = embedData, _tmp1));
                    return [
                        2
                    ];
            }
        });
    });
    return _downloadFileWithoutCache.apply(this, arguments);
}
