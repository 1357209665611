import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import orderBy from "lodash/orderBy";
import { createSelector, createStructuredSelector } from "reselect";
import { initialState } from "./constants";
var selectDomain = function(state) {
    return state.image || initialState;
};
var getImageFromOrigin = function(images, id) {
    var image = images.find(function(image) {
        return (image === null || image === void 0 ? void 0 : image.id) === id;
    });
    if (!image) return null;
    return image.base64 || image.srcUrl;
};
export var makeSelectUploadedImages = createSelector(selectDomain, function(imageState) {
    return imageState === null || imageState === void 0 ? void 0 : imageState.uploadedImages;
});
export var makeSelectDeletedPlacedImage = createSelector(selectDomain, function(imageState) {
    return imageState === null || imageState === void 0 ? void 0 : imageState.deletedPlacedImages;
});
export var makeSelectImageLibrary = createSelector(selectDomain, function(imageState) {
    return imageState.imageLibrary || [];
});
export var makeSelectPlacedImages = createSelector(makeSelectUploadedImages, makeSelectImageLibrary, selectDomain, function(uploadedImages, imageLibrary, imageState) {
    var placedImages = function(image) {
        var uploadedImageUrl = getImageFromOrigin(_to_consumable_array(imageLibrary).concat(_to_consumable_array(uploadedImages)), image.defaultImageId);
        return _object_spread_props(_object_spread({}, image), {
            srcUrl: uploadedImageUrl || image.srcUrl,
            base64: uploadedImageUrl || image.base64
        });
    };
    return orderBy(imageState === null || imageState === void 0 ? void 0 : imageState.placedImages.map(placedImages), function(image) {
        return image.orderSEQ;
    }) || [];
});
export var imageSelector = createStructuredSelector({
    uploadedImages: makeSelectUploadedImages,
    placedImages: makeSelectPlacedImages,
    imageLibrary: makeSelectImageLibrary,
    deletedPlacedImages: makeSelectDeletedPlacedImage
});
