import { createSlice as reduxCreateSlice } from "@reduxjs/toolkit";
var createSlice = function(param) {
    var name = param.name, initialState = param.initialState, reducers = param.reducers;
    return reduxCreateSlice({
        name: name,
        initialState: initialState,
        reducers: reducers
    });
};
export default createSlice;
