import { boxContainsPoint, rotateAroundPointByAngle } from "@brikl/studio-utils";
import { getUVCoordinatesFromIntersection } from "../";
import { fabricRequestRender } from "../_fabric/util";
import { getMaterial } from "../_three/utils";
var transform3DCoordinatesTo2D = function(param) {
    var uv = param.uv, fabricCanvas = param.fabricCanvas;
    var upperCanvasRect = fabricCanvas.upperCanvasEl.getBoundingClientRect();
    if (upperCanvasRect) {
        var gapX = fabricCanvas.viewportTransform ? upperCanvasRect.left + fabricCanvas.viewportTransform[5] : upperCanvasRect.left;
        var gapY = fabricCanvas.viewportTransform ? upperCanvasRect.top + fabricCanvas.viewportTransform[4] : upperCanvasRect.top;
        var clientX = uv.x + gapX;
        var clientY = uv.y + gapY;
        return {
            x: clientX,
            y: clientY
        };
    }
    return null;
};
export var syncMouseDownWithCanvas = function(param) {
    var fabricCanvas = param.fabricCanvas, orbitControls = param.orbitControls;
    return function(data) {
        var ref;
        if (!fabricCanvas) return;
        if (((ref = data.intersects) === null || ref === void 0 ? void 0 : ref.length) > 0) {
            // check user select colorzone or 3D Element
            var child = data.intersects[0].object;
            var materials = getMaterial(child.material);
            var isColorZone = materials.find(function(mat) {
                var ref;
                return ((ref = mat.userData) === null || ref === void 0 ? void 0 : ref.PrintPattern) === true;
            });
            if (!isColorZone) {
                // When user selected 3D element (eg. Zipper)
                var colorzoneSelectionBox = document.querySelector("#colorzoneSelectionBox");
                var has3DZone = false;
                var attr3DZone;
                // Check 3D element selected it on the list of selection or not
                colorzoneSelectionBox.querySelectorAll("option").forEach(function(option) {
                    var dataFullcode = option.getAttribute("data-fullcode");
                    // only stitch that have special name that start with "MatShape_" or "Stitch_"
                    if (dataFullcode === child.name || dataFullcode === "ES-STITCH" && (child.name.indexOf("MatShape_") !== -1 || child.name.indexOf("Stitch_") !== -1)) {
                        has3DZone = true;
                        attr3DZone = {
                            fullcode: dataFullcode,
                            value: option.getAttribute("value")
                        };
                    }
                });
                // If yes, then trigger event to selection box
                if (has3DZone && attr3DZone) {
                    // trigger event when export finished. to stop loading indicator
                    var select3DElement = new CustomEvent("select3DElement", {
                        bubbles: true,
                        detail: {
                            fullcode: attr3DZone.fullcode,
                            value: attr3DZone.value
                        }
                    });
                    window.dispatchEvent(select3DElement);
                }
                return;
            }
            var width = fabricCanvas.width, height = fabricCanvas.height;
            var uv = getUVCoordinatesFromIntersection({
                intersection: data.intersects[0],
                width: width,
                height: height
            });
            var canvasCoords = transform3DCoordinatesTo2D({
                uv: uv,
                fabricCanvas: fabricCanvas
            });
            if (canvasCoords) {
                // emitCoordinates({ left: canvasCoords.x, top: canvasCoords.y })
                fabricCanvas.targetObjectPos = {
                    left: canvasCoords.x / fabricCanvas.zoomLevel,
                    top: canvasCoords.y / fabricCanvas.zoomLevel
                };
                fabricCanvas.prevTargetObjectPos = {
                    left: 0,
                    top: 0
                };
                var obj = fabricCanvas.getActiveObject();
                fabricCanvas.targetControl = null;
                if (obj) {
                    // Find whether the mousedown is clicking on top left corner, top right corner, or neither.
                    if (!obj.lockRotation && boxContainsPoint(obj.oCoords.tl.corner, canvasCoords.x, canvasCoords.y)) fabricCanvas.targetControl = "tl";
                    if ((!obj.lockScalingX || !obj.lockScalingY) && boxContainsPoint(obj.oCoords.tr.corner, canvasCoords.x, canvasCoords.y)) fabricCanvas.targetControl = "tr";
                    if (obj && boxContainsPoint(obj.oCoords.mt.corner, canvasCoords.x, canvasCoords.y)) {
                        fabricCanvas.targetControl = "mt";
                        obj.bringForward();
                        fabricCanvas.trigger("object:relayer", {
                            target: obj
                        });
                    }
                    if (obj && boxContainsPoint(obj.oCoords.mb.corner, canvasCoords.x, canvasCoords.y)) {
                        fabricCanvas.targetControl = "mb";
                        obj.sendBackwards();
                        fabricCanvas.trigger("object:relayer", {
                            target: obj
                        });
                    }
                    // Prepare targetObject to do some transformation (such as rotation or scaling)
                    if (fabricCanvas.targetControl) fabricCanvas.targetObject = obj;
                }
                var selectedObject = fabricCanvas.getObjects().filter(function(object) {
                    return boxContainsPoint(object.aCoords, canvasCoords.x, canvasCoords.y);
                });
                if (selectedObject.length > 0) fabricCanvas.trigger("selection:created", {
                    target: selectedObject[0]
                });
                if (!fabricCanvas.targetControl) {
                    // This will be triggered in case of clicking outside the object, or inside the object but not the corner.
                    // This will be the list of text objects where the current mouse is inside.
                    var targetObjects = fabricCanvas.getObjects().map(function(object) {
                        if (!object.lockMovementX && !object.lockMovementY && boxContainsPoint(object.aCoords, canvasCoords.x, canvasCoords.y)) return object;
                        else return null;
                    }).filter(function(object) {
                        return object !== null;
                    });
                    // Set the topmost object to be the target object and bring to front.
                    fabricCanvas.targetObject = targetObjects[targetObjects.length - 1];
                    if (fabricCanvas.targetObject) {
                        // In case of interacting with some object
                        fabricCanvas.setActiveObject(fabricCanvas.targetObject);
                    } else {
                        // In case of interacting with colorzone or something else (that is not an object)
                        fabricCanvas.discardActiveObject();
                        var evt = new MouseEvent("mousedown", {
                            clientX: canvasCoords.x,
                            clientY: canvasCoords.y,
                            bubbles: true
                        });
                        fabricCanvas.upperCanvasEl.dispatchEvent(evt);
                    }
                    fabricRequestRender(fabricCanvas);
                }
            }
        } else {
            // In case of clicking outside all objects, reset everything.
            fabricCanvas.discardActiveObject();
            fabricCanvas.targetObject = null;
            fabricCanvas.targetObjectPos = {
                left: 0,
                top: 0
            };
            fabricCanvas.prevTargetObjectPos = {
                left: 0,
                top: 0
            };
            fabricRequestRender(fabricCanvas);
            if (orbitControls) orbitControls.enabled = true;
        }
    };
};
export var syncMouseMoveWithCanvas = function(param) {
    var svgDimension = param.svgDimension, fabricCanvas = param.fabricCanvas, orbitControls = param.orbitControls;
    return function(data) {
        var ref;
        if (!svgDimension || !orbitControls || !fabricCanvas) {
            return;
        }
        if (((ref = data.intersects) === null || ref === void 0 ? void 0 : ref.length) > 0 && svgDimension) {
            // check user select colorzone or 3D Element
            var child = data.intersects[0].object;
            var materials = getMaterial(child.material);
            var isColorZone = materials.find(function(mat) {
                var ref;
                return ((ref = mat.userData) === null || ref === void 0 ? void 0 : ref.PrintPattern) === true;
            });
            if (!isColorZone) {
                return;
            }
            var uv = getUVCoordinatesFromIntersection({
                intersection: data.intersects[0],
                width: fabricCanvas.width,
                height: fabricCanvas.height
            });
            var canvasCoords = transform3DCoordinatesTo2D({
                uv: uv,
                fabricCanvas: fabricCanvas
            });
            // TODO: Improve targetControl out of bound or drag cross elm mouse event (collect last coord compare with current coord)
            if (canvasCoords) {
                if ((data.mouse.pressBtn & 1) !== 0) {
                    if (fabricCanvas.targetObject && fabricCanvas.targetObjectPos && fabricCanvas.prevTargetObjectPos && !fabricCanvas.targetObject.lockScalingX && !fabricCanvas.targetObject.lockScalingY) {
                        var evtUp = new MouseEvent("mouseup", {
                            clientX: canvasCoords.x,
                            clientY: canvasCoords.y,
                            bubbles: true
                        });
                        fabricCanvas.upperCanvasEl.dispatchEvent(evtUp);
                        orbitControls.enabled = false;
                        var prevTargetObjectPos = fabricCanvas.prevTargetObjectPos;
                        var newX = canvasCoords.x / fabricCanvas.zoomLevel;
                        var newY = canvasCoords.y / fabricCanvas.zoomLevel;
                        var lastX = prevTargetObjectPos.left;
                        var lastY = prevTargetObjectPos.top;
                        fabricCanvas.targetObject = fabricCanvas.getActiveObject();
                        if (fabricCanvas.targetObject && !fabricCanvas.targetObject.lock) {
                            var diffVec = {
                                x: newX - lastX,
                                y: newY - lastY
                            };
                            if (fabricCanvas.targetControl !== "tr" && fabricCanvas.targetControl !== "tl") {
                                if (prevTargetObjectPos.left !== 0 && prevTargetObjectPos.top !== 0) {
                                    fabricCanvas.targetObject.left += diffVec.x;
                                    fabricCanvas.targetObject.top += diffVec.y;
                                }
                                // for (const coord in fabricCanvas.targetObject.aCoords)
                                //   updatePos(fabricCanvas.targetObject.aCoords[coord])
                                // for (const coord in fabricCanvas.targetObject.oCoords) {
                                //   const point = fabricCanvas.targetObject.oCoords[coord]
                                //   updatePos(point)
                                //   for (const subcoord in point.corner) {
                                //     updatePos(point.corner[subcoord])
                                //   }
                                // }
                                fabricCanvas.trigger("object:moving", {
                                    target: fabricCanvas.targetObject
                                });
                            } else if (fabricCanvas.targetControl === "tr") {
                                // In case of scaling object
                                // bottomLeftVector is a vector pointing from the center towards bottom left corner
                                var bottomeLeftVector = {
                                    x: -fabricCanvas.targetObject.width * fabricCanvas.targetObject.scaleX / 2,
                                    y: fabricCanvas.targetObject.height * fabricCanvas.targetObject.scaleY / 2
                                };
                                rotateAroundPointByAngle(bottomeLeftVector, [
                                    0,
                                    0
                                ], fabricCanvas.targetObject.angle * Math.PI / 180);
                                var displacementX = newX - (fabricCanvas.targetObject.left + bottomeLeftVector.x);
                                var displacementY = newY - (fabricCanvas.targetObject.top + bottomeLeftVector.y);
                                var projectionX = {
                                    x: fabricCanvas.targetObject.aCoords.br.x - fabricCanvas.targetObject.aCoords.bl.x,
                                    y: fabricCanvas.targetObject.aCoords.br.y - fabricCanvas.targetObject.aCoords.bl.y
                                };
                                var projectionY = {
                                    x: fabricCanvas.targetObject.aCoords.tl.x - fabricCanvas.targetObject.aCoords.bl.x,
                                    y: fabricCanvas.targetObject.aCoords.tl.y - fabricCanvas.targetObject.aCoords.bl.y
                                };
                                var norm = function(vec) {
                                    return Math.sqrt(vec.x * vec.x + vec.y * vec.y);
                                };
                                var componentX = (displacementX * projectionX.x + displacementY * projectionX.y) / norm(projectionX);
                                var componentY = (displacementX * projectionY.x + displacementY * projectionY.y) / norm(projectionY);
                                var scalingFactor = (componentX / fabricCanvas.targetObject.width + componentY / fabricCanvas.targetObject.height) / 2 / fabricCanvas.targetObject.scaleX;
                                if (scalingFactor > 0) {
                                    //Checking if scaling doesn't harm the minimum scaleX and scaleY from object setting minScaleLimit
                                    if (fabricCanvas.targetObject.scaleX * scalingFactor >= fabricCanvas.targetObject.minScaleLimit && fabricCanvas.targetObject.scaleY * scalingFactor >= fabricCanvas.targetObject.minScaleLimit) {
                                        fabricCanvas.targetObject.left -= (scalingFactor - 1) * bottomeLeftVector.x;
                                        fabricCanvas.targetObject.top -= (scalingFactor - 1) * bottomeLeftVector.y;
                                        fabricCanvas.targetObject.scaleX *= scalingFactor;
                                        fabricCanvas.targetObject.scaleY *= scalingFactor;
                                        fabricCanvas.targetObject.setCoords();
                                        fabricCanvas.trigger("object:scaling", {
                                            target: fabricCanvas.targetObject
                                        });
                                    }
                                }
                            } else if (fabricCanvas.targetControl === "tl") {
                                // In case of rotating object
                                var newAngle = Math.atan2(newY - fabricCanvas.targetObject.top, newX - fabricCanvas.targetObject.left);
                                fabricCanvas.targetObject.angle = 0;
                                fabricCanvas.targetObject.setCoords();
                                var initAngle = Math.atan2(fabricCanvas.targetObject.aCoords.tl.y - fabricCanvas.targetObject.top, fabricCanvas.targetObject.aCoords.tl.x - fabricCanvas.targetObject.left);
                                fabricCanvas.targetObject.angle = (newAngle - initAngle) * 180 / Math.PI;
                                fabricCanvas.targetObject.setCoords();
                                fabricCanvas.trigger("object:rotating", {
                                    target: fabricCanvas.targetObject
                                });
                            }
                            fabricCanvas.trigger("object:modified", {
                                target: fabricCanvas.targetObject
                            });
                        }
                        fabricCanvas.prevTargetObjectPos = {
                            left: newX,
                            top: newY
                        };
                        fabricRequestRender(fabricCanvas);
                    } else {
                        orbitControls.enabled = true;
                    }
                }
            // Removed the case for other mouse buttons
            }
        }
    };
};
