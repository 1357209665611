import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { put, select, takeLatest } from "redux-saga/effects";
import { actions as AopEngineActions } from "../AopEngine/slice";
import { selectSelectedDesignStep } from "./selectors";
import { actions } from "./slice";
function onInitialDesignStep() {
    var designStep, _tmp;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    select(selectSelectedDesignStep)
                ];
            case 1:
                designStep = _state.sent();
                if (!designStep) return [
                    2
                ];
                _tmp = {};
                // when loading the studio we select the first step (lowest sort order value)
                return [
                    4,
                    put(actions.selectStep((_tmp.id = designStep === null || designStep === void 0 ? void 0 : designStep.id, _tmp)))
                ];
            case 2:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
function onSelectDesignStep() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                // clear AOP selection
                return [
                    4,
                    put(AopEngineActions.focusAop(null))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
export default function rootSaga() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    takeLatest(actions.setDesignSteps.type, onInitialDesignStep)
                ];
            case 1:
                _state.sent();
                return [
                    4,
                    takeLatest(actions.selectStep.type, onSelectDesignStep)
                ];
            case 2:
                _state.sent();
                return [
                    2
                ];
        }
    });
};
