import { createSlice } from "../../@redux";
import { initialState } from "./constants";
export var slice = createSlice({
    name: "designStep",
    initialState: initialState,
    reducers: {
        resetData: function resetData() {
            return initialState;
        },
        reset: function reset(state) {
            state.selectedDesignStepId = null;
        },
        setDesignSteps: function setDesignSteps(state, param) {
            var payload = param.payload;
            state.designSteps = payload;
            state.isReady = true;
        },
        selectStep: function selectStep(state, param) {
            var payload = param.payload;
            state.selectedDesignStepId = payload.id;
        },
        goBack: function goBack(state) {
            var backStep = state.designSteps.find(function(d) {
                return d.type === "BACK";
            });
            if (backStep) {
                state.selectedDesignStepId = backStep.id;
            }
            state.selectedDesignStepId = null;
        }
    }
});
export var actions = slice.actions, reducer = slice.reducer, name = slice.name;
