import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { ZoneCustomizationType } from "@brikl/codegen";
export var customTemplateToZoneCustomizationInput = function(selectedAop) {
    // Clear the color fields when the custom AOP is in the selected zone.
    var clearColorFields = {
        colorHex: null,
        colorId: null
    };
    var id = selectedAop.id, defaultPrintId = selectedAop.defaultPrintId, zoneId = selectedAop.zoneId, transform = selectedAop.transform, templateId = selectedAop.templateId;
    return _object_spread({
        id: id,
        templateId: templateId,
        zoneId: zoneId,
        type: ZoneCustomizationType.AllOverPrintFill,
        alloverprintId: defaultPrintId,
        alloverprintCustomization: {
            offsetX: transform.offsetX,
            offsetY: transform.offsetY,
            rotation: transform.angle,
            scale: transform.scale
        }
    }, clearColorFields);
};
