import { BoardItemType } from "@brikl/codegen";
import { makePrintingTechniques } from "~/libs/store/src/util/placeholder";
import { getFile } from "../../../helpers/image";
import { transformPrintImages } from "./transform-print-images";
export var selectPrintImages = function(printDesigns, placeholders) {
    if (!printDesigns) return [];
    var printImages = transformPrintImages(printDesigns, placeholders);
    return printImages.filter(function(print) {
        return print.base64 || print.srcUrl;
    });
};
var createPrintLibrary = function(printLibrary) {
    var ref;
    return {
        id: printLibrary.id,
        fileName: getFile(printLibrary.imageUrl).fileName,
        base64: printLibrary.imageUrl,
        srcUrl: printLibrary.imageUrl || printLibrary.src,
        name: (ref = printLibrary.title) === null || ref === void 0 ? void 0 : ref.defaultValue
    };
};
export var isStepType = function(type) {
    return function(step) {
        return (step === null || step === void 0 ? void 0 : step.type) === type;
    };
};
export var isAOPStep = isStepType(BoardItemType.Alloverprint);
export var getPrintLibraries = function(designSteps) {
    var alloverPrintSteps = designSteps.filter(isAOPStep).flatMap(function(designStep) {
        var ref;
        return designStep === null || designStep === void 0 ? void 0 : (ref = designStep.printLibrary) === null || ref === void 0 ? void 0 : ref.map(createPrintLibrary);
    });
    return alloverPrintSteps;
};
export var getPrintingTechniques = function(designSteps, langCode) {
    return designSteps.filter(function(designStep) {
        return Array.isArray(designStep.colorPrintingTechniques);
    }).flatMap(function(designStep) {
        return makePrintingTechniques(designStep.colorPrintingTechniques, langCode);
    });
};
var ref, ref1;
export var getPrintingTechnique = function(designStep) {
    return {
        colorPrintingTechniques: (ref = designStep === null || designStep === void 0 ? void 0 : designStep.colorPrintingTechniques) !== null && ref !== void 0 ? ref : [],
        defaultPrintingTechniqueId: (ref1 = designStep === null || designStep === void 0 ? void 0 : designStep.defaultAopPrintingTechnique) !== null && ref1 !== void 0 ? ref1 : null
    };
};
