import { createSelector, createStructuredSelector } from "reselect";
import { initialState } from "./constants";
export var domainSelector = function(state) {
    return state.scene || initialState;
};
export var selectSelectedZoneId = createSelector(domainSelector, function(state) {
    return state.selectedZoneId;
});
export var selectSelectedZone3DElementId = createSelector(domainSelector, function(state) {
    return state.selectedZone3DElementId;
});
export var selectSelectedSVGElementId = createSelector(domainSelector, function(state) {
    return state.selectedSVGElementId;
});
export var sceneSelector = createStructuredSelector({
    selectedZoneId: selectSelectedZoneId,
    selectedZone3DElementId: selectSelectedZone3DElementId,
    selectedSVGElementId: selectSelectedSVGElementId
});
