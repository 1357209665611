import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { all, put, select, takeLatest } from "typed-redux-saga";
import { v4 } from "uuid";
import { AOP_TRANSFORM_INITIAL } from "@brikl/studio";
import { selectAllColorZones, selectSelectedColorZone } from "../../ColorFillEngine/selectors";
import { actions as colorFillEngineActions } from "../../ColorFillEngine/slice";
import { selectSelectedSVGElementId } from "../../SceneEngine/selectors";
import { actions as SceneEngineActions } from "../../SceneEngine/slice";
import { selectSelectedTemplate } from "../../TemplateEngine/selectors";
import { selectCustomTemplateAops, selectSelectedAop } from "../selectors";
import { actions } from "../slice";
export function onSelectAop(param) {
    var defaultPrintId, template, customTemplateAops, selectedZone, customTemplateAop, transformAop, error;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                defaultPrintId = param.payload;
                _state.label = 1;
            case 1:
                _state.trys.push([
                    1,
                    7,
                    ,
                    8
                ]);
                return [
                    5,
                    _ts_values(put(actions.setIsApplying(true)))
                ];
            case 2:
                _state.sent();
                return [
                    5,
                    _ts_values(select(selectSelectedTemplate))
                ];
            case 3:
                template = _state.sent();
                return [
                    5,
                    _ts_values(select(selectCustomTemplateAops))
                ];
            case 4:
                customTemplateAops = _state.sent();
                return [
                    5,
                    _ts_values(select(selectSelectedColorZone))
                ];
            case 5:
                selectedZone = _state.sent();
                customTemplateAop = customTemplateAops.find(function(ctAop) {
                    return (ctAop === null || ctAop === void 0 ? void 0 : ctAop.zone.zoneId) === selectedZone.zone.zoneId;
                });
                transformAop = null;
                // if found custom template
                if (customTemplateAop) {
                    // update it
                    transformAop = {
                        templateId: template === null || template === void 0 ? void 0 : template.id,
                        zoneId: selectedZone.zone.zoneId,
                        defaultPrintId: defaultPrintId,
                        id: customTemplateAop.id,
                        transform: customTemplateAop === null || customTemplateAop === void 0 ? void 0 : customTemplateAop.transform
                    };
                // otherwise
                } else {
                    // create new aop
                    transformAop = {
                        defaultPrintId: defaultPrintId,
                        id: v4(),
                        transform: AOP_TRANSFORM_INITIAL,
                        templateId: template === null || template === void 0 ? void 0 : template.id,
                        zoneId: selectedZone.zone.zoneId
                    };
                }
                console.log("saga:[onSelectAop] => completed ", transformAop);
                return [
                    5,
                    _ts_values(all([
                        put(actions.updateAop(transformAop)),
                        put(actions.focusAop(transformAop.id))
                    ]))
                ];
            case 6:
                _state.sent();
                return [
                    3,
                    8
                ];
            case 7:
                error = _state.sent();
                console.error("[AOP] => onSelectAop", error);
                return [
                    3,
                    8
                ];
            case 8:
                return [
                    2
                ];
        }
    });
}
// watch selected svg zone id
function onSelectSVGElement() {
    var selectedSVGZoneId, allColorZones, selectedColorZone, customTemplateAops, selectedZoneId, selectedAop, error;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    5,
                    ,
                    6
                ]);
                return [
                    5,
                    _ts_values(select(selectSelectedSVGElementId))
                ];
            case 1:
                selectedSVGZoneId = _state.sent();
                if (!selectedSVGZoneId) return [
                    2
                ];
                return [
                    5,
                    _ts_values(select(selectAllColorZones))
                ];
            case 2:
                allColorZones = _state.sent();
                selectedColorZone = allColorZones.find(function(allColorZone) {
                    return allColorZone.zone.svgIds.includes(selectedSVGZoneId);
                });
                if (!selectedColorZone) return [
                    2
                ];
                return [
                    5,
                    _ts_values(select(selectCustomTemplateAops))
                ];
            case 3:
                customTemplateAops = _state.sent();
                selectedZoneId = selectedColorZone.zone.zoneId;
                selectedAop = customTemplateAops.find(function(customTemplateAop) {
                    return customTemplateAop.zone.zoneId === selectedZoneId;
                });
                if (!selectedAop) return [
                    2
                ];
                console.log("saga:[onSelectSVGElement] => found existing AOP", selectedAop);
                return [
                    5,
                    _ts_values(put(actions.focusAop(selectedAop.id)))
                ];
            case 4:
                _state.sent();
                return [
                    3,
                    6
                ];
            case 5:
                error = _state.sent();
                console.error("saga:[onSelectSVGElement] => error", error);
                return [
                    3,
                    6
                ];
            case 6:
                return [
                    2
                ];
        }
    });
}
export function onFocusAop() {
    var selectedAop;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(select(selectSelectedAop))
                ];
            case 1:
                selectedAop = _state.sent();
                if (!selectedAop) return [
                    3,
                    3
                ];
                console.log("saga:[onFocusAop] => ", selectedAop, selectedAop.zone.zoneId);
                return [
                    5,
                    _ts_values(put(SceneEngineActions.selectZone(selectedAop.zone.zoneId)))
                ];
            case 2:
                _state.sent();
                _state.label = 3;
            case 3:
                return [
                    2
                ];
        }
    });
}
/**
 * Delete color if there is any color applied when switching zone from AOP component
 * @param action zoneId | current zone ID which is belong to selected AOP
 */ function onSwitchZoneAop(action) {
    var ref, aopZoneId, selectedZone, isSameZone, ref1, _tmp;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                aopZoneId = action.payload;
                return [
                    5,
                    _ts_values(select(selectSelectedColorZone))
                ];
            case 1:
                selectedZone = _state.sent();
                isSameZone = aopZoneId === (selectedZone === null || selectedZone === void 0 ? void 0 : (ref = selectedZone.zone) === null || ref === void 0 ? void 0 : ref.zoneId);
                if (!isSameZone) return [
                    3,
                    3
                ];
                _tmp = {};
                return [
                    5,
                    _ts_values(put(colorFillEngineActions.deleteColorByZoneId((_tmp.zoneId = selectedZone === null || selectedZone === void 0 ? void 0 : (ref1 = selectedZone.zone) === null || ref1 === void 0 ? void 0 : ref1.zoneId, _tmp))))
                ];
            case 2:
                _state.sent();
                _state.label = 3;
            case 3:
                return [
                    2
                ];
        }
    });
}
export function watchSelectAop() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(all([
                        takeLatest(actions.selectAop.type, onSelectAop),
                        // This action is obviously to check if user switch zone
                        // with current zone belong to current AOP or another
                        takeLatest(actions.ifSwitchZoneAop.type, onSwitchZoneAop),
                        takeLatest(actions.focusAop.type, onFocusAop),
                        takeLatest(SceneEngineActions.selectSVGElement.type, onSelectSVGElement)
                    ]))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
