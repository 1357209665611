import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import omit from "lodash/omit";
import { StudioActions } from "../..";
export function reducer(state, action) {
    if (state.isDebugEnabled) {
        console.info(action);
    }
    var assign = function(newState) {
        return Object.assign({}, state, _object_spread_props(_object_spread({}, newState), {
            prevAction: action,
            prevState: omit(state, "prevState")
        }));
    };
    switch(action.type){
        case StudioActions.INITIALIZE:
            return assign({
                initialized: true
            });
        case StudioActions.TOGGLE_DESIGNROOM:
            return assign({
                isDesignRoom: !state.isDesignRoom
            });
        case StudioActions.TOGGLE_DEBUG:
            return assign({
                isDebugEnabled: !state.isDebugEnabled
            });
        case StudioActions.TOGGLE_AUTO_ROTATE:
            return assign({
                isAutoRotateEnabled: !state.isAutoRotateEnabled
            });
        case StudioActions.TOGGLE_RECENTER_3D_CAMERA:
            return assign({
                isRecentering3DCamera: !state.isRecentering3DCamera
            });
        case StudioActions.TOGGLE_MOUSE_TRACKING:
            return assign({
                isMouseTrackingEnabled: !state.isMouseTrackingEnabled
            });
        case StudioActions.TOGGLE_3D_ZOOM:
            return assign({
                is3DZoomEnabled: !state.is3DZoomEnabled
            });
        case StudioActions.TOGGLE_2D_VISIBILITY:
            return assign({
                is2DVisible: !state.is2DVisible
            });
        case StudioActions.TOGGLE_3D_VISIBILITY:
            return assign({
                is3DVisible: !state.is3DVisible
            });
        case StudioActions.TOGGLE_BACKGROUND:
            return assign({
                isBackgroundEnabled: !state.isBackgroundEnabled
            });
        case StudioActions.SELECT_BACKGROUND:
            return assign({
                selectedBackground: action.payload.background
            });
        case StudioActions.CANVAS_READY:
            return assign({
                canvasSvgIds: action.payload.svgIds,
                canvas: action.payload.canvas,
                isCanvasReady: true
            });
        case StudioActions.LAYERS_READY:
            return assign({
                isLayersReady: true
            });
        case StudioActions.THREE_READY:
            return assign({
                isThreeReady: true
            });
        // case StudioActions.UPLOAD_PATTERN:
        //   return assign({
        //     uploadedPattern: [
        //       ...state.uploadedPattern,
        //       {
        //         id: action.payload.id,
        //         base64: action.payload.base64,
        //       },
        //     ],
        //   })
        // case StudioActions.ADD_PATTERN_IN_CANVAS:
        //   return assign({
        //     canvasPatterns: [...state.canvasPatterns, action.payload],
        //   })
        // case StudioActions.DELETE_UPLOAD_PATTERN:
        //   // TODO deleteLogoDesignRevision
        //   return assign({
        //     uploadedPattern: state.uploadedPattern.filter(
        //       image => image.id !== action.payload.id
        //     ),
        //   })
        // case StudioActions.DELETE_CANVAS_PATTERN:
        //   // TODO deleteLogoDesignRevision
        //   return assign({
        //     canvasPatterns: state.canvasPatterns.map(canvasImage => {
        //       if (canvasImage.id === action.payload.id) {
        //         return {
        //           ...canvasImage,
        //           ...action.payload,
        //         }
        //       }
        //       return canvasImage
        //     }),
        //   })
        // case StudioActions.MODIFY_PATTERN_IN_CANVAS:
        //   return assign({
        //     canvasPatterns: state.canvasPatterns.map(img => {
        //       if (img.id === action.payload.id) {
        //         return {
        //           ...img,
        //           ...action.payload,
        //         }
        //       }
        //       return img
        //     }),
        //   })
        case StudioActions.APPLY_PLACEHOLDER_IMAGE:
            // eslint-disable-next-line no-var
            var _placeholderCanvas;
            if (action.payload) {
                _placeholderCanvas = _to_consumable_array(state.placeholderCanvas).concat([
                    action.payload
                ]);
            } else {
                _placeholderCanvas = state.placeholderCanvas;
            }
            return assign({
                applyPlaceholder: action.payload,
                placeholderCanvas: _placeholderCanvas
            });
        case StudioActions.APPLY_PLACEHOLDER_TEXT:
            // eslint-disable-next-line no-var
            var _placeholderCanvas;
            if (action.payload) {
                _placeholderCanvas = _to_consumable_array(state.placeholderCanvas).concat([
                    action.payload
                ]);
            } else {
                _placeholderCanvas = state.placeholderCanvas;
            }
            return assign({
                applyPlaceholder: action.payload,
                placeholderCanvas: _placeholderCanvas
            });
        case StudioActions.REMOVE_PLACEHOLDER:
            return assign({
                placeholderCanvas: state.placeholderCanvas.filter(function(phc) {
                    var ref;
                    return phc.svgId !== ((ref = action.payload) === null || ref === void 0 ? void 0 : ref.svgId);
                }),
                removePlaceholder: action.payload
            });
        case StudioActions.SHOW_REQUEST_INDICATOR:
            return assign({
                isSendingRequest: true
            });
        case StudioActions.HIDE_REQUEST_INDICATOR:
            return assign({
                isSendingRequest: false
            });
        case StudioActions.CAPTURE_3D_REQUEST:
            return assign({
                capture3DRequest: action.payload
            });
        case StudioActions.CAPTURE_3D_ACTION:
            return assign({
                capturedAt: Math.floor(new Date().getTime() / 1000),
                capture3DRequest: false
            });
        case StudioActions.CAPTURE_3D_PREVIEW:
            // eslint-disable-next-line no-case-declarations
            var timestamp = Math.floor(new Date().getTime() / 1000);
            return assign({
                capturedAt: timestamp
            });
        case StudioActions.SELECT_PLACEHOLDER:
            return assign({
                selectedPlaceholderSvgId: action.payload.placeholderSvgId
            });
        case StudioActions.CLEAR_SELECT_PLACEHOLDER:
            return assign({
                selectedPlaceholderSvgId: null
            });
        case StudioActions.LOADING_STATUS:
            return assign({
                isLoading: action.payload
            });
        case StudioActions.CAPTURE_3D:
            return assign({
                capture3D: action.payload
            });
        case StudioActions.TOGGLE_GRID_HELPER:
            return assign({
                gridHelper: !state.gridHelper
            });
        case StudioActions.HAS_GRID_HELPER:
            return assign({
                hasGridHelper: action.payload
            });
        case StudioActions.CURRENT_2D_ZOOM:
            return assign({
                current2DZoom: action.payload
            });
        case StudioActions.SET_QUALITY_VALUE:
            return assign({
                qualityValue: action.payload
            });
        default:
            console.log("--> Unhandled action", action);
            return state;
    }
}
