/* eslint-disable @typescript-eslint/ban-ts-comment */ /* eslint-disable no-prototype-builtins */ /* eslint-disable prefer-const */ import clone from "lodash/clone";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import map from "lodash/map";
export var removeKeys = function(object, keys) {
    var finalObject = clone(object);
    keys.forEach(function(elem) {
        for(var prop in finalObject){
            if (finalObject.hasOwnProperty(prop)) {
                if (elem === prop) {
                    // simple key to delete
                    delete finalObject[prop];
                } else {
                    if (isObject(finalObject[prop])) {
                        if (isArray(finalObject[prop])) {
                            // @ts-ignore
                            finalObject[prop] = map(finalObject[prop], function(obj) {
                                return removeKeys(obj, keys);
                            });
                        } else {
                            finalObject[prop] = removeKeys(finalObject[prop], keys);
                        }
                    }
                }
            }
        }
    });
    return finalObject;
};
