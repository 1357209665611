import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { call, takeLatest } from "typed-redux-saga";
import { getMutationParams } from "../../../utils/saga-helpers";
import { actions } from "../slice";
import * as API from "./image-helpers/services";
function onPlaceImage(action) {
    var image, ref, apollo, meta, error;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    3,
                    ,
                    4
                ]);
                image = action.payload;
                return [
                    5,
                    _ts_values(getMutationParams())
                ];
            case 1:
                ref = _state.sent(), apollo = ref.apollo, meta = ref.meta;
                return [
                    5,
                    _ts_values(call(API.addImage, image, meta, apollo))
                ];
            case 2:
                _state.sent();
                return [
                    3,
                    4
                ];
            case 3:
                error = _state.sent();
                console.error("[ImageEngine] onPlaceImage => ", error);
                return [
                    3,
                    4
                ];
            case 4:
                return [
                    2
                ];
        }
    });
}
/**
 * Watch place image event
 */ export function watchPlaceImage() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    takeLatest(actions.placeImage.type, onPlaceImage)
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
