import _object_spread from "@swc/helpers/src/_object_spread.mjs";
export var productDesignVariables = function(ownedByOrganisationId, image) {
    if (!image) {
        return {
            defaultImageId: image === null || image === void 0 ? void 0 : image.defaultImageId,
            isOverlay: true,
            isVisible: true,
            logoDesign: {
                ownedByOrganisationId: ownedByOrganisationId,
                designAttributes: null
            }
        };
    }
    var flipX = image.flipX, flipY = image.flipY, left = image.left, lock = image.lock, rotate = image.rotate, scale = image.scale, top = image.top, visible = image.visible, defaultImageId = image.defaultImageId;
    return {
        isVisible: visible,
        isOverlay: true,
        defaultImageId: defaultImageId,
        logoDesign: {
            designAttributes: {
                top: top,
                left: left,
                scaleX: scale,
                scaleY: scale,
                angle: rotate,
                flipX: flipX,
                flipY: flipY,
                lock: lock
            }
        }
    };
};
export var transformPlacedPatterns = function(logoDesignRevisions) {
    return logoDesignRevisions.map(function(logoDesignRevision) {
        return _object_spread({}, transformPatterns(logoDesignRevision));
    });
};
export var transformPatterns = function(param) {
    var id = param.id, _logoDesign = param.logoDesign, designAttributes = _logoDesign.designAttributes, srcUrl = _logoDesign.src, defaultImageId = param.defaultImageId, placeholderId = param.placeholderId, printingTechniqueId = param.printingTechniqueId;
    return {
        base64: srcUrl,
        id: id,
        left: designAttributes.left,
        lock: designAttributes.lock,
        rotate: designAttributes.angle,
        scale: designAttributes.scaleX || designAttributes.scaleY,
        top: designAttributes.top,
        visible: true,
        defaultImageId: defaultImageId,
        placeholderId: placeholderId,
        printingTechniqueId: printingTechniqueId
    };
};
