import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { engines } from "../engines";
import { useInjectReducer, useInjectSaga } from "./redux-injectors";
/**
 * inject saga under DOM tree to inject context functions before start studio-engine
 */ export var EngineInjector = function() {
    Object.entries(engines).map(function(param) {
        var _param = _sliced_to_array(param, 2), key = _param[0], config = _param[1];
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useInjectSaga({
            key: key,
            saga: config.saga
        });
    });
    Object.entries(engines).map(function(param) {
        var _param = _sliced_to_array(param, 2), key = _param[0], config = _param[1];
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useInjectReducer({
            key: key,
            reducer: config.reducer
        });
    });
    return null;
};
