import { createSelector, createStructuredSelector } from "reselect";
export var stateDomain = function(state) {
    return state.global;
};
export var selectStudioState = createSelector(stateDomain, function(state) {
    return state === null || state === void 0 ? void 0 : state.studioContext;
});
export var selectSetUpError = createSelector(stateDomain, function(state) {
    return state === null || state === void 0 ? void 0 : state.setUpError;
});
export var selectNBE = createSelector(stateDomain, function(state) {
    return state === null || state === void 0 ? void 0 : state.isNBE;
});
export var globalSelectors = createStructuredSelector({
    selectStudioState: selectStudioState,
    selectSetUpError: selectSetUpError
});
