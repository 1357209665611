import { UpdateProductDesignRevisionDocument } from "@brikl/codegen";
export var updateProductDesignRevision = function(input, apollo, meta) {
    return apollo.mutate({
        mutation: UpdateProductDesignRevisionDocument,
        variables: {
            productDesignId: meta.productDesignId,
            revisionNo: meta.revisionNo,
            input: input
        }
    });
};
