import { UpdateZoneCustomizationDocument } from "@brikl/codegen";
export var updateZoneCustomization = function(input, apollo, meta) {
    return apollo.mutate({
        mutation: UpdateZoneCustomizationDocument,
        variables: {
            id: input.id,
            productDesignId: meta.productDesignId,
            revisionNo: meta.revisionNo,
            zoneId: input.zoneId,
            input: input
        }
    });
};
