import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { call, takeLatest } from "typed-redux-saga";
import { getMutationParams } from "../../../utils/saga-helpers";
import { actions } from "../slice";
import { deleteTextDesignRevision } from "./text-helpers/actions";
export function onDeleteText(param) {
    var text, ref, apollo, meta, error;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                text = param.payload;
                _state.label = 1;
            case 1:
                _state.trys.push([
                    1,
                    4,
                    ,
                    5
                ]);
                return [
                    5,
                    _ts_values(getMutationParams())
                ];
            case 2:
                ref = _state.sent(), apollo = ref.apollo, meta = ref.meta;
                if (meta.viewOnly) return [
                    2
                ];
                return [
                    5,
                    _ts_values(call(deleteTextDesignRevision, text, meta, apollo))
                ];
            case 3:
                _state.sent();
                return [
                    3,
                    5
                ];
            case 4:
                error = _state.sent();
                console.error("[ImageEngine] onDeleteText => ", error);
                return [
                    3,
                    5
                ];
            case 5:
                return [
                    2
                ];
        }
    });
}
export function watchTextDelete() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    takeLatest(actions.deleteText.type, onDeleteText)
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
