import isNil from "lodash/isNil";
var zoneCustomizationToTemplateColors = function(zc) {
    return zc.id && zc.colorId && {
        id: zc.id,
        templateId: zc.templateId,
        colorId: zc.colorId,
        zoneId: zc.zoneId
    };
};
export function getCustomTemplateColors(zoneCustomizations) {
    console.log("[Color Fill] zoneCustomizations:", {
        zoneCustomizations: zoneCustomizations
    });
    return zoneCustomizations === null || zoneCustomizations === void 0 ? void 0 : zoneCustomizations.map(zoneCustomizationToTemplateColors).filter(function(zoneCustomization) {
        return zoneCustomization;
    });
}
/**
 * this is illegal id
 */ var COLORZONE_NAME = "COLORZONE";
/**
 * check color data is default color
 * @param  {ProductColor} color
 * @returns boolean
 */ export function isDefaultColor(color) {
    var ref;
    if (isNil(color === null || color === void 0 ? void 0 : color.id) || isNil(color === null || color === void 0 ? void 0 : color.hex)) return false;
    if (color === null || color === void 0 ? void 0 : (ref = color.id) === null || ref === void 0 ? void 0 : ref.includes(COLORZONE_NAME)) return false;
    return true;
}
/**
 * get color data from current zone
 * @param  {IGetSelectedColorOnCurrentZoneOptions} }
 */ export function getSelectedColorOnCurrentZone(param) {
    var colorId = param.colorId, colorZone = param.colorZone;
    var selectedColor = colorZone === null || colorZone === void 0 ? void 0 : colorZone.colors.find(function(color) {
        return color.id === colorId;
    });
    if (selectedColor) return selectedColor;
    var isDefaultColor = colorZone.defaultColor.id === colorId;
    if (isDefaultColor) return colorZone.defaultColor;
    return null;
}
