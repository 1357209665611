import { createSlice } from "../../@redux";
export var initialState = {
    isReady: false,
    serverMessage: null,
    studioState: null,
    isStudioLoading: true,
    studioContext: null
};
var slice = createSlice({
    name: "global",
    initialState: initialState,
    reducers: {
        initial: function(state, payload) {
            var ref;
            state.isReady = false;
            state.isNBE = (ref = payload.payload) === null || ref === void 0 ? void 0 : ref.isNBE;
        },
        setSetUpError: function(state, param) {
            var payload = param.payload;
            state.setUpError = payload;
        },
        setStudioState: function(state, param) {
            var payload = param.payload;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.studioState = payload.studioState;
            state.studioContext = payload.context;
        },
        initialCompleted: function(state) {
            console.log("initialCompleted");
            state.isReady = true;
            state.isStudioLoading = false;
        },
        updateMessage: function(state, action) {
            state.serverMessage = action.payload;
        }
    }
});
export var actions = slice.actions, reducer = slice.reducer, name = slice.name;
