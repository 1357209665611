import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import uniqBy from "lodash/uniqBy";
import { all, call, put, select } from "typed-redux-saga";
import { takeLatest } from "typed-redux-saga";
import { v4 } from "uuid";
import { AllowedPlaceholderTypes } from "@brikl/studio";
import { selectAllDesignSteps } from "../../DesignStepEngine/selectors";
import { makeSelectPlacedImages } from "../../ImageEngine/selectors";
import { actions as imageEngineActions } from "../../ImageEngine/slice";
import { actions as layerEngineActions } from "../../LayerEngine/slice";
import { selectPlacedTexts } from "../../TextEngine/selectors";
import { actions as textEngineActions } from "../../TextEngine/slice";
import { makeSelectAvailablePlaceholders } from "../selectors";
import { actions } from "../slice";
var isSameGroup = function(param) {
    var currentPlaceholders = param.currentPlaceholders, placeholderId = param.placeholderId, combinationLogic = param.combinationLogic, placeholderGroups = param.placeholderGroups;
    var placeholderGroupInfo = placeholderGroups.find(function(group) {
        var ref;
        return group === null || group === void 0 ? void 0 : (ref = group.designPlaceholderIds) === null || ref === void 0 ? void 0 : ref.includes(placeholderId);
    });
    var isCurreplaceholderInAGroup = currentPlaceholders.filter(function(currentPlaceholderId) {
        var ref;
        return placeholderGroupInfo === null || placeholderGroupInfo === void 0 ? void 0 : (ref = placeholderGroupInfo.designPlaceholderIds) === null || ref === void 0 ? void 0 : ref.includes(currentPlaceholderId);
    });
    var isDuplicated = isCurreplaceholderInAGroup.length !== 0 && combinationLogic === "SINGLE";
    return isDuplicated;
};
var checkDefaultPlaceholderGroup = function(placeholderGroup, availablePlaceholderId) {
    return placeholderGroup.defaultPlaceholderId && availablePlaceholderId === placeholderGroup.defaultPlaceholderId || !placeholderGroup.defaultPlaceholderId;
};
function createDefaultPlaceholder(availablePlaceholders) {
    var currentPlacedTexts, currentPlacedImages, designSteps, currentPlacedTextIds, currentPlacedImageIds, defaultTexts, defaultImages, listDefaultImages, listDefaultTexts, _orderSEQ, listPlacedDefaultTexts, _orderSEQ1, listPlacedDefaultImages, listOrderSequences;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(select(selectPlacedTexts))
                ];
            case 1:
                currentPlacedTexts = _state.sent();
                return [
                    5,
                    _ts_values(select(makeSelectPlacedImages))
                ];
            case 2:
                currentPlacedImages = _state.sent();
                return [
                    5,
                    _ts_values(select(selectAllDesignSteps))
                ];
            case 3:
                designSteps = _state.sent();
                currentPlacedTextIds = currentPlacedTexts.map(function(text) {
                    return text.placeholderId;
                });
                currentPlacedImageIds = currentPlacedImages.map(function(image) {
                    return image.placeholderId;
                });
                defaultTexts = [];
                defaultImages = [];
                // we loop all design steps here and add image or text to respective placeholders
                designSteps === null || designSteps === void 0 ? void 0 : designSteps.forEach(function(step) {
                    var ref;
                    var designPlaceholderGroups = step === null || step === void 0 ? void 0 : step.designPlaceholderGroups;
                    step === null || step === void 0 ? void 0 : (ref = step.designPlaceholderGroups) === null || ref === void 0 ? void 0 : ref.forEach(function(placeholderGroup) {
                        var placeholderToFill = [];
                        if (placeholderGroup.combinationLogic === "SINGLE") {
                            var defaultPlaceholder = availablePlaceholders.find(function(availablePlaceholder) {
                                return checkDefaultPlaceholderGroup(placeholderGroup, availablePlaceholder.id);
                            });
                            if (!defaultPlaceholder) return;
                            if (placeholderGroup.combinationLogic === "SINGLE") {
                                var ref;
                                // for placeholder groups with SINGLE we won't be adding the default if there is 1 placeholder already occupied
                                var isDefaultPlaceholder = !((ref = placeholderGroup.designPlaceholderIds) === null || ref === void 0 ? void 0 : ref.find(function(designPlaceholderId) {
                                    var ref;
                                    return !!((ref = _to_consumable_array(currentPlacedImages).concat(_to_consumable_array(currentPlacedTexts))) === null || ref === void 0 ? void 0 : ref.find(function(current) {
                                        return current.placeholderId === designPlaceholderId;
                                    }));
                                }));
                                if (isDefaultPlaceholder) {
                                    placeholderToFill.push(defaultPlaceholder);
                                }
                            } else {
                                placeholderToFill.push(defaultPlaceholder);
                            }
                        } else {
                            var ref1;
                            (ref1 = placeholderGroup.designPlaceholderIds) === null || ref1 === void 0 ? void 0 : ref1.forEach(function(id) {
                                var ref;
                                var placeholderInfo = availablePlaceholders.find(function(a) {
                                    return a.id === id;
                                });
                                // TODO: consider maximum items here?
                                var isDefaultPlaceholder = placeholderInfo && !((ref = placeholderGroup.designPlaceholderIds) === null || ref === void 0 ? void 0 : ref.find(function(p) {
                                    return !!(currentPlacedImages === null || currentPlacedImages === void 0 ? void 0 : currentPlacedImages.find(function(c) {
                                        return c.placeholderId === p;
                                    }));
                                }));
                                if (isDefaultPlaceholder) {
                                    placeholderToFill.push(placeholderInfo);
                                }
                            });
                        }
                        // condition to add default image or text
                        placeholderToFill.forEach(function(placeholderFill) {
                            var ref, ref1;
                            if ((ref = placeholderFill.allowedTypes) === null || ref === void 0 ? void 0 : ref.includes(AllowedPlaceholderTypes.TEXT)) {
                                var count = 0;
                                var _defaultTexts;
                                var textsAvailable = _to_consumable_array(currentPlacedTexts).concat(_to_consumable_array((_defaultTexts = placeholderFill.defaultTexts) !== null && _defaultTexts !== void 0 ? _defaultTexts : []));
                                // eslint-disable-next-line no-unused-expressions
                                textsAvailable === null || textsAvailable === void 0 ? void 0 : textsAvailable.forEach(function(e) {
                                    if (placeholderFill.defaultTextId === e.id) {
                                        // TODO - check if placeholder are not taken by another value yet
                                        if (count <= placeholderFill.maximumItems && e.content) {
                                            var isDuplicated = isSameGroup({
                                                currentPlaceholders: currentPlacedTextIds,
                                                placeholderGroupId: placeholderGroup.id,
                                                placeholderId: placeholderFill.id,
                                                combinationLogic: placeholderGroup.combinationLogic,
                                                designPlaceholderIds: placeholderGroup.designPlaceholderIds,
                                                placeholderGroups: designPlaceholderGroups
                                            });
                                            if (!isDuplicated) {
                                                var ref;
                                                // Add text to a placeholder
                                                defaultTexts.push({
                                                    defaultTextId: e.id,
                                                    placeholderId: placeholderFill.id,
                                                    placeholderGroupId: placeholderGroup.id,
                                                    placeholderSvgId: placeholderFill.svgId,
                                                    text: e.content,
                                                    printingTechniqueId: placeholderFill === null || placeholderFill === void 0 ? void 0 : (ref = placeholderFill.defaultPrintingTechnique) === null || ref === void 0 ? void 0 : ref.id
                                                });
                                            }
                                        }
                                    }
                                    count++;
                                });
                            }
                            if ((ref1 = placeholderFill.allowedTypes) === null || ref1 === void 0 ? void 0 : ref1.includes(AllowedPlaceholderTypes.IMAGE)) {
                                var // eslint-disable-next-line no-unused-expressions
                                ref2;
                                placeholderFill === null || placeholderFill === void 0 ? void 0 : (ref2 = placeholderFill.defaultImages) === null || ref2 === void 0 ? void 0 : ref2.forEach(function(defaultImage) {
                                    if (!(placeholderFill.defaultImageId === defaultImage.id)) return;
                                    if (currentPlacedImages === null || currentPlacedImages === void 0 ? void 0 : currentPlacedImages.find(function(currentImage) {
                                        return currentImage.defaultImageId === defaultImage.id;
                                    })) {
                                        return;
                                    }
                                    var isDuplicated = isSameGroup({
                                        currentPlaceholders: currentPlacedImageIds,
                                        placeholderGroupId: placeholderGroup.id,
                                        placeholderId: placeholderFill.id,
                                        combinationLogic: placeholderGroup.combinationLogic,
                                        designPlaceholderIds: placeholderGroup.designPlaceholderIds,
                                        placeholderGroups: designPlaceholderGroups
                                    });
                                    if (!isDuplicated) {
                                        var ref;
                                        // TODO - check if placeholder are not taken by another value yet
                                        defaultImages.push({
                                            placeholderId: placeholderFill.id,
                                            placeholderGroupId: placeholderGroup.id,
                                            placeholderSvgId: placeholderFill.svgId,
                                            srcUrl: defaultImage.src,
                                            defaultImageId: defaultImage.id,
                                            printingTechniqueId: placeholderFill === null || placeholderFill === void 0 ? void 0 : (ref = placeholderFill.defaultPrintingTechnique) === null || ref === void 0 ? void 0 : ref.id
                                        });
                                    }
                                });
                            }
                        });
                    });
                });
                listDefaultImages = uniqBy(defaultImages, function(image) {
                    return image.placeholderId;
                });
                listDefaultTexts = uniqBy(defaultTexts, function(text) {
                    return text.placeholderId;
                });
                return [
                    4,
                    all(listDefaultTexts.map(function(defaultText, index) {
                        return call(addDefaultPlaceholderText, _object_spread_props(_object_spread({}, defaultText), {
                            orderSEQ: (_orderSEQ = defaultText.orderSEQ) !== null && _orderSEQ !== void 0 ? _orderSEQ : index + 1
                        }));
                    }))
                ];
            case 4:
                listPlacedDefaultTexts = _state.sent();
                return [
                    4,
                    all(listDefaultImages.map(function(defaultImage, index) {
                        return call(addDefaultPlaceholderImage, _object_spread_props(_object_spread({}, defaultImage), {
                            orderSEQ: (_orderSEQ1 = defaultImage.orderSEQ) !== null && _orderSEQ1 !== void 0 ? _orderSEQ1 : index + 1
                        }));
                    }))
                ];
            case 5:
                listPlacedDefaultImages = _state.sent();
                listOrderSequences = _to_consumable_array(listPlacedDefaultImages.map(function(image) {
                    return image.id;
                })).concat(_to_consumable_array(listPlacedDefaultTexts.map(function(text) {
                    return text.id;
                })));
                return [
                    4,
                    put(layerEngineActions.saveLayeringOrder(listOrderSequences))
                ];
            case 6:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
function addDefaultPlaceholderImage(props) {
    var placeholderId, placeholderGroupId, placeholderSvgId, srcUrl, defaultImageId, orderSEQ, printingTechniqueId, placeholderImage, _tmp;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                placeholderId = props.placeholderId, placeholderGroupId = props.placeholderGroupId, placeholderSvgId = props.placeholderSvgId, srcUrl = props.srcUrl, defaultImageId = props.defaultImageId, orderSEQ = props.orderSEQ, printingTechniqueId = props.printingTechniqueId;
                if (!srcUrl) return [
                    2
                ];
                _tmp = {};
                placeholderImage = (_tmp.left = 0, _tmp.top = 0, _tmp.base64 = srcUrl, _tmp.srcUrl = srcUrl, _tmp.deleted = false, _tmp.id = v4(), _tmp.orderSEQ = orderSEQ, _tmp.lock = false, _tmp.placeholderId = placeholderId, _tmp.placeholderSvgId = placeholderSvgId, _tmp.placeholderGroupId = placeholderGroupId, _tmp.isPlaceholderDefault = true, _tmp.defaultImageId = defaultImageId, _tmp.printingTechniqueId = printingTechniqueId, _tmp.rotate = 0, _tmp.scale = 1, _tmp.visible = true, _tmp);
                return [
                    5,
                    _ts_values(put(imageEngineActions.placeImage(placeholderImage)))
                ];
            case 1:
                _state.sent();
                return [
                    2,
                    placeholderImage
                ];
        }
    });
}
function addDefaultPlaceholderText(placeholderText) {
    var placeholderId, placeholderGroupId, placeholderSvgId, defaultTextId, text, printingTechniqueId, placeText, _tmp;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                placeholderId = placeholderText.placeholderId, placeholderGroupId = placeholderText.placeholderGroupId, placeholderSvgId = placeholderText.placeholderSvgId, defaultTextId = placeholderText.defaultTextId, text = placeholderText.text, printingTechniqueId = placeholderText.printingTechniqueId;
                _tmp = {};
                placeText = (_tmp.charSpacing = 0, _tmp.fillColor = "black", _tmp.flipX = false, _tmp.flipY = false, _tmp.fontFamily = "Arial", _tmp.fontSize = 60, _tmp.id = v4(), _tmp.left = 1370, _tmp.lock = false, _tmp.outline = 0, _tmp.outlineColor = "#000", _tmp.placeholderSvgId = placeholderSvgId, _tmp.placeholderGroupId = placeholderGroupId, _tmp.isPlaceholderDefault = true, _tmp.placeholderId = placeholderId, _tmp.printingTechniqueId = printingTechniqueId, _tmp.rotate = 0, _tmp.defaultTextId = defaultTextId, _tmp.scale = 2, _tmp.text = text, _tmp.top = 1552, _tmp.visible = true, _tmp);
                return [
                    4,
                    put(textEngineActions.placeText(placeText))
                ];
            case 1:
                _state.sent();
                return [
                    2,
                    placeText
                ];
        }
    });
}
export function onCreateDefaultPlaceholder() {
    var availablePlaceholders, defaultImages, defaultTexts, allDefaultPlaceholders;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(select(makeSelectAvailablePlaceholders))
                ];
            case 1:
                availablePlaceholders = _state.sent();
                defaultImages = availablePlaceholders.filter(function(availablePlaceholder) {
                    return availablePlaceholder.defaultImages;
                });
                defaultTexts = availablePlaceholders.filter(function(availablePlaceholder) {
                    return availablePlaceholder.defaultTexts;
                });
                allDefaultPlaceholders = _to_consumable_array(defaultImages).concat(_to_consumable_array(defaultTexts));
                return [
                    4,
                    call(createDefaultPlaceholder, allDefaultPlaceholders)
                ];
            case 2:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
/**
 * Watch place image event
 */ export function watchOnInitialPlaceholder() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    takeLatest(actions.loadPlaceholderCompleted.type, onCreateDefaultPlaceholder)
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
