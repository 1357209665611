import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { all, call, delay, put, select, takeLatest } from "typed-redux-saga";
import { deleteZoneCustomization } from "../../../utils/delete-zone-customization";
import { getMutationParams } from "../../../utils/saga-helpers";
import { updateProductDesignRevision } from "../../../utils/update-product-design-revision";
import { actions as aopActions } from "../../AopEngine/slice";
import { selectAll2dCustomZones, selectAllColorZones, selectCustomTemplateColorZone3DElements, selectSelectedTemplateColorZones } from "../../ColorFillEngine/selectors";
import { actions as colorFillActions } from "../../ColorFillEngine/slice";
import { selectStudioState } from "../../GlobalProvider/selectors";
import { actions as sceneActions } from "../../SceneEngine/slice";
import { createZonesMutation } from "../mutations/create-zones";
import { selectSelectedTemplate } from "../selectors";
import { actions } from "../slice";
export function selectTemplateSaga() {
    var ref, selectedTemplate, id, ref1, apollo, meta, payload, _tmp, colorZones, studioState, context, _tmp1, all2dCustomZones, all3dCustomZones, allCustomZones, _tmp2, _tmp3, _tmp4;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(select(selectSelectedTemplate))
                ];
            case 1:
                selectedTemplate = _state.sent();
                if (!selectedTemplate) return [
                    2
                ];
                id = selectedTemplate.id;
                return [
                    5,
                    _ts_values(getMutationParams())
                ];
            case 2:
                ref1 = _state.sent(), apollo = ref1.apollo, meta = ref1.meta;
                // At initial load, apollo won't be ready.
                // This is not an issue, because usePersistInitialTemplate hook can do this for us.
                if (!apollo) {
                    console.info("[Template] Apollo not ready; not persisting template.");
                    return [
                        2
                    ];
                }
                if (!id || !meta) return [
                    2
                ];
                _tmp = {};
                payload = (_tmp.selectedDesignTemplateId = id, _tmp);
                return [
                    5,
                    _ts_values(call(updateProductDesignRevision, payload, apollo, meta))
                ];
            case 3:
                _state.sent();
                return [
                    5,
                    _ts_values(select(selectAllColorZones))
                ];
            case 4:
                colorZones = _state.sent().filter(function(zone) {
                    return zone.templateId === id;
                });
                return [
                    5,
                    _ts_values(select(selectStudioState))
                ];
            case 5:
                studioState = _state.sent();
                _tmp1 = {};
                context = (_tmp1.selectedTemplateId = payload.selectedDesignTemplateId, _tmp1.revisionNo = studioState === null || studioState === void 0 ? void 0 : (ref = studioState.productData) === null || ref === void 0 ? void 0 : ref.getProductDesign.latestRevisionNo, _tmp1.productDesignId = studioState.productData.getProductDesign.id, _tmp1);
                return [
                    5,
                    _ts_values(select(selectAll2dCustomZones))
                ];
            case 6:
                all2dCustomZones = _state.sent();
                return [
                    5,
                    _ts_values(select(selectCustomTemplateColorZone3DElements))
                ];
            case 7:
                all3dCustomZones = _state.sent();
                allCustomZones = _to_consumable_array(all2dCustomZones).concat(_to_consumable_array(all3dCustomZones));
                return [
                    5,
                    _ts_values(all(allCustomZones.map(function(zone) {
                        return call(deleteZoneCustomization, {
                            zoneId: zone.id,
                            revisionNo: context.revisionNo,
                            productDesignId: context.productDesignId
                        }, apollo);
                    })))
                ];
            case 8:
                _state.sent();
                _tmp2 = {};
                _tmp3 = {};
                return [
                    5,
                    _ts_values(call(createZonesMutation(apollo), _object_spread_props(_object_spread(_tmp2, context), (_tmp3.colorZones = colorZones, _tmp3))))
                ];
            case 9:
                _state.sent();
                _tmp4 = {};
                console.log("[Template] Saved selected template:", (_tmp4.id = id, _tmp4.selectedTemplate = selectedTemplate, _tmp4));
                return [
                    5,
                    _ts_values(delay(2000) // wait for 3d rendering
                    )
                ];
            case 10:
                _state.sent();
                return [
                    5,
                    _ts_values(put(actions.selectTemplateCompleted()))
                ];
            case 11:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
/**
 * This effect is only to handle some specific data behaviours
 * on user selection
 */ function clearAnotherSelectionOnSelectTemplate(param) {
    var payload;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                payload = param.payload;
                // when user re-select make sure
                // 1 clear selected color from previous selection
                return [
                    5,
                    _ts_values(put(colorFillActions.selectColor(null)))
                ];
            case 1:
                _state.sent();
                // 2 clear selected zone from previous selection
                return [
                    5,
                    _ts_values(put(sceneActions.selectZone(null)))
                ];
            case 2:
                _state.sent();
                // 3 clear custom colors from previous template
                return [
                    5,
                    _ts_values(put(colorFillActions.resetCustomColors()))
                ];
            case 3:
                _state.sent();
                // 4 clear custom aop from previous selection
                return [
                    5,
                    _ts_values(put(aopActions.resetSelectedAop()))
                ];
            case 4:
                _state.sent();
                // 5 clear all custom aop from previous template
                return [
                    5,
                    _ts_values(put(aopActions.resetCustomAop(payload)))
                ];
            case 5:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
function onSelectTemplateCompleted() {
    var templateColorZones;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(select(selectSelectedTemplateColorZones))
                ];
            case 1:
                templateColorZones = _state.sent();
                if (!(templateColorZones && templateColorZones.length > 0)) return [
                    3,
                    3
                ];
                return [
                    5,
                    _ts_values(put(sceneActions.selectZone(templateColorZones[0].zone.zoneId)))
                ];
            case 2:
                _state.sent();
                _state.label = 3;
            case 3:
                return [
                    2
                ];
        }
    });
}
export function watchSelectTemplate() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(takeLatest(actions.selectTemplate.type, selectTemplateSaga))
                ];
            case 1:
                _state.sent();
                return [
                    5,
                    _ts_values(takeLatest(actions.selectTemplate.type, clearAnotherSelectionOnSelectTemplate))
                ];
            case 2:
                _state.sent();
                return [
                    5,
                    _ts_values(takeLatest(actions.selectTemplateCompleted.type, onSelectTemplateCompleted))
                ];
            case 3:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
