import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { call, put, select, takeLatest } from "typed-redux-saga";
import { selectSelectedDesignStep } from "../../DesignStepEngine/selectors";
import { selectSelectedText } from "../selectors";
import { actions } from "../slice";
function hasDefaultPrintingTechnique() {
    var selectedDesignStep, defaultTextPrintingTechnique;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    select(selectSelectedDesignStep)
                ];
            case 1:
                selectedDesignStep = _state.sent();
                if (!selectedDesignStep && selectedDesignStep.type !== "TEXT") return [
                    2
                ];
                defaultTextPrintingTechnique = selectedDesignStep.defaultTextPrintingTechnique;
                return [
                    2,
                    defaultTextPrintingTechnique
                ];
        }
    });
}
export function onSelectText() {
    var defaultTextPrintingTechnique, selectedText, selectedPrintingTechnique, _tmp, _tmp1, error;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    5,
                    ,
                    7
                ]);
                return [
                    4,
                    call(hasDefaultPrintingTechnique)
                ];
            case 1:
                defaultTextPrintingTechnique = _state.sent();
                return [
                    4,
                    select(selectSelectedText)
                ];
            case 2:
                selectedText = _state.sent();
                // Do nothing if no text has been selected
                if (!selectedText) return [
                    2
                ];
                selectedPrintingTechnique = (selectedText === null || selectedText === void 0 ? void 0 : selectedText.printingTechniqueId) || defaultTextPrintingTechnique;
                if (!(selectedText.printingTechniqueId !== selectedPrintingTechnique)) return [
                    3,
                    4
                ];
                _tmp = {};
                _tmp1 = {};
                return [
                    4,
                    put(actions.updateText(_object_spread_props(_object_spread(_tmp, selectedText), (_tmp1.printingTechniqueId = selectedPrintingTechnique, _tmp1))))
                ];
            case 3:
                _state.sent();
                console.log("[TextEngine] onSelectText: update default printing technique");
                _state.label = 4;
            case 4:
                return [
                    3,
                    7
                ];
            case 5:
                error = _state.sent();
                return [
                    4,
                    console.error("[TextEngine] onSelectText => ", error)
                ];
            case 6:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 7:
                return [
                    2
                ];
        }
    });
}
export function watchSelectText() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(takeLatest(actions.selectText.type, onSelectText))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
