import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
export var deleteUploadedPattern = function(state, param) {
    var pattern = param.payload;
    var isNonMatched = function(uploadPattern) {
        return uploadPattern.id !== pattern.id;
    };
    state.uploadedPatterns = state.uploadedPatterns.filter(isNonMatched);
};
export var deletePlacedPattern = function(state, param) {
    var pattern = param.payload;
    var isNonMatched = function(uploadPattern) {
        return uploadPattern.id !== pattern.id;
    };
    state.placedPatterns = state.placedPatterns.filter(isNonMatched);
    state.deletedPlacedPatterns = _to_consumable_array(state.deletedPlacedPatterns).concat([
        pattern
    ]);
};
