import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { all, call, put, select, takeLatest } from "typed-redux-saga";
import { getMutationParams } from "../../../utils/saga-helpers";
import { makeSelectPlacedPatterns } from "../selectors";
import { actions } from "../slice";
import * as API from "./pattern-helpers/services";
/**
 * Once upload patterns completed, replace pattern source at placed patterns.
 */ function onUploadedPattern(param) {
    var pattern, placedPattern, isMatched, existingPlacedPattern, tasks;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                pattern = param.payload;
                return [
                    5,
                    _ts_values(select(makeSelectPlacedPatterns))
                ];
            case 1:
                placedPattern = _state.sent();
                isMatched = function(placedPattern) {
                    return placedPattern.defaultImageId === pattern.id;
                };
                existingPlacedPattern = placedPattern.filter(isMatched);
                tasks = existingPlacedPattern.map(function(placedPattern) {
                    var action = actions.updatePlacedPattern(_object_spread({}, pattern, placedPattern));
                    return put(action);
                });
                return [
                    5,
                    _ts_values(all(tasks))
                ];
            case 2:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
function onPlacePattern(param) {
    var pattern, ref, apollo, meta, error;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                pattern = param.payload;
                _state.label = 1;
            case 1:
                _state.trys.push([
                    1,
                    4,
                    ,
                    5
                ]);
                return [
                    5,
                    _ts_values(getMutationParams())
                ];
            case 2:
                ref = _state.sent(), apollo = ref.apollo, meta = ref.meta;
                if (meta.viewOnly) return [
                    2
                ];
                return [
                    5,
                    _ts_values(call(API.addPattern, pattern, meta, apollo))
                ];
            case 3:
                _state.sent();
                return [
                    3,
                    5
                ];
            case 4:
                error = _state.sent();
                console.error("[PatternEngine] onPlacePattern => ", error);
                return [
                    3,
                    5
                ];
            case 5:
                return [
                    2
                ];
        }
    });
}
/**
 * Watch place pattern event
 */ export function watchPlacePattern() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    all([
                        takeLatest(actions.placePattern.type, onPlacePattern),
                        takeLatest(actions.uploadPatternSuccess.type, onUploadedPattern)
                    ])
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
