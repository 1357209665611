import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { all } from "redux-saga/effects";
import { watchInitialAop } from "./sagas/aop-initial.saga";
import { watchAopUpload } from "./sagas/aop-upload.saga";
import { watchSelectAop } from "./sagas/select-aop.saga";
import { watchUpdateAop, watchUpdateTransformAop } from "./sagas/update-aop.saga";
/**
 * Root saga manages watcher lifecycle
 */ export default function rootSaga() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    all([
                        watchInitialAop(),
                        watchSelectAop(),
                        watchAopUpload(),
                        watchUpdateAop(),
                        watchUpdateTransformAop()
                    ])
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
};
