import { AopEngineConfig } from "./containers/AopEngine";
import { CartEngineConfig } from "./containers/CartEngine";
import { ColorFillEngineConfig } from "./containers/ColorFillEngine";
import { DesignStepEngineConfig } from "./containers/DesignStepEngine";
import { GlobalEngineConfig } from "./containers/GlobalProvider";
import { ImageEngineConfig } from "./containers/ImageEngine";
import { LayerEngineConfig } from "./containers/LayerEngine";
import { MetadataEngineConfig } from "./containers/MetadataEngine";
import { PatternEngineConfig } from "./containers/PatternEngine";
import { PlaceholderEngineConfig } from "./containers/PlaceholderEngine";
import { SceneEngineConfig } from "./containers/SceneEngine";
import { TemplateEngineConfig } from "./containers/TemplateEngine";
import { TextEngineConfig } from "./containers/TextEngine";
// Add your engine configuration after you've created a new engine.
export var engines = {
    text: TextEngineConfig,
    cart: CartEngineConfig,
    colorFill: ColorFillEngineConfig,
    aop: AopEngineConfig,
    template: TemplateEngineConfig,
    image: ImageEngineConfig,
    placeholder: PlaceholderEngineConfig,
    global: GlobalEngineConfig,
    pattern: PatternEngineConfig,
    scene: SceneEngineConfig,
    metadata: MetadataEngineConfig,
    layer: LayerEngineConfig,
    designStep: DesignStepEngineConfig
};
