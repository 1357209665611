import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { all, call, takeLatest } from "typed-redux-saga";
import { getMutationParams } from "../../../utils/saga-helpers";
import { actions } from "../slice";
import * as API from "./image-helpers/services";
function onDeleteImage(param) {
    var image, ref, apollo, meta, results, error;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                image = param.payload;
                _state.label = 1;
            case 1:
                _state.trys.push([
                    1,
                    4,
                    ,
                    5
                ]);
                return [
                    5,
                    _ts_values(getMutationParams())
                ];
            case 2:
                ref = _state.sent(), apollo = ref.apollo, meta = ref.meta;
                if (meta.viewOnly) return [
                    2
                ];
                return [
                    5,
                    _ts_values(call(API.deleteImage, image, meta, apollo))
                ];
            case 3:
                results = _state.sent();
                if (results) console.log("[Image Engine] Delete completed:", results);
                return [
                    3,
                    5
                ];
            case 4:
                error = _state.sent();
                // TODO handle error nicely
                console.error("onDeleteImage", error);
                return [
                    3,
                    5
                ];
            case 5:
                return [
                    2
                ];
        }
    });
}
/**
 * Watcher
 */ // Watch delete uploaded image
export function watchDeleteImage() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    all([
                        takeLatest(actions.deleteUploadedImage.type, onDeleteImage),
                        takeLatest(actions.deletePlacedImage.type, onDeleteImage)
                    ])
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
