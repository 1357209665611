import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { useEffect } from "react";
import * as THREE from "three";
import { makeSelectAllFabrics, selectAppliedAllColor, selectIsTemplateReady, selectSelectedTemplate, selectSelectedZoneId, useColorEngineActions, useSelectors } from "@brikl/studio-engine";
import { selectAllCustomTemplateColors, selectAllCustomTemplateColorsZone3DElements } from "@brikl/studio-engine";
import { setColorEachZone, setColorOnSelectedZone } from "../../_fabric/color";
import { useFabricCanvas } from "../../_fabric/useFabricCanvas";
import { mapColorCMYK } from "../../_fabric/util";
import { useThree } from "../../_three/useThree";
import { convertHexStringToHexadecimal, getMaterial } from "../../_three/utils";
import { createLogger } from "../../logger";
import { useStudio } from "../state/state";
var logger = createLogger("studio:useCustomColorsEffect");
/**
 * This hook helps to keep the custom colors in the state
 * in sync with the svg elements in the canvas
 */ var buildHexString = function(hexString) {
    return "#".concat(hexString.toUpperCase());
};
export var useCustomColorsEffect = function() {
    // TODO(Phoom): Migrate color fill's selectedZone to Zone Engine in next PR
    var ref = _sliced_to_array(useStudio(), 1), ref1 = ref[0], isCanvasReady = ref1.isCanvasReady, isThreeReady = ref1.isThreeReady, only2D = ref1.only2D;
    var colorFillAction = useColorEngineActions();
    var fabricCanvas = useFabricCanvas();
    var scene = useThree().scene;
    var ref2 = _sliced_to_array(useSelectors(selectIsTemplateReady, selectSelectedTemplate), 2), isTemplateReady = ref2[0], selectedTemplate = ref2[1];
    var ref3 = _sliced_to_array(useSelectors(selectAllCustomTemplateColors, selectAllCustomTemplateColorsZone3DElements, selectAppliedAllColor, selectSelectedZoneId, makeSelectAllFabrics), 5), customTemplateColors = ref3[0], customTemplateColor3DElements = ref3[1], isAppliedAllColor = ref3[2], selectedZoneId = ref3[3], fabrics = ref3[4];
    useEffect(function() {
        if (!isCanvasReady) return;
        var svgObjects = fabricCanvas === null || fabricCanvas === void 0 ? void 0 : fabricCanvas.getObjects();
        if (customTemplateColors.length === 0) {
            colorFillAction.setIsAllColorApplied();
            return;
        }
        if (!isAppliedAllColor) {
            customTemplateColors === null || customTemplateColors === void 0 ? void 0 : customTemplateColors.forEach(function(customColor) {
                var ref, ref1;
                // MAP CMYK INFO
                var colorInfo = mapColorCMYK(fabrics, (ref = customColor.color) === null || ref === void 0 ? void 0 : ref.id, (ref1 = customColor.color) === null || ref1 === void 0 ? void 0 : ref1.hex);
                svgObjects === null || svgObjects === void 0 ? void 0 : svgObjects.forEach(setColorEachZone({
                    customColor: customColor,
                    only2D: only2D,
                    colorInfo: colorInfo
                }));
                logger(customColor);
            });
            colorFillAction.setIsAllColorApplied();
        }
        fabricCanvas === null || fabricCanvas === void 0 ? void 0 : fabricCanvas.requestRenderAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        customTemplateColors,
        isTemplateReady,
        isCanvasReady
    ]);
    useEffect(function() {
        var svgObjects = fabricCanvas === null || fabricCanvas === void 0 ? void 0 : fabricCanvas.getObjects();
        var selectedZone = customTemplateColors.find(function(customColor) {
            return customColor.zone.zoneId === selectedZoneId;
        });
        if (!selectedZone) return;
        setColorOnSelectedZone({
            selectedZone: selectedZone,
            fabrics: fabrics,
            only2D: only2D,
            svgObjects: svgObjects
        });
    }, [
        selectedZoneId,
        only2D,
        customTemplateColors,
        fabrics,
        fabricCanvas
    ]);
    /**
   * use to reset color to default when change template
   */ useEffect(function() {
        if (!(selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.id)) return;
        scene.traverse(function(objects) {
            if (!_instanceof(objects, THREE.Group)) return;
            objects.traverse(function(child) {
                if (!_instanceof(child, THREE.Mesh)) return;
                var materials = getMaterial(child.material);
                var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
                try {
                    for(var _iterator = materials[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                        var material = _step.value;
                        if (!material.userData.defaultColor) continue;
                        var hexadecimal = convertHexStringToHexadecimal(material.userData.defaultColor);
                        material.color.set(hexadecimal);
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally{
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return != null) {
                            _iterator.return();
                        }
                    } finally{
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }
            });
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.id
    ]);
    useEffect(function() {
        logger("isThreeReady => ", isThreeReady);
        logger("scene => ", scene);
        logger("scene.children => ", scene.children);
        if (!isThreeReady) return;
        // GET ONLY OBJECT ELM FROM SCENE
        scene.traverse(function(objects) {
            if (!_instanceof(objects, THREE.Group)) return;
            if (customTemplateColor3DElements.length === 0) return;
            objects.traverse(function(child) {
                if (!_instanceof(child, THREE.Mesh)) return;
                customTemplateColor3DElements.forEach(function(elm) {
                    var ref, ref1, ref2;
                    if (!elm) return;
                    var isStitch = elm === null || elm === void 0 ? void 0 : elm.zone.svgIds.find(function(id) {
                        return id.toLowerCase().indexOf("stitch") !== -1;
                    });
                    var shouldApplyColor = elm.zone.svgIds.includes(child.name) || isStitch;
                    if (!shouldApplyColor) return;
                    /**
           * NOTE(Poom): The eRelation here is the prefix of the element's name,
           * It's used to check if the name of the mesh starts with E1- or E2-.
           *
           * @example The name of the element is going to be E1-body, E2-leftArm, E2-rightArm,
           *  and the eRelation is E1- or E2-
           */ // 3D ELEMENT RELATION CONTROL
                    // GET E1, E2 .... THEN CHECK RELATION WITH ANOTHER 3D ELM
                    var svgFullCode = elm === null || elm === void 0 ? void 0 : elm.zone.svgIds.find(function(svg) {
                        return svg.includes("-");
                    });
                    var eSplit = svgFullCode.split("-");
                    var eRelation = eSplit ? eSplit[0] + "-" : null;
                    var isElationNameAndHasColor = eRelation && ((ref = child.name) === null || ref === void 0 ? void 0 : ref.indexOf(eRelation)) !== -1 && elm.color;
                    var isSvgNameAndHasColor = svgFullCode.indexOf("STITCH") !== -1 && (((ref1 = child.name) === null || ref1 === void 0 ? void 0 : ref1.indexOf("MatShape_")) !== -1 || ((ref2 = child.name) === null || ref2 === void 0 ? void 0 : ref2.indexOf("Stitch_")) !== -1) && (elm === null || elm === void 0 ? void 0 : elm.color);
                    if (isElationNameAndHasColor || isSvgNameAndHasColor) {
                        var materials = getMaterial(child.material);
                        var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
                        try {
                            for(var _iterator = materials[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                                var material = _step.value;
                                var hexadecimal = convertHexStringToHexadecimal(elm.color.hex);
                                /**
               * set default color for each mesh for reset color or change template
               */ if (!material.userData.defaultColor) {
                                    material.userData = _object_spread_props(_object_spread({}, material.userData), {
                                        defaultColor: buildHexString(material.color.getHexString())
                                    });
                                }
                                material.color.set(hexadecimal);
                            }
                        } catch (err) {
                            _didIteratorError = true;
                            _iteratorError = err;
                        } finally{
                            try {
                                if (!_iteratorNormalCompletion && _iterator.return != null) {
                                    _iterator.return();
                                }
                            } finally{
                                if (_didIteratorError) {
                                    throw _iteratorError;
                                }
                            }
                        }
                    }
                });
            });
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isThreeReady,
        customTemplateColor3DElements
    ]);
};
