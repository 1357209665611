import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _object_without_properties from "@swc/helpers/src/_object_without_properties.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import flatMap from "lodash/flatMap";
import uniqBy from "lodash/uniqBy";
import { all, call, put, setContext, takeLatest } from "typed-redux-saga";
import { reportError } from "@brikl/shared-utils";
import { isImageStep } from "~/libs/store/src/util/designStep";
import { generateVariableFontFaceDeclaration } from "~/libs/store/src/util/font-face";
import { makeAvailablePlaceholders } from "../../helpers/transformData";
import { createStudioEngineContext } from "../../utils/create-studio-engine-context";
import { getMutationParams } from "../../utils/saga-helpers";
import { actions as AopEngineActions } from "../AopEngine/slice";
import { actions as ColorFillEngineActions } from "../ColorFillEngine/slice";
import { getCustomTemplateColors } from "../ColorFillEngine/transforms/get-custom-template-colors";
import { actions as DesignStepEngineActions } from "../DesignStepEngine/slice";
import { initializeDesignSteps } from "../DesignStepEngine/transforms/initialize-design-steps";
import { selectAndTransformPlacedImages, selectAndTransformUploadedImages } from "../ImageEngine/sagas/image-helpers/selectors";
import { actions as ImageEngineActions } from "../ImageEngine/slice";
import { actions as LayerEngineActions } from "../LayerEngine/slice";
import { actions as MetadataEngineActions } from "../MetadataEngine/slice";
import { actions as PatternEngineActions } from "../PatternEngine/slice";
import { actions as PlaceholderEngineActions } from "../PlaceholderEngine/slice";
import { actions as TemplateEngineActions } from "../TemplateEngine/slice";
import { transformDesignTemplates } from "../TemplateEngine/transforms/transform-templates";
import { GOOGLE_FONTS, STANDARD_FONTS } from "../TextEngine/constants";
import { transformTextDesigns } from "../TextEngine/sagas/text-helpers/selectors";
import { actions as TextStepEngineActions } from "../TextEngine/slice";
import { getFontFamilyFromUrl } from "../TextEngine/utils/font-family";
import { actions as GlobalActions } from "./slice";
import { createImageLibrary, getPrintingTechniques } from "./utils";
import addCssRulesToHeader from "./utils/addCssRulesToHeader";
import addStylesheetURL from "./utils/addStylesheetURL";
import createStudioStates from "./utils/create-studio-state";
import { getProductDesignQuery, getProductQueryWithRevision, getShopInactiveDefaultFonts } from "./utils/global-helpers/get-product-data";
function setupDesignSteps(context) {
    var designSteps;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                designSteps = initializeDesignSteps(context);
                return [
                    4,
                    put(DesignStepEngineActions.setDesignSteps(designSteps))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
function setupTextPlacement(context) {
    var ref, ref1, _textDesigns, textDesigns, orderSequences, tmp, allFonts, apollo, fonts, placedTexts, getInactiveDefaultFonts, ref2, listInactiveDefaultFonts, listDefaultFonts, filteredActiveFonts, fontFamilyNames, _tmp, e;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    6,
                    ,
                    7
                ]);
                _textDesigns = context.textDesigns, textDesigns = _textDesigns === void 0 ? [] : _textDesigns, orderSequences = context.orderSequences, tmp = context.fonts, allFonts = tmp === void 0 ? [] : tmp;
                return [
                    5,
                    _ts_values(getMutationParams())
                ];
            case 1:
                apollo = _state.sent().apollo;
                console.log("[Engine] text placements => found fonts", allFonts);
                fonts = allFonts.filter(function(font) {
                    return font.isAvailableInDesignStudio;
                });
                placedTexts = transformTextDesigns(textDesigns, orderSequences || []);
                // inject google fonts
                return [
                    4,
                    all(GOOGLE_FONTS.map(function(fontUrl) {
                        return addStylesheetURL(fontUrl);
                    }))
                ];
            case 2:
                _state.sent();
                // inject custom fonts from font library
                return [
                    4,
                    all(fonts.map(function(font) {
                        return font.fontVariants.map(function(fontVariant) {
                            return addCssRulesToHeader(generateVariableFontFaceDeclaration(fontVariant, fontVariant.fontName));
                        });
                    }))
                ];
            case 3:
                _state.sent();
                return [
                    5,
                    _ts_values(call(getShopInactiveDefaultFonts, apollo))
                ];
            case 4:
                getInactiveDefaultFonts = _state.sent();
                listInactiveDefaultFonts = new Set((ref2 = (ref = getInactiveDefaultFonts.data) === null || ref === void 0 ? void 0 : (ref1 = ref.defaultFontSettings) === null || ref1 === void 0 ? void 0 : ref1.disabledInDesignStudio) !== null && ref2 !== void 0 ? ref2 : []);
                listDefaultFonts = _to_consumable_array(STANDARD_FONTS).concat(_to_consumable_array(flatMap(GOOGLE_FONTS !== null && GOOGLE_FONTS !== void 0 ? GOOGLE_FONTS : [], function(fontUrl) {
                    return getFontFamilyFromUrl(fontUrl);
                })));
                filteredActiveFonts = listDefaultFonts.filter(function(font) {
                    return !listInactiveDefaultFonts.has(font);
                });
                fontFamilyNames = // Put custom fonts in list first
                _to_consumable_array(flatMap(fonts, function(font) {
                    return font.fontVariants.map(function(fontVariant) {
                        return fontVariant.fontName;
                    });
                })).concat(_to_consumable_array(filteredActiveFonts));
                _tmp = {};
                return [
                    4,
                    put(TextStepEngineActions.loadPlacedTexts((_tmp.placedTexts = placedTexts, _tmp.fonts = fontFamilyNames, _tmp)))
                ];
            case 5:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 6:
                e = _state.sent();
                console.error("[Engine] setupTextplacement error", e);
                return [
                    3,
                    7
                ];
            case 7:
                return [
                    2
                ];
        }
    });
}
function setupAop(context) {
    var printDesigns, zoneCustomizations, shopDesignSteps, latestPlaceholders, initialAopStates, _tmp;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                printDesigns = context.printDesigns, zoneCustomizations = context.zoneCustomizations, shopDesignSteps = context.shopDesignSteps, latestPlaceholders = context.latestPlaceholders;
                _tmp = {};
                initialAopStates = (_tmp.printDesigns = printDesigns, _tmp.zoneCustomizations = zoneCustomizations, _tmp.shopDesignSteps = shopDesignSteps, _tmp.latestPlaceholders = latestPlaceholders, _tmp);
                return [
                    4,
                    put(AopEngineActions.initializeAop(initialAopStates))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
function setupImagePlacement(context) {
    var logoDesigns, orderSequences, latestPlaceholders, shopDesignSteps, placedImages, uploadedImages, imageLibrary, images, _tmp;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                logoDesigns = context.logoDesigns, orderSequences = context.orderSequences, latestPlaceholders = context.latestPlaceholders, shopDesignSteps = context.shopDesignSteps;
                placedImages = selectAndTransformPlacedImages(logoDesigns, latestPlaceholders, orderSequences);
                uploadedImages = selectAndTransformUploadedImages(logoDesigns);
                imageLibrary = shopDesignSteps.filter(isImageStep).flatMap(function(designStep) {
                    var ref;
                    return designStep === null || designStep === void 0 ? void 0 : (ref = designStep.imageLibrary) === null || ref === void 0 ? void 0 : ref.map(createImageLibrary);
                });
                _tmp = {};
                images = (_tmp.placedImages = placedImages, _tmp.uploadedImages = uploadedImages, _tmp.imageLibrary = imageLibrary, _tmp);
                return [
                    5,
                    _ts_values(put(ImageEngineActions.loadImages(images)))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
function setupColorfill(context) {
    var zoneCustomizations, latestElements, allCustomTemplateColors, customTemplateColorColors, customTemplateColors3DElements, _tmp;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                zoneCustomizations = context.zoneCustomizations, latestElements = context.latestElements;
                allCustomTemplateColors = getCustomTemplateColors(zoneCustomizations) || [];
                console.log("[ColorFill Engine] all Custom Template Colors =>", allCustomTemplateColors);
                customTemplateColorColors = allCustomTemplateColors.filter(function(customTemplateColor) {
                    return !customTemplateColor.zoneId.includes("_ELM3D");
                });
                customTemplateColors3DElements = allCustomTemplateColors.filter(function(customTemplateColor) {
                    return customTemplateColor.zoneId.includes("_ELM3D");
                });
                _tmp = {};
                console.log("[ColorFill Engine] Setup:", (_tmp.customTemplateColorColors = customTemplateColorColors, _tmp.customTemplateColors3DElements = customTemplateColors3DElements, _tmp));
                // Load all color zones 3d elements
                return [
                    4,
                    put(ColorFillEngineActions.initialColorZones3DElements(latestElements))
                ];
            case 1:
                _state.sent();
                // Load all customised color zone for 2d elements
                return [
                    4,
                    put(ColorFillEngineActions.initialCustomTemplateColors(customTemplateColorColors))
                ];
            case 2:
                _state.sent();
                // Load all customised color zone for 3d elements
                return [
                    4,
                    put(ColorFillEngineActions.initialCustomTemplateColors3DElements(customTemplateColors3DElements))
                ];
            case 3:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
function setupTemplates(context) {
    var id, templates, _tmp;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                id = context.selectedDesignTemplateId;
                templates = transformDesignTemplates(context);
                if (!(templates.length > 0)) return [
                    3,
                    2
                ];
                _tmp = {};
                // Loads the template into the studio engine.
                return [
                    4,
                    put(TemplateEngineActions.loadTemplates((_tmp.templates = templates, _tmp.selectedTemplateId = id, _tmp)))
                ];
            case 1:
                _state.sent();
                _state.label = 2;
            case 2:
                return [
                    2
                ];
        }
    });
}
function setupPattern(context) {
    var _logoDesigns, logoDesigns;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _logoDesigns = context.logoDesigns, logoDesigns = _logoDesigns === void 0 ? [] : _logoDesigns;
                if (logoDesigns.length === 0) return [
                    2
                ];
                return [
                    4,
                    put(PatternEngineActions.loadPatterns(logoDesigns))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
function setupLayers(context) {
    var orderSequences;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                orderSequences = context.orderSequences;
                if (!orderSequences) return [
                    2
                ];
                if (orderSequences.length === 0) return [
                    2
                ];
                return [
                    4,
                    put(LayerEngineActions.loadLayeringOrder(orderSequences))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
function setupPlaceholder(context) {
    var latestPlaceholders, langCode, enablePlaceholders, placeholders, _tmp;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                latestPlaceholders = context.latestPlaceholders, langCode = context.langCode;
                enablePlaceholders = makeAvailablePlaceholders(latestPlaceholders, langCode).filter(function(placeholder) {
                    return placeholder.isEnabled;
                });
                _tmp = {};
                placeholders = (_tmp.placeholders = uniqBy(enablePlaceholders, "id") || [], _tmp.printingTechniques = getPrintingTechniques(latestPlaceholders, langCode), _tmp);
                return [
                    4,
                    put(PlaceholderEngineActions.loadPlaceholders(placeholders))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
// function* setSceneEngine(context: IStudioEngineContext) {}
// function* setupCart(context: IStudioEngineContext) {}
function setupProductDesign(config) {
    var isDesignRoom, shopId, storeData, productDesignId, productId, ownedByOrganisationId, ref, apollo, productData, variables, _tmp, variables1, _tmp1, context, _tmp2, isWarrix, studioTheme, langCode, environment, productInfo, pxToCmDimension, studioState, _tmp3, _tmp4, e;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                isDesignRoom = config.isDesignRoom, shopId = config.shopId, storeData = config.storeData, productDesignId = config.productDesignId, productId = config.productId, ownedByOrganisationId = config.ownedByOrganisationId;
                _state.label = 1;
            case 1:
                _state.trys.push([
                    1,
                    7,
                    ,
                    8
                ]);
                return [
                    5,
                    _ts_values(getMutationParams())
                ];
            case 2:
                apollo = _state.sent().apollo;
                if (!apollo) throw new Error("Apollo client is missing");
                if (!config.isQueryByRevision) return [
                    3,
                    4
                ];
                _tmp = {};
                variables = (_tmp.shopId = config.shopId, _tmp.id = config.productDesignId, _tmp.ownedByOrganisationId = config.ownedByOrganisationId, _tmp.revisionNo = config.revisionNo, _tmp);
                return [
                    5,
                    _ts_values(call(getProductQueryWithRevision, apollo, variables))
                ];
            case 3:
                productData = _state.sent();
                return [
                    3,
                    6
                ];
            case 4:
                _tmp1 = {};
                variables1 = (_tmp1.shopId = config.shopId, _tmp1.id = config.productDesignId, _tmp1.ownedByOrganisationId = config.ownedByOrganisationId, _tmp1);
                return [
                    5,
                    _ts_values(call(getProductDesignQuery, apollo, variables1))
                ];
            case 5:
                productData = _state.sent();
                _state.label = 6;
            case 6:
                _tmp2 = {};
                context = createStudioEngineContext((_tmp2.ownedByOrganisationId = ownedByOrganisationId, _tmp2.productDesignId = productDesignId, _tmp2.shopId = shopId, _tmp2.isWarrix = storeData.isWarrix, _tmp2.productId = config.productId, _tmp2.revisionNo = config.revisionNo, _tmp2.isDesignRoom = isDesignRoom, _tmp2.viewOnly = config.viewOnly, _tmp2.disclaimerUrl = config.disclaimerUrl, _tmp2.currency = config.currency, _tmp2.countryCode = config.countryCode, _tmp2.thankYouSlug = config.thankYouSlug, _tmp2.storeData = config.storeData, _tmp2.isQueryByRevision = config.isQueryByRevision, _tmp2.productQuery = productData, _tmp2.mode = config.mode, _tmp2));
                isWarrix = storeData.isWarrix, studioTheme = storeData.studioTheme, langCode = storeData.langCode, environment = storeData.environment;
                if (!productData.data) return [
                    2
                ];
                productInfo = productData.data.getProductDesign;
                pxToCmDimension = ((ref = productInfo.product.dimensionReference) === null || ref === void 0 ? void 0 : ref.dimension) || 0;
                _tmp3 = {};
                studioState = createStudioStates((_tmp3.product = productInfo.product, _tmp3.isDesignRoom = config.isDesignRoom, _tmp3.isWarrix = isWarrix, _tmp3.shopId = config.shopId, _tmp3.studioTheme = studioTheme, _tmp3.langCode = langCode, _tmp3.productDesignId = config.productDesignId, _tmp3.productId = productId, _tmp3.pxToCmDimension = pxToCmDimension, _tmp3.organisationId = ownedByOrganisationId, _tmp3.environment = environment, _tmp3));
                _tmp4 = {};
                return [
                    2,
                    (_tmp4.productDesign = productInfo, _tmp4.studioState = studioState, _tmp4.context = context, _tmp4)
                ];
            case 7:
                e = _state.sent();
                console.error("[Product Design]", e);
                return [
                    3,
                    8
                ];
            case 8:
                return [
                    2
                ];
        }
    });
}
function resetAllData() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    all([
                        put(MetadataEngineActions.resetData()),
                        put(DesignStepEngineActions.resetData()),
                        put(TextStepEngineActions.resetData()),
                        put(AopEngineActions.resetData()),
                        put(ImageEngineActions.resetData()),
                        put(ColorFillEngineActions.resetData()),
                        put(TemplateEngineActions.resetData()),
                        put(PatternEngineActions.resetData()),
                        put(LayerEngineActions.resetData()),
                        put(PlaceholderEngineActions.resetData())
                    ])
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
export function setupEngineTasks(action) {
    var apolloClient, payload, _tmp, ref, studioState, context, productDesign, _tmp1, _tmp2, _tmp3, ref1, e, // callback error
    ref2;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                apolloClient = action.payload.apolloClient, payload = _object_without_properties(action.payload, [
                    "apolloClient"
                ]);
                _state.label = 1;
            case 1:
                _state.trys.push([
                    1,
                    8,
                    ,
                    9
                ]);
                // reset all data for new product
                return [
                    5,
                    _ts_values(resetAllData())
                ];
            case 2:
                _state.sent();
                _tmp = {};
                // set context
                return [
                    5,
                    _ts_values(setContext((_tmp.apolloClient = apolloClient, _tmp)))
                ];
            case 3:
                _state.sent();
                return [
                    5,
                    _ts_values(setupProductDesign(payload))
                ];
            case 4:
                ref = _state.sent(), studioState = ref.studioState, context = ref.context, productDesign = ref.productDesign;
                _tmp1 = {};
                _tmp2 = {};
                // Setup the metadata required by Apollo and Color fabrics
                return [
                    5,
                    _ts_values(put(MetadataEngineActions.setMetadata(_object_spread_props(_object_spread(_tmp1, payload), (_tmp2.fabrics = context.fabrics, _tmp2.thankYouSlug = context.thankYouSlug, _tmp2)))))
                ];
            case 5:
                _state.sent();
                _tmp3 = {};
                return [
                    4,
                    put(GlobalActions.setStudioState((_tmp3.studioState = studioState, _tmp3.context = context, _tmp3)))
                ];
            case 6:
                _state.sent();
                // callback data
                if (productDesign) {
                    ;
                    payload === null || payload === void 0 ? void 0 : (ref1 = payload.onDispatchSetProductDesign) === null || ref1 === void 0 ? void 0 : ref1.call(payload, productDesign);
                }
                return [
                    5,
                    _ts_values(runAllEngineTasks(context))
                ];
            case 7:
                _state.sent();
                return [
                    3,
                    9
                ];
            case 8:
                e = _state.sent();
                payload === null || payload === void 0 ? void 0 : (ref2 = payload.onDispatchSetProductDesign) === null || ref2 === void 0 ? void 0 : ref2.call(payload, null, e);
                console.error("setupEngineTasks error", e);
                return [
                    3,
                    9
                ];
            case 9:
                return [
                    2
                ];
        }
    });
}
export function setupSelectedTemplate(payload) {
    var allCustomTemplateColors, e, _tmp;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    2,
                    ,
                    4
                ]);
                allCustomTemplateColors = payload.zoneCustomizations.filter(function(zone) {
                    return zone.templateId === payload.selectedDesignTemplateId;
                });
                /**
     * if selected template id already has custom zones,
     * it must not create new customizes zones.
     */ if (allCustomTemplateColors.length !== 0) return [
                    2
                ];
                return [
                    5,
                    _ts_values(put(TemplateEngineActions.selectTemplate(payload.selectedDesignTemplateId)))
                ];
            case 1:
                _state.sent();
                return [
                    3,
                    4
                ];
            case 2:
                e = _state.sent();
                return [
                    4,
                    put(GlobalActions.setSetUpError(e))
                ];
            case 3:
                _state.sent();
                _tmp = {};
                reportError(e, (_tmp.title = "update design template", _tmp.module = "setupSelectedTemplate", _tmp));
                return [
                    3,
                    4
                ];
            case 4:
                return [
                    2
                ];
        }
    });
}
function runAllEngineTasks(payload) {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                // setup design steps
                return [
                    5,
                    _ts_values(setupDesignSteps(payload))
                ];
            case 1:
                _state.sent();
                // setup template
                return [
                    5,
                    _ts_values(setupTemplates(payload))
                ];
            case 2:
                _state.sent();
                // setup text
                return [
                    5,
                    _ts_values(setupTextPlacement(payload))
                ];
            case 3:
                _state.sent();
                // setup color fill
                return [
                    5,
                    _ts_values(setupColorfill(payload))
                ];
            case 4:
                _state.sent();
                // setup aop
                return [
                    5,
                    _ts_values(setupAop(payload))
                ];
            case 5:
                _state.sent();
                // setup images
                return [
                    5,
                    _ts_values(setupImagePlacement(payload))
                ];
            case 6:
                _state.sent();
                // setup placeholder
                return [
                    5,
                    _ts_values(setupPlaceholder(payload))
                ];
            case 7:
                _state.sent();
                // setup pattern
                return [
                    5,
                    _ts_values(setupPattern(payload))
                ];
            case 8:
                _state.sent();
                // setup layers
                return [
                    5,
                    _ts_values(setupLayers(payload))
                ];
            case 9:
                _state.sent();
                // setup selected template and create zone customization
                return [
                    5,
                    _ts_values(setupSelectedTemplate(payload))
                ];
            case 10:
                _state.sent();
                return [
                    5,
                    _ts_values(put(GlobalActions.initialCompleted()))
                ];
            case 11:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
/**
 * Root saga manages watcher lifecycle
 */ export default function rootSaga() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    takeLatest(GlobalActions.initial.type, setupEngineTasks)
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
};
