import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import * as THREE from "three";
import { SpotLightPositions } from "@brikl/studio-utils";
/**
 * Helper function to get the materials array
 *
 * @param  {any|any[]} material
 * @returns material
 */ export function getMaterial(material) {
    return Array.isArray(material) ? material : [
        material
    ];
}
/**
 * @see https://stackoverflow.com/questions/30359830/how-do-i-clear-three-js-scene/48722282
 * Clean up THREE.js objects alocated in memory
 * Not really sure if this is the best way... need more research.
 * @param  {THREE.Scene} scene
 */ export function cleanupResources(scene) {
    // Little hacks
    var obj = scene;
    while(obj.children.length > 0){
        cleanupResources(obj.children[0]);
        obj.remove(obj.children[0]);
    }
    if (obj.geometry) {
        obj.geometry.dispose();
    }
    if (obj.material) {
        //in case of map, bumpMap, normalMap, envMap ...
        Object.keys(obj.material).forEach(function(prop) {
            if (!obj.material[prop]) return;
            if (typeof obj.material[prop].dispose === "function") obj.material[prop].dispose();
        });
    }
}
/**
 * Convert hex string color to hex color adecimal
 * @description https://threejs.org/docs/#api/en/math/Color
 * @param hexString
 */ export function convertHexStringToHexadecimal(hexString) {
    if (hexString.length < 7) {
        var _hexString = _sliced_to_array(hexString, 4), _ = _hexString[0], r = _hexString[1], g = _hexString[2], b = _hexString[3];
        hexString = "#" + r + r + g + g + b + b;
    }
    var colorValue = parseInt(hexString.replace("#", "0x"), 16);
    return new THREE.Color(colorValue);
}
/**
 * Create a invisible box around the box to allow centralize in the scene
 * @param  {THREE.Group} object
 */ export function setupObjectInCenter(object) {
    object.position.set(0, 0, 0);
    var boxPosition = new THREE.Box3().setFromObject(object);
    var diffVec = new THREE.Vector3();
    boxPosition.getCenter(diffVec);
    diffVec.multiplyScalar(-1);
    object.traverse(function(child) {
        if (_instanceof(child, THREE.Mesh)) {
            child.position.add(diffVec);
        }
    });
}
/**
 * get 3D element that map with printpattren uv (FabricJS)
 * @param  {THREE.Group} object
 * @returns {THREE.Mesh} list meshes which have printPattern uv from fabricJS
 */ export function get3DPrintPatternObject(object) {
    var printPatternGroup = [];
    object.traverse(function(child) {
        if (_instanceof(child, THREE.Mesh)) {
            var materials = getMaterial(child.material);
            for(var x = 0; x < materials.length; x++){
                if (child.name.match(/([E])\d+/g)) {
                    if (materials[x].userData.PrintPattern === true) {
                        var exist = printPatternGroup.find(function(obj) {
                            return obj.name === child.name;
                        });
                        if (!exist) printPatternGroup.push(child);
                        continue;
                    }
                }
            }
        }
    });
    return printPatternGroup;
}
/**
 * map fabricjs (canvas) into threejs material (model)
 * @param  {THREE.Group} object
 * @param  {HTMLCanvasElement} canvas
 */ export function updateObjectMaterialsWithCanvas(object, canvas) {
    object.traverse(function(child) {
        if (!_instanceof(child, THREE.Mesh)) return;
        var materials = getMaterial(child.material);
        var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
        try {
            for(var _iterator = materials[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                var material = _step.value;
                var ref;
                if (material.map && ((ref = material.userData) === null || ref === void 0 ? void 0 : ref.PrintPattern) === true) {
                    material.map.image = canvas;
                    material.transparent = true;
                    material.map.needsUpdate = true;
                }
                // materials[x].bumpMap = null
                material.needsUpdate = true;
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally{
            try {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                }
            } finally{
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }
    });
}
var DEFAULT_COLOR = 0xffffff;
export function createLight(param) {
    var type = param.type, skyColor = param.skyColor, grounColor = param.grounColor, color = param.color, intensity = param.intensity, distance = param.distance, name = param.name, visible = param.visible, position = param.position, id = param.id;
    var light;
    switch(type){
        case "ambient":
            light = new THREE.AmbientLight(new THREE.Color(color !== null && color !== void 0 ? color : DEFAULT_COLOR), intensity);
            break;
        case "dome":
            light = new THREE.HemisphereLight(new THREE.Color(skyColor !== null && skyColor !== void 0 ? skyColor : DEFAULT_COLOR), new THREE.Color(grounColor !== null && grounColor !== void 0 ? grounColor : 0xbbbbbb), intensity);
            break;
        case "spot":
            light = new THREE.SpotLight(new THREE.Color(color !== null && color !== void 0 ? color : DEFAULT_COLOR), intensity, distance);
            break;
    }
    setDefaultLightAttribute(light);
    if (position) {
        var _position = SpotLightPositions[position], x = _position.x, y = _position.y, z = _position.z;
        light.position.set(x, y, z);
        light.distance = distance;
        light.intensity = intensity;
    }
    light.visible = visible;
    // identity id to update and delete
    light.userData.name = name;
    light.userData.uuid = id;
    return light;
}
export function createLightHelper(light) {
    var helperSize = 15;
    var helperColor = 0x000000;
    if (_instanceof(light, THREE.AmbientLight)) return;
    var lightHelper;
    if (_instanceof(light, THREE.SpotLight) || _instanceof(light, THREE.DirectionalLight)) {
        lightHelper = new THREE.PointLightHelper(light, helperSize, helperColor);
    } else if (_instanceof(light, THREE.HemisphereLight)) {
        lightHelper = new THREE.HemisphereLightHelper(light, helperSize, helperColor);
    }
    // assign light id to update color of size
    lightHelper.userData.lightId = light.userData.uuid;
    return lightHelper;
}
function setDefaultLightAttribute(light) {
    if (!(_instanceof(light, THREE.SpotLight) || _instanceof(light, THREE.DirectionalLight))) return;
    if (_instanceof(light, THREE.SpotLight)) {
        light.angle = Math.PI / 2;
        light.penumbra = 0.1;
        light.decay = 2;
        light.distance = 2000;
    }
    light.castShadow = true;
    light.shadow.bias = -0.0001;
    light.shadow.mapSize.width = 1024 * 4;
    light.shadow.mapSize.height = 1024 * 4;
}
/**
 * Sets basic lightning
 * @param  {THREE.Scene} scene
 * @param  {THREE.PerspectiveCamera} camera
 * @param  {number} spotLightIntensity
 */ export function setupLights(param) {
    var scene = param.scene, camera = param.camera, spotLightIntensity = param.spotLightIntensity, isUsedNewLight = param.isUsedNewLight;
    if (isUsedNewLight) {
        var spotLightColor = 0xffffff;
        // digitize version
        var ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
        ambientLight.name = "ambientLight";
        var hemisphereLight = new THREE.HemisphereLight(0xffffff, 0xbbbbbb, 0.1);
        hemisphereLight.name = "hemisphereLight";
        // START Front right light
        var frontRightLight = new THREE.SpotLight(spotLightColor, spotLightIntensity);
        frontRightLight.name = "frontRightLight";
        // helper for debuggin
        // const frontRightLightHelper = new THREE.SpotLightHelper(
        //   frontRightLight,
        //   // helperSize,
        //   helperColor
        // )
        setDefaultLightAttribute(frontRightLight);
        frontRightLight.distance = 1200;
        frontRightLight.position.set(600, 200, 200);
        scene.add(frontRightLight);
        // END Front right light
        // START Front left light
        var frontLeftLight = new THREE.SpotLight(spotLightColor, spotLightIntensity);
        frontLeftLight.name = "frontLeftLight";
        // helper for debugging
        // const frontLeftLightHelper = new THREE.PointLightHelper(
        //   frontLeftLight,
        //   helperSize,
        //   helperColor
        // )
        setDefaultLightAttribute(frontLeftLight);
        frontLeftLight.distance = 1500;
        frontLeftLight.position.set(-500, 200, 200);
        scene.add(frontLeftLight);
        // END Front left light
        // STAR Back right light
        var backRightLight = new THREE.SpotLight(spotLightColor, spotLightIntensity);
        backRightLight.name = "backRightLight";
        // helper for debugging
        // const backRightLightHelper = new THREE.PointLightHelper(
        //   backRightLight,
        //   helperSize,
        //   helperColor
        // )
        setDefaultLightAttribute(backRightLight);
        backRightLight.distance = 1150;
        backRightLight.position.set(300, 350, -750);
        scene.add(backRightLight);
        // END Back right light
        // START Back left light
        var backLeftLight = new THREE.SpotLight(spotLightColor, spotLightIntensity);
        backLeftLight.name = "backLeftLight";
        // helper for debugging
        // const backlLeftLightHelper = new THREE.PointLightHelper(
        //   backLeftLight,
        //   helperSize,
        //   helperColor
        // )
        setDefaultLightAttribute(frontRightLight);
        backLeftLight.distance = 1150;
        backLeftLight.position.set(-300, 350, -750);
        scene.add(backLeftLight);
        // END Back left light
        // START Front
        var frontLight = new THREE.SpotLight(spotLightColor, 0.025);
        setDefaultLightAttribute(frontLight);
        // helper for debugging
        // const frontLightHelper = new THREE.PointLightHelper(
        //   frontLight,
        //   helperSize,
        //   helperColor
        // )
        frontLight.position.set(0, 0, 10);
        scene.add(frontLight);
        scene.add(ambientLight);
        scene.add(hemisphereLight);
    } else {
        var ambientLight1 = new THREE.AmbientLight(0xffffff, 0.6);
        var spotLight = new THREE.SpotLight(0xffffff, spotLightIntensity);
        var hemisphereLight1 = new THREE.HemisphereLight(0xffffff, 0xbbbbbb, 0.1);
        spotLight.position.set(0, 500, 500);
        spotLight.angle = Math.PI / 2;
        spotLight.penumbra = 0.1;
        spotLight.decay = 2;
        spotLight.distance = 4000;
        spotLight.shadow.mapSize.width = 1000;
        spotLight.shadow.mapSize.height = 1000;
        scene.add(ambientLight1);
        scene.add(hemisphereLight1);
        camera.add(spotLight);
    }
}
/**
 * 3D Helper for THREEJS
 * Use for debug 3D model such as model invisible, model miss location, model size ....
 * How to use : add get parameter "3DHelper" into url
 * @param  {THREE.Scene} scene
 */ export function setupHelper(scene) {
    var urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.has("3DHelper")) {
        return;
    }
    var axesHelper = new THREE.AxesHelper(5);
    scene.add(axesHelper);
    var size = 10;
    var divisions = 10;
    var gridHelper = new THREE.GridHelper(size, divisions);
    scene.add(gridHelper);
}
/**
 * Before rendering the model in three.js we need to scale
 * to a smaller size.
 * @param object
 */ export function setupObjectScale(object) {
    var boxScale = new THREE.Box3().setFromObject(object);
    var xLength = boxScale.max.x - boxScale.min.x;
    var yLength = boxScale.max.y - boxScale.min.y;
    var modelScale = xLength > yLength ? 1 / xLength : 1 / yLength;
    console.log("[THREEJS OBJECT]", object);
    // Set model scale
    object.traverse(function(child) {
        if (_instanceof(child, THREE.Mesh)) {
            child.scale.set(modelScale, modelScale, modelScale);
            setupStitchTransparent(child);
            // splitMaterial(child) // FOR CHECK ELM REASON
            element3DSetup(child) // FORCE SIDE RENDER
            ;
        }
    });
}
/**
 * sometime material turn to front or back side render mean it will transparent in some direction
 * then this for force double side render for make sure every material visible
 * @param  {THREE.Mesh} child
 */ function element3DSetup(child) {
    var materials = getMaterial(child.material);
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        for(var _iterator = materials[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var material = _step.value;
            if (child.name.match(/([E])\d+/g)) {
                material.side = THREE.DoubleSide;
                if (!material.userData.printPattern) {
                    // 3D element with non print pattern UV map will toggle to transparent because it is image texture like stitch
                    material.transparent = true;
                }
            }
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
}
/**
 * setup stitch mesh into model
 * STITCH is material of 3D model (AKA. Shirt seam) It is images base that texture on top of material
 * @param  {THREE.Mesh} child
 */ function setupStitchTransparent(child) {
    var materials = getMaterial(child.material);
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        for(var _iterator = materials[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var material = _step.value;
            var ref, ref1;
            // Stitch in 3D model, the name of material it auto genertated by CLO3D application
            // Now auto gen name have 2 name because of CLO3D version changed
            // Old model stitch will name MatShape_ and new stitch is Stitch_
            if (((ref = child.name) === null || ref === void 0 ? void 0 : ref.indexOf("MatShape_")) !== -1 || ((ref1 = child.name) === null || ref1 === void 0 ? void 0 : ref1.indexOf("Stitch_")) !== -1) {
                material.side = THREE.DoubleSide // Make sure stitch is can view by all angle
                ;
                material.alphaTest = 0.1 // Trick for make material correct visible https://threejs.org/docs/#api/en/materials/Material.alphaTest
                ;
                material.transparent = true;
                material.needsUpdate = true;
            }
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
}
