import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";
import { rgbToHex, splitRgbFromString } from "~/libs/uploadbox/src/UploadBox/svglib";
/**
 * convert color in rgb format to hex format
 * @param  {string} rgbString
 */ function convertRgbToHex(rgbString) {
    if (isEmpty(rgbString) || rgbString === "none") return "#000000" // default color of SVG path is black
    ;
    var ref = _sliced_to_array(splitRgbFromString(rgbString), 3), red = ref[0], green = ref[1], blue = ref[2];
    return rgbToHex(red, green, blue);
}
/**
 * get color from element for linear and non linear gradient
 * @param colorZoneElement
 */ function getColorFromElement(colorZoneElement) {
    var hasNoChildren = colorZoneElement.children.length === 0;
    // color fill in zone element tag
    if (hasNoChildren) {
        return getComputedStyle(colorZoneElement).fill;
    }
    var isLinearGradient = colorZoneElement.querySelector("linearGradient");
    // in case of linear gradient, it must get color from stop element not linearGradient
    if (isLinearGradient) {
        var stopElement = colorZoneElement.querySelector("stop");
        return getComputedStyle(stopElement).stopColor;
    }
    // in case of non linear gradient, it must select tag element to fill color
    var nonGroupElment = colorZoneElement.querySelector("*:not(linearGradient):not(stop):not(defs):not(clipPath):not(defs > *):not(g)");
    return getComputedStyle(nonGroupElment).fill;
}
/**
 * get list color of each zone by uniqe element id
 * @param  {HTMLElement} designTemplateDoc
 */ function getColorZoneElements(designTemplateDoc) {
    var colorZoneElements = designTemplateDoc.querySelectorAll("[id^='COLORZONE']");
    var listColorValues = Array.from(colorZoneElements).map(function(colorZoneElement) {
        return {
            id: colorZoneElement.id,
            colorFill: getColorFromElement(colorZoneElement)
        };
    });
    return uniqBy(listColorValues.filter(function(colorValue) {
        return(// exclude linear gredient element
        colorValue.colorFill.includes("rgb") || // include non assigned color to path
        colorValue.colorFill.includes("none") || isEmpty(colorValue.colorFill));
    }).map(function(colorValue) {
        return {
            id: colorValue.id,
            colorFill: convertRgbToHex(colorValue.colorFill)
        };
    }), function(colorValue) {
        return colorValue.id;
    });
}
export function getDefaultColorValuesFromDesignTemplate(designTemplateText) {
    // HACK: to get fill attribute from design template
    var designTemplateDoc = document.getElementById("design-template");
    designTemplateDoc.innerHTML = designTemplateText;
    return getColorZoneElements(designTemplateDoc);
}
