export var OBJECT_VISIBILITY_PROPERTIES = {
    mtr: false,
    ml: false,
    mr: false,
    bl: false,
    // mt: false,
    // mb: false,
    br: false
};
export var OBJECT_PLACEHOLDER_VISIBILITY_PROPERTIES = {
    mtr: false,
    ml: false,
    mr: false,
    bl: false,
    mt: false,
    mb: false,
    br: false,
    tl: false,
    tr: false
};
export var OBJECT_SINGLE_LAYER_VISIBILITY_PROPERTIES = {
    mt: false,
    mb: false
};
