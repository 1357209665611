import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { all, fork } from "typed-redux-saga";
import { watchDeletePattern } from "./sagas/delete-pattern.saga";
import { loadPatterns } from "./sagas/load-pattern-saga";
import { watchPlacePattern } from "./sagas/place-pattern.saga";
import { watchUpdatePlacedPattern } from "./sagas/update-pattern.saga";
import { watchUploadPatterns } from "./sagas/upload-pattern.saga";
/**
 * Root saga manages watcher lifecycle
 */ export default function rootSaga() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(all([
                        // load patterns
                        fork(loadPatterns),
                        // place pattern
                        fork(watchPlacePattern),
                        // upload pattern
                        fork(watchUploadPatterns),
                        // update pattern
                        fork(watchUpdatePlacedPattern),
                        // watch delete uploaded and placed patterns
                        fork(watchDeletePattern)
                    ]))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
};
