import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
export var updateText = function(state, action) {
    state.isUpdating = true;
    state.isUpdated = false;
};
export var updateTextSuccess = function(state, param) {
    var newText = param.payload;
    var originalText = state.placedTexts.find(function(placedText) {
        return placedText.id === newText.id;
    });
    var updatedText = _object_spread({}, originalText, newText);
    state.placedTexts = _to_consumable_array(state.placedTexts.filter(function(placedText) {
        return placedText.id !== newText.id;
    })).concat([
        updatedText, 
    ]);
    state.isUpdating = false;
    state.isUpdated = true;
    state.isFailure = false;
};
