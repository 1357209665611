import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { delay, fork, getContext, select } from "typed-redux-saga";
/**
 * Get apollo client object from saga context
 */ export function getApolloClient() {
    var apollo;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(getContext("apolloClient"))
                ];
            case 1:
                apollo = _state.sent();
                return [
                    2,
                    apollo
                ];
        }
    });
}
/**
 * Get meta data from store
 */ export function getProductMetadata() {
    var metadata;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(select(function(state) {
                        return state.metadata;
                    }))
                ];
            case 1:
                metadata = _state.sent();
                return [
                    2,
                    metadata
                ];
        }
    });
}
/**
 * Prepare apollo hook and shop metadata when mutate
 */ export function getMutationParams() {
    var apollo, meta, _tmp;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(getApolloClient())
                ];
            case 1:
                apollo = _state.sent();
                return [
                    5,
                    _ts_values(getProductMetadata())
                ];
            case 2:
                meta = _state.sent();
                _tmp = {};
                return [
                    2,
                    (_tmp.apollo = apollo, _tmp.meta = meta, _tmp)
                ];
        }
    });
}
/**
 * Create cancelable watcher
 */ export function createCancelableTask() {
    var msec = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 500;
    var ongoing;
    return function(task) {
        var _len, args, _key;
        var _arguments = arguments;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    for(_len = _arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++){
                        args[_key - 1] = _arguments[_key];
                    }
                    if (ongoing && ongoing.isRunning()) {
                        ongoing.cancel();
                    }
                    return [
                        5,
                        _ts_values(fork(function() {
                            return _ts_generator(this, function(_state) {
                                switch(_state.label){
                                    case 0:
                                        return [
                                            5,
                                            _ts_values(delay(msec))
                                        ];
                                    case 1:
                                        _state.sent();
                                        return [
                                            5,
                                            _ts_values(fork.apply(void 0, [
                                                task
                                            ].concat(_to_consumable_array(args))))
                                        ];
                                    case 2:
                                        _state.sent();
                                        return [
                                            2
                                        ];
                                }
                            });
                        }))
                    ];
                case 1:
                    ongoing = _state.sent();
                    return [
                        2
                    ];
            }
        });
    };
}
