import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _object_without_properties from "@swc/helpers/src/_object_without_properties.mjs";
import { isEmberaldBlackShop, isWarrixShop } from "~/libs/store/src/util/shopCondition";
export function createStudioEngineContext(config) {
    var // @ts-ignore
    ref, // @ts-ignore
    ref1, ref2, ref3, ref4;
    var productQuery = config.productQuery, storeData = config.storeData, context = _object_without_properties(config, [
        "productQuery",
        "storeData"
    ]);
    var productData = productQuery === null || productQuery === void 0 ? void 0 : productQuery.data;
    var ref5;
    var latestRevision = (ref5 = productData === null || productData === void 0 ? void 0 : (ref = productData.getProductDesign) === null || ref === void 0 ? void 0 : ref.revision) !== null && ref5 !== void 0 ? ref5 : productData === null || productData === void 0 ? void 0 : (ref1 = productData.getProductDesign) === null || ref1 === void 0 ? void 0 : ref1.latestRevision;
    var product = productData === null || productData === void 0 ? void 0 : (ref2 = productData.getProductDesign) === null || ref2 === void 0 ? void 0 : ref2.product;
    var shopConfig = productData === null || productData === void 0 ? void 0 : productData.shopConfiguration;
    var salesChannelId = storeData.salesChannelId || "MYBRIKL";
    var langCode = storeData.langCode;
    var _revisionNo, ref6;
    return _object_spread_props(_object_spread({
        productData: productData
    }, context), {
        // Metadata
        langCode: langCode,
        salesChannelId: salesChannelId,
        organisationId: config.ownedByOrganisationId,
        productLoading: productQuery === null || productQuery === void 0 ? void 0 : productQuery.loading,
        revisionNo: (_revisionNo = config.revisionNo) !== null && _revisionNo !== void 0 ? _revisionNo : 1,
        isRevisionQuery: config.isQueryByRevision,
        // Templates
        selectedDesignTemplateId: latestRevision === null || latestRevision === void 0 ? void 0 : latestRevision.selectedDesignTemplateId,
        productTemplates: product === null || product === void 0 ? void 0 : product.designTemplates,
        blankTemplatePreviewSrc: product === null || product === void 0 ? void 0 : product.blankTemplatePreviewSrc,
        // Zone Customizations (Color Fill/AOP)
        zoneCustomizations: latestRevision === null || latestRevision === void 0 ? void 0 : latestRevision.zoneCustomizations,
        // Scene
        latestElements: product === null || product === void 0 ? void 0 : product.latestElements,
        fabrics: shopConfig === null || shopConfig === void 0 ? void 0 : (ref3 = shopConfig.fabrics) === null || ref3 === void 0 ? void 0 : ref3.edges,
        // Text
        textDesigns: latestRevision === null || latestRevision === void 0 ? void 0 : latestRevision.textDesigns,
        orderSequences: (ref6 = latestRevision === null || latestRevision === void 0 ? void 0 : latestRevision.orderSequences) !== null && ref6 !== void 0 ? ref6 : [],
        /**
      Design Steps
      The design step on product level may override the shop level.
      @see initializeDesignSteps for more context on why there are two steps.
    */ shopDesignSteps: (shopConfig === null || shopConfig === void 0 ? void 0 : (ref4 = shopConfig.designStudioSettings) === null || ref4 === void 0 ? void 0 : ref4.designSteps) || [],
        productDesignSteps: (product === null || product === void 0 ? void 0 : product.designSteps) || [],
        // AOP & Pattern Fill
        printDesigns: latestRevision === null || latestRevision === void 0 ? void 0 : latestRevision.printDesigns,
        // Image
        logoDesigns: latestRevision === null || latestRevision === void 0 ? void 0 : latestRevision.logoDesigns,
        // Placeholders
        latestPlaceholders: product === null || product === void 0 ? void 0 : product.latestPlaceholders,
        // Special Shops Flag
        isWarrix: isWarrixShop(config.shopId),
        isNBE: config.isNBE,
        // PoC for Emerald Black
        isEmeraldBlack: isEmberaldBlackShop(config.shopId),
        // To determine if the app is design room
        isDesignRoom: context === null || context === void 0 ? void 0 : context.isDesignRoom,
        fonts: storeData.fonts || []
    });
}
