import { createSlice } from "../../@redux";
import { initialState } from "./constants";
export var slice = createSlice({
    name: "scene",
    initialState: initialState,
    reducers: {
        selectZone: function(state, param) {
            var payload = param.payload;
        // no action required
        },
        selectZoneCompleted: function selectZoneCompleted(state, param) {
            var payload = param.payload;
            state.selectedZoneId = payload;
            state.isReady = true;
        },
        selectZone3DElement: function selectZone3DElement(state, param) {
            var payload = param.payload;
            state.selectedZone3DElementId = payload;
        },
        selectSVGElement: function selectSVGElement(state, param) {
            var payload = param.payload;
            state.selectedSVGElementId = payload;
        }
    }
});
export var actions = slice.actions, reducer = slice.reducer, name = slice.name;
