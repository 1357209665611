import find from "lodash/find";
import { createSelector, createStructuredSelector } from "reselect";
import { selectSelectedDesignStep } from "../DesignStepEngine/selectors";
import { selectSelectedTemplate } from "../TemplateEngine/selectors";
import { initialState } from "./constants";
var selectDomain = function(state) {
    return state.placeholder || initialState;
};
export var isUploading = createSelector([
    selectDomain
], function(placeholderState) {
    return placeholderState === null || placeholderState === void 0 ? void 0 : placeholderState.isUploading;
});
export var selectedPlaceholderIds = createSelector([
    selectDomain
], function(placeholderState) {
    return (placeholderState === null || placeholderState === void 0 ? void 0 : placeholderState.selectedPlaceholderId) || null;
});
export var makeSelectSelectedPlaceholder = createSelector(selectDomain, selectedPlaceholderIds, function(placeholderState, selectedPlaceholder) {
    return find(placeholderState.placeholders, {
        id: selectedPlaceholder.placeholderId,
        svgId: selectedPlaceholder.placeholderSvgId
    });
});
export var makeSelectSelectedPlaceholderGroup = createSelector(selectDomain, selectSelectedDesignStep, function(placeholderState, selectedDesignStep) {
    if (placeholderState === null || placeholderState === void 0 ? void 0 : placeholderState.selectedPlaceholderGroup) {
        return placeholderState === null || placeholderState === void 0 ? void 0 : placeholderState.selectedPlaceholderGroup;
    }
    var ref;
    return (ref = selectedDesignStep.designPlaceholderGroups[0]) !== null && ref !== void 0 ? ref : null;
});
export var makeSelectAvailablePlaceholders = createSelector(selectDomain, selectSelectedTemplate, function(placeholderState, selectedDesignTemplate) {
    // select all placeholders
    var placeholders = placeholderState.placeholders || [];
    // Filter only placeholders belong to selected template
    return placeholders.filter(function(placeholder) {
        return placeholder.assignedProductDesignTemplateIds.includes(selectedDesignTemplate === null || selectedDesignTemplate === void 0 ? void 0 : selectedDesignTemplate.id);
    });
});
export var selectAllSelectedPrintingTechniques = createSelector([
    selectDomain
], function(placeholderState) {
    return (placeholderState === null || placeholderState === void 0 ? void 0 : placeholderState.selectedPrintingTechniques) || [];
});
export var selectDelectedPlaceholderGroup = createSelector([
    selectDomain
], function(placeholderState) {
    return (placeholderState === null || placeholderState === void 0 ? void 0 : placeholderState.selectedPlaceholderGroup) || [];
});
export var selectPlaceholderPrintingTechniques = createSelector(makeSelectSelectedPlaceholder, function(selectedPlaceholder) {
    return (selectedPlaceholder === null || selectedPlaceholder === void 0 ? void 0 : selectedPlaceholder.printingTechniques) || [];
});
export var selectSelectedPrintingTechnique = createSelector(selectAllSelectedPrintingTechniques, makeSelectSelectedPlaceholder, selectSelectedDesignStep, function(allSelectedPrintingTechniques, selectedPlaceholder, selectedDesignStep) {
    var printingTechnique = find(allSelectedPrintingTechniques, {
        placeholderId: selectedPlaceholder === null || selectedPlaceholder === void 0 ? void 0 : selectedPlaceholder.id,
        placeholderSvgId: selectedPlaceholder === null || selectedPlaceholder === void 0 ? void 0 : selectedPlaceholder.svgId,
        designStepId: selectedDesignStep === null || selectedDesignStep === void 0 ? void 0 : selectedDesignStep.id
    });
    if (!printingTechnique) return null;
    return find(selectedPlaceholder.printingTechniques, {
        id: printingTechnique === null || printingTechnique === void 0 ? void 0 : printingTechnique.printingTechniqueId
    }) || null;
});
export var placeholderSelectors = createStructuredSelector({
    placeholders: makeSelectAvailablePlaceholders,
    selectedPlaceholder: makeSelectSelectedPlaceholder,
    printingTechniques: selectPlaceholderPrintingTechniques,
    selectedPrintingTechnique: selectSelectedPrintingTechnique,
    selectedPlaceholderGroup: selectDelectedPlaceholderGroup,
    isUploading: isUploading
});
