import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import find from "lodash/find";
import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import { createSelector, createStructuredSelector } from "reselect";
import { selectAllColorZones } from "../ColorFillEngine/selectors";
import { controlPermissions as defaultControlPermissions, initialState as defualtStateAop } from "./constants";
export var aopSelector = function(state) {
    return state.aop || defualtStateAop;
};
export var selectAvailableAops = createSelector(aopSelector, function(state) {
    return state.availableAops.filter(function(aop) {
        return aop;
    }) || [];
});
var _isShowingMobilePanel;
export var selectIsShowingMobilePanel = createSelector(aopSelector, function(state) {
    return (_isShowingMobilePanel = state.isShowingMobilePanel) !== null && _isShowingMobilePanel !== void 0 ? _isShowingMobilePanel : false;
});
var _selectedMobileControl;
export var selectSelectedMobileControl = createSelector(aopSelector, function(state) {
    return (_selectedMobileControl = state.selectedMobileControl) !== null && _selectedMobileControl !== void 0 ? _selectedMobileControl : false;
});
export var selectStateCustomTemplateAops = createSelector(aopSelector, function(state) {
    return state.customTemplateAops || [];
});
export var selectUploadedAop = createSelector(aopSelector, function(state) {
    return uniqBy(state.uploadedAops, "id");
});
export var selectCustomTemplateAops = createSelector(selectStateCustomTemplateAops, selectUploadedAop, selectAvailableAops, selectAllColorZones, function(customTemplateAops, uploadedAops, printAops, colorZones) {
    try {
        var getAop = function(defaultPrintId) {
            return find(_to_consumable_array(uploadedAops).concat(_to_consumable_array(printAops)), {
                id: defaultPrintId
            });
        };
        var getZone = function(id) {
            return get(colorZones.find(function(colorZone) {
                var ref;
                return (colorZone === null || colorZone === void 0 ? void 0 : (ref = colorZone.zone) === null || ref === void 0 ? void 0 : ref.zoneId) === id;
            }), "zone", null);
        };
        var ref;
        return (ref = customTemplateAops.filter(function(customTemplateAop) {
            return customTemplateAop && (customTemplateAop === null || customTemplateAop === void 0 ? void 0 : customTemplateAop.id);
        }).map(function(customTemplateAop) {
            return {
                id: customTemplateAop === null || customTemplateAop === void 0 ? void 0 : customTemplateAop.id,
                aop: getAop(customTemplateAop.defaultPrintId),
                templateId: customTemplateAop.templateId,
                transform: customTemplateAop.transform,
                defaultPrintId: customTemplateAop.defaultPrintId,
                zone: getZone(customTemplateAop.zoneId),
                printingTechniqueId: customTemplateAop.printingTechniqueId
            };
        })) !== null && ref !== void 0 ? ref : [];
    } catch (error) {
        console.error("[AOP -> Selector] -> selectCustomTemplateAops", error);
        return [];
    }
});
var _controlPermissions;
export var selectControlPermissionAop = createSelector(aopSelector, function(state) {
    return (_controlPermissions = state.controlPermissions) !== null && _controlPermissions !== void 0 ? _controlPermissions : defaultControlPermissions;
});
export var selectLoadingAops = createSelector(selectUploadedAop, function(s) {
    return s.filter(function(uploadFile) {
        return (uploadFile === null || uploadFile === void 0 ? void 0 : uploadFile.isLoading) && (uploadFile === null || uploadFile === void 0 ? void 0 : uploadFile.file);
    });
});
var _selectedColorPrintingTechniqueId;
export var selectSelectedColorPrintingTechniqueId = createSelector(aopSelector, function(state) {
    return (_selectedColorPrintingTechniqueId = state.selectedColorPrintingTechniqueId) !== null && _selectedColorPrintingTechniqueId !== void 0 ? _selectedColorPrintingTechniqueId : null;
});
export var selectSelectedAop = createSelector(aopSelector, selectCustomTemplateAops, function(state, customTemplateAops) {
    if (!customTemplateAops) return null;
    return customTemplateAops === null || customTemplateAops === void 0 ? void 0 : customTemplateAops.find(function(customTemplateAop) {
        return (customTemplateAop === null || customTemplateAop === void 0 ? void 0 : customTemplateAop.id) === state.selectedAopId;
    });
});
export var selectIsAllAopApplied = createSelector(aopSelector, function(aop) {
    return aop.isAllAopApplied;
});
var _isApplyingAop;
export var selectIsApplyingAop = createSelector(aopSelector, function(state) {
    return (_isApplyingAop = state.isApplyingAop) !== null && _isApplyingAop !== void 0 ? _isApplyingAop : false;
});
export var aopEngineSelector = createStructuredSelector({
    selectedColorPrintingTechniqueId: selectSelectedColorPrintingTechniqueId,
    controlPermissions: selectControlPermissionAop,
    selectedAop: selectSelectedAop,
    availableAops: selectAvailableAops,
    uploadedAops: selectUploadedAop,
    customTemplateAops: selectCustomTemplateAops,
    isShowingMobilePanel: selectIsShowingMobilePanel,
    selectedMobileControl: selectSelectedMobileControl,
    isApplyingAop: selectIsApplyingAop,
    isAllAopApplied: selectIsAllAopApplied
});
