import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import find from "lodash/find";
import uniqBy from "lodash/uniqBy";
import { createSelector, createStructuredSelector } from "reselect";
import { selectSelectedZone3DElementId, selectSelectedZoneId } from "../SceneEngine/selectors";
import { selectSelectedTemplate } from "../TemplateEngine/selectors";
import { initialState } from "./constants";
import { isDefaultColor } from "./transforms/get-custom-template-colors";
export var colorFillSelector = function(state) {
    return state.colorFill || initialState;
};
var _customTemplateColors;
export var selectAll2dCustomZones = createSelector(colorFillSelector, function(state) {
    return (_customTemplateColors = state.customTemplateColors) !== null && _customTemplateColors !== void 0 ? _customTemplateColors : [];
});
var _colorZones;
export var selectAllColorZones = createSelector(colorFillSelector, function(state) {
    return (_colorZones = state.colorZones) !== null && _colorZones !== void 0 ? _colorZones : [];
});
export var selectTemplateColorError = createSelector(colorFillSelector, function(state) {
    return state.templateColorError;
});
export var selectAllColorZone3DElements = createSelector(colorFillSelector, function(state) {
    return state.colorZones3DElements || [];
});
export var selectCustomTemplateColorZone3DElements = createSelector(colorFillSelector, function(state) {
    return state.customTemplateColor3DElements || [];
});
export var selectSelectedColorId = createSelector(colorFillSelector, function(state) {
    return state.selectedColorId;
});
export var selectAllFabrics = createSelector(colorFillSelector, function() {
    return [];
});
// select 3d color zones element by selected zone id
export var selectAllCustomTemplateColors3DElementBasedSelectedTemplate = createSelector(selectCustomTemplateColorZone3DElements, selectSelectedTemplate, function(customTemplateColor3DElements, selectedTemplate) {
    return customTemplateColor3DElements.filter(function(customTemplateColor) {
        return customTemplateColor.templateId === (selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.id);
    });
});
// select custom template color based on selected template
export var selectAllCustomTemplateColorsBasedSelectedTemplate = createSelector(colorFillSelector, selectSelectedTemplate, function(state, selectedTemplate) {
    // expose only custom template color based selected template
    return state === null || state === void 0 ? void 0 : state.customTemplateColors.filter(function(customTemplateColor) {
        return customTemplateColor.templateId === (selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.id);
    });
});
// select color zone 3d element based selected zone + template
export var selectSelectedColorZone3DElement = createSelector(selectAllColorZone3DElements, selectSelectedZone3DElementId, function(allColorZone3DElements, selectedZone3DElementId) {
    return allColorZone3DElements.find(function(allColorZone3DElement) {
        return allColorZone3DElement.id === selectedZone3DElementId;
    });
});
// select 3d custom color zones element by selected zone id
export var selectSelectedCustomTemplateColorZone3DElement = createSelector(selectAllCustomTemplateColors3DElementBasedSelectedTemplate, selectSelectedZone3DElementId, function(customTemplateColor3DElements, selectedZone3DElementId) {
    return customTemplateColor3DElements.find(function(customTemplateColor) {
        return customTemplateColor.zoneId === selectedZone3DElementId;
    });
});
// select only all colorzone belong to selected template
export var selectSelectedTemplateColorZones = createSelector(selectAllColorZones, selectSelectedTemplate, function(colorZones, selectedTemplate) {
    return colorZones.filter(function(zone) {
        return zone.templateId === selectedTemplate.id;
    }) || [];
});
// select only color zone belong to selected template and selected zone
export var selectSelectedColorZone = createSelector(selectSelectedTemplateColorZones, selectSelectedZoneId, function(colorZones, selectedZoneId) {
    if (!colorZones) return;
    var _selectedZoneId = selectedZoneId;
    // select first element in an array if selected zone is not set
    // ensure selcted zone is always has value
    if (!_selectedZoneId) {
        var ref, ref1;
        _selectedZoneId = (ref = colorZones[0]) === null || ref === void 0 ? void 0 : (ref1 = ref.zone) === null || ref1 === void 0 ? void 0 : ref1.zoneId;
    }
    return find(colorZones, function(colorZone) {
        var ref;
        return (colorZone === null || colorZone === void 0 ? void 0 : (ref = colorZone.zone) === null || ref === void 0 ? void 0 : ref.zoneId) === _selectedZoneId;
    });
});
// select all colors available in selected color zone
export var selectAvailableColorInSelectedColorZones = createSelector(selectSelectedColorZone, selectSelectedColorZone3DElement, function(selectedColorZone, selectedColorZone3DElement) {
    // if 3d element selected return list of color zone for 3d element
    if (selectedColorZone3DElement) return (selectedColorZone3DElement === null || selectedColorZone3DElement === void 0 ? void 0 : selectedColorZone3DElement.availableColors) || [];
    // otherwise use normal color zones from templates
    return (selectedColorZone === null || selectedColorZone === void 0 ? void 0 : selectedColorZone.colors) || [];
});
// get only list of zones from select template color zones
export var selectAllColorZone2DElements = createSelector(selectSelectedTemplateColorZones, function(colorZones) {
    return uniqBy(colorZones.flatMap(function(colorZone) {
        return colorZone.zone;
    }), function(zone) {
        return zone.zoneId;
    }) || [];
});
// re-create custom template colors with full data
export var selectAllCustomTemplateColors = createSelector(selectAllColorZones, selectAllCustomTemplateColorsBasedSelectedTemplate, function(allColorZones, customTemplateColorsBasedSelectedTemplate) {
    var getColorZone = function(zoneId, colorId) {
        var colorZone = allColorZones.find(function(colorZone) {
            return (colorZone === null || colorZone === void 0 ? void 0 : colorZone.zone.zoneId) === zoneId;
        });
        // in case of reset AOP, reset default color id
        var isDefaultColor = colorZone.defaultColor.id === colorId && colorZone.zone.zoneId === zoneId;
        if (isDefaultColor) {
            return {
                zone: colorZone.zone,
                color: colorZone.defaultColor
            };
        }
        // selected color from merchant setting
        var color = colorZone.colors.find(function(color) {
            return color.id === colorId;
        });
        return {
            zone: colorZone.zone,
            color: color
        };
    };
    // eslint-disable-next-line array-callback-return
    return customTemplateColorsBasedSelectedTemplate.map(function(customColor) {
        var colorZone = getColorZone(customColor.zoneId, customColor.colorId);
        if (colorZone) {
            return _object_spread({
                id: customColor.id,
                templateId: customColor.templateId
            }, colorZone);
        }
    });
});
// re-create custom template colors with full data
export var selectAllCustomTemplateColorsZone3DElements = createSelector(selectAllColorZone3DElements, selectAllCustomTemplateColors3DElementBasedSelectedTemplate, function(allColorZone3DElements, customTemplateColors3DElementBasedSelectedTemplate) {
    var getColorZone3DElement = function(zoneId, colorId) {
        var ref;
        var colorZone3DElement = allColorZone3DElements.find(function(colorZone) {
            return (colorZone === null || colorZone === void 0 ? void 0 : colorZone.id) === zoneId;
        });
        if (!colorZone3DElement) return;
        var color = colorZone3DElement === null || colorZone3DElement === void 0 ? void 0 : (ref = colorZone3DElement.availableColors) === null || ref === void 0 ? void 0 : ref.find(function(color) {
            return color.id === colorId;
        });
        return {
            zone: {
                name: colorZone3DElement.name,
                svgIds: [
                    colorZone3DElement.fullCode
                ],
                zoneId: colorZone3DElement.id
            },
            color: color
        };
    };
    return customTemplateColors3DElementBasedSelectedTemplate.map(// eslint-disable-next-line array-callback-return
    function(customColor) {
        var colorZone = getColorZone3DElement(customColor.zoneId, customColor.colorId);
        if (colorZone) {
            return _object_spread({
                id: customColor.id,
                templateId: customColor.templateId
            }, colorZone);
        }
    });
});
export var selectSelectedCustomTemplateColor = createSelector(selectAllCustomTemplateColors, selectSelectedColorZone, function(customTemplateColors, selectedColorZone) {
    if (!customTemplateColors) return;
    return customTemplateColors.find(function(customTemplateColor) {
        return (customTemplateColor === null || customTemplateColor === void 0 ? void 0 : customTemplateColor.zone.zoneId) === (selectedColorZone === null || selectedColorZone === void 0 ? void 0 : selectedColorZone.zone.zoneId);
    });
});
export var selectSelectedCustomTemplateColor3DElement = createSelector(selectAllCustomTemplateColorsZone3DElements, selectSelectedColorZone3DElement, function(customTemplateColors3DElement, selectedColorZone3DElement) {
    if (!customTemplateColors3DElement) return;
    return customTemplateColors3DElement.find(function(customTemplateColor) {
        return (customTemplateColor === null || customTemplateColor === void 0 ? void 0 : customTemplateColor.zone.zoneId) === (selectedColorZone3DElement === null || selectedColorZone3DElement === void 0 ? void 0 : selectedColorZone3DElement.id);
    });
});
export var selectAppliedAllColor = createSelector(colorFillSelector, function(state) {
    return state.isAppliedAllColor;
});
// check selected custom template has default color
export var selectSelectedZoneHasDefaultColor = createSelector(selectAllColorZones, selectSelectedColorZone, function(allColorZones, selectedColorZone) {
    if (allColorZones.length === 0) return false;
    var templateColors = allColorZones.find(function(customTemplateColor) {
        return (customTemplateColor === null || customTemplateColor === void 0 ? void 0 : customTemplateColor.zone.zoneId) === (selectedColorZone === null || selectedColorZone === void 0 ? void 0 : selectedColorZone.zone.zoneId);
    });
    if (!templateColors) return false;
    return isDefaultColor(templateColors === null || templateColors === void 0 ? void 0 : templateColors.defaultColor);
});
export var colorFillEngineSelector = createStructuredSelector({
    customTemplateColors: selectAllCustomTemplateColors,
    customTemplateColor3DElements: selectAllCustomTemplateColorsZone3DElements,
    selectedCustomTemplateColor3DElement: selectSelectedCustomTemplateColor3DElement,
    selectedCustomTemplateColor: selectSelectedCustomTemplateColor,
    selectedColorZone: selectSelectedColorZone,
    selectedColorZone3DElement: selectSelectedColorZone3DElement,
    colors: selectAvailableColorInSelectedColorZones,
    templateColorZones: selectSelectedTemplateColorZones,
    templateColorError: selectTemplateColorError,
    // flag
    selectedZoneHasDefaultColor: selectSelectedZoneHasDefaultColor,
    // zones
    zone2DElements: selectAllColorZone2DElements,
    zone3DElements: selectAllColorZone3DElements,
    fabrics: selectAllFabrics,
    isAppliedAllColor: selectAppliedAllColor
});
