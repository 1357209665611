import { v4 } from "uuid";
import { CreateZoneCustomizationDocument, ZoneCustomizationType } from "@brikl/codegen";
import { isDefaultColor } from "../../ColorFillEngine/transforms/get-custom-template-colors";
export var createZonesMutation = function(apollo) {
    return function(param) {
        var productDesignId = param.productDesignId, _revisionNo = param.revisionNo, revisionNo = _revisionNo === void 0 ? 1 : _revisionNo, colorZones = param.colorZones, selectedTemplateId = param.selectedTemplateId;
        var listCallToCreateZone = colorZones.filter(function(element) {
            var ref;
            return (ref = element.zone) === null || ref === void 0 ? void 0 : ref.zoneId;
        }).map(function(element) {
            var ref, ref1;
            var defaultColor = element.defaultColor;
            var id = v4();
            var isDefault = isDefaultColor(defaultColor);
            var variables = {
                productDesignId: productDesignId,
                revisionNo: revisionNo,
                id: id,
                zoneId: (ref = element.zone) === null || ref === void 0 ? void 0 : ref.zoneId,
                input: {
                    id: id,
                    templateId: selectedTemplateId,
                    zoneId: (ref1 = element.zone) === null || ref1 === void 0 ? void 0 : ref1.zoneId,
                    type: ZoneCustomizationType.SingleColorFill,
                    colorId: isDefault ? defaultColor.id : null,
                    colorHex: isDefault ? defaultColor.hex : null
                }
            };
            return apollo.mutate({
                mutation: CreateZoneCustomizationDocument,
                variables: variables
            });
        });
        return Promise.all(listCallToCreateZone);
    };
};
