/**
 * Inpired by https://github.com/react-spring/react-three-fiber/blob/master/src/reconciler.tsx
 * @see https://stackoverflow.com/questions/10735922/how-to-stop-a-requestanimationframe-recursion-loop/10748750
 */ import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import * as Sentry from "@sentry/browser";
import gifshot from "gifshot";
import * as THREE from "three";
import GLTFExporter from "three-gltf-exporter";
import { downloadFileToUser } from "~/libs/studio-utils/src";
import { getProductInfo } from "../_fabric/export";
import { convertImageUrlToImageElement } from "../_fabric/util";
import { createMultipleCapture } from "./utils";
export var MultiViewExports;
(function(MultiViewExports) {
    MultiViewExports["FRONT"] = "FRONT";
    MultiViewExports["BACK"] = "BACK";
    MultiViewExports["LEFT"] = "LEFT";
    MultiViewExports["RIGHT"] = "RIGHT";
})(MultiViewExports || (MultiViewExports = {}));
var cameraMultiplePositions = {
    FRONT: 0,
    BACK: 180,
    LEFT: 90,
    RIGHT: 270
};
/**
 * Keep track of requestAnimationFrame id
 */ var requestId;
var captureAll = false;
var captureCurrent = false;
var captureInDirection = false;
var captureDirection = 0;
var captureGif = false;
var intervalGif = 0.1;
var captureMultiple = false;
var captureMultipleOptions = [];
var productData;
var shouldDownload = true;
var productTitle;
var productTitlePosition;
var productLogoData;
var productLogoPosition;
var quality = 1 // Quality of 3D export (Resolution)
;
var isCaptureCurrentView = false;
var isExportGifToProductMedia = false;
var gifPositions = [
    0,
    22.5,
    45,
    67.5,
    90,
    112.5,
    135,
    157.5,
    180,
    202.5,
    225,
    247.5,
    270,
    292.5,
    315,
    337.5, 
];
/**
 * export 3d scene to png with current view (camera position)
 * @param  {IAnimationLoopOption} option for running animation when loaded 3d
 */ function captureCurrentView(param) {
    var renderer = param.renderer, controls = param.controls, scene = param.scene, camera = param.camera, fabricCanvas = param.fabricCanvas;
    if (isCaptureCurrentView) {
        isCaptureCurrentView = false;
        captureInDirection = false;
        captureCurrent = false;
        renderer.render(scene, camera);
        export3DScene({
            renderer: renderer,
            returnBase64: false,
            fabricCanvas: fabricCanvas
        });
        runAnimationLoop({
            controls: controls,
            renderer: renderer,
            scene: scene,
            camera: camera,
            fabricCanvas: fabricCanvas
        });
        return;
    }
}
function captureGifView(_) {
    return _captureGifView.apply(this, arguments);
}
function _captureGifView() {
    _captureGifView = /**
 * export 3d scene to GIF format
 * @param  {IAnimationLoopOption} option for running animation when loaded 3d
 */ _async_to_generator(function(param) {
        var scene, controls, renderer, camera, isExportToProductMedia, fabricCanvas, currentCamera, imageTasks, gifBase64, exportGifEvent, _tmp, _tmp1, e, exportGifEvent1, _tmp2;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    scene = param.scene, controls = param.controls, renderer = param.renderer, camera = param.camera, isExportToProductMedia = param.isExportToProductMedia, fabricCanvas = param.fabricCanvas;
                    // This call again after render excecute
                    scene.background = new THREE.Color(0xffffff);
                    currentCamera = camera.position.clone();
                    _state.label = 1;
                case 1:
                    _state.trys.push([
                        1,
                        3,
                        4,
                        5
                    ]);
                    imageTasks = gifPositions.map(function(angle) {
                        changeView(controls, camera, angle);
                        renderer.render(scene, camera);
                        return export3DScene({
                            renderer: renderer,
                            returnBase64: true,
                            degree: angle,
                            fabricCanvas: fabricCanvas
                        });
                    });
                    return [
                        4,
                        createGif(imageTasks, renderer.getSize(new THREE.Vector2()))
                    ];
                case 2:
                    gifBase64 = _state.sent();
                    _tmp = {};
                    _tmp1 = {};
                    // export to product media need to emit base64
                    if (isExportToProductMedia) {
                        exportGifEvent = new CustomEvent("exportGifEvent", (_tmp.bubbles = true, _tmp.detail = {
                            type: "complete",
                            content: gifBase64
                        }, _tmp));
                        window.dispatchEvent(exportGifEvent);
                    } else {
                        downloadFileToUser((_tmp1.filename = "BRIKL-3D-MODEL.gif", _tmp1.embedData = gifBase64, _tmp1));
                    }
                    return [
                        3,
                        5
                    ];
                case 3:
                    e = _state.sent();
                    Sentry.captureException(e);
                    _tmp2 = {};
                    exportGifEvent1 = new CustomEvent("exportGifEvent", (_tmp2.bubbles = true, _tmp2.detail = {
                        type: "error",
                        content: null
                    }, _tmp2));
                    window.dispatchEvent(exportGifEvent1);
                    return [
                        3,
                        5
                    ];
                case 4:
                    scene.background = null;
                    // RETURN CURRENT CAMERA POSITION
                    camera.position.set(currentCamera.x, currentCamera.y, currentCamera.z);
                    controls.update();
                    return [
                        7
                    ];
                case 5:
                    return [
                        2
                    ];
            }
        });
    });
    return _captureGifView.apply(this, arguments);
}
function resetCamera(camera) {
    camera.fov = 50;
    camera.position.z = 1.5;
    camera.updateProjectionMatrix();
}
/**
 * export 3d scene in 360 angle views
 * @param  {IAnimationLoopOption} option for running animation when loaded 3d
 */ function captureAllView(param) {
    var scene = param.scene, controls = param.controls, renderer = param.renderer, camera = param.camera, fabricCanvas = param.fabricCanvas;
    // This call again after render excecute
    var currentCamera = camera.position.clone();
    // scene.background = new THREE.Color(0xffffff)
    changeView(controls, camera, 0);
    renderer.render(scene, camera) // excecute
    ;
    scene.userData.capture3D[0] = export3DScene({
        renderer: renderer,
        returnBase64: true,
        degree: 0,
        fabricCanvas: fabricCanvas
    });
    changeView(controls, camera, 90);
    renderer.render(scene, camera) // excecute
    ;
    scene.userData.capture3D[90] = export3DScene({
        renderer: renderer,
        returnBase64: true,
        degree: 90,
        fabricCanvas: fabricCanvas
    });
    changeView(controls, camera, 180);
    renderer.render(scene, camera) // excecute
    ;
    scene.userData.capture3D[180] = export3DScene({
        renderer: renderer,
        returnBase64: true,
        degree: 180,
        fabricCanvas: fabricCanvas
    });
    changeView(controls, camera, 270);
    renderer.render(scene, camera) // excecute
    ;
    scene.userData.capture3D[270] = export3DScene({
        renderer: renderer,
        returnBase64: true,
        degree: 270,
        fabricCanvas: fabricCanvas
    });
    captureAll = false;
    // scene.background = null
    // RETURN CURRENT CAMERA POSITION
    camera.position.set(currentCamera.x, currentCamera.y, currentCamera.z);
    controls.update();
}
/**
 * export 3d scene in specified angle view
 * @param  {IAnimationLoopOption} option for running animation when loaded 3d
 */ function captureInDirectionView(param) {
    var scene = param.scene, controls = param.controls, renderer = param.renderer, camera = param.camera, fabricCanvas = param.fabricCanvas;
    var currentCamera = camera.position.clone();
    captureInDirection = false;
    changeViewManual(controls, camera, captureDirection);
    renderer.render(scene, camera) // excecute
    ;
    export3DScene({
        renderer: renderer,
        returnBase64: false,
        degree: captureDirection,
        fabricCanvas: fabricCanvas
    });
    camera.position.set(currentCamera.x, currentCamera.y, currentCamera.z);
    controls.update();
}
function captureMultipleViews(_) {
    return _captureMultipleViews.apply(this, arguments);
}
function _captureMultipleViews() {
    _captureMultipleViews = /**
 * export 3d scene to multi-view (FRONT, BACK)
 * @param  {IAnimationLoopOption} option for running animation when loaded 3d
 */ _async_to_generator(function(param) {
        var scene, controls, renderer, camera, fabricCanvas, currentCamera, captureSets, _tmp;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    scene = param.scene, controls = param.controls, renderer = param.renderer, camera = param.camera, fabricCanvas = param.fabricCanvas;
                    currentCamera = camera.position.clone();
                    if (captureMultipleOptions.length < 1 || captureMultipleOptions.length === 0) throw new Error("captureMultipleOptions is required");
                    captureSets = captureMultipleOptions.map(function(capture) {
                        var deg = cameraMultiplePositions[capture];
                        changeView(controls, camera, deg);
                        renderer.render(scene, camera) // excecute
                        ;
                        return export3DScene({
                            renderer: renderer,
                            returnBase64: true,
                            imageQuality: null,
                            degree: deg,
                            fabricCanvas: fabricCanvas
                        });
                    });
                    return [
                        4,
                        Promise.all(captureSets)
                    ];
                case 1:
                    _state.sent();
                    _tmp = {};
                    createMultipleCapture((_tmp.captureSets = captureSets, _tmp.productData = productData, _tmp.shouldDownload = shouldDownload, _tmp.productTitle = productTitle, _tmp.productTitlePosition = productTitlePosition, _tmp.productLogoPosition = productLogoPosition, _tmp.productLogoData = productLogoData, _tmp.fabricCanvas = fabricCanvas, _tmp));
                    captureMultiple = false;
                    camera.position.set(currentCamera.x, currentCamera.y, currentCamera.z);
                    controls.update();
                    return [
                        2
                    ];
            }
        });
    });
    return _captureMultipleViews.apply(this, arguments);
}
/**
 * run animation to render 3d model
 * @param  {IAnimationLoopOption} option for running animation when loaded 3d
 */ export function startAnimationLoop(param) {
    var controls = param.controls, renderer = param.renderer, scene = param.scene, camera = param.camera, fabricCanvas = param.fabricCanvas;
    requestId = undefined;
    controls.update();
    if (quality !== 1) renderer.setPixelRatio(window.devicePixelRatio * quality) // Update resolution quality only when need, for prevend performance drop because fps loop
    ;
    captureCurrentView({
        controls: controls,
        renderer: renderer,
        scene: scene,
        camera: camera,
        fabricCanvas: fabricCanvas
    });
    if (captureGif) {
        resetCamera(camera);
        captureGifView({
            controls: controls,
            renderer: renderer,
            scene: scene,
            camera: camera,
            isExportToProductMedia: isExportGifToProductMedia,
            fabricCanvas: fabricCanvas
        });
        captureGif = false;
    } else if (captureAll) {
        resetCamera(camera);
        captureAllView({
            controls: controls,
            renderer: renderer,
            scene: scene,
            camera: camera,
            fabricCanvas: fabricCanvas
        });
        captureAll = false;
    } else if (captureInDirection) {
        resetCamera(camera);
        captureInDirectionView({
            controls: controls,
            renderer: renderer,
            scene: scene,
            camera: camera,
            fabricCanvas: fabricCanvas
        });
        captureInDirection = false;
    } else if (captureMultiple) {
        resetCamera(camera);
        captureMultipleViews({
            controls: controls,
            renderer: renderer,
            scene: scene,
            camera: camera,
            fabricCanvas: fabricCanvas
        });
        captureMultiple = false;
    } else {
        renderer.render(scene, camera) // excecute
        ;
    }
    runAnimationLoop({
        controls: controls,
        renderer: renderer,
        scene: scene,
        camera: camera,
        fabricCanvas: fabricCanvas
    });
}
/**
 * start animation to render 3d scene with model in every tick time
 * @param  {IAnimationLoopOption} option for running animation when loaded 3d
 */ function runAnimationLoop(param) {
    var controls = param.controls, renderer = param.renderer, scene = param.scene, camera = param.camera, fabricCanvas = param.fabricCanvas;
    if (!requestId) {
        try {
            requestId = requestAnimationFrame(function() {
                return startAnimationLoop({
                    controls: controls,
                    renderer: renderer,
                    scene: scene,
                    camera: camera,
                    fabricCanvas: fabricCanvas
                });
            });
        } catch (error) {
            // TODO this seems to break
            console.error(error);
        }
    }
}
/**
 * stop animation loop about rendering 3d model
 */ export function stopAnimationLoop() {
    if (requestId) {
        cancelAnimationFrame(requestId);
        requestId = undefined;
    }
}
/**
 * toggle flag to capture 3d scene to
 * @param  {boolean} _capture
 */ export function toggleCapture3DAll(_capture) {
    captureAll = _capture;
}
/**
 * @param  {IToggleGifCaptureOptions} option for capture gif file from 3d scene
 */ export function toggleGifCapture(param) {
    var _capture = param._capture, interval = param.interval, isExportToProductMedia = param.isExportToProductMedia;
    captureGif = _capture;
    intervalGif = interval;
    isExportGifToProductMedia = isExportToProductMedia;
}
/**
 * toggle flag to export 3d scene to other format such as GLTF format
 * or PNG format with sepecfied camara angle view
 * @param  {IToggleCapture3DOptions} }
 */ export function toggleCapture3D(param) {
    var _capture = param._capture, _direction = param._direction, _productData = param._productData, _quality = param._quality, __isCaptureCurrentView = param._isCaptureCurrentView, _isCaptureCurrentView = __isCaptureCurrentView === void 0 ? false : __isCaptureCurrentView;
    if (_productData) productData = _productData;
    if (_quality) {
        quality = _quality;
    }
    if ((_direction || _direction === 0) && _direction !== -1) {
        captureInDirection = _capture;
        captureDirection = _direction;
    } else {
        captureCurrent = _capture;
    }
    isCaptureCurrentView = _isCaptureCurrentView;
}
/**
 * toggle flag to export 3d scene to multi-view image format
 * @param  {boolean} _capture
 * @param  {MultiViewExports[]} options
 * @param  {IProductData} _productData?
 * @param  {boolean} isDownloadable
 * @param  {string} _productTitle?
 * @param  {EmbedCapturePosition} _productTitlePostion?
 * @param  {string} _productLogoData?
 * @param  {EmbedCapturePosition} _productLogoPosition?
 */ export function toggleMultipleCapture(_capture) {
    var options = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : [], _productData = arguments.length > 2 ? arguments[2] : void 0, isDownloadable = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : true, _productTitle = arguments.length > 4 ? arguments[4] : void 0, _productTitlePostion = arguments.length > 5 ? arguments[5] : void 0, _productLogoData = arguments.length > 6 ? arguments[6] : void 0, _productLogoPosition = arguments.length > 7 ? arguments[7] : void 0;
    if (_productData) {
        productData = _productData;
    }
    captureMultiple = _capture;
    captureMultipleOptions = options;
    shouldDownload = isDownloadable;
    productTitle = _productTitle;
    productTitlePosition = _productTitlePostion;
    productLogoData = _productLogoData;
    productLogoPosition = _productLogoPosition;
}
/**
 * export 3d scene to png format with quality
 * @param  {IExport3DSceneParam}
 */ export function export3DScene(param) {
    var renderer = param.renderer, returnBase64 = param.returnBase64, _imageQuality = param.imageQuality, imageQuality = _imageQuality === void 0 ? 0.92 : _imageQuality, degree = param.degree, fabricCanvas = param.fabricCanvas;
    try {
        // Return increase pixel to normal for prevend assume performance
        renderer.setPixelRatio(window.devicePixelRatio * quality);
        var strMime = "image/png";
        var imgData = renderer.domElement.toDataURL(strMime, imageQuality);
        quality = 1;
        renderer.setPixelRatio(window.devicePixelRatio * quality);
        if (returnBase64) {
            return imgData;
        }
        if (productData) {
            mergeCaptureWithProductInfo(imgData, productData, "BRIKL-3D-Model-Export".concat(degree ? "-" + degree + "-deg" : "", ".png"), "3D", fabricCanvas);
            return;
        }
        var filename = "BRIKL-3D-Model-Export".concat(degree ? "-" + degree + "-deg" : "", ".png");
        downloadFileToUser({
            embedData: imgData,
            filename: filename
        });
        return;
    } catch (e) {
        console.error("reconciler.export3DScene.error", e);
        return;
    }
}
export var EmbedCapturePosition;
(function(EmbedCapturePosition) {
    EmbedCapturePosition[EmbedCapturePosition["TOP_LEFT"] = 0] = "TOP_LEFT";
    EmbedCapturePosition[EmbedCapturePosition["TOP_CENTER"] = 1] = "TOP_CENTER";
    EmbedCapturePosition[EmbedCapturePosition["TOP_RIGHT"] = 2] = "TOP_RIGHT";
    EmbedCapturePosition[EmbedCapturePosition["BOTTOM_LEFT"] = 3] = "BOTTOM_LEFT";
    EmbedCapturePosition[EmbedCapturePosition["BOTTOM_CENTER"] = 4] = "BOTTOM_CENTER";
    EmbedCapturePosition[EmbedCapturePosition["BOTTOM_RIGHT"] = 5] = "BOTTOM_RIGHT";
})(EmbedCapturePosition || (EmbedCapturePosition = {}));
/**
 * calculate the position of embed data into 3d scene in image format
 * @param  {number} width
 * @param  {number} height
 * @param  {number} edgeGap
 * @param  {number} logoWidth?
 * @param  {number} logoHeight?
 * @returns Map
 */ var calculatePositionForEmbedding = function(width, height, edgeGap, logoWidth) {
    return new Map([
        [
            EmbedCapturePosition.TOP_LEFT,
            {
                x: edgeGap * width * 0.5,
                y: edgeGap * height * 0.5,
                textAlign: "start",
                textBaseline: "top"
            }, 
        ],
        [
            EmbedCapturePosition.TOP_CENTER,
            {
                x: logoWidth ? width * 0.5 - logoWidth * 0.25 : width * 0.5,
                y: edgeGap * height * 0.5,
                textAlign: "center",
                textBaseline: "top"
            }, 
        ],
        [
            EmbedCapturePosition.TOP_RIGHT,
            {
                x: logoWidth ? width - (edgeGap * width * 0.5 + logoWidth * 0.5) : width - edgeGap * width * 0.5,
                y: edgeGap * height * 0.5,
                textAlign: "right",
                textBaseline: "top"
            }, 
        ],
        [
            EmbedCapturePosition.BOTTOM_LEFT,
            {
                x: edgeGap * width * 0.5,
                y: height - edgeGap * height * 1.65,
                textAlign: "left",
                textBaseline: "bottom"
            }, 
        ],
        [
            EmbedCapturePosition.BOTTOM_CENTER,
            {
                x: logoWidth ? width * 0.5 - logoWidth * 0.25 : width * 0.5,
                y: height - edgeGap * height * 1.65,
                textAlign: "center",
                textBaseline: "bottom"
            }, 
        ],
        [
            EmbedCapturePosition.BOTTOM_RIGHT,
            {
                x: logoWidth ? width - (edgeGap * width * 0.5 + logoWidth * 0.5) : width - edgeGap * width * 0.5,
                y: height - edgeGap * height * 1.65,
                textAlign: "end",
                textBaseline: "bottom"
            }, 
        ], 
    ]);
};
/**
 * emebed text(product title) and logo to model image
 * @param  {ICapture} props
 */ export function embedTextAndLogoToCapture(props) {
    return _embedTextAndLogoToCapture.apply(this, arguments);
}
function _embedTextAndLogoToCapture() {
    _embedTextAndLogoToCapture = _async_to_generator(function(props) {
        var imgData, logoData, text, logoPosition, textPosition, templateImageElm, _tmp, logoElm, _tmp1, canvas, ctx, imageWidth, imageHeight, logoCoordinates, textCoordinates, textSize, fontName;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    imgData = props.dataURL, logoData = props.productLogoData, text = props.productTitle, logoPosition = props.productLogoPosition, textPosition = props.productTitlePosition;
                    if (!imgData) return [
                        3,
                        2
                    ];
                    return [
                        4,
                        convertImageUrlToImageElement(imgData)
                    ];
                case 1:
                    _tmp = _state.sent();
                    return [
                        3,
                        3
                    ];
                case 2:
                    _tmp = null;
                    _state.label = 3;
                case 3:
                    templateImageElm = _tmp;
                    if (!logoData) return [
                        3,
                        5
                    ];
                    return [
                        4,
                        convertImageUrlToImageElement(logoData)
                    ];
                case 4:
                    _tmp1 = _state.sent();
                    return [
                        3,
                        6
                    ];
                case 5:
                    _tmp1 = null;
                    _state.label = 6;
                case 6:
                    logoElm = _tmp1;
                    canvas = document.createElement("canvas");
                    ctx = canvas.getContext("2d");
                    imageWidth = templateImageElm.width;
                    imageHeight = templateImageElm.height;
                    canvas.width = imageWidth;
                    canvas.height = imageHeight;
                    ctx.drawImage(templateImageElm, 0, 0);
                    if (logoData) {
                        logoCoordinates = calculatePositionForEmbedding(imageWidth, imageHeight, 0.1, logoData ? logoElm.width : null).get(logoPosition);
                        if (imageWidth > logoElm.width) {
                            ctx.drawImage(logoElm, logoCoordinates.x, logoCoordinates.y, logoElm.width * 0.5, logoElm.height * 0.5);
                        } else {
                            ctx.drawImage(logoElm, logoCoordinates.x, logoCoordinates.y, logoElm.width * 0.1, logoElm.height * 0.1);
                        }
                    }
                    if (text) {
                        textCoordinates = calculatePositionForEmbedding(imageWidth, logoData ? imageHeight + logoElm.height : imageHeight, 0.1).get(textPosition);
                        textSize = 60;
                        fontName = "Poppins";
                        ctx.fillStyle = "red";
                        ctx.font = "".concat(textSize, "px ").concat(fontName, " ");
                        ctx.textAlign = textCoordinates.textAlign;
                        ctx.textBaseline = textCoordinates.textBaseline;
                        if (logoPosition === textPosition && logoPosition < 3) {
                            ctx.fillText(text, textCoordinates.x, logoElm ? textCoordinates.y + logoElm.height * 0.6 : textCoordinates.y);
                        } else if (logoPosition === textPosition && logoPosition > 2) {
                            ctx.fillText(text, textCoordinates.x, logoElm ? textCoordinates.y - logoElm.height : textCoordinates.y);
                        } else {
                            ctx.fillText(text, textCoordinates.x, textCoordinates.y);
                        }
                    }
                    return [
                        2,
                        canvas.toDataURL()
                    ];
            }
        });
    });
    return _embedTextAndLogoToCapture.apply(this, arguments);
}
/**
 * export 3d scene to gltf file
 * @returns Promise
 */ export var exportGltfFile = function() {
    var _ref = _async_to_generator(function(scene) {
        var getGltfData, gltfString, gltfBlob, _tmp, _tmp1;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    getGltfData = function() {
                        return new Promise(function(resolve) {
                            var exporter = new GLTFExporter();
                            // Parse the input and generate the glTF output
                            exporter.parse(scene, function(gltf) {
                                resolve(JSON.stringify(gltf, null, 2));
                            }, {});
                        });
                    };
                    return [
                        4,
                        getGltfData()
                    ];
                case 1:
                    gltfString = _state.sent();
                    _tmp = {};
                    _tmp1 = {};
                    gltfBlob = new File([
                        new Blob([
                            gltfString
                        ], (_tmp.type = "text/plain", _tmp))
                    ], "gltf-file", (_tmp1.lastModified = new Date().getTime(), _tmp1));
                    return [
                        2,
                        gltfBlob
                    ];
            }
        });
    });
    return function exportGltfFile(scene) {
        return _ref.apply(this, arguments);
    };
}();
/**
 * embed product data into canvas element
 * @param  {string} imgData
 * @param  {IProductData} _productData
 * @param  {string} fileName
 * @param  {string} infoSize
 */ export function mergeCaptureWithProductInfo(imgData, _productData, fileName, infoSize, fabricCanvas) {
    return _mergeCaptureWithProductInfo.apply(this, arguments);
}
function _mergeCaptureWithProductInfo() {
    _mergeCaptureWithProductInfo = _async_to_generator(function(imgData, _productData, fileName, infoSize, fabricCanvas) {
        var templateImageElm, productInfoB64, _tmp, productInfoImageElm, canvas, ctx, exportImage, _tmp1;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        convertImageUrlToImageElement(imgData)
                    ];
                case 1:
                    templateImageElm = _state.sent();
                    _tmp = {};
                    return [
                        4,
                        getProductInfo((_tmp.productData = _productData, _tmp.option = infoSize, _tmp.fabricCanvas = fabricCanvas, _tmp))
                    ];
                case 2:
                    productInfoB64 = _state.sent();
                    return [
                        4,
                        convertImageUrlToImageElement(productInfoB64)
                    ];
                case 3:
                    productInfoImageElm = _state.sent();
                    canvas = document.createElement("canvas");
                    ctx = canvas.getContext("2d");
                    canvas.width = templateImageElm.width;
                    canvas.height = templateImageElm.height;
                    ctx.drawImage(templateImageElm, 0, 0);
                    ctx.drawImage(productInfoImageElm, templateImageElm.width - productInfoImageElm.width, 50);
                    exportImage = canvas.toDataURL();
                    _tmp1 = {};
                    downloadFileToUser((_tmp1.filename = fileName, _tmp1.embedData = exportImage, _tmp1));
                    return [
                        2
                    ];
            }
        });
    });
    return _mergeCaptureWithProductInfo.apply(this, arguments);
}
/**
 * change camera view for exporting 3d scene
 * @param  {$TSFixMe} controls
 * @param  {THREE.PerspectiveCamera} camera
 * @param  {number} direction
 */ export function changeView(controls, camera, direction) {
    try {
        if (direction || direction === 0) {
            var deg = THREE.MathUtils.degToRad(direction + 90);
            camera.position.x = 1.5 * Math.cos(deg);
            camera.position.z = 1.5 * Math.sin(deg);
            camera.position.y = 0;
            controls.update();
        } else {
            var deg1 = THREE.MathUtils.degToRad(90);
            camera.position.x = 1.5 * Math.cos(deg1);
            camera.position.z = 1.5 * Math.sin(deg1);
            camera.position.y = 0;
            controls.update();
        }
    } catch (e) {
        // statements
        console.error("reconciler.changeView.error", e);
    }
}
/**
 * change camera view in specified angles
 * @param  {$TSFixMe} controls
 * @param  {THREE.PerspectiveCamera} camera
 * @param  {number} direction
 */ export function changeViewManual(controls, camera, direction) {
    try {
        var cameraPositions = {
            45: {
                x: 1,
                y: 0,
                z: 1
            },
            90: {
                x: 1.5,
                y: 0,
                z: 0
            },
            135: {
                x: 1,
                y: 0,
                z: -1
            },
            180: {
                x: 0,
                y: 0,
                z: -1.5
            },
            225: {
                x: -1,
                y: 0,
                z: -1
            },
            270: {
                x: -1.5,
                y: 0,
                z: 0
            },
            315: {
                x: -1,
                y: 0,
                z: 1
            },
            0: {
                x: 0,
                y: 0,
                z: 1.5
            }
        };
        if (direction) {
            var positions = cameraPositions[direction];
            camera.position.set(positions.x, positions.y, positions.z);
            controls.update();
        } else {
            var positions1 = cameraPositions[0];
            camera.position.set(positions1.x, positions1.y, positions1.z);
            controls.update();
        }
    } catch (e) {
        // statements
        console.error("reconciler.changeViewManual.error", e);
    }
}
/**
 * generate GIF with list of images
 * @param  {string[]} images
 * @param  {THREE.Vector2} dimension
 * @returns Promise
 */ function createGif(images, dimension) {
    return new Promise(function(resolve) {
        var options = {
            images: images,
            interval: intervalGif,
            // numFrames: 10,
            // numWorkers: 10,
            gifWidth: dimension.x,
            gifHeight: dimension.y
        };
        gifshot.createGIF(options, function(obj) {
            return resolve(obj.image);
        });
    });
}
