import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { makeAvailablePlaceholders } from "~/libs/store/src/util/placeholder";
export var alternativeNormalMap = function(isWarrix) {
    return isWarrix ? [
        {
            src: "https://cdn.mybrikl.com/15d5c13e-b98d-4d44-944b-c52f47f44f18/studio/material/Atom_01_NRM_30-ljwXwaB0t.png",
            repeat: 3,
            intensity: 0.5
        },
        {
            src: "https://cdn.mybrikl.com/15d5c13e-b98d-4d44-944b-c52f47f44f18/studio/material/Needle_09_NRM_30-gm0pHEtSw.png",
            repeat: 9,
            intensity: 0.5
        },
        {
            src: "https://cdn.mybrikl.com/15d5c13e-b98d-4d44-944b-c52f47f44f18/studio/material/Rainy_New5_NRM_30-csSXrRXV7.png",
            repeat: 30,
            intensity: 0.5
        }, 
    ] : [];
};
var createStudioStates = function(param) {
    var product = param.product, isWarrix = param.isWarrix, langCode = param.langCode, studioTheme = param.studioTheme, shopId = param.shopId, productDesignId = param.productDesignId, productId = param.productId, isDesignRoom = param.isDesignRoom, pxToCmDimension = param.pxToCmDimension, organisationId = param.organisationId, environment = param.environment;
    var ref, ref1, ref2;
    var productData = product;
    var materialConfigurations = productData === null || productData === void 0 ? void 0 : (ref = productData.materialConfigurations) === null || ref === void 0 ? void 0 : ref.map(function(nm) {
        return {
            src: nm.src,
            repeat: nm.repeat,
            intensity: nm.intensity,
            alternativeNormalMap: alternativeNormalMap(isWarrix)
        };
    });
    var ref3;
    // SHOW & ACTIVE 3D 2D LOGIC
    // NO ALL CONFIG THEN 3D AUTO VISIBLE & ACTIVE / 2D OFF
    // IF ALL ACTIVE THEN VISIBLE 3D & INVISIBLE 2D
    // IF 3D INACTIVE & 2D ACTIVE THEN THIS IS 2D ONLY PRODUCT
    var enable3D = (ref3 = productData === null || productData === void 0 ? void 0 : (ref1 = productData.studioSettings) === null || ref1 === void 0 ? void 0 : ref1.show3D) !== null && ref3 !== void 0 ? ref3 : true;
    var ref4;
    var enable2D = (ref4 = productData === null || productData === void 0 ? void 0 : (ref2 = productData.studioSettings) === null || ref2 === void 0 ? void 0 : ref2.show2D) !== null && ref4 !== void 0 ? ref4 : false;
    var only2D = enable2D === true && enable3D === false ? true : false;
    var is3DVisible = enable3D;
    var is2DVisible = only2D;
    var studioInitialState = _object_spread_props(_object_spread({
        environment: environment,
        enable2D: enable2D,
        enable3D: enable3D,
        is2DVisible: is2DVisible,
        is3DVisible: is3DVisible,
        only2D: only2D,
        shopId: shopId,
        productDesignId: productDesignId,
        productId: productId,
        ownedByOrganisationId: organisationId
    }, studioTheme && {
        theme: studioTheme
    }), {
        isDesignRoom: isDesignRoom,
        isBackgroundEnabled: (productData === null || productData === void 0 ? void 0 : productData.studioBackgroundFileSrc) !== null,
        selectedBackground: productData === null || productData === void 0 ? void 0 : productData.studioBackgroundFileSrc,
        availableBackgrounds: [],
        isAutoRotateEnabled: false,
        isDebugEnabled: true,
        normalMapConfig: materialConfigurations || [],
        pxToCmDimension: pxToCmDimension,
        availablePlaceholders: makeAvailablePlaceholders(productData === null || productData === void 0 ? void 0 : productData.latestPlaceholders, langCode)
    });
    return studioInitialState;
};
export default createStudioStates;
