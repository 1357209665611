import { createSlice } from "../../@redux";
var initialState = {
    orderSequences: []
};
export var slice = createSlice({
    name: "layer",
    initialState: initialState,
    reducers: {
        resetData: function() {
            return initialState;
        },
        /** Saves the layering information into the backend. */ saveLayeringOrder: function saveLayeringOrder(state, param) {
            var payload = param.payload;
            state.orderSequences = payload;
        },
        /** Loads the layering information into engine and fabric. */ loadLayeringOrder: function loadLayeringOrder(state, param) {
            var payload = param.payload;
            state.orderSequences = payload;
            console.log("[Layer] Order Sequences Loaded:", payload);
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        forceReorderLayer: function forceReorderLayer() {}
    }
});
export var actions = slice.actions, reducer = slice.reducer, name = slice.name;
