// create default control adjustment variables
export var controlPermissions = {
    horizontal: true,
    vertical: true,
    scale: true,
    rotate: true
};
export var initialState = {
    isAllAopApplied: false,
    isApplyingAop: false,
    selectedAopId: null,
    colorPrintingTechniques: [],
    selectedColorPrintingTechniqueId: null,
    controlPermissions: controlPermissions,
    availableAops: [],
    uploadedAops: [],
    customTemplateAops: [],
    isShowingMobilePanel: false,
    selectedMobileControl: null,
    isReady: false
};
