/* eslint-disable @typescript-eslint/ban-ts-comment */ import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { all, call, delay, put, select, takeEvery, takeLatest } from "typed-redux-saga";
import { getNewBackendStoreApolloClient } from "@brikl/apollo-new-backend-store";
import { getMutationParams } from "../../../utils/saga-helpers";
import { selectNBE } from "../../GlobalProvider/selectors";
import * as API from "../apis/get-design-price";
import { actions } from "../slice";
export function fetchDesignPrice(customizationGroups, meta, apollo) {
    return _fetchDesignPrice.apply(this, arguments);
}
function _fetchDesignPrice() {
    _fetchDesignPrice = _async_to_generator(function(customizationGroups, meta, apollo) {
        var error;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    _state.trys.push([
                        0,
                        2,
                        ,
                        3
                    ]);
                    return [
                        4,
                        API.getDesignPrice(customizationGroups, meta, apollo)
                    ];
                case 1:
                    return [
                        2,
                        _state.sent()
                    ];
                case 2:
                    error = _state.sent();
                    return [
                        2,
                        error
                    ];
                case 3:
                    return [
                        2
                    ];
            }
        });
    });
    return _fetchDesignPrice.apply(this, arguments);
}
export function fetchDesignPriceNBE(items, meta, apollo) {
    return _fetchDesignPriceNBE.apply(this, arguments);
}
function _fetchDesignPriceNBE() {
    _fetchDesignPriceNBE = _async_to_generator(function(items, meta, apollo) {
        var error;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    _state.trys.push([
                        0,
                        2,
                        ,
                        3
                    ]);
                    return [
                        4,
                        API.getDesignPriceNBE(items, meta, apollo)
                    ];
                case 1:
                    return [
                        2,
                        _state.sent()
                    ];
                case 2:
                    error = _state.sent();
                    return [
                        2,
                        error
                    ];
                case 3:
                    return [
                        2
                    ];
            }
        });
    });
    return _fetchDesignPriceNBE.apply(this, arguments);
}
export function onLoadDesignPriceNBE(action) {
    var meta, items, apolloNBE, _tmp, data, error;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    5,
                    ,
                    6
                ]);
                return [
                    5,
                    _ts_values(getMutationParams())
                ];
            case 1:
                meta = _state.sent().meta;
                items = action.payload;
                return [
                    5,
                    _ts_values(delay(1000))
                ];
            case 2:
                _state.sent();
                _tmp = {};
                apolloNBE = getNewBackendStoreApolloClient((_tmp.shopId = meta === null || meta === void 0 ? void 0 : meta.shopId, _tmp.currencyCode = meta === null || meta === void 0 ? void 0 : meta.currency, _tmp.langCode = meta === null || meta === void 0 ? void 0 : meta.langCode, _tmp.countryCode = meta === null || meta === void 0 ? void 0 : meta.countryCode, _tmp));
                return [
                    5,
                    _ts_values(call(//@ts-ignore
                    fetchDesignPriceNBE, items, meta, apolloNBE))
                ];
            case 3:
                data = _state.sent().data;
                return [
                    5,
                    _ts_values(put(actions.updateDesignPriceSuccessNBE(data.getCartPrice)))
                ];
            case 4:
                _state.sent();
                return [
                    3,
                    6
                ];
            case 5:
                error = _state.sent();
                console.log("[CartEngine] => onLoadDesignPrice error", error);
                return [
                    3,
                    6
                ];
            case 6:
                return [
                    2
                ];
        }
    });
}
export function onLoadDesignPrice(action) {
    var ref, meta, apollo, customizationGroups, isNBE, apolloNBE, _tmp, data, data1, error;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    10,
                    ,
                    11
                ]);
                return [
                    5,
                    _ts_values(getMutationParams())
                ];
            case 1:
                ref = _state.sent(), meta = ref.meta, apollo = ref.apollo;
                customizationGroups = action.payload;
                return [
                    5,
                    _ts_values(delay(1000))
                ];
            case 2:
                _state.sent();
                return [
                    5,
                    _ts_values(select(selectNBE))
                ];
            case 3:
                isNBE = _state.sent();
                if (!isNBE) return [
                    3,
                    6
                ];
                _tmp = {};
                apolloNBE = getNewBackendStoreApolloClient((_tmp.shopId = meta === null || meta === void 0 ? void 0 : meta.shopId, _tmp.currencyCode = meta === null || meta === void 0 ? void 0 : meta.currency, _tmp.langCode = meta === null || meta === void 0 ? void 0 : meta.langCode, _tmp.countryCode = meta === null || meta === void 0 ? void 0 : meta.countryCode, _tmp));
                return [
                    5,
                    _ts_values(call(//@ts-ignore
                    fetchDesignPriceNBE, customizationGroups, meta, apolloNBE))
                ];
            case 4:
                data = _state.sent().data;
                return [
                    5,
                    _ts_values(put(actions.updateDesignPriceSuccessNBE(data.getCartPrice)))
                ];
            case 5:
                _state.sent();
                return [
                    3,
                    9
                ];
            case 6:
                return [
                    5,
                    _ts_values(call(fetchDesignPrice, customizationGroups, meta, apollo))
                ];
            case 7:
                data1 = _state.sent().data;
                return [
                    5,
                    _ts_values(put(actions.updateDesignPriceSuccess(data1.tempProductDesignPrice)))
                ];
            case 8:
                _state.sent();
                console.log("[CartEngine] => Update Cart total price", data1.tempProductDesignPrice);
                _state.label = 9;
            case 9:
                return [
                    3,
                    11
                ];
            case 10:
                error = _state.sent();
                console.log("[CartEngine] => onLoadDesignPrice error", error);
                return [
                    3,
                    11
                ];
            case 11:
                return [
                    2
                ];
        }
    });
}
function onUpdateDesignPrice(action) {
    var cartTotalPrice;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                cartTotalPrice = action.payload;
                return [
                    5,
                    _ts_values(put(actions.updateDesignPriceSuccess(cartTotalPrice)))
                ];
            case 1:
                _state.sent();
                console.log("[CartEngine] => Update Cart total price", cartTotalPrice);
                return [
                    2
                ];
        }
    });
}
/**
 * Watch on load cart items event
 */ export function watchLoadAndUpdateDesignPrice() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(all([
                        takeEvery(actions.loadDesignPrice.type, onLoadDesignPrice),
                        takeEvery(actions.loadDesignPriceNBE.type, onLoadDesignPriceNBE),
                        takeLatest(actions.updateDesignPrice.type, onUpdateDesignPrice)
                    ]))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
