import { FabricObjectType } from "./@types";
import { getObjectsByType } from "./util";
// Fabricjs object property
export var visualizeObjectProperties = {
    hasBorders: false,
    hasRotatingPoint: false,
    hasControls: false,
    lockMovementX: true,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    lockUniScaling: true,
    lockRotation: true,
    evented: false,
    excludeFromExport: true,
    visible: false
};
export function createGuideLine(baseTemplateForSnap, fabricCanvas) {
    // Get objects from base template
    var svgObjects = baseTemplateForSnap.svgObjects;
    svgObjects.forEach(function(svg) {
        // Condition : only Polygon, Polyline, Rectangle Object
        // strokeDashArray mostly for grading and grading is dashed line
        // If object smaller than 50px width height, then ignore
        if ([
            "polygon",
            "polyline",
            "rect"
        ].includes(svg.type) && !svg.strokeDashArray && svg.getScaledWidth() > 50 && svg.getScaledHeight() > 50) {
            // DEBUGING
            // svg.set({
            //   stroke: '#f00',
            //   strokeWidth: 5,
            // })
            // useFabricCanvas().add(svg)
            // // Bounding Rect
            // const rect = new fabric.Rect({
            //   width: svg.getScaledWidth(),
            //   height: svg.getScaledHeight(),
            //   left: svg.left,
            //   top: svg.top,
            //   fill: 'transparent',
            //   strokeWidth: 5,
            //   stroke: '#0f0',
            // })
            // useFabricCanvas().add(rect)
            // DEBUGING
            // Simple snap line, vertical and horizontal
            var centerPoint = svg.getCenterPoint();
            var vLine = new fabric.Rect({
                width: svg.getScaledWidth(),
                height: 0,
                left: svg.left,
                top: centerPoint.y,
                strokeWidth: 2,
                strokeDashArray: [
                    20
                ],
                stroke: "#b23566",
                data: {
                    type: FabricObjectType.GUIDELINE,
                    enabled: false
                }
            });
            vLine.set(visualizeObjectProperties);
            var hLine = new fabric.Rect({
                width: 0,
                height: svg.getScaledHeight(),
                left: centerPoint.x,
                top: svg.top,
                strokeWidth: 2,
                strokeDashArray: [
                    20
                ],
                stroke: "#b23566",
                data: {
                    type: FabricObjectType.GUIDELINE,
                    enabled: false
                }
            });
            hLine.set(visualizeObjectProperties);
            fabricCanvas.add(vLine);
            fabricCanvas.add(hLine);
        }
    });
}
// Toggle snap guideline
export function toggleGuideLine(visible, fabricCanvas) {
    var guideLines = getObjectsByType({
        type: FabricObjectType.GUIDELINE,
        fabricCanvas: fabricCanvas
    });
    var triggerRender = false // Prevent render without any changed
    ;
    guideLines.forEach(function(line) {
        var ref;
        if (line.visible === visible || ((ref = line.data) === null || ref === void 0 ? void 0 : ref.enabled) === false) return;
        triggerRender = true;
        line.set({
            visible: visible
        });
    });
    if (triggerRender) fabricCanvas.requestRenderAll() // only render when has changed
    ;
}
// Smart feature that can snap object to grid line
// Idea is of center of moving object have nearest X or Y with snap line, then it auto snap to grid
export function snapToGrid(param) {
    var object = param.object, is3DControl = param.is3DControl, fabricCanvas = param.fabricCanvas;
    var factorAccumulate = is3DControl ? 5 : 30 // factor that prevent snap stuckering durring move object out of snap
    ;
    object.setCoords() // pull lasted coordinate
    ;
    // get current object center
    var objectCenterX = Math.round(object.getCenterPoint().x);
    var objectCenterY = Math.round(object.getCenterPoint().y);
    // Get all available guide line
    var guideLines = getObjectsByType({
        type: FabricObjectType.GUIDELINE,
        fabricCanvas: fabricCanvas
    });
    guideLines.forEach(function(line) {
        var ref;
        if (((ref = line.data) === null || ref === void 0 ? void 0 : ref.enabled) === false) return;
        // Check where object intersect with guide line
        var intersectLine = object.intersectsWithObject(line);
        if (intersectLine) {
            var guideLineCenterX = Math.round(line.getCenterPoint().x);
            var guideLineCenterY = Math.round(line.getCenterPoint().y);
            // This is range of snapping point, explain below
            // guideLineCenterXL <----- guideLineCenterX -----> guideLineCenterXR
            // 70px   <-----  100px  ----->   130px // factorAccumulate = 30
            // So if user moving object within 70px --- 130px then it will auto snap to center
            var guideLineCenterXL = guideLineCenterX - factorAccumulate // Extend to left
            ;
            var guideLineCenterXR = guideLineCenterX + factorAccumulate // Entend to right
            ;
            var guideLineCenterYB = guideLineCenterY - factorAccumulate // Extend to bottom
            ;
            var guideLineCenterYT = guideLineCenterY + factorAccumulate // Entend to top
            ;
            if (objectCenterX >= guideLineCenterXL && objectCenterX <= guideLineCenterXR) {
                // If object move within guide line X range of snap point then it auto snap to center of guide line
                // console.log('AUTO SNAP X')
                object.set({
                    left: line.getCenterPoint().x
                }).setCoords();
            } else if (objectCenterY >= guideLineCenterYB && objectCenterY <= guideLineCenterYT) {
                // If object move within guide line Y range of snap point then it auto snap to center of guide line
                // console.log('AUTO SNAP Y')
                object.set({
                    top: line.getCenterPoint().y
                }).setCoords();
            }
        }
    });
}
