import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
export function uploadImages(state, param) {
    var newImages = param.payload;
    state.uploadedImages = _to_consumable_array(state.uploadedImages).concat(_to_consumable_array(newImages.map(function(image) {
        return _object_spread_props(_object_spread({}, image), {
            isUploading: true,
            isUploaded: false,
            isUploadFailed: false
        });
    })));
}
export var uploadImageSuccess = function(state, param) {
    var newImage = param.payload;
    var uploadedNewImage = _object_spread_props(_object_spread({}, newImage), {
        isUploading: false,
        isUploaded: true,
        isUploadFailed: false
    });
    var uploadedImageIndex = state.uploadedImages.findIndex(function(uploadedImage) {
        return uploadedImage.id === newImage.id;
    });
    if (uploadedImageIndex !== -1) {
        state.uploadedImages[uploadedImageIndex] = _object_spread({}, state.uploadedImages[uploadedImageIndex], uploadedNewImage);
    }
};
export var uploadImageFailed = function(state, param) {
    var errorImage = param.payload;
// TODO handle error message
};
