import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import omit from "lodash/omit";
import { call, select, takeLatest } from "typed-redux-saga";
import { getMutationParams } from "../../../utils/saga-helpers";
import { makeSelectPlacedPatterns } from "../selectors";
import { actions } from "../slice";
import * as API from "./pattern-helpers/services";
function onUpdatePlacedPattern(action) {
    var payload, newPlacedPattern, ref, meta, apollo, placedPatterns, isMatched, existingPlacedPatterns, updatedPattern, _tmp, error;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    4,
                    ,
                    5
                ]);
                payload = action.payload;
                newPlacedPattern = payload;
                return [
                    5,
                    _ts_values(getMutationParams())
                ];
            case 1:
                ref = _state.sent(), meta = ref.meta, apollo = ref.apollo;
                if (meta.viewOnly) return [
                    2
                ];
                return [
                    5,
                    _ts_values(select(makeSelectPlacedPatterns))
                ];
            case 2:
                placedPatterns = _state.sent();
                if (!placedPatterns || placedPatterns.length === 0) return [
                    2
                ];
                isMatched = function(placedPattern) {
                    return placedPattern.id === newPlacedPattern.id;
                };
                existingPlacedPatterns = placedPatterns.find(isMatched);
                if (!existingPlacedPatterns) return [
                    2
                ];
                _tmp = {};
                updatedPattern = _object_spread(_tmp, existingPlacedPatterns, omit(newPlacedPattern, [
                    "base64",
                    "srcUrl",
                    "src"
                ]));
                return [
                    5,
                    _ts_values(call(API.updatePattern, updatedPattern, meta, apollo))
                ];
            case 3:
                _state.sent();
                console.log("[PatternEngine] onUpdatePlacedPattern => ", updatedPattern);
                return [
                    3,
                    5
                ];
            case 4:
                error = _state.sent();
                console.error("[PatternEngine] onUpdatePlacedPattern Error => ", error);
                return [
                    3,
                    5
                ];
            case 5:
                return [
                    2
                ];
        }
    });
}
/**
 * Watch update pattern event
 */ export function watchUpdatePlacedPattern() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(takeLatest(actions.updatePlacedPattern.type, onUpdatePlacedPattern))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
