import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { call, put, select } from "typed-redux-saga";
import { v4 } from "uuid";
import { selectSelectedTemplate } from "../../TemplateEngine/selectors";
import { selectSelectedColorZone3DElement, selectSelectedCustomTemplateColorZone3DElement } from "../selectors";
import { actions } from "../slice";
import { onUpdateColorFill } from "./update-color";
export function onUpdateColorZone3DElement(action) {
    var ref, selectedColorId, selectedColorZone3DElement, ref1, selectedTemplateId, selectedCustomTemplateColorZone3DElement, selectedColor, newCustomTemplateColor, zoneId, _tmp, id, zoneId1, _tmp1, error, _tmp2;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    6,
                    ,
                    7
                ]);
                selectedColorId = action.payload;
                return [
                    5,
                    _ts_values(select(selectSelectedColorZone3DElement))
                ];
            case 1:
                selectedColorZone3DElement = _state.sent();
                return [
                    5,
                    _ts_values(select(selectSelectedTemplate))
                ];
            case 2:
                ref1 = _state.sent(), selectedTemplateId = ref1.id;
                return [
                    5,
                    _ts_values(select(selectSelectedCustomTemplateColorZone3DElement))
                ];
            case 3:
                selectedCustomTemplateColorZone3DElement = _state.sent();
                selectedColor = selectedColorZone3DElement === null || selectedColorZone3DElement === void 0 ? void 0 : (ref = selectedColorZone3DElement.availableColors) === null || ref === void 0 ? void 0 : ref.find(function(color) {
                    return color.id === selectedColorId;
                });
                newCustomTemplateColor = null;
                _tmp = {};
                _tmp1 = {};
                // then create new custom color
                if (!selectedCustomTemplateColorZone3DElement) {
                    zoneId = selectedColorZone3DElement.id;
                    newCustomTemplateColor = {
                        id: v4(),
                        colorId: selectedColor.id,
                        zoneId: zoneId,
                        templateId: selectedTemplateId
                    };
                    console.log("[Color Fill] Assigning to new color zone 3D:", (_tmp.newCustomTemplateColor = newCustomTemplateColor, _tmp));
                } else {
                    id = selectedCustomTemplateColorZone3DElement.id, zoneId1 = selectedCustomTemplateColorZone3DElement.zoneId;
                    newCustomTemplateColor = {
                        id: id,
                        colorId: selectedColor.id,
                        zoneId: zoneId1,
                        templateId: selectedTemplateId
                    };
                    console.log("[Color Fill] Assigning to existing color zone 3D:", (_tmp1.newCustomTemplateColor = newCustomTemplateColor, _tmp1));
                }
                return [
                    5,
                    _ts_values(call(onUpdateColorFill, newCustomTemplateColor, selectedColor.hex))
                ];
            case 4:
                _state.sent();
                return [
                    5,
                    _ts_values(put(actions.setColorZone3DElementTemplateColor(newCustomTemplateColor)))
                ];
            case 5:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 6:
                error = _state.sent();
                _tmp2 = {};
                console.error("[Color Fill] Update Custom Color error:", (_tmp2.error = error, _tmp2));
                throw error;
            case 7:
                return [
                    2
                ];
        }
    });
}
