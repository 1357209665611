import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { getCanvasColorZoneId } from "./export.utils";
import { mapColorCMYK } from "./util";
export var isInTheSameZone = function(svgIds, objectId) {
    return svgIds.includes(objectId) || // it should get zone id from children id eg COLORZONE-1_1 => COLORZONE-1
    svgIds.map(function(svgId) {
        return getCanvasColorZoneId(svgId);
    }).includes(objectId);
};
export var setColorEachZone = function(param) {
    var customColor = param.customColor, colorInfo = param.colorInfo, only2D = param.only2D;
    return function(svgObject) {
        var ref, ref1;
        var shouldChangeColor = // Is the current area?
        isInTheSameZone(customColor === null || customColor === void 0 ? void 0 : (ref = customColor.zone) === null || ref === void 0 ? void 0 : ref.svgIds, svgObject.id) && // // Is there a color hex code?
        !!(customColor === null || customColor === void 0 ? void 0 : (ref1 = customColor.color) === null || ref1 === void 0 ? void 0 : ref1.hex);
        if (!shouldChangeColor) return;
        // if it has aop, remove it
        if (svgObject.data.aopSrc) {
            delete svgObject.data.aopSrc;
        }
        // Apply color if match the coditions above
        svgObject.set({
            data: _object_spread_props(_object_spread({
                fillType: "COLOR"
            }, svgObject.data), {
                CMYK: colorInfo === null || colorInfo === void 0 ? void 0 : colorInfo.CMYK,
                colorInfo: colorInfo
            })
        });
        if (svgObject.get("type") === "image") {
            var filter_mode = "tint";
            if (only2D) {
                filter_mode = "overlay";
            }
            svgObject.filters = [];
            svgObject.filters.push(new fabric.Image.filters.BlendColor({
                color: customColor.color.hex,
                mode: filter_mode,
                alpha: 1
            }));
            svgObject.applyFilters();
        } else if (_instanceof(svgObject.fill, fabric.Gradient)) {
            var gradient = new fabric.Gradient(svgObject.fill);
            gradient.colorStops.forEach(function(stop) {
                stop.color = customColor.color.hex;
            });
            svgObject.set({
                fill: gradient
            });
        } else {
            svgObject.set({
                fill: customColor.color.hex
            });
        }
    };
};
export var setColorOnSelectedZone = function(param) {
    var selectedZone = param.selectedZone, fabrics = param.fabrics, only2D = param.only2D, svgObjects = param.svgObjects;
    var ref, ref1;
    var colorInfo = mapColorCMYK(fabrics, (ref = selectedZone.color) === null || ref === void 0 ? void 0 : ref.id, (ref1 = selectedZone.color) === null || ref1 === void 0 ? void 0 : ref1.hex);
    svgObjects === null || svgObjects === void 0 ? void 0 : svgObjects.filter(function(object) {
        var ref;
        return isInTheSameZone(selectedZone === null || selectedZone === void 0 ? void 0 : (ref = selectedZone.zone) === null || ref === void 0 ? void 0 : ref.svgIds, object.id);
    }).forEach(setColorEachZone({
        customColor: selectedZone,
        only2D: only2D,
        colorInfo: colorInfo
    }));
};
