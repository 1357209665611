import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { createSlice } from "../../@redux";
// Normal pattern actions
import * as deletePatternsActions from "./slices/delete-pattern.actions";
import * as loadPatternsActions from "./slices/load-pattern.actions";
import * as placePatternsActions from "./slices/place-pattern.actions";
import * as updatePatternsActions from "./slices/update-pattern.actions";
import * as uploadPatternsActions from "./slices/upload-pattern.actions";
// Placeholder pattern actions
export var initialState = {
    isLoading: false,
    uploadedPatterns: [],
    placedPatterns: [],
    deletedPlacedPatterns: [],
    isReady: false
};
export var slice = createSlice({
    name: "pattern",
    initialState: initialState,
    reducers: _object_spread({
        resetData: function() {
            return initialState;
        }
    }, loadPatternsActions, uploadPatternsActions, placePatternsActions, deletePatternsActions, updatePatternsActions)
});
export var actions = slice.actions, reducer = slice.reducer, name = slice.name;
