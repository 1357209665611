import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import uniqBy from "lodash/uniqBy";
import { getCanvasColorZoneId } from "@brikl/studio";
import { mapColorZones } from "./map-color-zones";
/** Extracts the material (Fabric Composition) from the zones. */ export function getMaterialsFromZones(zones, fabrics) {
    var ref;
    var transform = function(id) {
        var ref;
        return (ref = fabrics.find(function(e) {
            var ref;
            return ((ref = e.node) === null || ref === void 0 ? void 0 : ref.id) === id;
        })) === null || ref === void 0 ? void 0 : ref.node;
    };
    return (ref = zones.materialIds) === null || ref === void 0 ? void 0 : ref.map(transform);
}
/** Extracts the color zone from product template. */ export function getColorZonesFromTemplate(template, fabrics, langCode) {
    var ref, ref1;
    var enabledZoneIds = uniqBy(template.zones, function(zone) {
        return getCanvasColorZoneId(zone.svgIds[0]);
    }).filter(function(zone) {
        return zone.enabled;
    }).map(function(zone) {
        return getCanvasColorZoneId(zone.svgIds[0]);
    });
    var zones = (ref1 = (ref = template.zones) === null || ref === void 0 ? void 0 : ref.filter(function(z) {
        return z.enabled && enabledZoneIds.includes(getCanvasColorZoneId(z.svgIds[0]));
    })) === null || ref1 === void 0 ? void 0 : ref1.sort(function(a, b) {
        return a.sortOrder - b.sortOrder;
    }).map(function(z) {
        return _object_spread_props(_object_spread({}, z), {
            materials: getMaterialsFromZones(z, fabrics)
        });
    });
    return mapColorZones(_object_spread_props(_object_spread({}, template), {
        zones: uniqBy(zones, function(zone) {
            return getCanvasColorZoneId(zone.svgIds[0]);
        })
    }), langCode);
}
