import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import { transformDesignStep } from "@brikl/store";
export var TemplateStep = {
    id: "TEMPLATE",
    sortOrder: -3000,
    type: "TEMPLATE_SELECTION"
};
var ColorStep = {
    id: "COLOR",
    sortOrder: 1,
    type: "COLOR"
};
var AOPStep = {
    id: "AOP",
    sortOrder: 2,
    type: "AOP"
};
var ImageStep = {
    id: "Image",
    sortOrder: 4,
    type: "IMAGE"
};
var DEFAULT_DESIGN_ROOM_STEPS = [
    TemplateStep,
    ColorStep,
    AOPStep,
    {
        id: "TEXT",
        sortOrder: 3,
        type: "TEXT"
    },
    ImageStep,
    {
        id: "Pattern",
        sortOrder: 5,
        type: "PATTERN"
    }, 
];
var getStandardDesignStep = function(productSteps, shopSteps, langCode) {
    if ((productSteps === null || productSteps === void 0 ? void 0 : productSteps.length) > 0) {
        return productSteps === null || productSteps === void 0 ? void 0 : productSteps.map(function(designStep) {
            return transformDesignStep({
                designStep: designStep,
                langCode: langCode
            });
        });
    }
    return shopSteps === null || shopSteps === void 0 ? void 0 : shopSteps.map(function(shopStep) {
        return transformDesignStep({
            designStep: shopStep,
            langCode: langCode
        });
    });
};
/**
 * Generates the design steps from the studio context.
 *
 * Design steps can be defined on shop, saleschannel and product level which are more specific each level.
 * So they may set design steps on shop level if there are none defined on product level those are taken.
 * If they have set design steps on product level those are taken.
 *
 * For example, shop level they allow text and images only,
 * but for some product they can enable AOP and personalization as well.
 *
 **/ export function initializeDesignSteps(context) {
    var shopDesignSteps = context.shopDesignSteps, productDesignSteps = context.productDesignSteps, isDesignRoom = context.isDesignRoom, langCode = context.langCode, productTemplates = context.productTemplates, mode = context.mode;
    if (mode === "ImageCustomisation") return [
        ImageStep
    ];
    var shopSteps = shopDesignSteps;
    var productSteps = productDesignSteps;
    var designSteps = getStandardDesignStep(productSteps, shopSteps, langCode);
    var steps = _to_consumable_array(designSteps).concat(// if there are no more than 1 design template we are hiding the design template step
    _to_consumable_array((productTemplates === null || productTemplates === void 0 ? void 0 : productTemplates.length) > 1 ? [
        TemplateStep
    ] : []));
    if (isDesignRoom) {
        steps = buildDesignSteps(steps, isDesignRoom);
    }
    return sortBy(steps, "sortOrder", "desc");
}
/**
 * Design steps in design room should included all steps from DEFAULT_DESIGN_ROOM_STEPS
 * If not, add them and ensure that design room steps not conflicted with current design steps
 */ function buildDesignSteps(steps, isDesignRoom) {
    var getType = function(steps) {
        return steps.map(function(step) {
            return step.type;
        });
    };
    // get design room step type
    var designRoomStepTypes = getType(DEFAULT_DESIGN_ROOM_STEPS);
    // Is it included in current steps
    var currentSteps = function(designStep) {
        return !getType(steps).includes(designStep.type);
    };
    var designRoomSteps = function(designStep) {
        return designRoomStepTypes.includes(designStep.type);
    };
    if (isDesignRoom) {
        return uniqBy(_to_consumable_array(steps).concat(_to_consumable_array(DEFAULT_DESIGN_ROOM_STEPS)), function(step) {
            return step.type;
        });
    }
    // Check every design room standard design steps exist in current design steps
    if (!steps.every(designRoomSteps)) {
        steps = // Remove existing design room step if there is any design step from shop setting
        _to_consumable_array(DEFAULT_DESIGN_ROOM_STEPS.filter(currentSteps)).concat(// Add design steps from shop setting
        _to_consumable_array(steps));
    }
    return steps;
}
