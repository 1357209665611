import React from "react";
/**
 * Keeps a reference of the current instance of the 2d canvas
 *
 */ export var fabricCanvasRef = React.createRef();
/**
 * Just a clean way to access the canvas. I might later so just to keep React I did as it was a hook.
 */ export var useFabricCanvas = function() {
    return "object" !== "undefined" ? fabricCanvasRef.current : null;
};
export var useFabricCanvasRef = function() {
    return "object" !== "undefined" ? fabricCanvasRef : null;
};
