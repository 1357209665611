import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { put, takeLatest } from "typed-redux-saga";
import { actions } from "../slice";
export function onLoadText(param) {
    var payload, error;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                payload = param.payload;
                _state.label = 1;
            case 1:
                _state.trys.push([
                    1,
                    3,
                    ,
                    4
                ]);
                return [
                    5,
                    _ts_values(put(actions.loadPlacedTextsSuccess(payload)))
                ];
            case 2:
                _state.sent();
                console.log("[TextEngine] loaded successfully => ", payload);
                return [
                    3,
                    4
                ];
            case 3:
                error = _state.sent();
                console.error("[TextEngine] onLoadText => ", error);
                return [
                    3,
                    4
                ];
            case 4:
                return [
                    2
                ];
        }
    });
}
export function watchLoadPlacedTexts() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(takeLatest(actions.loadPlacedTexts.type, onLoadText))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
