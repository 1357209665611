export var TemplateType;
(function(TemplateType) {
    TemplateType["COLORZONE"] = "COLORZONE";
    TemplateType["BLANK"] = "BLANK";
})(TemplateType || (TemplateType = {}));
export var FabricObjectType;
(function(FabricObjectType) {
    FabricObjectType["COLORZONE"] = "COLORZONE";
    FabricObjectType["PLACEHOLDER"] = "PLACEHOLDER";
    FabricObjectType["Text"] = "Text";
    FabricObjectType["Image"] = "Image";
    FabricObjectType["TextImage"] = "TextImage";
    FabricObjectType["GRADING"] = "GRADING";
    FabricObjectType["PLACEMENT_FRAME"] = "PLACEMENT_FRAME";
    FabricObjectType["PLACEMENT_IN_FRAME"] = "PLACEMENT_IN_FRAME";
    FabricObjectType["GUIDELINE"] = "GUIDELINE";
})(FabricObjectType || (FabricObjectType = {}));
export var AllowedPlaceholderTypes;
(function(AllowedPlaceholderTypes) {
    AllowedPlaceholderTypes["TEXT"] = "TEXT";
    AllowedPlaceholderTypes["IMAGE"] = "IMAGE";
})(AllowedPlaceholderTypes || (AllowedPlaceholderTypes = {}));
