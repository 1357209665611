var listImageExtensions = [
    "jpg",
    "png",
    "svg",
    "jpeg"
];
export var isImageFile = function(filename) {
    var fileType = filename.split(".").pop();
    return listImageExtensions.includes(fileType);
};
