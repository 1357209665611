import cloneDeep from "lodash/cloneDeep";
import omitDeep from "omit-deep";
import { GetDesignPriceDocument, studioCartpriceDocument } from "@brikl/codegen";
export var getDesignPrice = function(items, meta, apollo) {
    // Important data
    var ownedByOrganisationId = meta.ownedByOrganisationId;
    var cartItems = items;
    // Prepare parameters for query
    var inputVariables = {
        ownedByOrganisationId: ownedByOrganisationId,
        items: omitDeep(cloneDeep(cartItems), [
            "quantityIndex"
        ])
    };
    // Query
    return getDesignPriceQuery(apollo)(inputVariables);
};
/**
 *
 * @param apollo ApolloClient
 */ export var getDesignPriceQuery = function(apollo) {
    return function(variables) {
        apollo.mutate({
            mutation: GetDesignPriceDocument,
            variables: variables
        });
        return apollo.mutate({
            mutation: GetDesignPriceDocument,
            variables: variables
        });
    };
};
export var getDesignPriceNBE = function(items, meta, apollo) {
    var ref;
    // Important data
    var cartItems = items;
    // Prepare parameters for query
    var inputVariables = {
        input: {
            id: "RANDOMID",
            userId: meta === null || meta === void 0 ? void 0 : meta.ownedByOrganisationId,
            shopId: meta === null || meta === void 0 ? void 0 : meta.shopId,
            organisationId: meta === null || meta === void 0 ? void 0 : meta.ownedByOrganisationId,
            salesChannelId: meta === null || meta === void 0 ? void 0 : (ref = meta.salesChannelId) === null || ref === void 0 ? void 0 : ref.toString(),
            currency: meta === null || meta === void 0 ? void 0 : meta.currency,
            langCode: meta === null || meta === void 0 ? void 0 : meta.langCode,
            couponCodes: [],
            lineItems: cartItems
        }
    };
    // Query
    return getDesignPriceQueryNBE(apollo)(inputVariables);
};
/**
 *
 * @param apollo ApolloClient
 */ export var getDesignPriceQueryNBE = function(apollo) {
    return function(variables) {
        apollo.mutate({
            mutation: studioCartpriceDocument,
            variables: variables
        });
        return apollo.mutate({
            mutation: studioCartpriceDocument,
            variables: variables
        });
    };
};
