import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import cloneDeep from "lodash/cloneDeep";
import { getScaleTransformValue, makeImgIxURL } from "@brikl/studio-utils";
import { environment } from "~/apps/dashboard/environments/environment";
import { isBrowser } from "../constants";
import { AOP_TRANSFORM_INITIAL } from "../index";
import { useFabricCanvas } from "./useFabricCanvas";
import { convertSvgToBase64, fabricRequestRender, loadImageFromURL } from "./util";
export var CACHE_PATTERN_MAP = new Map();
var CACHE_AOP_ZONE = new Map();
// Globals
var _svgDom;
if (isBrowser) {
    _svgDom = document.createElementNS("http://www.w3.org/2000/svg", "svg");
}
var fetchPatternImage = function() {
    var _ref = _async_to_generator(function(src) {
        return _ts_generator(this, function(_state) {
            return [
                2,
                loadImageFromURL(src)
            ];
        });
    });
    return function fetchPatternImage(src) {
        return _ref.apply(this, arguments);
    };
}();
var fetchPatternSvg = function() {
    var _ref = _async_to_generator(function(src) {
        var svgToPng;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        convertSvgToBase64(src)
                    ];
                case 1:
                    svgToPng = _state.sent();
                    return [
                        2,
                        loadImageFromURL(svgToPng.base64)
                    ];
            }
        });
    });
    return function fetchPatternSvg(src) {
        return _ref.apply(this, arguments);
    };
}();
var fetchPattern = function() {
    var _ref = _async_to_generator(function(src) {
        var cached, image, aopUrl, _tmp, isNotSvg;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    cached = CACHE_PATTERN_MAP.get(src);
                    if (cached) return [
                        2,
                        cached
                    ];
                    _tmp = {};
                    aopUrl = makeImgIxURL((_tmp.url = src, _tmp.environment = environment.API_STAGE, _tmp.queryOptions = {
                        q: "15"
                    }, _tmp));
                    isNotSvg = src.indexOf(".svg") === -1;
                    if (!isNotSvg) return [
                        3,
                        2
                    ];
                    return [
                        4,
                        fetchPatternImage(aopUrl)
                    ];
                case 1:
                    image = _state.sent();
                    return [
                        3,
                        4
                    ];
                case 2:
                    return [
                        4,
                        fetchPatternSvg(aopUrl)
                    ];
                case 3:
                    image = _state.sent();
                    _state.label = 4;
                case 4:
                    CACHE_PATTERN_MAP.set(src, image);
                    return [
                        2,
                        image
                    ];
            }
        });
    });
    return function fetchPattern(src) {
        return _ref.apply(this, arguments);
    };
}();
export var hashCode = function(str) {
    var ref;
    return (ref = Array.from(str).reduce(function(s, c) {
        return Math.imul(31, s) + c.charCodeAt(0) | 0;
    }, 0)) === null || ref === void 0 ? void 0 : ref.toString();
};
export var aopPattern = function() {
    var _ref = _async_to_generator(function(src, image, transform) {
        var _patternSourceCanvas, _tmp, matrixTransform, _tmp1, patternOptions, _tmp2, pattern;
        return _ts_generator(this, function(_state) {
            _tmp = {};
            _patternSourceCanvas = new fabric.StaticCanvas(src, (_tmp.renderOnAddRemove = false, _tmp.skipTargetFind = false, _tmp.selection = false, _tmp.enableRetinaScaling = false, _tmp));
            _patternSourceCanvas.add(image).renderAll();
            matrixTransform = _svgDom === null || _svgDom === void 0 ? void 0 : _svgDom.createSVGMatrix().rotate(transform.angle).scale(getScaleTransformValue(transform.scale));
            _tmp1 = {};
            _patternSourceCanvas.setDimensions((_tmp1.width = image.getScaledWidth(), _tmp1.height = image.getScaledHeight(), _tmp1)).renderAll();
            _tmp2 = {};
            patternOptions = (_tmp2.source = _patternSourceCanvas.getElement(), _tmp2.repeat = transform.repeat ? "repeat" : "no-repeat", _tmp2.offsetX = transform.offsetX, _tmp2.offsetY = transform.offsetY, _tmp2.patternTransform = [
                matrixTransform.a,
                matrixTransform.b,
                matrixTransform.c,
                matrixTransform.d,
                matrixTransform.e,
                matrixTransform.f, 
            ], _tmp2.imageTransformMatrix = image.calcOwnMatrix(), _tmp2.originOffsetX = 0, _tmp2.originOffsetY = 0, _tmp2.transform = transform, _tmp2);
            pattern = new fabric.Pattern(patternOptions);
            // DEBUG purpose only
            // console.log(
            //   'aopPattern',
            //   pattern,
            //   image,
            //   matrixTransform,
            //   _patternSourceCanvas,
            //   transform
            // )
            return [
                2,
                pattern
            ];
        });
    });
    return function aopPattern(src, image, transform) {
        return _ref.apply(this, arguments);
    };
}();
export var applyAOP = function(param) {
    var _pattern = param._pattern, transform = param.transform, aopSrc = param.aopSrc, fabricCanvas = param.fabricCanvas, svgObject = param.svgObject;
    var ref, ref1;
    var isColorExist = ((ref = svgObject.data) === null || ref === void 0 ? void 0 : ref.CMYK) || ((ref1 = svgObject.data) === null || ref1 === void 0 ? void 0 : ref1.colorInfo);
    if (isColorExist) {
        var ref2, ref3;
        (ref2 = svgObject.data) === null || ref2 === void 0 ? void 0 : delete ref2.CMYK;
        (ref3 = svgObject.data) === null || ref3 === void 0 ? void 0 : delete ref3.colorInfo;
    }
    if (svgObject.data.fillType !== "AOP") {
        svgObject.data.fillType = "AOP";
    }
    var pattern = cloneDeep(_pattern);
    pattern.offsetX = transform.offsetX - svgObject.left;
    pattern.offsetY = transform.offsetY - svgObject.top;
    pattern.originOffsetX = -svgObject.left;
    pattern.originOffsetY = -svgObject.top;
    // NOTE: performance for aop only
    svgObject.hasControls = false;
    svgObject.hasBorders = false;
    svgObject.hasRotatingPoint = false;
    pattern.transform = transform;
    svgObject.set({
        fill: pattern,
        data: _object_spread_props(_object_spread({
            fillType: "AOP"
        }, svgObject.data), {
            aopSrc: aopSrc,
            hasCached: true
        })
    });
    fabricRequestRender(fabricCanvas);
};
export var updateAOP = function(param) {
    var svgObject = param.svgObject, transformTrack = param.transformTrack, transform = param.transform;
    var pattern = svgObject.fill;
    svgObject.set({
        dirty: false
    });
    if (transformTrack === "angle" || transformTrack === "scale") {
        var matrixTransform = _svgDom === null || _svgDom === void 0 ? void 0 : _svgDom.createSVGMatrix().rotate(transform.angle).scale(getScaleTransformValue(transform.scale));
        pattern.patternTransform = [
            matrixTransform.a,
            matrixTransform.b,
            matrixTransform.c,
            matrixTransform.d,
            matrixTransform.e,
            matrixTransform.f, 
        ];
    } else {
        switch(transformTrack){
            case "offsetX":
                pattern.offsetX = transform.offsetX - svgObject.left;
                break;
            case "offsetY":
                pattern.offsetY = transform.offsetY - svgObject.top;
                break;
        }
    }
    pattern.transform = transform;
    svgObject.set({
        dirty: true
    });
};
var checkExistingAop = function(svgIds, aopSrc, fabricCanvas) {
    var svgObjects = fabricCanvas.getObjects();
    var existingAop = false;
    svgObjects === null || svgObjects === void 0 ? void 0 : svgObjects.forEach(function(svgObject) {
        var ref;
        if (!(svgIds.includes(svgObject.id) && aopSrc.indexOf((ref = svgObject.data) === null || ref === void 0 ? void 0 : ref.aopSrc) !== -1)) {
            return;
        }
        existingAop = true;
    });
    return existingAop;
};
export var useAOP = function() {
    var fabricCanvas = useFabricCanvas();
    var createCacheKey = function(zoneId, aopSrc) {
        return "name:".concat(zoneId, ",url:").concat(aopSrc.replace(/\?datetime=[0-9]+/g, ""));
    };
    var getZoneIndicesFromCache = function(key) {
        return CACHE_AOP_ZONE.get(key);
    };
    var setZoneIndicesCache = function(key, indices) {
        return CACHE_AOP_ZONE.set(key, indices);
    };
    var applyNewAOP = function() {
        var _ref = _async_to_generator(function(param) {
            var cacheKey, svgIds, aopSrc, transform, svgObjects, aopImage, _pattern, zoneIndices, listCacheZoneIndices;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        cacheKey = param.cacheKey, svgIds = param.svgIds, aopSrc = param.aopSrc, transform = param.transform;
                        console.debug("APPLY NEW AOP", svgIds, aopSrc);
                        svgObjects = fabricCanvas.getObjects();
                        return [
                            4,
                            fetchPattern(aopSrc)
                        ];
                    case 1:
                        aopImage = _state.sent();
                        return [
                            4,
                            aopPattern(aopSrc, aopImage, transform !== null && transform !== void 0 ? transform : AOP_TRANSFORM_INITIAL)
                        ];
                    case 2:
                        _pattern = _state.sent();
                        zoneIndices = getZoneIndicesFromCache(cacheKey);
                        // in case of cache hit get svg object then update them not search all svg object
                        if (zoneIndices && zoneIndices.length > 0) {
                            console.debug("[FABRIC AOP] cache hit => name: ".concat(cacheKey));
                            zoneIndices.forEach(function(cacheIndex) {
                                applyAOP({
                                    _pattern: _pattern,
                                    transform: transform,
                                    aopSrc: aopSrc,
                                    fabricCanvas: fabricCanvas,
                                    svgObject: svgObjects[cacheIndex]
                                });
                            });
                        } else {
                            listCacheZoneIndices = [];
                            svgObjects.forEach(function(svgObject, index) {
                                if (svgIds.includes(svgObject.id)) {
                                    listCacheZoneIndices.push(index);
                                    applyAOP({
                                        _pattern: _pattern,
                                        aopSrc: aopSrc,
                                        transform: transform,
                                        fabricCanvas: fabricCanvas,
                                        svgObject: svgObject
                                    });
                                }
                            });
                            setZoneIndicesCache(cacheKey, listCacheZoneIndices);
                            console.debug("[FABRIC AOP] create new cache key => ".concat(CACHE_AOP_ZONE));
                        }
                        console.debug("[FABRIC AOP] => current objects", fabricCanvas._objects.length);
                        return [
                            2
                        ];
                }
            });
        });
        return function applyNewAOP(_) {
            return _ref.apply(this, arguments);
        };
    }();
    var applyExistingAOP = function(param) {
        var cacheKey = param.cacheKey, svgIds = param.svgIds, aopSrc = param.aopSrc, transform = param.transform, transformTrack = param.transformTrack;
        console.log("UPDATE EXISTING AOP", svgIds, aopSrc);
        var svgObjects = fabricCanvas.getObjects();
        var zoneIndices = getZoneIndicesFromCache(cacheKey);
        if (zoneIndices && zoneIndices.length > 0) {
            console.debug("[FABRIC EXISTING AOP] cache hit => name: ".concat(cacheKey));
            zoneIndices.forEach(function(cacheIndex) {
                updateAOP({
                    transformTrack: transformTrack,
                    transform: transform,
                    svgObject: svgObjects[cacheIndex]
                });
            });
        } else {
            var listCacheZoneIndices = [];
            svgObjects.forEach(function(svgObject) {
                if (svgIds.includes(svgObject.id)) {
                    updateAOP({
                        svgObject: svgObject,
                        transform: transform,
                        transformTrack: transformTrack
                    });
                }
            });
            setZoneIndicesCache(cacheKey, listCacheZoneIndices);
            console.debug("[Fabric EXISTING AOP] create new cache key => ".concat(CACHE_AOP_ZONE));
        }
    };
    var setAOP = function() {
        var _ref = _async_to_generator(function(param) {
            var aopSrc, svgIds, transform, zoneId, transformTrack, cacheKey, existingAop, _tmp, _tmp1, err;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        aopSrc = param.aopSrc, svgIds = param.svgIds, transform = param.transform, zoneId = param.zoneId, transformTrack = param.transformTrack;
                        if (!aopSrc || svgIds.length === 0) {
                            return [
                                2
                            ];
                        }
                        cacheKey = createCacheKey(zoneId, aopSrc);
                        _state.label = 1;
                    case 1:
                        _state.trys.push([
                            1,
                            6,
                            ,
                            7
                        ]);
                        existingAop = checkExistingAop(svgIds, aopSrc, fabricCanvas);
                        if (!!existingAop) return [
                            3,
                            3
                        ];
                        _tmp = {};
                        return [
                            4,
                            applyNewAOP((_tmp.cacheKey = cacheKey, _tmp.svgIds = svgIds, _tmp.aopSrc = aopSrc, _tmp.transform = transform, _tmp))
                        ];
                    case 2:
                        _state.sent();
                        return [
                            3,
                            5
                        ];
                    case 3:
                        if (!(existingAop && transformTrack)) return [
                            3,
                            5
                        ];
                        _tmp1 = {};
                        return [
                            4,
                            applyExistingAOP((_tmp1.cacheKey = cacheKey, _tmp1.svgIds = svgIds, _tmp1.aopSrc = aopSrc, _tmp1.transform = transform, _tmp1.transformTrack = transformTrack, _tmp1))
                        ];
                    case 4:
                        _state.sent();
                        _state.label = 5;
                    case 5:
                        fabricRequestRender(fabricCanvas, "renderAll");
                        return [
                            3,
                            7
                        ];
                    case 6:
                        err = _state.sent();
                        console.error("Error applying AOP", err);
                        return [
                            3,
                            7
                        ];
                    case 7:
                        return [
                            2
                        ];
                }
            });
        });
        return function setAOP(_) {
            return _ref.apply(this, arguments);
        };
    }();
    return {
        setAOP: setAOP
    };
};
