export var initialState = {
    isAppliedAllColor: false,
    customTemplateColors: [],
    colorZones: [],
    colorZones3DElements: [],
    customTemplateColor3DElements: [],
    selectedColorId: null,
    isReady: false,
    templateColorError: null
};
