import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import pick from "lodash/pick";
import { all, call, delay, put, select, takeEvery } from "typed-redux-saga";
import { isNonImageType } from "../../../helpers/image";
import { getMutationParams } from "../../../utils/saga-helpers";
import { createPrint } from "../mutations/create-print";
import { uploadPrintImage } from "../mutations/upload-print-image";
import { selectLoadingAops } from "../selectors";
import { actions } from "../slice";
var FILE_FIELDS = [
    "file",
    "id",
    "base64",
    "srcUrl",
    "fileName",
    "isLoading", 
];
/** Updates the file metadata on the frontend store. */ function updateFileData(files) {
    var createTask;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                createTask = function createTask(aop) {
                    var payload = pick(aop, FILE_FIELDS);
                    var action = actions.aopUploadComplete(payload);
                    return put(action);
                };
                if (files.length < 1) return [
                    2
                ];
                return [
                    5,
                    _ts_values(all(files.map(createTask)))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
/** Updates the file metadata on the frontend store for image files. */ function updateImageFileData(files) {
    var pendingImages;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                pendingImages = files.filter(function(image) {
                    return isNonImageType(image.file);
                });
                return [
                    5,
                    _ts_values(call(updateFileData, pendingImages))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
export function onPrintUpload() {
    var ref, apollo, meta, selectedFiles, uploadTasks, uploadedFiles, _tmp, createPrintTasks, createdPrints, _tmp1, error;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    9,
                    ,
                    10
                ]);
                return [
                    5,
                    _ts_values(getMutationParams())
                ];
            case 1:
                ref = _state.sent(), apollo = ref.apollo, meta = ref.meta;
                // TODO will use it when product metadata is ready to use
                // const selectedDesignStep = yield* select(selectSelectedDesignStep)
                if (meta.viewOnly) return [
                    2
                ];
                return [
                    5,
                    _ts_values(select(selectLoadingAops))
                ];
            case 2:
                selectedFiles = _state.sent();
                // Update the file metadata of the uploaded images.
                return [
                    5,
                    _ts_values(call(updateImageFileData, selectedFiles))
                ];
            case 3:
                _state.sent();
                uploadTasks = selectedFiles.map(function(aop) {
                    return call(uploadPrintImage, aop, meta, apollo);
                });
                return [
                    5,
                    _ts_values(all(uploadTasks))
                ];
            case 4:
                uploadedFiles = _state.sent();
                _tmp = {};
                console.log("[AOP] Uploaded Files:", (_tmp.uploadedFiles = uploadedFiles, _tmp));
                createPrintTasks = uploadedFiles.map(function(aop) {
                    return call(createPrint, aop, meta, apollo);
                });
                return [
                    5,
                    _ts_values(all(createPrintTasks))
                ];
            case 5:
                createdPrints = _state.sent();
                _tmp1 = {};
                console.log("[AOP] Created Prints:", (_tmp1.createdPrints = createdPrints, _tmp1));
                // Update the metadata for image files in the store.
                return [
                    5,
                    _ts_values(call(updateImageFileData, uploadedFiles))
                ];
            case 6:
                _state.sent();
                // Wait for the images to finish uploading.
                // Also, wait for processing of non-image files to finish.
                return [
                    5,
                    _ts_values(delay(10000))
                ];
            case 7:
                _state.sent();
                // Update the metadata for non-image files that requires backend processing
                // e.g. Illustrator/PDF in the store.
                return [
                    5,
                    _ts_values(call(updateFileData, uploadedFiles))
                ];
            case 8:
                _state.sent();
                console.log("[AOP] Print upload completed.");
                return [
                    3,
                    10
                ];
            case 9:
                error = _state.sent();
                console.warn("[AOP] Print upload error:", error);
                return [
                    3,
                    10
                ];
            case 10:
                return [
                    2
                ];
        }
    });
}
export function watchAopUpload() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    takeEvery(actions.uploadAop.type, onPrintUpload)
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
