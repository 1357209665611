import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import uniqBy from "lodash/uniqBy";
export var loadSelectedTemplateTexts = function(state, param) {
    var assignedPlaceholderIds = param.payload;
    state.placedTexts = state.placedTexts.filter(function(text) {
        return assignedPlaceholderIds.includes(text.placeholderId);
    });
};
export var loadPlacedTexts = function(state, action) {
    state.isLoading = true;
    state.isLoaded = false;
    state.isReady = false;
};
export var loadPlacedTextsSuccess = function(state, param) {
    var payload = param.payload;
    state.isLoading = false;
    state.isLoaded = false;
    state.placedTexts = uniqBy(_to_consumable_array(state.placedTexts).concat(_to_consumable_array(payload.placedTexts)), "id");
    state.fonts = payload.fonts;
    state.isReady = true;
};
