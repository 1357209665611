/**
 * Transform canvas object to new structure for mutation schema
 *
 * @param FabricTextData
 */ export var mapObjectToMutationInput = function(param) {
    var id = param.id, charSpacing = param.charSpacing, fillColor = param.fillColor, flipX = param.flipX, flipY = param.flipY, fontFamily = param.fontFamily, // fontSize,
    left = param.left, lock = param.lock, orderSEQ = param.orderSEQ, outline = param.outline, outlineColor = param.outlineColor, rotate = param.rotate, scale = param.scale, text = param.text, top = param.top, visible = param.visible, placeholderId = param.placeholderId, printingTechniqueId = param.printingTechniqueId, width = param.width, height = param.height, colorId = param.colorId, opacity = param.opacity;
    return {
        stepId: id,
        isVisible: visible,
        placeholderId: placeholderId,
        printingTechniqueId: printingTechniqueId,
        textDesign: {
            fontFamily: fontFamily,
            content: text,
            colorHex: fillColor,
            colorId: colorId,
            outlineHex: outlineColor,
            lineSpacing: charSpacing,
            outlineSize: outline,
            orderSeq: orderSEQ,
            designAttributes: {
                opacity: opacity,
                flipX: flipX,
                flipY: flipY,
                top: top,
                angle: rotate,
                left: left,
                scaleX: scale,
                scaleY: scale,
                width: width,
                height: height,
                lock: lock
            }
        }
    };
};
/**
 * Transform canvas object from ProductDesignText to FabricTextData type
 *
 * @param textDesigns
 */ export var canvasTextDesignRevisionTransform = function(textDesigns, orderSequences) {
    return textDesigns.map(function(param) {
        var id = param.id, isVisible = param.isVisible, placeholderId = param.placeholderId, printingTechniqueId = param.printingTechniqueId, _textDesign = param.textDesign, outlineHex = _textDesign.outlineHex, colorHex = _textDesign.colorHex, content = _textDesign.content, _designAttributes = _textDesign.designAttributes, left = _designAttributes.left, top = _designAttributes.top, scaleX = _designAttributes.scaleX, scaleY = _designAttributes.scaleY, lock = _designAttributes.lock, flipY = _designAttributes.flipY, flipX = _designAttributes.flipX, angle = _designAttributes.angle, width = _designAttributes.width, height = _designAttributes.height, opacity = _designAttributes.opacity, fontFamily = _textDesign.fontFamily, lineSpacing = _textDesign.lineSpacing, outlineSize = _textDesign.outlineSize;
        return {
            outlineColor: outlineHex,
            rotate: angle,
            charSpacing: lineSpacing,
            fillColor: colorHex,
            flipX: flipX,
            flipY: flipY,
            fontFamily: fontFamily,
            // Font size not available in schema, leave it zero to avoid UI crashing
            fontSize: 0,
            id: id,
            left: left,
            lock: lock,
            outline: outlineSize,
            scale: scaleX || scaleY,
            text: content,
            top: top,
            visible: isVisible,
            printingTechniqueId: printingTechniqueId,
            placeholderId: placeholderId,
            orderSeq: orderSequences ? orderSequences.indexOf(id) + 1 : 0,
            width: width,
            height: height,
            opacity: opacity !== null && opacity !== void 0 ? opacity : 1
        };
    });
};
