import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
export function uploadAop(state, param) {
    var newAops = param.payload;
    var newUploadingAops = newAops.map(function(aop) {
        return _object_spread_props(_object_spread({}, aop), {
            isLoading: true
        });
    });
    state.uploadedAops = _to_consumable_array(state.uploadedAops).concat(_to_consumable_array(newUploadingAops));
}
export function aopUploadComplete(state, param) {
    var newAop = param.payload;
    var aopUploaded = function(aop) {
        return _object_spread_props(_object_spread({}, aop, newAop), {
            isLoading: false
        });
    };
    var newUpdatedAop = state.uploadedAops.filter(function(file) {
        return file.id === newAop.id;
    }).map(aopUploaded);
    state.uploadedAops = state.uploadedAops.filter(function(aop) {
        return aop.id !== newAop.id;
    }).concat(newUpdatedAop);
}
