import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _define_property from "@swc/helpers/src/_define_property.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import _type_of from "@swc/helpers/src/_type_of.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import axios from "axios";
import cssParser from "js-css-parser";
import _cloneDeep from "lodash/cloneDeep";
import flatMap from "lodash/flatMap";
import isNil from "lodash/isNil";
import orderBy from "lodash/orderBy";
import _uniqBy from "lodash/uniqBy";
import { generateVariableFontFaceDeclaration } from "~/libs/store/src/util/font-face";
import { GOOGLE_FONTS } from "~/libs/studio-engine/src/containers/TextEngine/constants";
import { getFontFamilyFromUrl } from "~/libs/studio-engine/src/containers/TextEngine/utils/font-family";
import { hexToRgb } from "~/libs/studio-utils/src";
import { fixCDN } from "~/libs/studio-utils/src/CDNHelper";
import { FabricObjectType } from "./@types";
import { checkFontFamilyOnOriginalTemplate, getCanvasColorZoneId, getSvgInnerContent, svgToHTMLElement, toLowerCase } from "./export.utils";
import { convertImageToBase64, convertImageUrlToBase64, convertSvgToObject } from "./util";
import { removeUnusedElement } from "./utils/cleanupElement";
export var createFontSVGDiv = function(virtualRawSVGDiv, styleFontFamily) {
    return function() {
        var fontList = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [], svg = arguments.length > 1 ? arguments[1] : void 0;
        var ref, ref1;
        // google fonts - available for all shops
        var googleFonts = GOOGLE_FONTS.map(function(fontUrl) {
            return '@import url("'.concat(fontUrl.replace(/;/g, ",").replace(/&/g, "%7C"), '");');
        });
        // font from font library
        var shopFonts = fontList === null || fontList === void 0 ? void 0 : fontList.flatMap(function(font) {
            return font.fontVariants.map(function(fontVariant) {
                return generateVariableFontFaceDeclaration(fontVariant, fontVariant.fontName);
            });
        });
        styleFontFamily.innerHTML = "\n  ".concat(googleFonts.join(""), "\n  ").concat(shopFonts.join(" "), "\n  ");
        virtualRawSVGDiv.innerHTML = getSvgInnerContent(svg);
        (ref = virtualRawSVGDiv.querySelector("svg")) === null || ref === void 0 ? void 0 : ref.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
        (ref1 = virtualRawSVGDiv.querySelector("svg")) === null || ref1 === void 0 ? void 0 : ref1.prepend(styleFontFamily);
        return virtualRawSVGDiv;
    };
};
function splitLayerGroup(originalSVG, baseSVG) {
    // PDF Can't separate group of svg <g>. So we need to split <g> group element for separate layer group
    var wrappedOriginalSVGElement = svgToHTMLElement(originalSVG);
    if (!wrappedOriginalSVGElement.children.length) {
        return;
    }
    var patterns = wrappedOriginalSVGElement.querySelectorAll("pattern") // FOR PATTERN
    ;
    var groupZone = wrappedOriginalSVGElement.querySelectorAll("*");
    console.log("groupZone", {
        groupZone: groupZone,
        baseSVG: baseSVG
    });
    var splitSVG = [];
    if (baseSVG) {
        var wrapperBaseSVGElement = svgToHTMLElement(baseSVG);
        // ASSIGN BASE TEMPLATE VECTOR (only inner svg)
        if (!wrapperBaseSVGElement.children.length) {
            return;
        }
        var baseSVGElement = wrapperBaseSVGElement.querySelector("svg");
        if (!baseSVGElement) {
            return;
        }
        var baseSVGViewBox = baseSVGElement.getAttribute("viewBox");
        var elements = Array.from(baseSVGElement.children);
        var svg = wrappedElementWithSVG(elements, baseSVGViewBox);
        splitSVG.push({
            svg: svg.outerHTML
        });
    }
    var domIdCheck = [
        "COLORZONE",
        "VIEWZONE",
        "LOGOZONE",
        "OBJECTZONE",
        "PRODUCT-INFO", 
    ];
    groupZone.forEach(function(element) {
        var idCheck = element.getAttribute("id");
        var proceedDom = domIdCheck.find(function(id) {
            return idCheck && idCheck.indexOf(id) !== -1;
        });
        if (!proceedDom) return;
        var originalSVGElement = wrappedOriginalSVGElement.querySelector("svg");
        if (!originalSVGElement) {
            return;
        }
        var originalSVGViewBox = originalSVGElement.getAttribute("viewBox");
        // find if color zone is part for parent group to position it correctly
        var parentGroupElement = null;
        if (idCheck.includes("COLORZONE")) {
            parentGroupElement = element.parentElement.closest("g[transform]");
        }
        var svg = wrappedElementWithSVG([
            parentGroupElement || element
        ], originalSVGViewBox);
        // ASSIGN PATTERN TO SVG
        patterns.forEach(function(pattern) {
            if (pattern && pattern.getAttribute("id") === idCheck + "-pattern") {
                svg.prepend(pattern);
            }
        });
        splitSVG.push({
            svg: svg.outerHTML
        });
    });
    return splitSVG.map(function(item) {
        return _object_spread_props(_object_spread({}, item), {
            svg: convertHtmlEntitiesToTag(item.svg)
        });
    });
}
function wrappedElementWithSVG(elements, viewBox) {
    var svg = document.createElement("svg");
    svg.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
    svg.setAttribute("x", "0");
    svg.setAttribute("y", "0");
    if (viewBox) svg.setAttribute("viewBox", viewBox);
    elements.forEach(function(element) {
        svg.appendChild(element);
    });
    return svg;
}
var createFontFamilyDictionary = function(fontList) {
    return fontList.flatMap(function(value) {
        return value.fontVariants.map(function(fontVariant) {
            var fontUrl = "".concat(fontVariant.src, "?datetime=") + new Date().getTime();
            return _define_property({}, fontVariant.fontName, _object_spread({
                normal: fontUrl
            }, fontVariant.fontStyle !== "normal" && _define_property({}, fontVariant.fontStyle, fontUrl)));
        });
    }).reduce(function(obj, item) {
        return Object.assign(obj, item);
    }, {});
};
function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") // $& means the whole matched string
    ;
}
function replaceAll(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
}
function convertHtmlEntitiesToTag(svgText) {
    return replaceAll(replaceAll(svgText, "&lt;", "<"), "&gt;", ">");
}
export function createSvgEmbedData(svgText) {
    return "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(convertHtmlEntitiesToTag(svgText))));
}
export function setSizeTemplateToOriginal(fileUrl) {
    if (!fileUrl) return;
    return new Promise(function(resolve) {
        fabric.util.request(fixCDN(fileUrl), {
            method: "get",
            onComplete: function() {
                var _ref = _async_to_generator(function(r) {
                    var svgRaw, _tmp;
                    return _ts_generator(this, function(_state) {
                        svgRaw = r.responseText.replace(/<!DOCTYPE[\s\S]*?(\[[\s\S]*\])*?\s]>/i, "");
                        document.querySelector("#sizeSVG").innerHTML = svgRaw;
                        _tmp = {};
                        resolve(_tmp);
                        return [
                            2
                        ];
                    });
                });
                return function(r) {
                    return _ref.apply(this, arguments);
                };
            }()
        });
    });
}
function createFontFamilyDictionaryFromGoogleFonts(googleFonts) {
    return googleFonts.flatMap(function(googleFont) {
        var googleFontWeights = Object.keys(googleFont.files).map(function(weightKey) {
            var fontUrl = googleFont.files[weightKey];
            return _object_spread({
                normal: fontUrl.replace("http://", "https://")
            }, weightKey !== "normal" && _define_property({}, weightKey, fontUrl));
        }).reduce(function(obj, item) {
            return Object.assign(obj, item);
        }, {});
        return _define_property({}, googleFont.family, googleFontWeights);
    }).reduce(function(obj, item) {
        return Object.assign(obj, item);
    }, {});
}
function fetchGoogleFont() {
    return _fetchGoogleFont.apply(this, arguments);
}
function _fetchGoogleFont() {
    _fetchGoogleFont = _async_to_generator(function() {
        var response, _tmp, result, e;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    _state.trys.push([
                        0,
                        2,
                        ,
                        3
                    ]);
                    _tmp = {};
                    return [
                        4,
                        axios((_tmp.url = "https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyCxQ3t_Vt7b1CzsTKhE5SvQkAY99W9UHE4", _tmp))
                    ];
                case 1:
                    response = _state.sent();
                    result = response.data;
                    return [
                        2,
                        Promise.resolve(result.items)
                    ];
                case 2:
                    e = _state.sent();
                    return [
                        2,
                        Promise.reject(e)
                    ];
                case 3:
                    return [
                        2
                    ];
            }
        });
    });
    return _fetchGoogleFont.apply(this, arguments);
}
var googleFontNames = flatMap(GOOGLE_FONTS !== null && GOOGLE_FONTS !== void 0 ? GOOGLE_FONTS : [], function(fontUrl) {
    return getFontFamilyFromUrl(fontUrl);
});
var FALLBACK_FONT = {
    normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
    bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
    italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
    bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf"
};
function getPDFFontsFromSvg(svg, fontList) {
    return _getPDFFontsFromSvg.apply(this, arguments);
}
function _getPDFFontsFromSvg() {
    _getPDFFontsFromSvg = // TODO: check to more refactoring
    _async_to_generator(function(svg, fontList) {
        var allGoogleFonts, fontStandards, googleFonts, customFonts, fontNames, missingFonts, fallbackFonts, _tmp, fonts, _tmp1, _tmp2, _tmp3;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        fetchGoogleFont()
                    ];
                case 1:
                    allGoogleFonts = _state.sent();
                    fontStandards = allGoogleFonts.filter(function(googleFont) {
                        return toLowerCase(googleFontNames).includes(googleFont === null || googleFont === void 0 ? void 0 : googleFont.family.toLowerCase());
                    });
                    googleFonts = createFontFamilyDictionaryFromGoogleFonts(fontStandards);
                    customFonts = createFontFamilyDictionary(fontList);
                    fontNames = _to_consumable_array(Object.keys(googleFonts)).concat(_to_consumable_array(Object.keys(customFonts)));
                    missingFonts = checkFontFamilyOnOriginalTemplate(svg, fontNames);
                    _tmp = {};
                    fallbackFonts = _tmp;
                    if (missingFonts.length) {
                        missingFonts.forEach(function(fn) {
                            fallbackFonts[fn] = FALLBACK_FONT;
                        });
                    }
                    _tmp1 = {};
                    _tmp2 = {};
                    fonts = _object_spread_props(_object_spread(_tmp1, googleFonts, customFonts, fallbackFonts), (_tmp2.// Roboto is default font by pdfmake // Please don't remove this
                    Roboto = FALLBACK_FONT, _tmp2));
                    _tmp3 = {};
                    return [
                        2,
                        (_tmp3.fonts = fonts, _tmp3.fallbackFonts = fallbackFonts, _tmp3)
                    ];
            }
        });
    });
    return _getPDFFontsFromSvg.apply(this, arguments);
}
var getBaseTemplateSvg = function(baseTemplateSVG, includeGrading) {
    if (!includeGrading) return null;
    var svgDoc = new DOMParser().parseFromString(baseTemplateSVG, "image/svg+xml");
    removeUnusedElement({
        svgDoc: svgDoc,
        isRemoveAIElement: true
    });
    var serializer = new XMLSerializer();
    var svgString = serializer.serializeToString(svgDoc).replace("<switch>", "").replace("</switch>", "");
    return svgString;
};
export var drawPDF = function() {
    var _ref = _async_to_generator(function(props) {
        var svg, baseTemplateSVG, width, height, fontList, includeGrading, splitSVG, pdfMake, ref, fonts, fallbackFonts, docDefinition, _tmp, createdPdf, resultBase64, _tmp1;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    svg = props.svg, baseTemplateSVG = props.baseTemplateSVG, width = props.width, height = props.height, fontList = props.fontList, includeGrading = props.includeGrading;
                    splitSVG = splitLayerGroup(svg, // Pdf make lib does not support switch SVG tag
                    getBaseTemplateSvg(baseTemplateSVG, includeGrading));
                    if (!splitSVG) {
                        throw new Error("Nothing to print");
                    }
                    return [
                        4,
                        import("pdfmake/build/pdfmake")
                    ];
                case 1:
                    pdfMake = _state.sent();
                    return [
                        4,
                        getPDFFontsFromSvg(svg, fontList)
                    ];
                case 2:
                    ref = _state.sent(), fonts = ref.fonts, fallbackFonts = ref.fallbackFonts;
                    _tmp = {};
                    docDefinition = (_tmp.pageSize = {
                        // pageSize missing about 50-100px so we increase them by 100px
                        // width +500 is extend for product info
                        width: width,
                        height: height
                    }, _tmp.pageMargins = 0, _tmp.compress = false, _tmp.content = splitSVG, _tmp);
                    createdPdf = pdfMake.createPdf(docDefinition, undefined, fonts);
                    return [
                        4,
                        getPDFBase64(createdPdf)
                    ];
                case 3:
                    resultBase64 = _state.sent();
                    _tmp1 = {};
                    return [
                        2,
                        (_tmp1.fontsError = Object.keys(fallbackFonts), _tmp1.data = resultBase64, _tmp1)
                    ];
            }
        });
    });
    return function drawPDF(props) {
        return _ref.apply(this, arguments);
    };
}();
function getPDFBase64(pdf) {
    return _getPDFBase64.apply(this, arguments);
}
function _getPDFBase64() {
    _getPDFBase64 = _async_to_generator(function(pdf) {
        return _ts_generator(this, function(_state) {
            return [
                2,
                new Promise(function(res) {
                    pdf.getBase64(function(b64) {
                        return res(b64);
                    });
                })
            ];
        });
    });
    return _getPDFBase64.apply(this, arguments);
}
export function getFillFromColorzone(fabricCanvas) {
    // GET COLORZONE FILL FROM FABRICJS
    var objects = fabricCanvas.getObjects();
    var _colorzoneList = objects.filter(function(obj) {
        var ref;
        return (obj === null || obj === void 0 ? void 0 : (ref = obj.data) === null || ref === void 0 ? void 0 : ref.type) === FabricObjectType.COLORZONE && _type_of((obj === null || obj === void 0 ? void 0 : obj.fill) === "string" || (obj === null || obj === void 0 ? void 0 : obj.fill) === "linear") && (obj === null || obj === void 0 ? void 0 : obj.id);
    }).map(function(obj) {
        var ref, ref1, ref2, ref3, ref4;
        // REGEX COLORZONE-ID CLEAN ID WITH CORRECT FORMAT COLORZONE-X
        var zoneId = getCanvasColorZoneId(obj === null || obj === void 0 ? void 0 : obj.id);
        var assignFill = ((ref = obj.data) === null || ref === void 0 ? void 0 : (ref1 = ref.colorInfo) === null || ref1 === void 0 ? void 0 : ref1.hex) || obj.fill // obj.data?.colorInfo?.hex From assign color from user // If missing it will get actual color direct from FabricJS "obj.fill"
        ;
        var offsetFill = null;
        if (typeof assignFill !== "string" && (assignFill === null || assignFill === void 0 ? void 0 : (ref2 = assignFill.colorStops) === null || ref2 === void 0 ? void 0 : ref2.length)) {
            // Make sure fill is string, because sometime default gradient color will provide data with fill object, so need to get actual string color inside gradient object.
            var sortedColorStops = orderBy(assignFill.colorStops, function(colorStop) {
                return colorStop.offset;
            }, "desc");
            assignFill = sortedColorStops[0].color;
            offsetFill = sortedColorStops[0].offset;
        }
        return {
            id: zoneId,
            fill: assignFill,
            offset: offsetFill,
            CMYK: (ref3 = obj.data) === null || ref3 === void 0 ? void 0 : ref3.CMYK,
            colorInfo: (ref4 = obj.data) === null || ref4 === void 0 ? void 0 : ref4.colorInfo
        };
    });
    var colorzoneList = _uniqBy(_colorzoneList, "id");
    console.log("colorzoneList", colorzoneList);
    return colorzoneList;
}
export function getAopFromColorzone(fabricCanvas) {
    return _getAopFromColorzone.apply(this, arguments);
}
function _getAopFromColorzone() {
    _getAopFromColorzone = _async_to_generator(function(fabricCanvas) {
        var objects, _aopList, aopList, aopListMap;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    objects = fabricCanvas.getObjects();
                    _aopList = objects.filter(function(obj) {
                        var ref, ref1;
                        return (obj === null || obj === void 0 ? void 0 : (ref = obj.data) === null || ref === void 0 ? void 0 : ref.type) === FabricObjectType.COLORZONE && typeof (obj === null || obj === void 0 ? void 0 : obj.fill) !== "string" && (obj === null || obj === void 0 ? void 0 : (ref1 = obj.fill) === null || ref1 === void 0 ? void 0 : ref1.type) !== "linear" && (obj === null || obj === void 0 ? void 0 : obj.id);
                    }).map(function(obj) {
                        var ref, ref1, ref2;
                        console.log("AOP OBJ", obj);
                        return {
                            id: obj.id,
                            fill: (ref = obj.data) === null || ref === void 0 ? void 0 : ref.aopSrc,
                            patternTransform: _cloneDeep((ref1 = obj.fill) === null || ref1 === void 0 ? void 0 : ref1.patternTransform),
                            transform: (ref2 = obj.fill) === null || ref2 === void 0 ? void 0 : ref2.transform
                        };
                    });
                    aopList = _uniqBy(_aopList, "id");
                    aopList = aopList.filter(function(element) {
                        return element !== undefined;
                    });
                    aopListMap = aopList.map(function(aop, i) {
                        var ref;
                        if (!aop.fill) return {};
                        if (((ref = aop.fill) === null || ref === void 0 ? void 0 : ref.indexOf(".svg")) !== -1) {
                            aopList[i].fileType = "vector";
                            return convertSvgToObject(fixCDN(aop.fill));
                        } else {
                            aopList[i].fileType = "image";
                            return convertImageUrlToBase64(fixCDN(aop.fill), "INCLUDE_DIMENSION");
                        }
                    });
                    return [
                        4,
                        Promise.all(aopListMap).then(function(aoplm) {
                            console.log("aopList", aoplm);
                            return aopList.map(function(aop, i) {
                                if (aop.fileType === "vector") {
                                    aop.vectorInfo = {
                                        vector: aoplm[i].toSVG(),
                                        width: aoplm[i].width,
                                        height: aoplm[i].height
                                    };
                                    return aop;
                                }
                                aop.base64Info = aoplm[i];
                                return aop;
                            });
                        })
                    ];
                case 1:
                    return [
                        2,
                        _state.sent()
                    ];
            }
        });
    });
    return _getAopFromColorzone.apply(this, arguments);
}
function getFillFromText(fabricCanvas) {
    // GET COLORZONE FILL FROM FABRICJS
    var objects = fabricCanvas.getObjects();
    var _colorTextList = objects.map(function(obj) {
        var ref;
        if ((obj === null || obj === void 0 ? void 0 : (ref = obj.data) === null || ref === void 0 ? void 0 : ref.type) === FabricObjectType.Text && typeof (obj === null || obj === void 0 ? void 0 : obj.fill) === "string") {
            var ref1, ref2;
            return {
                id: obj.id,
                fill: obj.fill,
                CMYK: (ref1 = obj.data) === null || ref1 === void 0 ? void 0 : ref1.CMYK,
                colorInfo: (ref2 = obj.data) === null || ref2 === void 0 ? void 0 : ref2.colorInfo
            };
        }
        return obj;
    });
    var colorTextList = _uniqBy(_colorTextList, "id");
    colorTextList = colorTextList.filter(function(element) {
        return element !== undefined;
    });
    console.log("colorTextList", colorTextList);
    return colorTextList;
}
// HACK: getAttribute always return style with null value, that's why we can do this way
export function zoneElementHasFillAsColor(element) {
    var ref, ref1, ref2, ref3, ref4;
    // in case of zone element has fill attribute
    var fillAttribute = (ref1 = element === null || element === void 0 ? void 0 : (ref = element.attributes) === null || ref === void 0 ? void 0 : ref.getNamedItem("fill")) === null || ref1 === void 0 ? void 0 : ref1.value;
    if (fillAttribute) {
        return !(fillAttribute === null || fillAttribute === void 0 ? void 0 : fillAttribute.includes("url"));
    }
    var ref5;
    // in case of zone element has style attributes, it has to split to find fill attribute value
    var styleValues = (ref5 = (ref3 = element === null || element === void 0 ? void 0 : (ref2 = element.attributes) === null || ref2 === void 0 ? void 0 : ref2.getNamedItem("style")) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.value) === null || ref4 === void 0 ? void 0 : ref4.split(";")) !== null && ref5 !== void 0 ? ref5 : [];
    var fillColorStyle = styleValues.find(function(style) {
        return style.includes("fill:");
    });
    return !(fillColorStyle === null || fillColorStyle === void 0 ? void 0 : fillColorStyle.includes("url"));
}
export function replaceColorzoneToOriginalSVG(param) {
    var colorMode = param.colorMode, colorzoneList = param.colorzoneList, aopList = param.aopList, originalSVG = param.originalSVG, extendWidth = param.extendWidth, fabricCanvas = param.fabricCanvas;
    var ref, ref1, ref2;
    var virtualRawSVGDiv = svgToHTMLElement(originalSVG);
    if (!virtualRawSVGDiv.children.length) {
        return;
    }
    (ref = virtualRawSVGDiv.querySelector("svg")) === null || ref === void 0 ? void 0 : ref.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
    var viewBox = virtualRawSVGDiv.querySelector("svg").getAttribute("viewBox").split(" ");
    var extendWidthViewBox = parseFloat(viewBox[2]) + extendWidth;
    (ref1 = virtualRawSVGDiv.querySelector("svg")) === null || ref1 === void 0 ? void 0 : ref1.setAttribute("viewBox", "".concat(viewBox[0], " ").concat(viewBox[1], " ").concat(extendWidthViewBox, " ").concat(viewBox[3]));
    // virtualRawSVGDiv.querySelector('defs').innerHTML = virtualRawSVGDiv.querySelector('defs').innerHTML.replace(/fill: ((0x){0,1}|#{0,1})([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})/g, '')
    // CLEAR <style> and add to inline style for prevent css interubt
    applyStylesToElement(virtualRawSVGDiv);
    (ref2 = virtualRawSVGDiv.querySelector("style")) === null || ref2 === void 0 ? void 0 : ref2.remove();
    colorzoneList.forEach(function(cz) {
        var _colorzones = virtualRawSVGDiv.querySelectorAll("[id^='" + cz.id + "']");
        var colorFill = cz.fill// RGB
        ;
        if (colorMode === "CMYK") {
            // RESEARCH: IF NO CMYK CONFIG THEN WE CAN KEEP HEX, BECAUSE SOFTWARE WILL TO CONVERT IT SELF
            if (cz.CMYK) {
                colorFill = "cmyk(".concat(cz.CMYK.C, ", ").concat(cz.CMYK.M, ", ").concat(cz.CMYK.Y, ", ").concat(cz.CMYK.K, ")");
            }
        }
        var fillColorNormal = function(dom) {
            dom.setAttribute("fill", colorFill);
            if (colorMode !== "CMYK") dom.style.setProperty("fill", colorFill) // RGB use color from css then we need to force update for rgb
            ;
            if (colorMode === "CMYK") dom.style.setProperty("fill", "unset") // Prevend PDF fallback to style css dom
            ;
            // ******************************************* //
            dom.querySelectorAll("*:not(linearGradient):not(stop)").forEach(function(z) {
                z.setAttribute("fill", colorFill);
                if (colorMode !== "CMYK") z.style.setProperty("fill", colorFill) // RGB use color from css then we need to force update for rgb
                ;
                if (colorMode === "CMYK") z.style.setProperty("fill", "unset") // Prevend PDF fallback to style css dom
                ;
            });
        };
        var fillColorGradient = function(dom) {
            var colorStops = dom.querySelectorAll("stop");
            colorStops.forEach(function(cs) {
                var ref;
                if (cs.getAttribute("offset") !== ((ref = cz.offset) === null || ref === void 0 ? void 0 : ref.toString()) && // in case of offset is null it must be applied to all stop colors
                !isNil(cz.offset)) return;
                cs.setAttribute("stop-color", colorFill);
                if (colorMode !== "CMYK") cs.style.setProperty("stop-color", colorFill) // RGB use color from css then we need to force update for rgb
                ;
                if (colorMode === "CMYK") cs.style.setProperty("stop-color", "unset") // Prevend PDF fallback to style css dom
                ;
            });
        };
        _colorzones.forEach(function(zone) {
            var actualZoneId = getCanvasColorZoneId(cz.id);
            var actualZoneIdDOM = getCanvasColorZoneId(zone.id);
            if (actualZoneIdDOM !== actualZoneId) return;
            var linearGradient = zone.querySelectorAll("linearGradient");
            var nonLinearGradient = zone.querySelectorAll("*:not(linearGradient):not(stop)");
            if (linearGradient.length) {
                console.log("has linearGradient", linearGradient);
                // ASSIGN COLOR TO LINEAR GRADIENT
                linearGradient.forEach(function(linZone) {
                    fillColorGradient(linZone);
                });
                nonLinearGradient.forEach(function(nonLinZone) {
                    var isLinearGradient = nonLinZone.querySelector("linearGradient");
                    if (!isLinearGradient && zoneElementHasFillAsColor(nonLinZone)) {
                        fillColorNormal(nonLinZone);
                    }
                });
                return;
            }
            fillColorNormal(zone);
        });
    });
    // AOP TO DOM PROCESS
    console.log("aopList USED", aopList);
    aopList.forEach(function(cz) {
        var ref, ref1, ref2, ref3, ref4;
        // assign aop to raw svg need to build dom pattern by our self, and this pattern dom need to compatible with illustrator
        // Some transform might not 100% accurate, user need to tune then again
        // This feature for make aop worked on illustrator first.
        // <pattern width="1024" height="1024" patternUnits="userSpaceOnUse" id="New_Pattern_Swatch_2" style="overflow:visible;">
        // <image style="overflow:visible;" width="1024" height="1024" xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEASABIAAD/7gAOQWRvYmUAZMAAAAAB/9sAhAABA
        var _colorzones = virtualRawSVGDiv.querySelectorAll("[id^='" + cz.id + "']");
        var colorFill = "url(#" + cz.id + "-pattern)";
        _colorzones.forEach(function(zone) {
            var actualZoneId = getCanvasColorZoneId(cz.id);
            var actualZoneIdDOM = getCanvasColorZoneId(zone.id);
            if (actualZoneIdDOM !== actualZoneId) return;
            zone.setAttribute("fill", colorFill);
            zone.style.setProperty("fill", colorFill);
            // ******************************************* //
            zone.querySelectorAll("*").forEach(function(z) {
                z.setAttribute("fill", colorFill);
                z.style.setProperty("fill", colorFill);
            });
        });
        var defsDom = document.createElement("defs");
        var patternDom = document.createElement("pattern");
        patternDom.setAttributeNS(null, "patternUnits", "userSpaceOnUse");
        patternDom.setAttribute("style", "overflow:visible;");
        patternDom.setAttribute("id", cz.id + "-pattern");
        patternDom.setAttribute("width", ((ref = cz.base64Info) === null || ref === void 0 ? void 0 : ref.width.toString()) || ((ref1 = cz.vectorInfo) === null || ref1 === void 0 ? void 0 : ref1.width.toString()));
        patternDom.setAttribute("height", ((ref2 = cz.base64Info) === null || ref2 === void 0 ? void 0 : ref2.height.toString()) || ((ref3 = cz.vectorInfo) === null || ref3 === void 0 ? void 0 : ref3.height.toString()));
        if ((ref4 = cz.patternTransform) === null || ref4 === void 0 ? void 0 : ref4.length) {
            var ptMatrix = cz.patternTransform;
            // ADJUST X & Y
            ptMatrix[4] = ptMatrix[4] + cz.transform.offsetX;
            ptMatrix[5] = ptMatrix[5] + cz.transform.offsetY;
            var patternTransform = "matrix(".concat(ptMatrix[0], ", ").concat(ptMatrix[1], ", ").concat(ptMatrix[2], ", ").concat(ptMatrix[3], ", ").concat(ptMatrix[4], ", ").concat(ptMatrix[5], ")");
            patternDom.setAttributeNS(null, "patternTransform", patternTransform);
        }
        if (cz.fileType === "image") {
            var ref5, ref6, ref7;
            var imageDom = document.createElement("image");
            imageDom.setAttribute("style", "overflow:visible;");
            imageDom.setAttribute("width", (ref5 = cz.base64Info) === null || ref5 === void 0 ? void 0 : ref5.width.toString());
            imageDom.setAttribute("height", (ref6 = cz.base64Info) === null || ref6 === void 0 ? void 0 : ref6.height.toString());
            imageDom.setAttribute("xlink:href", (ref7 = cz.base64Info) === null || ref7 === void 0 ? void 0 : ref7.base64);
            patternDom.appendChild(imageDom);
        } else if (cz.fileType === "vector") {
            var vectorDom = document.createElement("g");
            vectorDom.textContent = cz.vectorInfo.vector;
            patternDom.appendChild(vectorDom);
        }
        defsDom.appendChild(patternDom);
        console.log("defsDom", defsDom);
        // AOP TO DOM PROCESS
        virtualRawSVGDiv.querySelector("svg").prepend(defsDom);
    });
    mergeTextAndImageToDom({
        virtualRawSVGDiv: virtualRawSVGDiv,
        colorMode: colorMode,
        fabricCanvas: fabricCanvas
    });
    return virtualRawSVGDiv.innerHTML;
}
/**
 * Merge text + image to raw svg
 * @param {HTMLElement} virtualRawSVGDiv
 * @param {string} colorMode
 */ function mergeTextAndImageToDom(param) {
    var virtualRawSVGDiv = param.virtualRawSVGDiv, colorMode = param.colorMode, fabricCanvas = param.fabricCanvas;
    var objects = fabricCanvas.getObjects();
    var mergeObject = objects.filter(function(obj) {
        var ref, ref1, ref2;
        return ((ref = obj.data) === null || ref === void 0 ? void 0 : ref.type) === FabricObjectType.Text || ((ref1 = obj.data) === null || ref1 === void 0 ? void 0 : ref1.type) === FabricObjectType.Image || ((ref2 = obj.data) === null || ref2 === void 0 ? void 0 : ref2.type) === FabricObjectType.TextImage // This is text as images
        ;
    });
    mergeObject.forEach(function(item) {
        var virtual_group = document.createElementNS("http://www.w3.org/2000/svg", "g");
        virtual_group.setAttribute("id", "OBJECTZONE");
        virtual_group.innerHTML = item.toSVG();
        if (!item.toSVG()) return;
        var textCheck = virtual_group.querySelector("text");
        // if ( item.data?.type === FabricObjectType.Image ) return // IGNORE THIS UNTIL PDF IMAGE ADDED DONE
        if (textCheck && item.data.type === FabricObjectType.Text) {
            var colorFill = item.fill// RGB
            ;
            if (colorMode === "CMYK") {
                var ref;
                // RESEARCH: IF NO CMYK CONFIG THEN WE CAN KEEP HEX, BECAUSE SOFTWARE WILL TO CONVERT IT SELF
                if ((ref = item.data) === null || ref === void 0 ? void 0 : ref.CMYK) {
                    var ref1, ref2, ref3, ref4;
                    colorFill = "cmyk(".concat((ref1 = item.data) === null || ref1 === void 0 ? void 0 : ref1.CMYK.C, ", ").concat((ref2 = item.data) === null || ref2 === void 0 ? void 0 : ref2.CMYK.M, ", ").concat((ref3 = item.data) === null || ref3 === void 0 ? void 0 : ref3.CMYK.Y, ", ").concat((ref4 = item.data) === null || ref4 === void 0 ? void 0 : ref4.CMYK.K, ")");
                }
            }
            if (colorMode !== "CMYK") textCheck.style.setProperty("fill", colorFill) // RGB use color from css then we need to force update for rgb
            ;
            if (colorMode === "CMYK") textCheck.style.setProperty("fill", "unset") // Prevend PDF fallback to style css dom
            ;
            textCheck.setAttribute("fill", colorFill);
        } else if (item.type === "group") {
            // SVG STUFF
            // CMYK MAPPING PREPARE
            var itemGroup = item;
            var getCMYK = itemGroup.getObjects().map(function(object) {
                var ref;
                return (ref = object.data) === null || ref === void 0 ? void 0 : ref.colorInfo;
            });
            console.log("getCMYK", getCMYK);
            // CMYK MAPPING PREPARE
            // LOOP SVG ELEMENT AND MAP CMYK HERE
            var vectors = virtual_group.querySelectorAll("*");
            console.log("vectors", vectors);
            vectors.forEach(function(vector) {
                var ref;
                if (vector.getAttribute("fill") || ((ref = vector.style) === null || ref === void 0 ? void 0 : ref.fill)) {
                    var ref1;
                    // console.log('I HAVE COLOR', [vector], vector.getAttribute('fill'), vector.style?.fill)
                    // SOME OF THEM MIGHT BE RGB NOT HEX :: BE CAREFUL rgb(177, 51, 101)
                    // TODO: WHEN BACKEND HAVE MATCHING CMYK FEATURE THEN DO MAP CMYK HERE
                    var cmykMap = getCMYK.find(function(colorSet) {
                        var ref, ref1;
                        if (!(colorSet === null || colorSet === void 0 ? void 0 : colorSet.hex)) return false;
                        var rgb = hexToRgb(colorSet.hex);
                        if (!rgb) return false;
                        var rgbString = "rgb(".concat(rgb.r, ", ").concat(rgb.g, ", ").concat(rgb.b, ")");
                        return colorSet.hex === (vector.getAttribute("fill") || ((ref = vector.style) === null || ref === void 0 ? void 0 : ref.fill)) || rgbString === (vector.getAttribute("fill") || ((ref1 = vector.style) === null || ref1 === void 0 ? void 0 : ref1.fill));
                    });
                    var colorFill = vector.getAttribute("fill") || ((ref1 = vector.style) === null || ref1 === void 0 ? void 0 : ref1.fill);
                    console.log("cmykMap", cmykMap, colorFill);
                    if (colorMode === "CMYK") {
                        // RESEARCH: IF NO CMYK CONFIG THEN WE CAN KEEP HEX, BECAUSE SOFTWARE WILL TO CONVERT IT SELF
                        if (cmykMap && cmykMap.CMYK) {
                            colorFill = "cmyk(".concat(cmykMap.CMYK.C, ", ").concat(cmykMap.CMYK.M, ", ").concat(cmykMap.CMYK.Y, ", ").concat(cmykMap.CMYK.K, ")");
                        }
                        vector.style.setProperty("fill", "unset") // Prevend PDF fallback to style css dom
                        ;
                        vector.setAttribute("fill", colorFill);
                    }
                }
            });
        }
        virtualRawSVGDiv.querySelector("svg").appendChild(virtual_group);
    });
// MERGE TEXT + IMAGE TO RAW SVG
}
export var applyStylesToElement = function(virtualDiv) {
    virtualDiv.querySelectorAll("style").forEach(function(style) {
        var stylesheet = cssParser.JSCSSParser(style.innerHTML);
        stylesheet.forEach(function(css) {
            var selectors = css.selector.split(", ");
            selectors === null || selectors === void 0 ? void 0 : selectors.forEach(function(selector) {
                virtualDiv.querySelectorAll(selector).forEach(function(elem) {
                    var ref;
                    var _$style = elem.getAttribute("style") || "";
                    (ref = css.declarations) === null || ref === void 0 ? void 0 : ref.forEach(function(decl) {
                        // eslint-disable-next-line prefer-const
                        var property = decl.property, value = decl.value;
                        if (property && value) {
                            // js-css-parser turn all props & value to lowercase, so this break id
                            // TODO: find better tool for css parser
                            // Now i fix by check SVGID_ then turn to uppercase
                            value = value.indexOf("svgid_") !== -1 ? value.replace("svgid_", "SVGID_") : value;
                            _$style += property + ": " + value + "; ";
                        }
                    });
                    elem.setAttribute("style", _$style);
                });
            });
        });
    });
};
function buildCMYKString(colorZone) {
    var colorInfo = colorZone === null || colorZone === void 0 ? void 0 : colorZone.colorInfo;
    return "\n  Color Preset:\n  HEX: ".concat((colorInfo === null || colorInfo === void 0 ? void 0 : colorInfo.hex) || "-", "\n  Pantone: ").concat((colorInfo === null || colorInfo === void 0 ? void 0 : colorInfo.pantone) || "-", "\n  Internal code: ").concat((colorInfo === null || colorInfo === void 0 ? void 0 : colorInfo.code) || "-", "\n  Internal name: ").concat((colorInfo === null || colorInfo === void 0 ? void 0 : colorInfo.internalName) || "-", "\n  CMYK: (").concat(colorZone.CMYK.C, ", ").concat(colorZone.CMYK.M, ", ").concat(colorZone.CMYK.Y, ", ").concat(colorZone.CMYK.K, ")\n\n  ");
}
export function mergeProductInfoWithShopLogo(_) {
    return _mergeProductInfoWithShopLogo.apply(this, arguments);
}
function _mergeProductInfoWithShopLogo() {
    _mergeProductInfoWithShopLogo = _async_to_generator(function(param) {
        var width, tmpCanvas, logoUrl, productInfo, logoFabric, _tmp;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    width = param.width, tmpCanvas = param.tmpCanvas, logoUrl = param.logoUrl, productInfo = param.productInfo;
                    if (isNil(logoUrl)) return [
                        2
                    ];
                    return [
                        4,
                        convertImageToBase64(fixCDN(logoUrl))
                    ];
                case 1:
                    logoFabric = _state.sent();
                    logoFabric.scaleToHeight(150);
                    _tmp = {};
                    logoFabric.set((_tmp.originX = "right", _tmp.left = width, _tmp));
                    tmpCanvas.setWidth(productInfo.getScaledWidth() + logoFabric.getScaledWidth());
                    tmpCanvas.setHeight(productInfo.getScaledHeight() + logoFabric.getScaledHeight());
                    return [
                        2,
                        logoFabric
                    ];
            }
        });
    });
    return _mergeProductInfoWithShopLogo.apply(this, arguments);
}
export function getProductInfo(_) {
    return _getProductInfo.apply(this, arguments);
}
function _getProductInfo() {
    _getProductInfo = _async_to_generator(function(param) {
        var productData, svg, option, fabricCanvas, width, cmykString, colorzoneList, colorTextList, productInfoString, tmpCanvas, _tmp, productInfo, _tmp1, logoFabric, _tmp2, ref, virtualRawSVGDiv, _tmp3, _tmp4, dataUrlOptions, _tmp5;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    productData = param.productData, svg = param.svg, option = param.option, fabricCanvas = param.fabricCanvas;
                    width = 650;
                    cmykString = "";
                    colorzoneList = getFillFromColorzone(fabricCanvas);
                    colorTextList = getFillFromText(fabricCanvas);
                    colorzoneList.forEach(function(colorZone) {
                        if (colorZone.CMYK) {
                            cmykString += buildCMYKString(colorZone);
                        }
                    });
                    colorTextList.forEach(function(textColorZone) {
                        if (textColorZone.CMYK) {
                            cmykString += buildCMYKString(textColorZone);
                        }
                    });
                    productInfoString = "\n  Product Information\n  Order Number:\n  ".concat(productData.orderNumber || "-", "\n  Product ID:\n  ").concat(productData.id || "-", "\n  Product Title:\n  ").concat(productData.title || "-", "\n  Product Size:\n  ").concat(productData.size || "-", "\n  CMYK:\n  ").concat(cmykString || "-", "\n  Note:\n  ").concat(productData.note || "-", "\n  ");
                    _tmp = {};
                    tmpCanvas = new fabric.StaticCanvas("cc", (_tmp.width = 1, _tmp.height = 1, _tmp));
                    _tmp1 = {};
                    productInfo = new fabric.Textbox(productInfoString, (_tmp1.fontSize = 30, _tmp1.top = 150, _tmp1.width = width, _tmp1.textAlign = "right", _tmp1));
                    tmpCanvas.add(productInfo);
                    _tmp2 = {};
                    return [
                        4,
                        mergeProductInfoWithShopLogo((_tmp2.logoUrl = productData.shopLogoUrl, _tmp2.tmpCanvas = tmpCanvas, _tmp2.productInfo = productInfo, _tmp2.width = width, _tmp2))
                    ];
                case 1:
                    logoFabric = _state.sent();
                    _tmp3 = {};
                    _tmp4 = {};
                    _tmp5 = {};
                    if (svg) {
                        ;
                        virtualRawSVGDiv = svgToHTMLElement(svg);
                        if (!virtualRawSVGDiv.children.length) {
                            return [
                                2
                            ];
                        }
                        (ref = virtualRawSVGDiv.querySelector("svg")) === null || ref === void 0 ? void 0 : ref.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
                        productInfo.set((_tmp3.left = productInfo.left + fabricCanvas.getWidth() / fabricCanvas.getZoom() - 100, _tmp3.top = 10 + productInfo.top, _tmp3));
                        if (logoFabric) {
                            logoFabric.set((_tmp4.left = logoFabric.left + fabricCanvas.getWidth() / fabricCanvas.getZoom() - 100, _tmp4.top = 10 + logoFabric.top, _tmp4));
                        }
                        return [
                            2,
                            virtualRawSVGDiv.innerHTML.replace("</svg>", '\n      \n\n      <g id="PRODUCT-INFO">\n      '.concat(productInfo.toSVG(), "\n      ").concat(logoFabric ? logoFabric.toSVG() : "", "\n      </g>\n      \n\n      </svg>\n      "))
                        ];
                    } else {
                        dataUrlOptions = _tmp5;
                        if (option === "3D") {
                            dataUrlOptions.multiplier = 0.3;
                        } else if (option === "MULTI3D") {
                            dataUrlOptions.multiplier = 0.1;
                        }
                        return [
                            2,
                            tmpCanvas.toDataURL(dataUrlOptions)
                        ];
                    }
                    return [
                        2
                    ];
            }
        });
    });
    return _getProductInfo.apply(this, arguments);
}
export function getOriginalBaseTemplateSVG(baseSVG) {
    var virtualRawSVGDiv = svgToHTMLElement(baseSVG);
    if (!virtualRawSVGDiv.children.length) {
        return;
    }
    return virtualRawSVGDiv.innerHTML;
}
