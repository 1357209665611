import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { call, select, takeLatest } from "typed-redux-saga";
import { v4 } from "uuid";
import { getMutationParams } from "../../../utils/saga-helpers";
import { selectSelectedDesignStep } from "../../DesignStepEngine/selectors";
import { actions } from "../slice";
import { onPlacePersonalizationText } from "./placeholder-text.saga";
import { createTextDesignRevision } from "./text-helpers/actions";
export function onPlaceGeneralText(param) {
    var text, ref, apollo, meta, _id, placeText, _tmp, _tmp1, error;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                text = param.payload;
                _state.label = 1;
            case 1:
                _state.trys.push([
                    1,
                    4,
                    ,
                    5
                ]);
                return [
                    5,
                    _ts_values(getMutationParams())
                ];
            case 2:
                ref = _state.sent(), apollo = ref.apollo, meta = ref.meta;
                if (meta.viewOnly) return [
                    2
                ];
                _tmp = {};
                _tmp1 = {};
                placeText = _object_spread_props(_object_spread(_tmp, text), (_tmp1.id = (_id = text.id) !== null && _id !== void 0 ? _id : v4(), _tmp1));
                return [
                    5,
                    _ts_values(call(createTextDesignRevision, placeText, meta, apollo))
                ];
            case 3:
                _state.sent();
                console.log("[TextEngine] onPlaceGeneralText ", text);
                return [
                    3,
                    5
                ];
            case 4:
                error = _state.sent();
                console.error("[TextEngine] onPlaceGeneralText => ", error);
                return [
                    3,
                    5
                ];
            case 5:
                return [
                    2
                ];
        }
    });
}
export function onPlaceText(action) {
    var selectedDesignStep, error;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    8,
                    ,
                    9
                ]);
                return [
                    5,
                    _ts_values(select(selectSelectedDesignStep))
                ];
            case 1:
                selectedDesignStep = _state.sent();
                console.log("saga:onPlaceText:selectedDesignStep", selectedDesignStep.type, action);
                if (!action.payload.isPlaceholderDefault) return [
                    3,
                    3
                ];
                return [
                    4,
                    call(onPlaceGeneralText, action)
                ];
            case 2:
                _state.sent();
                return [
                    2
                ];
            case 3:
                if (!(selectedDesignStep.type === "PERSONALIZATION")) return [
                    3,
                    5
                ];
                return [
                    4,
                    call(onPlacePersonalizationText, action)
                ];
            case 4:
                _state.sent();
                _state.label = 5;
            case 5:
                if (!(selectedDesignStep.type === "TEXT")) return [
                    3,
                    7
                ];
                return [
                    4,
                    call(onPlaceGeneralText, action)
                ];
            case 6:
                _state.sent();
                _state.label = 7;
            case 7:
                return [
                    3,
                    9
                ];
            case 8:
                error = _state.sent();
                console.error("[TextEngine] onPlaceText => ", error);
                return [
                    3,
                    9
                ];
            case 9:
                return [
                    2
                ];
        }
    });
}
export function watchPlaceText() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    takeLatest(actions.placeText.type, onPlaceText)
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
