import { canvasTextDesignRevisionTransform } from "./transform";
/**
 * Get the canvas text design
 *
 * You can use `useGetProductDesignQuery` to get the Product Design data
 * @param productDesign Product Design
 * @return list of text data
 */ export var transformTextDesigns = function(textDesigns, orderSequences) {
    if (!textDesigns) return [];
    var textPlacementDesigns = textDesigns.filter(function(textDesignRevision) {
        return textDesignRevision.textDesign !== null;
    });
    // Return new structure
    return canvasTextDesignRevisionTransform(textPlacementDesigns, orderSequences);
};
