import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import * as THREE from "three";
import { getMaterial } from "./setupModel";
// Gen canvas with power of two to make it render correct size
var _canvas;
function makePowerOfTwoSquare(image) {
    return _makePowerOfTwoSquare.apply(this, arguments);
}
function _makePowerOfTwoSquare() {
    _makePowerOfTwoSquare = /**
 * create pattern of normal map with image canvas element
 * @param  {ImageCanvasElement} image
 * @returns {ImageCanvasElement} image canvas element which already added pattern
 */ _async_to_generator(function(image) {
        return _ts_generator(this, function(_state) {
            return [
                2,
                new Promise(function(resolve) {
                    if (_instanceof(image, HTMLImageElement) || _instanceof(image, HTMLCanvasElement) || _instanceof(image, ImageBitmap)) {
                        if (_canvas === undefined) _canvas = document.createElementNS("http://www.w3.org/1999/xhtml", "canvas");
                        // Create our primary canvas and fill it with the pattern
                        var _canvasRepeat = document.createElement("canvas");
                        if (_instanceof(image, HTMLImageElement)) {
                            // on Firefox brower, image still empty until call onload to ensure it load completely
                            image.onload = function() {
                                addImagePatternToCanvas(_canvasRepeat, image);
                                resolve(_canvasRepeat);
                            };
                        } else {
                            addImagePatternToCanvas(_canvasRepeat, image);
                            resolve(_canvasRepeat);
                        }
                    }
                    resolve(image);
                })
            ];
        });
    });
    return _makePowerOfTwoSquare.apply(this, arguments);
}
function addImagePatternToCanvas(canvas, image) {
    var ctx = canvas.getContext("2d");
    var ratio = Math.round(image.width / image.height);
    canvas.width = image.width;
    canvas.height = image.height * ratio;
    var pattern = ctx.createPattern(image, "repeat");
    ctx.fillStyle = pattern;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    return canvas;
}
/**
 * get normal map setting from 3d model
 * @param  {string} nm_src normal map source url
 * @param  {number} nm_width normal map width
 * @param  {number} nm_height normal map height
 * @param  {ISvgDimensions} svgDimension width and height svg file
 * @returns repeat and intensity value
 */ function getNormalMapRepeatIntensity(normalMapSrc, normalMapWidth, normalMapHeight, svgDimension) {
    var ref;
    // DEFAULT
    var repeat = 1.0;
    var intensity = 0.5;
    // REGEX intensity & repeat from file name
    var NRM_INTENSITY = normalMapSrc.match(/(NRM_\d*.\d*)/g);
    if (NRM_INTENSITY !== null) {
        // EXTRACT number from regex
        var _intensity = NRM_INTENSITY[0].replace("NRM_", "").replace(".", "");
        _intensity = parseInt(_intensity);
        _intensity += 15 // Add factor to make it more accuarate intensity
        ;
        intensity = parseFloat("0." + _intensity.toString()) // Convert to 0.xxxx
        ;
    }
    // CHECK NM SPECIFIC repeat
    // Some NM smaller than common calculation so this will force to be repeat = 1
    var CHECK_REPEAT = normalMapSrc.match(/(R\d*.\d*)/g) // CHECK REPEAT 1
    ;
    CHECK_REPEAT = CHECK_REPEAT === null || CHECK_REPEAT === void 0 ? void 0 : (ref = CHECK_REPEAT[0]) === null || ref === void 0 ? void 0 : ref.replace("R", "");
    CHECK_REPEAT = parseInt(CHECK_REPEAT);
    if (CHECK_REPEAT === 1) {
        repeat = 1.0;
    } else {
        // if not it will use calculation by compare size (pattern size and normal map size)
        // Return base template power of two dimension
        var pattern_pw2_w = floorPowerOfTwo(svgDimension.width);
        var pattern_pw2_h = floorPowerOfTwo(svgDimension.height);
        // Return normalmap image power of two dimension
        var nm_pw2_w = floorPowerOfTwo(normalMapWidth);
        var nm_pw2_h = floorPowerOfTwo(normalMapHeight);
        // Add factor to make it more accuarate size
        var factor_repeat = 2;
        if (nm_pw2_w !== nm_pw2_h) {
            // IF IMAGE NOT SQUARE SO GENERATE CANVAS THAT FIT POWER OF 2
            factor_repeat = 1.1;
        } else if (nm_pw2_w === pattern_pw2_w || nm_pw2_h === pattern_pw2_h) {
            factor_repeat = 1;
        }
        var r = pattern_pw2_w / nm_pw2_w;
        repeat = r * r / factor_repeat // FORCE SIZE FACTOR
        ;
    }
    return {
        repeat: repeat,
        intensity: intensity
    };
}
function getNormalMapFileName(fileUrl) {
    var m = fileUrl.toString().match(/.*\/(.+?)\./);
    if (m && m.length > 1) {
        return m[1];
    }
}
/**
 * GLOBAL for store repeat, intensity before sending to onBeforeCompile
 * because onBeforeCompile is callback function that run after 1st renderer
 */ var storeNMParameter = [];
/**
 * apply normal map file to 3d material
 * @param  {THREE.MeshPhongMaterial} material
 * @param  {ISVGDimensions} svgDimension width and height svg file
 * @param  {INormalMap[]} normalMapConfig width and height svg file
 */ function applyNormalMap(material, svgDimension, normalMapConfig) {
    material.normalMap = material.bumpMap;
    var nmConfig = normalMapConfig.find(function(nm) {
        var ref, ref1;
        return getNormalMapFileName(nm.src) === getNormalMapFileName((ref = material.normalMap) === null || ref === void 0 ? void 0 : (ref1 = ref.image) === null || ref1 === void 0 ? void 0 : ref1.src);
    });
    if (!nmConfig) {
        nmConfig = getNormalMapRepeatIntensity(material.normalMap.image.src, material.normalMap.image.width, material.normalMap.image.height, svgDimension);
    } else {
        material.userData.normalMapConfig = nmConfig;
    }
    prepareApplyNormalMap(material, nmConfig);
    // CLEAR BUMPMAP
    material.bumpMap = null;
    material.needsUpdate = true;
}
/**
 * prepare value before apply normal map
 * @param  {THREE.MeshPhongMaterial} material
 * @param  {INormalMap} nmConfig
 */ export function prepareApplyNormalMap(material, nmConfig) {
    return _prepareApplyNormalMap.apply(this, arguments);
}
function _prepareApplyNormalMap() {
    _prepareApplyNormalMap = _async_to_generator(function(material, nmConfig) {
        var nm_pw2_w, nm_pw2_h;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    nm_pw2_w = floorPowerOfTwo(material.normalMap.image.width);
                    nm_pw2_h = floorPowerOfTwo(material.normalMap.image.height);
                    if (!(nm_pw2_w !== nm_pw2_h)) return [
                        3,
                        2
                    ];
                    _ = material.normalMap;
                    return [
                        4,
                        makePowerOfTwoSquare(material.normalMap.image)
                    ];
                case 1:
                    _.image = _state.sent();
                    _state.label = 2;
                case 2:
                    // CHECK ASSIGN NEW MAP WITH POWER OF 2
                    storeNMParameter[material.name] = {
                        repeat: nmConfig.repeat,
                        intensity: nmConfig.intensity
                    };
                    applyCustomFragmentShader(material);
                    return [
                        2
                    ];
            }
        });
    });
    return _prepareApplyNormalMap.apply(this, arguments);
}
/**
 * apply 3d fragment shader to rasterize into a set of colors and a single depth value
 * @param  {THREE.MeshPhongMaterial} material
 */ function applyCustomFragmentShader(material) {
    material.onBeforeCompile = function(shader) {
        var ref, ref1, ref2, ref3, ref4, ref5;
        // Default val if have no val in storeNMParameter
        var R = 1.0;
        var IN = 0.5;
        if (storeNMParameter[this.name]) {
            R = storeNMParameter[this.name].repeat.toFixed(4);
            IN = storeNMParameter[this.name].intensity.toFixed(4);
        }
        if (((ref = material.userData) === null || ref === void 0 ? void 0 : (ref1 = ref.shader) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.uniforms) === null || ref2 === void 0 ? void 0 : ref2.R) && ((ref3 = material.userData) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.shader) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.uniforms) === null || ref5 === void 0 ? void 0 : ref5.IN)) {
            material.userData.shader.uniforms.R.value = R;
            material.userData.shader.uniforms.IN.value = IN;
            return;
        }
        // NOTE: VAL SHOULD BE FLOAT ONLY
        shader.uniforms.R = {
            value: R
        };
        shader.uniforms.IN = {
            value: IN
        };
        // replace fragmentShader from phong fragmentShader
        // Resource about this it in three (node_module)
        // ORIGINAL : var normalmap_pars_fragment = "#ifdef USE_NORMALMAP\n\tuniform sampler2D normalMap;\n\tuniform vec2 normalScale;\n#endif\n#ifdef OBJECTSPACE_NORMALMAP\n\tuniform mat3 normalMatrix;\n#endif\n#if ! defined ( USE_TANGENT ) && ( defined ( TANGENTSPACE_NORMALMAP ) || defined ( USE_CLEARCOAT_NORMALMAP ) )\n\tvec3 perturbNormal2Arb( vec3 eye_pos, vec3 surf_norm, vec3 mapN ) {\n\t\tvec3 q0 = vec3( dFdx( eye_pos.x ), dFdx( eye_pos.y ), dFdx( eye_pos.z ) );\n\t\tvec3 q1 = vec3( dFdy( eye_pos.x ), dFdy( eye_pos.y ), dFdy( eye_pos.z ) );\n\t\tvec2 st0 = dFdx( vUv.st );\n\t\tvec2 st1 = dFdy( vUv.st );\n\t\tfloat scale = sign( st1.t * st0.s - st0.t * st1.s );\n\t\tvec3 S = normalize( ( q0 * st1.t - q1 * st0.t ) * scale );\n\t\tvec3 T = normalize( ( - q0 * st1.s + q1 * st0.s ) * scale );\n\t\tvec3 N = normalize( surf_norm );\n\t\tmat3 tsn = mat3( S, T, N );\n\t\tmapN.xy *= ( float( gl_FrontFacing ) * 2.0 - 1.0 );\n\t\treturn normalize( tsn * mapN );\n\t}\n#endif";
        shader.fragmentShader = shader.fragmentShader.replace("#include <normalmap_pars_fragment>", [
            "#ifdef USE_NORMALMAP\n        uniform sampler2D normalMap;\n        uniform vec2 normalScale;\n        uniform float R;\n        uniform float IN;\n        #endif\n        #ifdef OBJECTSPACE_NORMALMAP\n        uniform mat3 normalMatrix;\n        #endif\n        #if ! defined ( USE_TANGENT ) && ( defined ( TANGENTSPACE_NORMALMAP ) || defined ( USE_CLEARCOAT_NORMALMAP ) )\n        vec3 perturbNormal2Arb( vec3 eye_pos, vec3 surf_norm, vec3 mapN ) {\n          vec3 q0 = vec3( dFdx( eye_pos.x ), dFdx( eye_pos.y ), dFdx( eye_pos.z ) );\n          vec3 q1 = vec3( dFdy( eye_pos.x ), dFdy( eye_pos.y ), dFdy( eye_pos.z ) );\n          vec2 st0 = dFdx( vUv.st );\n          vec2 st1 = dFdy( vUv.st );\n          float scale = sign( st1.t * st0.s - st0.t * st1.s );\n          vec3 S = normalize( ( q0 * st1.t - q1 * st0.t ) * scale );\n          vec3 T = normalize( ( - q0 * st1.s + q1 * st0.s ) * scale );\n          vec3 N = normalize( surf_norm );\n          mat3 tsn = mat3( S, T, N );\n          mapN = texture2D( normalMap, vUv * R ).xyz * 2.0 - 1.0; // REPEAT NORMAL MAP\n          mapN.xy *= (normalScale * IN); // NORMAL MAP INTENSITY\n          mapN.xy *= ( float( gl_FrontFacing ) * 2.0 - 1.0 );\n          return normalize( tsn * mapN );\n        }\n        #endif", 
        ].join("\n"));
        material.userData.shader = shader;
    };
}
/**
 * find power of two of images
 * @param  {number} value
 */ function floorPowerOfTwo(value) {
    return Math.pow(2, Math.floor(Math.log(value) / Math.LN2));
}
/**
 * Sets normal map
 * @param  {THREE.Group} object
 * @param  {ISVGDimensions} svgDimension
 * @param  {INormalMap[]} normalMapConfig
 */ export function setNormalMap(object, svgDimension, normalMapConfig) {
    object.traverse(function(child) {
        if (_instanceof(child, THREE.Mesh)) {
            var materials = getMaterial(child.material);
            var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
            try {
                for(var _iterator = materials[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                    var material = _step.value;
                    if (material.bumpMap && material.bumpMap.image !== undefined) {
                        applyNormalMap(material, svgDimension, normalMapConfig);
                        material.side = THREE.FrontSide;
                    } else {
                        material.bumpMap = null;
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally{
                try {
                    if (!_iteratorNormalCompletion && _iterator.return != null) {
                        _iterator.return();
                    }
                } finally{
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }
        }
    });
}
