export var initialState = {
    isLoading: false,
    isLoaded: false,
    isUpdating: false,
    isUpdated: false,
    isFailure: null,
    selectedTextId: null,
    fonts: [],
    placedTexts: [],
    deletedPlacedTexts: [],
    isReady: false
};
export var GOOGLE_FONTS = [
    "https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Catamaran:wght@400;700&family=Caveat+Brush&family=Comfortaa:wght@400;500&family=Henny+Penny&family=Montserrat:wght@400;500;700&family=Mulish:wght@400;500&family=Oswald:wght@400;500&family=PT+Sans&family=Poppins:wght@300;500&family=Prompt&family=Roboto+Slab:wght@400;500&family=Roboto:wght@300;400;700&family=Rubik:wght@400;500&family=Varela&family=Viga&family=Work+Sans:wght@300;400&family=Yesteryear&display", 
];
export var STANDARD_FONTS = [
    "Arial"
];
export var textEngineConstants = {
    GOOGLE_FONTS: GOOGLE_FONTS,
    STANDARD_FONTS: STANDARD_FONTS
};
