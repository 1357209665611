import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { actions as aopEngineActions } from "../containers/AopEngine/slice";
import { actions as cartEngineActions } from "../containers/CartEngine/slice";
import { actions as colorEngineActions } from "../containers/ColorFillEngine/slice";
import { actions as designStepEngineActions } from "../containers/DesignStepEngine/slice";
import { actions as globalEngineActions } from "../containers/GlobalProvider/slice";
import { actions as imageEngineActions } from "../containers/ImageEngine/slice";
import { actions as layerEnginActions } from "../containers/LayerEngine/slice";
import { actions as patternEngineActions } from "../containers/PatternEngine/slice";
import { actions as placeholderEngineActions } from "../containers/PlaceholderEngine/slice";
import { actions as sceneEngineActions } from "../containers/SceneEngine/slice";
import { actions as templateEngineActions } from "../containers/TemplateEngine/slice";
import { actions as textEngineActions } from "../containers/TextEngine/slice";
import { createActionHook } from "../helpers/createActionHook";
export var useColorEngineActions = createActionHook(colorEngineActions);
export var useTextEngineActions = createActionHook(textEngineActions);
export var useAopEngineActions = createActionHook(aopEngineActions);
export var useCartEngineActions = createActionHook(cartEngineActions);
export var useImageEngineActions = createActionHook(imageEngineActions);
export var useTemplateEngineActions = createActionHook(templateEngineActions);
export var usePlaceholderEngineActions = createActionHook(placeholderEngineActions);
export var usePatternEngineActions = createActionHook(patternEngineActions);
export var useSceneEngineActions = createActionHook(sceneEngineActions);
export var useLayerEngineActions = createActionHook(layerEnginActions);
export var useDesignStepEngineActions = createActionHook(designStepEngineActions);
export var useGlobalEngineActions = createActionHook(globalEngineActions);
export function useSelectors() {
    for(var _len = arguments.length, selectors = new Array(_len), _key = 0; _key < _len; _key++){
        selectors[_key] = arguments[_key];
    }
    var selector = useMemo(function() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return createSelector.apply(void 0, _to_consumable_array(selectors).concat([
            function() {
                for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                    args[_key] = arguments[_key];
                }
                return args;
            }
        ]));
    }, [
        selectors
    ]);
    return useSelector(selector, shallowEqual);
}
