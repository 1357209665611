import { CreateLogoDesignRevisionDocument, DeleteLogoDesignRevisionDocument, UpdateLogoDesignRevisionDocument } from "@brikl/codegen";
/**
 *
 * @param apollo ApolloClient
 */ export var createImageMutation = function(apollo) {
    return function(param) {
        var id = param.id, ownedByOrganisationId = param.ownedByOrganisationId, productDesignId = param.productDesignId, revisionNo = param.revisionNo, input = param.input;
        if (!id) return;
        return apollo.mutate({
            mutation: CreateLogoDesignRevisionDocument,
            variables: {
                id: id,
                ownedByOrganisationId: ownedByOrganisationId,
                productDesignId: productDesignId,
                revisionNo: revisionNo,
                input: input
            }
        });
    };
};
/**
 *
 * @param apollo ApolloClient
 */ export var updateImageMutation = function(apollo) {
    return function(param) {
        var id = param.id, ownedByOrganisationId = param.ownedByOrganisationId, productDesignId = param.productDesignId, revisionNo = param.revisionNo, input = param.input;
        return apollo.mutate({
            mutation: UpdateLogoDesignRevisionDocument,
            variables: {
                id: id,
                ownedByOrganisationId: ownedByOrganisationId,
                productDesignId: productDesignId,
                revisionNo: revisionNo,
                input: input
            }
        });
    };
};
/**
 *
 * @param apollo ApolloClient
 */ export var deleteImageMutation = function(apollo) {
    return function(param) {
        var productDesignId = param.productDesignId, ownedByOrganisationId = param.ownedByOrganisationId, revisionNo = param.revisionNo, id = param.id;
        return apollo.mutate({
            mutation: DeleteLogoDesignRevisionDocument,
            variables: {
                productDesignId: productDesignId,
                ownedByOrganisationId: ownedByOrganisationId,
                revisionNo: revisionNo,
                id: id
            }
        });
    };
};
