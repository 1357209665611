import { EventEmitter } from "events";
import { useEffect } from "react";
import debounce from "lodash/debounce";
var CoordinatesEmitter = new EventEmitter();
export var useStudioCoordinatesListener = function(fn, deps) {
    useEffect(function() {
        var listener = debounce(fn, 300);
        CoordinatesEmitter.on("click", listener);
        return function() {
            CoordinatesEmitter.off("click", listener);
        };
    }, deps);
};
export var getStudioCoordinatesEmmiter = function() {
    return function(coords) {
        return CoordinatesEmitter.emit("click", coords);
    };
};
