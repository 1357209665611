import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { put, select, takeLatest } from "typed-redux-saga";
import { selectAopStep } from "../../DesignStepEngine/selectors";
import { makeSelectLangCode } from "../../MetadataEngine/selectors";
import { actions } from "../slice";
import { getPrintingTechniques, getPrintLibraries, selectPrintImages } from "../transforms/aop-utils";
import { getTransformAops } from "../transforms/get-custom-template-aop";
function onInitialAop(action) {
    var _payload, printDesigns, latestPlaceholders, zoneCustomizations, shopDesignSteps, langCode, aopDesignSteps, availableAops, colorPrintingTechniques, ref, uploadedAops, customTemplateAops, initialAopStates, _tmp, e;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    4,
                    ,
                    5
                ]);
                _payload = action.payload, printDesigns = _payload.printDesigns, latestPlaceholders = _payload.latestPlaceholders, zoneCustomizations = _payload.zoneCustomizations, shopDesignSteps = _payload.shopDesignSteps;
                return [
                    5,
                    _ts_values(select(makeSelectLangCode))
                ];
            case 1:
                langCode = _state.sent();
                return [
                    5,
                    _ts_values(select(selectAopStep))
                ];
            case 2:
                aopDesignSteps = _state.sent();
                availableAops = getPrintLibraries(shopDesignSteps);
                colorPrintingTechniques = getPrintingTechniques(aopDesignSteps, langCode);
                uploadedAops = (ref = selectPrintImages(printDesigns, latestPlaceholders)) !== null && ref !== void 0 ? ref : [];
                customTemplateAops = getTransformAops(zoneCustomizations);
                _tmp = {};
                initialAopStates = (_tmp.colorPrintingTechniques = colorPrintingTechniques, _tmp.uploadedAops = uploadedAops, _tmp.availableAops = availableAops, _tmp.customTemplateAops = customTemplateAops, _tmp);
                console.log("[AOP] Initial AOP => ", initialAopStates);
                return [
                    5,
                    _ts_values(put(actions.initializeAopCompleted(initialAopStates)))
                ];
            case 3:
                _state.sent();
                return [
                    3,
                    5
                ];
            case 4:
                e = _state.sent();
                console.error("[AOP] Initial AOP errors => ", e);
                return [
                    3,
                    5
                ];
            case 5:
                return [
                    2
                ];
        }
    });
}
export function watchInitialAop() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(takeLatest(actions.initializeAop.type, onInitialAop))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
