import * as THREE from "three";
import { WEBGL } from "three/examples/jsm/WebGL.js";
// HACK(Poom): WebGL Rendering Context type
var glContext = function(gl2) {
    return gl2;
};
/**
 * initialize 3d renderer
 */ function createInitialState() {
    console.log("start");
    // https://developer.mozilla.org/en-US/docs/Web/API/WebGL_API/WebGL_best_practices
    // ** PERFORMANCE CHECK https://threejs.org/docs/#api/en/renderers/WebGLRenderer
    // precision: 'lowp' 'mediump' 'highp' Defaults to "highp"
    // powerPreference: "high-performance", "low-power" or "default". Default is "default".
    // TODO: CHECK MORE PARAM FOR PERFORMANCE
    // ** PERFORMANCE CHECK
    // - getMaxAnisotropy(): Returns the maximum available anisotropy.
    // - getMaxPrecision(): Returns the maximum available precision for vertex and fragment shaders.
    var renderer;
    if (WEBGL.isWebGL2Available() === true) {
        console.log("WEBGL2 AVAILABLE");
        var canvas = document.createElement("canvas");
        var context = canvas.getContext("webgl2", {
            alpha: true
        });
        renderer = new THREE.WebGLRenderer({
            canvas: canvas,
            context: glContext(context)
        });
    // WEBGL.getWebGL2ErrorMessage() // ERROR LOG FOR WEBGL2
    } else {
        console.log("WEBGL2 NOT AVAILABLE > USE WEBGL1", WEBGL.getWebGL2ErrorMessage());
        renderer = new THREE.WebGLRenderer({
            alpha: true
        });
    }
    var scene = new THREE.Scene();
    // INIT CAPTURE STORE
    scene.userData.capture3D = {
        0: null,
        90: null,
        180: null,
        270: null
    };
    return {
        renderer: renderer,
        scene: scene,
        camera: new THREE.PerspectiveCamera(),
        raycaster: new THREE.Raycaster()
    };
}
var _globalThreeState = [];
if (!_globalThreeState[0]) {
    _globalThreeState[0] = createInitialState();
}
/**
 * shared state to get scene with specified instance number
 * @param  {number} instance
 */ export function globalThreeState() {
    var instance = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : null;
    if (instance) {
        if (!_globalThreeState[instance]) {
            _globalThreeState[instance] = createInitialState();
        }
        return _globalThreeState[instance];
    } else {
        return _globalThreeState[0];
    }
}
