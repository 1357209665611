import { AOP_TRANSFORM_INITIAL } from "@brikl/studio";
function createTransform(zone) {
    var transform = zone.alloverprintCustomization;
    if (!transform) return AOP_TRANSFORM_INITIAL;
    return {
        angle: transform.rotation,
        offsetX: transform.offsetX,
        offsetY: transform.offsetY,
        scale: transform.scale,
        repeat: true
    };
}
export function getTransformAops(zoneCustomizations) {
    var transform = function transform(zoneCustomization) {
        return {
            id: zoneCustomization === null || zoneCustomization === void 0 ? void 0 : zoneCustomization.id,
            templateId: zoneCustomization === null || zoneCustomization === void 0 ? void 0 : zoneCustomization.templateId,
            transform: createTransform(zoneCustomization),
            defaultPrintId: zoneCustomization === null || zoneCustomization === void 0 ? void 0 : zoneCustomization.alloverprintId,
            zoneId: zoneCustomization === null || zoneCustomization === void 0 ? void 0 : zoneCustomization.zoneId,
            printingTechniqueId: zoneCustomization === null || zoneCustomization === void 0 ? void 0 : zoneCustomization.printingTechniqueId
        };
    };
    return zoneCustomizations === null || zoneCustomizations === void 0 ? void 0 : zoneCustomizations.map(transform).filter(function(zoneCustomization) {
        return zoneCustomization.id && zoneCustomization.defaultPrintId;
    });
}
