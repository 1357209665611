import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { createSlice } from "../../@redux";
import { initialState } from "./constants";
import * as deleteTextActions from "./slices/delete-text.actions";
import * as loadTextActions from "./slices/load-text.actions";
import * as placeTextActions from "./slices/place-text.actions";
import * as updateTextActions from "./slices/update-text.actions";
export var slice = createSlice({
    name: "text",
    initialState: initialState,
    reducers: _object_spread({
        resetData: function() {
            return initialState;
        },
        resetPlacedTexts: function(state) {
            state.placedTexts = [];
        },
        selectText: function(state, param) {
            var payload = param.payload;
            state.selectedTextId = payload;
        }
    }, loadTextActions, placeTextActions, updateTextActions, deleteTextActions)
});
export var actions = slice.actions, reducer = slice.reducer, name = slice.name;
