import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { useCallback, useMemo } from "react";
import debounce from "lodash/debounce";
import isObject from "lodash/isObject";
import * as THREE from "three";
import { createLight, useThree } from "@brikl/studio";
import { setupLights as _setupLightsInternal } from "@brikl/studio";
import { createLightHelper } from "~/libs/studio/src/_three/utils/setupModel";
import { isMaisonShop } from "../constants";
var isLight = function(object) {
    return _instanceof(object, THREE.SpotLight) || _instanceof(object, THREE.AmbientLight) || _instanceof(object, THREE.HemisphereLight);
};
var isHelper = function(object) {
    return _instanceof(object, THREE.SpotLightHelper) || _instanceof(object, THREE.HemisphereLightHelper) || _instanceof(object, THREE.DirectionalLightHelper) || _instanceof(object, THREE.PointLightHelper);
};
var COLOR_FIELDS = [
    "color",
    "skyColor",
    "groundColor"
];
export var useEditLight = function(param) {
    var shopId = param.shopId, isLegacyLightSetting = param.isLegacyLightSetting;
    var ref = useThree(), scene = ref.scene, camera = ref.camera;
    var updateLight = debounce(function(lightId, field, value) {
        scene.traverse(function(object) {
            var ref;
            if (((ref = object.userData) === null || ref === void 0 ? void 0 : ref.uuid) !== lightId) return;
            if (isObject(value)) {
                Object.entries(value).forEach(function(param) {
                    var _param = _sliced_to_array(param, 2), key = _param[0], _$value = _param[1];
                    object[field][key] = _$value;
                });
            } else {
                if (COLOR_FIELDS.includes(field) && typeof value !== "boolean") {
                    object[field] = new THREE.Color(value);
                } else {
                    object[field] = value;
                }
            }
        });
    }, 500);
    var disableGridHelper = useCallback(function() {
        scene.children.forEach(function(object) {
            if (isHelper(object)) {
                object.visible = false;
            }
        });
    }, [
        scene
    ]);
    var removeLight = useCallback(function(lightId) {
        scene.traverse(function(object) {
            object.traverse(function(child) {
                if (child.userData.uuid !== lightId) return;
                scene.remove(child);
            });
        });
    }, [
        scene
    ]);
    var resetLights = useCallback(function() {
        scene.children.forEach(function(object) {
            object.traverse(function(child) {
                if (isLight(child) || isHelper(child)) {
                    child.visible = false;
                    child.userData = null;
                }
            });
        });
    }, [
        scene
    ]);
    var addLights = useCallback(function(listLights) {
        var showHelper = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
        listLights.forEach(function(lightData) {
            var light = createLight(lightData);
            var lightHelper = createLightHelper(light);
            // Ambient light doesn't have helper
            if (lightHelper && showHelper) {
                scene.add(lightHelper);
            }
            scene.add(light);
        });
    }, [
        scene
    ]);
    var updateLightColorHelper = useCallback(function(lightId, color) {
        scene.traverse(function(object) {
            if (object.userData.lightId === lightId) {
                object.color = new THREE.Color(color);
            }
        });
    }, [
        scene
    ]);
    var setupLights = useCallback(function() {
        _setupLightsInternal({
            scene: scene,
            camera: camera,
            spotLightIntensity: isMaisonShop(shopId) ? 0.02 : 0.6,
            isUsedNewLight: !isLegacyLightSetting
        });
    }, [
        scene,
        camera,
        shopId,
        isLegacyLightSetting
    ]);
    return useMemo(function() {
        return {
            updateLight: updateLight,
            resetLights: resetLights,
            addLights: addLights,
            removeLight: removeLight,
            updateLightColorHelper: updateLightColorHelper,
            setupLights: setupLights,
            disableGridHelper: disableGridHelper
        };
    }, [
        updateLight,
        resetLights,
        addLights,
        removeLight,
        updateLightColorHelper,
        setupLights,
        disableGridHelper, 
    ]);
};
