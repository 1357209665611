import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import omit from "lodash/omit";
import { call, select, takeEvery } from "typed-redux-saga";
import { getMutationParams } from "../../../utils/saga-helpers";
import { makeSelectPlacedImages, makeSelectUploadedImages } from "../selectors";
import { actions } from "../slice";
import * as API from "./image-helpers/services";
function onUpdatePlacedImage(action) {
    var newPlacedImage, ref, meta, apollo, placedImages, uploadedImages, existImage, updatedImage, _tmp, error;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                newPlacedImage = action.payload;
                _state.label = 1;
            case 1:
                _state.trys.push([
                    1,
                    6,
                    ,
                    7
                ]);
                return [
                    5,
                    _ts_values(getMutationParams())
                ];
            case 2:
                ref = _state.sent(), meta = ref.meta, apollo = ref.apollo;
                if (meta.viewOnly) return [
                    2
                ];
                return [
                    5,
                    _ts_values(select(makeSelectPlacedImages))
                ];
            case 3:
                placedImages = _state.sent();
                return [
                    5,
                    _ts_values(select(makeSelectUploadedImages))
                ];
            case 4:
                uploadedImages = _state.sent();
                existImage = _to_consumable_array(uploadedImages).concat(_to_consumable_array(placedImages)).find(function(uploadedImage) {
                    return uploadedImage.id === newPlacedImage.defaultImageId || uploadedImage.id === newPlacedImage.id;
                });
                if (!existImage) return [
                    2
                ];
                _tmp = {};
                updatedImage = _object_spread(_tmp, existImage, omit(newPlacedImage, [
                    "base64",
                    "srcUrl"
                ]));
                return [
                    5,
                    _ts_values(call(API.updateImage, updatedImage, meta, apollo))
                ];
            case 5:
                _state.sent();
                console.log("[ImageEngine] onUpdatePlacedImage => ", updatedImage);
                return [
                    3,
                    7
                ];
            case 6:
                error = _state.sent();
                console.error("[ImageEngine] onUpdatePlacedImage Error => ", error);
                return [
                    3,
                    7
                ];
            case 7:
                return [
                    2
                ];
        }
    });
}
/**
 * Watch update image event
 */ export function watchUpdatePlacedImage() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(takeEvery(actions.updatePlacedImage.type, onUpdatePlacedImage))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
