import { DashboardShopDefaultFontSettingsDocument, GetDesignWithRevisionDocument, GetProductDesignStorefrontDocument } from "@brikl/codegen";
export var getProductDesignQuery = function(apollo, variables) {
    return apollo.query({
        query: GetProductDesignStorefrontDocument,
        variables: variables,
        fetchPolicy: "no-cache"
    });
};
export var getProductQueryWithRevision = function(apollo, variables) {
    return apollo.query({
        query: GetDesignWithRevisionDocument,
        variables: variables,
        fetchPolicy: "no-cache"
    });
};
export var getShopInactiveDefaultFonts = function(apollo) {
    return apollo.query({
        query: DashboardShopDefaultFontSettingsDocument,
        fetchPolicy: "no-cache"
    });
};
