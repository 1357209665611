import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import isArray from "lodash/isArray";
import { all, put, takeLatest } from "typed-redux-saga";
import { actions as TemplateEngineActions } from "../../TemplateEngine/slice";
import { actions } from "../slice";
import { get3DElements } from "../transforms/get-3d-elements";
// Load color zones from design templates
function onInitialColorZones(action) {
    var templates, makeColors, colorZones;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                templates = action.payload.templates;
                if (!isArray(templates)) return [
                    2
                ];
                makeColors = function(colors) {
                    return colors.filter(function(color) {
                        return color.hex;
                    });
                };
                colorZones = templates.flatMap(function(template) {
                    var templateColorZones = template.colorZones || [];
                    return templateColorZones.map(function(colorZone) {
                        return _object_spread_props(_object_spread({}, colorZone), {
                            colors: makeColors(colorZone.colors),
                            templateId: template.id
                        });
                    });
                });
                // Create Color zones data
                return [
                    5,
                    _ts_values(put(actions.initialColorZonesCompleted(colorZones)))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
// Load color zones for 3d elements from 3d elements
function onInitial3DElement(action) {
    var latestElements, colorZones3DElements;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                latestElements = action.payload;
                colorZones3DElements = get3DElements(latestElements);
                return [
                    5,
                    _ts_values(put(actions.initialColorZones3DElementsCompleted(colorZones3DElements)))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
export function watchInitialColorZones() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(all([
                        takeLatest(TemplateEngineActions.loadTemplatesCompleted, onInitialColorZones),
                        takeLatest(actions.initialColorZones3DElements.type, onInitial3DElement)
                    ]))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
