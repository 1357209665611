import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { all, put, takeEvery } from "typed-redux-saga";
import { actions as DesignStepAction } from "../DesignStepEngine/slice";
import { watchOnInitialPlaceholder } from "./sagas/create-placeholder-saga";
import { watchUploadPlaceholderImage } from "./sagas/image-placeholder.saga";
import { loadPlaceholders } from "./sagas/load-placeholder-saga";
import { watchUpdatePlaceholder } from "./sagas/update-placeholder.saga";
import { actions } from "./slice";
function watchDesignStep() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(takeEvery(DesignStepAction.selectStep, function() {
                        return put(actions.clearSelection());
                    }))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
/**
 * Root saga manages watcher lifecycle
 */ export default function rootSaga() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    all([
                        // load placeholders
                        loadPlaceholders(),
                        // Watch upload/place/delete placeholder
                        watchUploadPlaceholderImage(),
                        // Watch update placeholder
                        watchUpdatePlaceholder(),
                        // Watch design step
                        watchDesignStep(),
                        // Watch on create initial placeholders
                        watchOnInitialPlaceholder()
                    ])
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
};
