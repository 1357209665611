import uniqBy from "lodash/uniqBy";
import { makePrintingTechnique } from "~/libs/store/src/util/placeholder";
import { getFile } from "../..";
export var createImageLibrary = function(logoStep) {
    return {
        id: logoStep.id,
        fileName: getFile(logoStep.imageUrl).fileName,
        base64: logoStep.imageUrl,
        srcUrl: logoStep.imageUrl,
        isUploadFailed: false,
        isUploaded: true,
        isUploading: false
    };
};
/**
 * Get and transform printing techniques
 * @param designPlaceholders
 * @return printing techniques
 */ export function getPrintingTechniques(designPlaceholders, langCode) {
    var printingTechniques = uniqBy(designPlaceholders === null || designPlaceholders === void 0 ? void 0 : designPlaceholders.flatMap(function(designPlaceholder) {
        return designPlaceholder.printingTechniques;
    }), "id");
    return printingTechniques.map(function(placeholder) {
        return makePrintingTechnique(placeholder, langCode);
    });
}
