import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { FileDestination, UploadFileDocument } from "@brikl/codegen";
import { uploadToS3 } from "@brikl/store";
import { fixCDNFromS3 } from "@brikl/studio-utils";
import { getFile, S3GetFile } from "../../../helpers/image";
/** Uploads the print image to S3 via Apollo. */ export var uploadPrintImage = function() {
    var _ref = _async_to_generator(function(aop, meta, apollo) {
        return _ts_generator(this, function(_state) {
            return [
                2,
                new Promise(function(resolve, reject) {
                    var ownedByOrganisationId = meta.ownedByOrganisationId;
                    var file = aop.file;
                    var uploadFile = function(param) {
                        var variables = param.variables;
                        return apollo.mutate({
                            mutation: UploadFileDocument,
                            variables: variables
                        });
                    };
                    var files = [
                        file
                    ];
                    var prefix = [
                        "ownedByOrganisationId"
                    ];
                    var input = {
                        ownedByOrganisationId: ownedByOrganisationId
                    };
                    var dst = FileDestination.BoarditemPrint;
                    var onDone = function() {
                        var _ref = _async_to_generator(function(uploadResult) {
                            var ref, ref1, fileUrl, result, _tmp;
                            return _ts_generator(this, function(_state) {
                                fileUrl = S3GetFile(uploadResult === null || uploadResult === void 0 ? void 0 : (ref = uploadResult.data) === null || ref === void 0 ? void 0 : (ref1 = ref.uploadFile) === null || ref1 === void 0 ? void 0 : ref1.url).fileUrl;
                                _tmp = {};
                                result = (_tmp.id = aop.id, _tmp.file = file, _tmp.fileName = getFile(fileUrl).fileName, _tmp.srcUrl = fixCDNFromS3(getFile(fileUrl).fileUrl), _tmp.base64 = null, _tmp);
                                resolve(result);
                                return [
                                    2
                                ];
                            });
                        });
                        return function onDone(uploadResult) {
                            return _ref.apply(this, arguments);
                        };
                    }();
                    uploadToS3(files, prefix, input, dst, uploadFile, true, null, onDone).then().catch(reject);
                })
            ];
        });
    });
    return function uploadPrintImage(aop, meta, apollo) {
        return _ref.apply(this, arguments);
    };
}();
