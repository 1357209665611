import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { all, call, delay, put, takeEvery } from "typed-redux-saga";
import { FileDestination } from "@brikl/codegen";
import { uploadImage } from "../../../helpers/upload";
import { isImageFile } from "../../../utils/is-image-file";
import { getMutationParams } from "../../../utils/saga-helpers";
import { actions } from "../slice";
import * as uploadImageApiService from "./image-helpers/services";
export function onUploadImages(param) {
    var payload, uploadFiles, ref, apollo, meta, uploadFileParams, uploadedImages, someAreImages, error;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                payload = param.payload;
                _state.label = 1;
            case 1:
                _state.trys.push([
                    1,
                    8,
                    ,
                    9
                ]);
                uploadFiles = payload;
                return [
                    5,
                    _ts_values(getMutationParams())
                ];
            case 2:
                ref = _state.sent(), apollo = ref.apollo, meta = ref.meta;
                uploadFileParams = uploadFiles.map(function(param) {
                    var id = param.id, file = param.file, base64 = param.base64;
                    return {
                        id: id,
                        file: file,
                        base64: base64
                    };
                });
                return [
                    5,
                    _ts_values(all(uploadFileParams.map(function(image) {
                        return call(uploadImage, FileDestination.BoarditemLogo, image, meta, apollo);
                    })))
                ];
            case 3:
                uploadedImages = _state.sent();
                // Create image
                return [
                    5,
                    _ts_values(all(uploadedImages.map(function(image) {
                        return call(uploadImageApiService.createImage, image, meta, apollo);
                    })))
                ];
            case 4:
                _state.sent();
                someAreImages = uploadFileParams.some(function(uploadFile) {
                    return isImageFile(uploadFile.file.name);
                });
                if (!!someAreImages) return [
                    3,
                    6
                ];
                return [
                    4,
                    delay(10000)
                ];
            case 5:
                _state.sent();
                _state.label = 6;
            case 6:
                // update all uploaded and created images
                return [
                    5,
                    _ts_values(all(uploadedImages.map(function(image) {
                        return put(actions.uploadImageSuccess(image));
                    })))
                ];
            case 7:
                _state.sent();
                console.log("[ImageEngine] non-images uploaded successfully => ", uploadedImages);
                return [
                    3,
                    9
                ];
            case 8:
                error = _state.sent();
                console.error("[ImageEngine] onUploadImages => ", error);
                return [
                    3,
                    9
                ];
            case 9:
                return [
                    2
                ];
        }
    });
}
/**
 * Watch upload image event
 */ export function watchUploadImages() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    takeEvery(actions.uploadImages.type, onUploadImages)
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
