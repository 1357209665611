import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { useCallback } from "react";
import * as THREE from "three";
import { fixCDN } from "~/libs/studio-utils/src";
import { getMaterial, prepareApplyNormalMap } from "./utils";
var normalMapCache = new Map();
export function useSwitchNormalMap(scene) {
    var switchNormalMap = useCallback(function(normalMapName, normalMapConfig, applyAllMesh) {
        var textureLoader = new THREE.TextureLoader();
        textureLoader.crossOrigin = "anonymous";
        var applyNormalmap = function(texture) {
            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
            scene.traverse(function(objects) {
                if (!_instanceof(objects, THREE.Group)) return;
                objects.traverse(function() {
                    var _ref = _async_to_generator(function(child) {
                        var materials, _iteratorNormalCompletion, _didIteratorError, _iteratorError, _iterator, _step, material, err;
                        return _ts_generator(this, function(_state) {
                            switch(_state.label){
                                case 0:
                                    if (!_instanceof(child, THREE.Mesh)) return [
                                        2
                                    ];
                                    materials = getMaterial(child.material);
                                    _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
                                    _state.label = 1;
                                case 1:
                                    _state.trys.push([
                                        1,
                                        6,
                                        7,
                                        8
                                    ]);
                                    _iterator = materials[Symbol.iterator]();
                                    _state.label = 2;
                                case 2:
                                    if (!!(_iteratorNormalCompletion = (_step = _iterator.next()).done)) return [
                                        3,
                                        5
                                    ];
                                    material = _step.value;
                                    if (!(material.name === normalMapName || applyAllMesh)) return [
                                        3,
                                        4
                                    ];
                                    material.normalMap = texture;
                                    return [
                                        4,
                                        prepareApplyNormalMap(material, normalMapConfig)
                                    ];
                                case 3:
                                    _state.sent();
                                    material.needsUpdate = true;
                                    _state.label = 4;
                                case 4:
                                    _iteratorNormalCompletion = true;
                                    return [
                                        3,
                                        2
                                    ];
                                case 5:
                                    return [
                                        3,
                                        8
                                    ];
                                case 6:
                                    err = _state.sent();
                                    _didIteratorError = true;
                                    _iteratorError = err;
                                    return [
                                        3,
                                        8
                                    ];
                                case 7:
                                    try {
                                        if (!_iteratorNormalCompletion && _iterator.return != null) {
                                            _iterator.return();
                                        }
                                    } finally{
                                        if (_didIteratorError) {
                                            throw _iteratorError;
                                        }
                                    }
                                    return [
                                        7
                                    ];
                                case 8:
                                    return [
                                        2
                                    ];
                            }
                        });
                    });
                    return function(child) {
                        return _ref.apply(this, arguments);
                    };
                }());
            }) // end scene.traverse
            ;
        };
        var isHitCached = normalMapCache.get(normalMapConfig.src);
        if (isHitCached) {
            applyNormalmap(isHitCached);
        } else {
            textureLoader.load(fixCDN(normalMapConfig === null || normalMapConfig === void 0 ? void 0 : normalMapConfig.src), function(texture) {
                applyNormalmap(texture);
                normalMapCache.set(normalMapConfig.src, texture);
            }) // end textureLoader
            ;
        }
    }, [
        scene
    ]);
    return {
        switchNormalMap: switchNormalMap
    };
}
