import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { call, delay, put, select, takeLatest } from "typed-redux-saga";
import { getMutationParams } from "../../../utils/saga-helpers";
import { updateZoneCustomization } from "../../../utils/update-zone-customization";
import { selectSelectedColorZone } from "../../ColorFillEngine/selectors";
import { actions as colorFillEngineActions } from "../../ColorFillEngine/slice";
import { selectSelectedZone3DElementId, selectSelectedZoneId } from "../../SceneEngine/selectors";
import { selectStateCustomTemplateAops } from "../selectors";
import { actions } from "../slice";
import { customTemplateToZoneCustomizationInput } from "../transforms/custom-template-to-aop-input";
function updateProductAop(customizationInput) {
    var ref, apollo, meta, _tmp, _tmp1;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(getMutationParams())
                ];
            case 1:
                ref = _state.sent(), apollo = ref.apollo, meta = ref.meta;
                _tmp = {};
                console.log("[AOP] Updating Product AOP:", (_tmp.customizationInput = customizationInput, _tmp));
                return [
                    5,
                    _ts_values(call(updateZoneCustomization, customizationInput, apollo, meta))
                ];
            case 2:
                _state.sent();
                _tmp1 = {};
                console.log("[AOP] Product AOP Updated:", (_tmp1.customizationInput = customizationInput, _tmp1));
                return [
                    2
                ];
        }
    });
}
export function onUpdateAop(action) {
    var aop, zoneCustomizationInput, _tmp;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                aop = action.payload;
                if (!aop) return [
                    2
                ];
                zoneCustomizationInput = customTemplateToZoneCustomizationInput(aop);
                _tmp = {};
                console.log("[AOP] Updating Product zoneCustomizationInput:", (_tmp.zoneCustomizationInput = zoneCustomizationInput, _tmp));
                if (!zoneCustomizationInput.zoneId) return [
                    2
                ];
                return [
                    5,
                    _ts_values(call(updateProductAop, zoneCustomizationInput))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
export function onUpdateTransformAop(param) {
    var payload, aopId, transform, newAop, stateCustomTemplateAops, stateCustomTemplateAop, zoneCustomizationInput, _tmp, _tmp1, _tmp2;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                payload = param.payload;
                // waits for 1000ms before performing its logic
                return [
                    5,
                    _ts_values(delay(1000))
                ];
            case 1:
                _state.sent();
                aopId = payload.id;
                transform = payload.transform;
                newAop = payload.aop;
                return [
                    5,
                    _ts_values(select(selectStateCustomTemplateAops))
                ];
            case 2:
                stateCustomTemplateAops = _state.sent();
                stateCustomTemplateAop = stateCustomTemplateAops.find(function(ctAop) {
                    return (ctAop === null || ctAop === void 0 ? void 0 : ctAop.id) === aopId;
                });
                _tmp = {};
                _tmp1 = {};
                zoneCustomizationInput = customTemplateToZoneCustomizationInput(_object_spread_props(_object_spread(_tmp, stateCustomTemplateAop, newAop), (_tmp1.transform = transform, _tmp1)));
                _tmp2 = {};
                console.log("[AOP] Updating/Transform Product zoneCustomizationInput:", (_tmp2.zoneCustomizationInput = zoneCustomizationInput, _tmp2));
                if (!zoneCustomizationInput.zoneId) return [
                    2
                ];
                return [
                    5,
                    _ts_values(call(updateProductAop, zoneCustomizationInput))
                ];
            case 3:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
/**
 * delete aop on current template
 */ function onDeleteAop() {
    var ref, ref1, selectedZone3DElementId, selectedZone2dId, selectedColorZone, defaultColorId, _tmp, _tmp1;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(select(selectSelectedZone3DElementId))
                ];
            case 1:
                selectedZone3DElementId = _state.sent();
                return [
                    5,
                    _ts_values(select(selectSelectedZoneId))
                ];
            case 2:
                selectedZone2dId = _state.sent();
                return [
                    5,
                    _ts_values(select(selectSelectedColorZone))
                ];
            case 3:
                selectedColorZone = _state.sent();
                defaultColorId = selectedColorZone === null || selectedColorZone === void 0 ? void 0 : (ref = selectedColorZone.defaultColor) === null || ref === void 0 ? void 0 : ref.id;
                if (!defaultColorId) return [
                    2
                ];
                _tmp = {};
                return [
                    5,
                    _ts_values(put(actions.deleteByZoneId((_tmp.zoneId = selectedZone3DElementId !== null && selectedZone3DElementId !== void 0 ? selectedZone3DElementId : selectedZone2dId, _tmp))))
                ];
            case 4:
                _state.sent();
                _tmp1 = {};
                // reset before apply default color
                return [
                    5,
                    _ts_values(put(colorFillEngineActions.deleteColorByZoneId((_tmp1.zoneId = selectedColorZone === null || selectedColorZone === void 0 ? void 0 : (ref1 = selectedColorZone.zone) === null || ref1 === void 0 ? void 0 : ref1.zoneId, _tmp1))))
                ];
            case 5:
                _state.sent();
                return [
                    5,
                    _ts_values(put(colorFillEngineActions.selectColor(defaultColorId)))
                ];
            case 6:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
export function watchUpdateAop() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(takeLatest(actions.updateAop.type, onUpdateAop))
                ];
            case 1:
                _state.sent();
                return [
                    5,
                    _ts_values(takeLatest(actions.deleteAop.type, onDeleteAop))
                ];
            case 2:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
// To debounce a sequence, put the built-in delay helper in the forked task:
export function watchUpdateTransformAop() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(takeLatest(actions.updateAopTransform.type, onUpdateTransformAop))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
