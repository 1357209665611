import { configureStore } from "@reduxjs/toolkit";
import { createInjectorsEnhancer } from "redux-injectors";
import createSagaMiddleware from "redux-saga";
import createReducer from "./reducers";
var isDev = "production" === "development";
function configureAppStore() {
    var preloadedState = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    var middlewares = [];
    var reduxSagaMonitorOptions = {};
    var sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
    // Create the store with saga middleware
    var runSaga = sagaMiddleware.run;
    middlewares.push(sagaMiddleware);
    var enhancers = [
        createInjectorsEnhancer({
            createReducer: createReducer,
            runSaga: runSaga
        })
    ];
    return configureStore({
        reducer: createReducer(),
        middleware: middlewares,
        devTools: isDev,
        preloadedState: preloadedState,
        enhancers: enhancers
    });
}
export default configureAppStore;
