export var selectAop = function(state, param) {
    var selectedAopId = param.payload;
    state.selectedAopId = selectedAopId;
};
export var focusAop = function(state, param) {
    var selectedAopId = param.payload;
    state.selectedAopId = selectedAopId;
};
export var deleteAop = function(state, param) {
    var selectedAopId = param.payload;
    state.selectedAopId = null;
};
