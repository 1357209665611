import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { useEffect } from "react";
import last from "lodash/last";
import orderBy from "lodash/orderBy";
import { getUserAgent } from "@brikl/store";
import { StudioActions, useStudio } from "@brikl/studio";
import { isImageCustomizationMode } from "@brikl/studio-utils";
import { useFabricCanvasRef } from "../../_fabric/useFabricCanvas";
import { getQualityList, setQualityControl } from "../../_fabric/util";
export var useDefaultTemplateDimension = function() {
    var fabricCanvasRef = useFabricCanvasRef();
    var fabricCanvas = fabricCanvasRef.current;
    var ref = _sliced_to_array(useStudio(), 2), studio = ref[0], dispatch = ref[1];
    // at this moment for safari only to set as lowest dimension
    var isSafari = getUserAgent().includes("Safari");
    useEffect(function() {
        if (!studio.isCanvasReady || isImageCustomizationMode() || studio.only2D) return;
        var qualityOptions = orderBy(getQualityList(fabricCanvas) || [], function(quality) {
            return quality.value;
        }, "desc");
        var defaultColorIndex = qualityOptions.length > 1 ? qualityOptions.length - 2 // Medium
         : qualityOptions.length - 1 // Low
        ;
        if (!qualityOptions[defaultColorIndex]) return; // some products already had low dimension( <= 2500)
        var defaultQualityValue = isSafari ? last(qualityOptions) : qualityOptions[defaultColorIndex];
        var quality = defaultQualityValue.quality;
        setQualityControl({
            option: quality,
            fabricCanvas: fabricCanvas
        });
        dispatch({
            type: StudioActions.SET_QUALITY_VALUE,
            payload: quality
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        studio.isCanvasReady
    ]);
};
