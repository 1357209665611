import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import pick from "lodash/pick";
import { createSlice } from "../../@redux";
export var initialState = {
    shopId: null,
    productId: null,
    revisionNo: 1,
    productDesignId: null,
    ownedByOrganisationId: null,
    viewOnly: false,
    langCode: null,
    salesChannelId: null,
    fabrics: [],
    disclaimerUrl: null,
    currency: null,
    countryCode: null,
    thankYouSlug: null,
    isDesignRoom: false,
    isReady: false
};
export var slice = createSlice({
    name: "metadata",
    initialState: initialState,
    reducers: {
        resetData: function() {
            return initialState;
        },
        setMetadata: function(state, param) {
            var payload = param.payload;
            return _object_spread_props(_object_spread(_object_spread_props(_object_spread({}, state), {
                langCode: payload.storeData.langCode,
                salesChannelId: payload.storeData.salesChannelId
            }), pick(payload, [
                "isDesignRoom",
                "salesChannelId",
                "langCode",
                "currency",
                "countryCode",
                "shopId",
                "productId",
                "revisionNo",
                "productDesignId",
                "ownedByOrganisationId",
                "viewOnly",
                "fabrics",
                "disclaimerUrl",
                "thankYouSlug",
                "fabrics", 
            ])), {
                isReady: true
            });
        }
    }
});
export var actions = slice.actions, reducer = slice.reducer, name = slice.name;
