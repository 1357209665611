import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import * as Sentry from "@sentry/browser";
import { svgParser } from "@brikl/studio-utils";
var AI_UNUSED_ELEMENT = [
    "#adobe_illustrator_pgf",
    "foreignObject"
];
var INVISIBLE_ELEMENT = [
    "title",
    "desc"
];
var UNUSED_ELEMENT = _to_consumable_array(INVISIBLE_ELEMENT);
export function removeUnusedElement(param) {
    var svgDoc = param.svgDoc, isRemoveAIElement = param.isRemoveAIElement;
    if (isRemoveAIElement) {
        var _UNUSED_ELEMENT;
        (_UNUSED_ELEMENT = UNUSED_ELEMENT).push.apply(_UNUSED_ELEMENT, _to_consumable_array(AI_UNUSED_ELEMENT));
    }
    svgDoc.querySelectorAll(UNUSED_ELEMENT.join(",")).forEach(function(element) {
        return element.remove();
    });
}
function cleanupElementId(svgDoc) {
    var listColorZoneElements = svgDoc.querySelectorAll("[id^='COLORZONE']");
    listColorZoneElements.forEach(function(colorZoneElement) {
        colorZoneElement.querySelectorAll("*:not(linearGradient):not(stop):not(defs):not(clipPath):not(defs > *)").forEach(function(element) {
            if (element.id) {
                element.setAttribute("data-id", element.id);
            }
            element.removeAttribute("id");
        });
    });
}
export function cleanupDesignTemplate(param) {
    var svgText = param.svgText, _isRemoveAIElement = param.isRemoveAIElement, isRemoveAIElement = _isRemoveAIElement === void 0 ? false : _isRemoveAIElement;
    var svgDoc = svgParser(svgText);
    try {
        // remove unused elements
        removeUnusedElement({
            svgDoc: svgDoc,
            isRemoveAIElement: isRemoveAIElement
        });
        // cleanup id attributes from each color zone
        cleanupElementId(svgDoc);
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
    }
    var serializer = new XMLSerializer();
    var svgString = serializer.serializeToString(svgDoc);
    return new Blob([
        svgString
    ], {
        type: "image/svg+xml;charset=utf-8"
    });
}
