import trim from "lodash/trim";
import uniq from "lodash/uniq";
import { generateVariableFontFaceDeclaration } from "~/libs/store/src/util/font-face";
import { GOOGLE_FONTS } from "~/libs/studio-engine/src/containers/TextEngine/constants";
export function getSvgInnerContent(svg) {
    return svg.slice(svg.indexOf("<svg"));
}
/**
 * Find colorzone id from string
 *
 * @param {string} id  color zone id eg: COLORZONE-ID
 * @return null not id not existed or return correct COLORZONE-ID
 */ export var getCanvasColorZoneId = function(id) {
    var pattern = /(COLORZONE-+[\d]+)/g;
    var match = id === null || id === void 0 ? void 0 : id.match(pattern);
    if (!match) return null;
    return match[0];
};
export var toLowerCase = function(s) {
    return s.map(function(y) {
        return y.toLowerCase();
    });
};
export function checkFontFamilyOnOriginalTemplate(originalSVG, fontFamilyNames) {
    var virtualRawSVGDiv = svgToHTMLElement(originalSVG);
    var allTextDom = getAllTextElementFromDom(virtualRawSVGDiv);
    var allTextFontFamily = getAllFontsFamiliesFromTextElements(allTextDom);
    return uniq(allTextFontFamily).filter(function(fontFamilyName) {
        return !toLowerCase(fontFamilyNames).includes(fontFamilyName.toLowerCase());
    });
}
export function svgToHTMLElement(svgString) {
    var divElement = document.createElement("div");
    divElement.innerHTML = getSvgInnerContent(svgString);
    return divElement;
}
function getAllTextElementFromDom(domElement) {
    return domElement.querySelectorAll("Text");
}
export function getAllFontsFamiliesFromTextElements(allTextDom) {
    var allTextFontFamily = [];
    allTextDom.forEach(function(text) {
        var ref;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        var fontFamilyString = (ref = text.style) === null || ref === void 0 ? void 0 : ref.fontFamily;
        if (!fontFamilyString) return;
        var splitFont = fontFamilyString.split(",").map(function(f) {
            return trim(f);
        });
        allTextFontFamily = allTextFontFamily.concat(splitFont);
    });
    return allTextFontFamily;
}
export function getScaledHeight(fabricCanvas) {
    return fabricCanvas.getHeight() / fabricCanvas.getZoom();
}
export function getScaledWidth(fabricCanvas) {
    return fabricCanvas.getWidth() / fabricCanvas.getZoom();
}
export function addFontsToSvgElement(svgElement) {
    var fonts = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : [];
    var ref, ref1;
    var wrapperElement = document.createElement("div");
    var styleElement = document.createElement("style");
    // google fonts - available for all shops
    var googleFonts = GOOGLE_FONTS.map(function(fontUrl) {
        return '@import url("'.concat(fontUrl.replace(/;/g, ",").replace(/&/g, "%7C"), '");');
    });
    // font from font library
    var shopFonts = fonts.flatMap(function(font) {
        return font.fontVariants.map(function(fontVariant) {
            return generateVariableFontFaceDeclaration(fontVariant, fontVariant.fontName);
        });
    });
    styleElement.innerHTML = "\n  ".concat(googleFonts.join(""), "\n  ").concat(shopFonts.join(" "), "\n  ");
    wrapperElement.innerHTML = getSvgInnerContent(svgElement);
    (ref = wrapperElement.querySelector("svg")) === null || ref === void 0 ? void 0 : ref.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
    (ref1 = wrapperElement.querySelector("svg")) === null || ref1 === void 0 ? void 0 : ref1.prepend(styleElement);
    return wrapperElement;
}
export function getSvgSizeTemplate() {
    if (!document.querySelector("#sizeSVG")) {
        console.warn("NO SIZE TEMPLATE EXIST");
        return;
    }
    // Parse svg str to dom
    var sizeSVGStr = document.querySelector("#sizeSVG").innerHTML;
    var svgAsDom = svgToHTMLElement(sizeSVGStr);
    if (!svgAsDom.children.length) return;
    return svgAsDom;
}
