import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { all } from "redux-saga/effects";
import { watchLoadAndUpdateDesignPrice } from "./sagas/designprice.saga";
export default function rootSaga() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    all([
                        watchLoadAndUpdateDesignPrice()
                    ])
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
};
