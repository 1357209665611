import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
export var selectColor = function(state, action) {
    state.selectedColorId = action.payload;
};
export var setTemplateColor = function(state, param) {
    var payload = param.payload;
    var index = state.customTemplateColors.findIndex(function(ctColor) {
        return (ctColor === null || ctColor === void 0 ? void 0 : ctColor.id) === payload.id;
    });
    if (index !== -1) {
        state.customTemplateColors[index] = payload;
    } else {
        state.customTemplateColors = _to_consumable_array(state.customTemplateColors).concat([
            payload
        ]);
    }
};
export var setErrorForTemplateColor = function(state, action) {
    state.templateColorError = action.payload.error;
};
export var setColorZone3DElementTemplateColor = function(state, param) {
    var payload = param.payload;
    var index = state.customTemplateColor3DElements.findIndex(function(ctColor) {
        return ctColor.id === payload.id;
    });
    if (index !== -1) {
        state.customTemplateColor3DElements[index] = payload;
    } else {
        state.customTemplateColor3DElements = _to_consumable_array(state.customTemplateColor3DElements).concat([
            payload, 
        ]);
    }
};
export var deleteColorByZoneId = function(state, param) {
    var payload = param.payload;
    state.customTemplateColors = state.customTemplateColors.filter(function(colorFill) {
        return colorFill.zoneId !== payload.zoneId;
    });
    state.customTemplateColor3DElements = state.customTemplateColor3DElements.filter(function(colorFill) {
        return colorFill.zoneId !== payload.zoneId;
    });
};
