import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { all, call, delay, put, takeEvery } from "typed-redux-saga";
import { FileDestination } from "@brikl/codegen";
import { isNonImageType } from "../../../helpers/image";
import { uploadImage as uploadPattern } from "../../../helpers/upload";
import { getMutationParams } from "../../../utils/saga-helpers";
import { actions } from "../slice";
import * as API from "./pattern-helpers/services";
export function onUploadPatterns(param) {
    var uploadFiles, ref, apollo, meta, uploadFileParams, uploadedPatterns, someAreNonImages, error;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                uploadFiles = param.payload;
                _state.label = 1;
            case 1:
                _state.trys.push([
                    1,
                    8,
                    ,
                    9
                ]);
                return [
                    5,
                    _ts_values(getMutationParams())
                ];
            case 2:
                ref = _state.sent(), apollo = ref.apollo, meta = ref.meta;
                if (meta.viewOnly) return [
                    2
                ];
                uploadFileParams = uploadFiles.map(function(param) {
                    var id = param.id, file = param.file, base64 = param.base64;
                    return {
                        id: id,
                        file: file,
                        base64: base64
                    };
                });
                return [
                    5,
                    _ts_values(all(uploadFileParams.map(function(pattern) {
                        return call(uploadPattern, FileDestination.BoarditemLogo, pattern, meta, apollo);
                    })))
                ];
            case 3:
                uploadedPatterns = _state.sent();
                // Create pattern
                return [
                    5,
                    _ts_values(all(uploadedPatterns.map(function(pattern) {
                        return call(API.createPattern, pattern, meta, apollo);
                    })))
                ];
            case 4:
                _state.sent();
                console.log("[PatternEngine] patterns uploaded successfully => ", uploadedPatterns);
                someAreNonImages = uploadedPatterns.some(function(pattern) {
                    return isNonImageType(pattern.file);
                });
                if (!someAreNonImages) return [
                    3,
                    6
                ];
                // for non-patterns must delay 10s as we don't have upload status subscribe
                return [
                    4,
                    delay(10000)
                ];
            case 5:
                _state.sent();
                _state.label = 6;
            case 6:
                // update all uploaded and created patterns
                return [
                    5,
                    _ts_values(all(uploadedPatterns.map(function(pattern) {
                        return put(actions.uploadPatternSuccess(pattern));
                    })))
                ];
            case 7:
                _state.sent();
                return [
                    3,
                    9
                ];
            case 8:
                error = _state.sent();
                console.error("[PatternEngine] onUploadPatterns => ", error);
                return [
                    3,
                    9
                ];
            case 9:
                return [
                    2
                ];
        }
    });
}
/**
 * Watch upload pattern event
 */ export function watchUploadPatterns() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    takeEvery(actions.uploadPatterns.type, onUploadPatterns)
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
