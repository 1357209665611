import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { createSelector, createStructuredSelector } from "reselect";
var selectDomain = function(state) {
    return state.pattern;
};
export var makeSelectUploadedPatterns = createSelector([
    selectDomain
], function(state) {
    return (state === null || state === void 0 ? void 0 : state.uploadedPatterns) || [];
});
var getImageFromOrigin = function(uploadedPatterns, id) {
    var image = uploadedPatterns.find(function(image) {
        return image.id === id;
    });
    if (!image) return null;
    return image.base64 || image.srcUrl;
};
export var makeSelectPlacedPatterns = createSelector(makeSelectUploadedPatterns, selectDomain, function(uploadedPatterns, state) {
    var createPlacedPatterns = function(pattern) {
        var imageSrc = getImageFromOrigin(uploadedPatterns, pattern.defaultImageId);
        return _object_spread_props(_object_spread({}, pattern), {
            srcUrl: imageSrc || pattern.srcUrl,
            base64: imageSrc || pattern.base64
        });
    };
    return (state === null || state === void 0 ? void 0 : state.placedPatterns.map(createPlacedPatterns)) || [];
});
export var makeSelectDeletedPlacedImage = createSelector([
    selectDomain
], function(state) {
    return (state === null || state === void 0 ? void 0 : state.deletedPlacedPatterns) || [];
});
export var patternSelectors = createStructuredSelector({
    uploadedPatterns: makeSelectUploadedPatterns,
    placedPatterns: makeSelectPlacedPatterns,
    deletedPlacedPatterns: makeSelectDeletedPlacedImage
});
