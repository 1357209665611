import { createMutation, deleteMutation, updateMutation } from "./mutation";
import { mapObjectToMutationInput } from "./transform";
/**
 * Create text design revision
 *
 * @param params ICreateStudioStateSubscriberParams
 * @param newCanvasText FabricTextData
 */ export var createTextDesignRevision = function(text, meta, apollo) {
    if (!text) return false;
    var id = text.id;
    // Product design ID and Revision No
    var productDesignId = meta.productDesignId, revisionNo = meta.revisionNo;
    // Transform text input object
    var productDesignInput = mapObjectToMutationInput(text);
    // Execute Mutation
    return createMutation(apollo)({
        id: id,
        productDesignId: productDesignId,
        revisionNo: revisionNo,
        input: productDesignInput
    });
};
/**
 * Update text design revision
 *
 * @param params ICreateStudioStateSubscriberParams
 * @param updatedCanvasText FabricTextData
 */ export var updateTextDesignRevision = function(text, meta, apollo) {
    if (!text) return false;
    var id = text.id;
    // Product design ID and Revision No
    var productDesignId = meta.productDesignId, revisionNo = meta.revisionNo;
    // Transform text input object
    var productDesignInput = mapObjectToMutationInput(text);
    // Execute Mutation
    updateMutation(apollo)({
        id: id,
        productDesignId: productDesignId,
        revisionNo: revisionNo,
        input: productDesignInput
    });
};
/**
 * Delete text design revision
 *
 * @param params ICreateStudioStateSubscriberParams
 * @param id canvas id
 */ export var deleteTextDesignRevision = function(text, meta, apollo) {
    if (!text) return false;
    // Product design ID and Revision No
    var productDesignId = meta.productDesignId, revisionNo = meta.revisionNo;
    // Mutation
    return deleteMutation(apollo)({
        id: text.id,
        productDesignId: productDesignId,
        revisionNo: revisionNo
    });
};
