import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { all, put, select, takeEvery } from "typed-redux-saga";
import { getMutationParams } from "../../../utils/saga-helpers";
import { selectSelectedDesignStep } from "../../DesignStepEngine/selectors";
import { actions as imageEngineActions } from "../../ImageEngine/slice";
import { actions as extEngineActions } from "../../TextEngine/slice";
import { makeSelectSelectedPlaceholder } from "../selectors";
import { actions } from "../slice";
function onUpdatePrintingTechnique(action) {
    var newPlacedImage, meta, designStep, selectedPlaceholder, printingTechniqueId, payload, _tmp, error;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                newPlacedImage = action.payload;
                return [
                    5,
                    _ts_values(getMutationParams())
                ];
            case 1:
                meta = _state.sent().meta;
                if (meta.viewOnly) return [
                    2
                ];
                _state.label = 2;
            case 2:
                _state.trys.push([
                    2,
                    6,
                    ,
                    7
                ]);
                return [
                    5,
                    _ts_values(select(selectSelectedDesignStep))
                ];
            case 3:
                designStep = _state.sent();
                return [
                    5,
                    _ts_values(select(makeSelectSelectedPlaceholder))
                ];
            case 4:
                selectedPlaceholder = _state.sent();
                printingTechniqueId = newPlacedImage.printingTechniqueId;
                if (!selectedPlaceholder) return [
                    2
                ];
                _tmp = {};
                payload = (_tmp.designStepId = designStep.id, _tmp.placeholderSvgId = selectedPlaceholder.svgId, _tmp.placeholderId = selectedPlaceholder.id, _tmp.printingTechniqueId = printingTechniqueId, _tmp);
                return [
                    4,
                    put(actions.updateSelectedPrintingTechniques(payload))
                ];
            case 5:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 6:
                error = _state.sent();
                console.error("[PlaceholderEngine] onUpdatePrintingTechnique Error => ", error);
                return [
                    3,
                    7
                ];
            case 7:
                return [
                    2
                ];
        }
    });
}
/**
 * Watch update placeholder
 */ export function watchUpdatePlaceholder() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(all([
                        takeEvery(imageEngineActions.updatePlacedImage.type, onUpdatePrintingTechnique),
                        takeEvery(extEngineActions.updateText, onUpdatePrintingTechnique)
                    ]))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
