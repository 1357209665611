import _object_spread from "@swc/helpers/src/_object_spread.mjs";
export var updatePlacedImage = function(state, param) {
    var updatedImage = param.payload;
    var ref;
    var placedImageIndex = state === null || state === void 0 ? void 0 : (ref = state.placedImages) === null || ref === void 0 ? void 0 : ref.findIndex(function(image) {
        return image.id === updatedImage.id;
    });
    if (placedImageIndex !== -1) {
        state.placedImages[placedImageIndex] = _object_spread({}, state.placedImages[placedImageIndex], updatedImage);
    }
};
