import { createSlice } from "../../@redux";
import { initialState } from "./constants";
import { deleteColorByZoneId, selectColor, setColorZone3DElementTemplateColor, setErrorForTemplateColor, setTemplateColor } from "./slices/apply-color.actions";
export var slice = createSlice({
    name: "colorFill",
    initialState: initialState,
    reducers: {
        selectColor: selectColor,
        setTemplateColor: setTemplateColor,
        setColorZone3DElementTemplateColor: setColorZone3DElementTemplateColor,
        deleteColorByZoneId: deleteColorByZoneId,
        setErrorForTemplateColor: setErrorForTemplateColor,
        resetData: function resetData() {
            return initialState;
        },
        resetCustomColors: function resetCustomColors(state) {
            state.customTemplateColor3DElements = [];
            state.customTemplateColors = [];
        },
        setIsAllColorApplied: function setIsAllColorApplied(state) {
            state.isAppliedAllColor = true;
        },
        initialColorZones: function initialColorZones(state, action) {
        // no action required
        },
        initialColorZonesCompleted: function initialColorZonesCompleted(state, param) {
            var payload = param.payload;
            state.colorZones = payload;
            state.isReady = true;
        },
        initialColorZones3DElements: function initialColorZones3DElements(state, action) {
        // no action required
        },
        initialColorZones3DElementsCompleted: function initialColorZones3DElementsCompleted(state, param) {
            var payload = param.payload;
            state.colorZones3DElements = payload;
            state.isReady = true;
        },
        initialCustomTemplateColors: function initialCustomTemplateColors(state, param) {
            var payload = param.payload;
            state.customTemplateColors = payload;
        },
        initialCustomTemplateColors3DElements: function initialCustomTemplateColors3DElements(state, param) {
            var payload = param.payload;
            state.customTemplateColor3DElements = payload;
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        resetZoneColor: function resetZoneColor(_, action) {}
    }
});
export var reducer = slice.reducer, name = slice.name, actions = slice.actions;
