import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
export function updateAop(state, param) {
    var payload = param.payload;
    if (!payload) return;
    var customTemplateAopIndex = state.customTemplateAops.findIndex(function(customTemplateAop) {
        return (customTemplateAop === null || customTemplateAop === void 0 ? void 0 : customTemplateAop.id) === payload.id;
    });
    // then update it
    if (customTemplateAopIndex !== -1) {
        state.customTemplateAops[customTemplateAopIndex] = payload;
    // otherwise add new AOP
    } else {
        state.customTemplateAops = _to_consumable_array(state.customTemplateAops).concat([
            payload
        ]);
    }
}
export var updateAopTransform = function(state, param) {
    var payload = param.payload;
    if (!payload) return;
    var customTemplateAopIndex = state.customTemplateAops.findIndex(function(customTemplateAop) {
        return (customTemplateAop === null || customTemplateAop === void 0 ? void 0 : customTemplateAop.id) === payload.id;
    });
    if (customTemplateAopIndex !== -1) {
        state.customTemplateAops[customTemplateAopIndex] = _object_spread_props(_object_spread({}, state.customTemplateAops[customTemplateAopIndex]), {
            transform: payload.transform
        });
    }
};
