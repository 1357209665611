import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
export var loadImages = function(state, param) {
    var payload = param.payload;
    state.isLoading = true;
};
export var loadImagesSuccess = function(state, param) {
    var payload = param.payload;
    var loaded = function(image) {
        return _object_spread_props(_object_spread({}, image), {
            isUploading: false,
            isUploaded: true,
            isUploadFailed: false
        });
    };
    state.placedImages = payload.placedImages;
    state.uploadedImages = payload.uploadedImages.map(loaded);
    state.imageLibrary = payload.imageLibrary;
    state.isLoading = false;
    state.isReady = true;
};
export var loadSelectedTemplateImages = function(state, param) {
    var assignedPlaceholderIds = param.payload;
    state.placedImages = state.placedImages.filter(function(image) {
        return assignedPlaceholderIds.includes(image.placeholderId);
    });
};
