import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
export var deleteText = function(state, param) {
    var payload = param.payload;
    var id = payload.id;
    state.placedTexts = state.placedTexts.filter(function(placedText) {
        return placedText.id !== id;
    });
    state.selectedTextId = null;
    state.deletedPlacedTexts = _to_consumable_array(state.deletedPlacedTexts).concat([
        {
            id: id
        }
    ]);
};
