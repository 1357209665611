import { CreateTextDesignRevisionDocument, DeleteTextDesignRevisionDocument, UpdateTextDesignRevisionDocument } from "@brikl/codegen";
/**
 *
 * @param apollo ApolloClient
 */ export var createMutation = function(apollo) {
    return function(param) {
        var id = param.id, productDesignId = param.productDesignId, revisionNo = param.revisionNo, input = param.input;
        return apollo.mutate({
            mutation: CreateTextDesignRevisionDocument,
            variables: {
                id: id,
                productDesignId: productDesignId,
                revisionNo: revisionNo,
                input: input
            }
        });
    };
};
/**
 *
 * @param apollo ApolloClient
 */ export var updateMutation = function(apollo) {
    return function(param) {
        var id = param.id, productDesignId = param.productDesignId, revisionNo = param.revisionNo, input = param.input;
        return apollo.mutate({
            mutation: UpdateTextDesignRevisionDocument,
            variables: {
                id: id,
                productDesignId: productDesignId,
                revisionNo: revisionNo,
                input: input
            }
        });
    };
};
/**
 *
 * @param apollo ApolloClient
 */ export var deleteMutation = function(apollo) {
    return function(param) {
        var id = param.id, productDesignId = param.productDesignId, revisionNo = param.revisionNo;
        return apollo.mutate({
            mutation: DeleteTextDesignRevisionDocument,
            variables: {
                id: id,
                productDesignId: productDesignId,
                revisionNo: revisionNo
            }
        });
    };
};
