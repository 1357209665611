import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { all, put, takeEvery } from "typed-redux-saga";
import { watchSelectSVGElement } from "./sagas/select-scene.saga";
import { actions } from "./slice";
function onSelectZone(action) {
    var selectedZoneId;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                selectedZoneId = action.payload;
                if (!selectedZoneId) return [
                    2
                ];
                if (!selectedZoneId.includes("_ELM3D")) return [
                    3,
                    2
                ];
                return [
                    5,
                    _ts_values(put(actions.selectZone3DElement(selectedZoneId)))
                ];
            case 1:
                _state.sent();
                return [
                    3,
                    4
                ];
            case 2:
                return [
                    5,
                    _ts_values(put(actions.selectZone3DElement(null)))
                ];
            case 3:
                _state.sent();
                _state.label = 4;
            case 4:
                return [
                    5,
                    _ts_values(put(actions.selectZoneCompleted(selectedZoneId)))
                ];
            case 5:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
function watchSelectZone() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(takeEvery(actions.selectZone.type, onSelectZone))
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
export default function rootSaga() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    all([
                        watchSelectZone(),
                        watchSelectSVGElement()
                    ])
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
};
