/**
 * Calculate mouse X and Y based on the element dimensions
 * @param  {number} offsetX
 * @param  {number} offsetY
 * @param  {number} width
 * @param  {number} height
 */ export function getMouseXY(param) {
    var offsetX = param.offsetX, offsetY = param.offsetY, width = param.width, height = param.height;
    return {
        x: offsetX / width * 2 - 1,
        y: -(offsetY / height) * 2 + 1
    };
}
/**
 * Calculates the X and Y coordinates based where you click in the 3d scene
 * We can use this coords to trigger events in the 2d canvas.
 * @param  {IGetUVCoordinatesFromIntersection} uvData uv coordinate from interection between mouse and 3d meshes
 * @returns pixelY
 */ export function getUVCoordinatesFromIntersection(param) {
    var intersection = param.intersection, width = param.width, height = param.height;
    var uv = intersection.uv;
    var pixelX = Math.round(uv.x * width);
    var pixelY = uv.y > 1 ? Math.round((uv.y - Math.round(uv.y)) * height - height) : Math.round(uv.y * height - height);
    while(pixelY <= -0.1){
        pixelY = pixelY * -1;
    }
    while(pixelX <= -0.1){
        pixelX = pixelX * -1;
        pixelX = width - pixelX;
    }
    while(pixelY > height){
        pixelY = pixelY - height;
    }
    while(pixelX > width){
        pixelX = pixelX - width;
    }
    return {
        x: pixelX,
        y: pixelY
    };
}
