import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { createStandaloneToast } from "@chakra-ui/react";
import { dash } from "@brikl/design-core";
import { reportError } from "@brikl/shared-utils";
import { downloadFileToUser, getURLParams, isImageCustomizationMode } from "@brikl/studio-utils";
import { delay } from "~/apps/dashboard/src/util/delay";
import { createSvgEmbedData, drawPDF, getAopFromColorzone, getFillFromColorzone, getOriginalBaseTemplateSVG, getProductInfo, replaceColorzoneToOriginalSVG } from "./export";
import { addFontsToSvgElement, getScaledHeight, getScaledWidth, getSvgSizeTemplate } from "./export.utils";
import { useFabricCanvasRef } from "./useFabricCanvas";
import { addImageToCanvas, convertImageUrlToImageElement, getBaseSvgElement, getBlobFromCanvas, getOriginalSvgElement, setQualityByZoomLevel, setQualityControl } from "./util";
export default function useFabricCanvasExport() {
    var fabricCanvasRef = useFabricCanvasRef();
    function export2D(_) {
        return _export2D.apply(this, arguments);
    }
    function _export2D() {
        _export2D = _async_to_generator(function(param) {
            var productData, fileType, option, colorMode, _fontList, fontList, includeGrading, filename, _is2DProduct, is2DProduct, onWarning, fabricCanvas, currentZoom, _tmp, extendWidth, exportEvent, _tmp1, originalSvgElement, sizeSvgElement, _tmp2, _tmp3, _tmp4, _tmp5, imgData, _tmp6, productInfoB64, productInfoImage, _tmp7, _tmp8, templateImageElm, canvas, _tmp9, _tmp10, _tmp11, _tmp12;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        productData = param.productData, fileType = param.fileType, option = param.option, colorMode = param.colorMode, _fontList = param.fontList, fontList = _fontList === void 0 ? [] : _fontList, includeGrading = param.includeGrading, filename = param.filename, _is2DProduct = param.is2DProduct, is2DProduct = _is2DProduct === void 0 ? false : _is2DProduct, onWarning = param.onWarning;
                        fabricCanvas = fabricCanvasRef.current;
                        currentZoom = fabricCanvas.zoomLevel;
                        _tmp = {};
                        if (!isImageCustomizationMode() || is2DProduct) {
                            setQualityControl((_tmp.option = "ORIGINAL", _tmp.fabricCanvas = fabricCanvas, _tmp)) // return to original for export original quality
                            ;
                        }
                        return [
                            4,
                            delay(1000)
                        ];
                    case 1:
                        _state.sent();
                        extendWidth = !productData ? 0 : 650;
                        _tmp1 = {};
                        exportEvent = new CustomEvent("exportEvent", (_tmp1.bubbles = true, _tmp1));
                        if (!(fileType === "svg" || fileType === "pdf")) return [
                            3,
                            5
                        ];
                        originalSvgElement = getOriginalSvgElement();
                        sizeSvgElement = getSvgSizeTemplate();
                        if (!(fileType === "pdf")) return [
                            3,
                            3
                        ];
                        _tmp2 = {};
                        return [
                            4,
                            export2DAsPDF((_tmp2.svgElement = option === "size" ? sizeSvgElement : originalSvgElement, _tmp2.filename = filename, _tmp2.fontList = fontList, _tmp2.productData = productData, _tmp2.includeGrading = includeGrading, _tmp2.colorMode = colorMode, _tmp2.onWarning = onWarning, _tmp2))
                        ];
                    case 2:
                        _state.sent();
                        _tmp3 = {};
                        setQualityByZoomLevel((_tmp3.zoomLevel = currentZoom, _tmp3.fabricCanvas = fabricCanvas, _tmp3));
                        return [
                            2
                        ];
                    case 3:
                        _tmp4 = {};
                        export2dAsSvg((_tmp4.svgElement = option === "size" ? sizeSvgElement : originalSvgElement, _tmp4.filename = filename, _tmp4.fontList = fontList, _tmp4.productData = productData, _tmp4.includeGrading = includeGrading, _tmp4.colorMode = "RGB", _tmp4.onWarning = onWarning, _tmp4));
                        _tmp5 = {};
                        setQualityByZoomLevel((_tmp5.zoomLevel = currentZoom, _tmp5.fabricCanvas = fabricCanvas, _tmp5));
                        return [
                            2
                        ];
                    case 4:
                        return [
                            3,
                            13
                        ];
                    case 5:
                        _tmp6 = {};
                        imgData = fabricCanvas.toDataURL((_tmp6.multiplier = 1, _tmp6));
                        if (!imgData) return [
                            3,
                            13
                        ];
                        if (!productData) return [
                            3,
                            9
                        ];
                        _tmp7 = {};
                        return [
                            4,
                            getProductInfo((_tmp7.productData = productData, _tmp7.fabricCanvas = fabricCanvasRef.current, _tmp7))
                        ];
                    case 6:
                        // Merge productInfo to export design
                        productInfoB64 = _state.sent();
                        _tmp8 = productInfoB64;
                        if (!_tmp8) return [
                            3,
                            8
                        ];
                        return [
                            4,
                            convertImageUrlToImageElement(productInfoB64)
                        ];
                    case 7:
                        _tmp8 = _state.sent();
                        _state.label = 8;
                    case 8:
                        productInfoImage = _tmp8;
                        _state.label = 9;
                    case 9:
                        return [
                            4,
                            convertImageUrlToImageElement(imgData)
                        ];
                    case 10:
                        templateImageElm = _state.sent();
                        _tmp9 = {};
                        canvas = addImageToCanvas((_tmp9.image = templateImageElm, _tmp9.xAxis = 0, _tmp9.yAxis = 0, _tmp9.canvasHeight = getScaledHeight(fabricCanvas), _tmp9.canvasWidth = getScaledWidth(fabricCanvas) + extendWidth, _tmp9.width = getScaledWidth(fabricCanvas), _tmp9.height = getScaledHeight(fabricCanvas), _tmp9));
                        _tmp10 = {};
                        if (productInfoImage) {
                            addImageToCanvas((_tmp10.image = productInfoImage, _tmp10.xAxis = getScaledWidth(fabricCanvas), _tmp10.yAxis = 150, _tmp10.canvas = canvas, _tmp10));
                        }
                        if (!(option === "blob")) return [
                            3,
                            12
                        ];
                        window.dispatchEvent(exportEvent);
                        return [
                            4,
                            getBlobFromCanvas(canvas)
                        ];
                    case 11:
                        return [
                            2,
                            _state.sent()
                        ];
                    case 12:
                        _tmp11 = {};
                        downloadFileToUser((_tmp11.embedData = canvas.toDataURL(), _tmp11.filename = filename, _tmp11));
                        _tmp12 = {};
                        setQualityByZoomLevel((_tmp12.zoomLevel = currentZoom, _tmp12.fabricCanvas = fabricCanvas, _tmp12));
                        _state.label = 13;
                    case 13:
                        window.dispatchEvent(exportEvent);
                        return [
                            2
                        ];
                }
            });
        });
        return _export2D.apply(this, arguments);
    }
    function createTemplateSvg(_) {
        return _createTemplateSvg.apply(this, arguments);
    }
    function _createTemplateSvg() {
        _createTemplateSvg = _async_to_generator(function(param) {
            var svgElement, colorMode, fontList, productData, includeGrading, domStr, _tmp, _tmp1, gradingDom, gradingSVG, baseTemplateSvg, baseSvgElement, _tmp2;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        svgElement = param.svgElement, colorMode = param.colorMode, fontList = param.fontList, productData = param.productData, includeGrading = param.includeGrading;
                        _tmp = {};
                        return [
                            4,
                            getSvgElementWithColorZone((_tmp.svgElement = svgElement, _tmp.colorMode = colorMode, _tmp))
                        ];
                    case 1:
                        domStr = _state.sent();
                        domStr = addFontsToSvgElement(domStr, fontList).innerHTML;
                        if (!productData) return [
                            3,
                            3
                        ];
                        _tmp1 = {};
                        return [
                            4,
                            getProductInfo((_tmp1.productData = productData, _tmp1.svg = domStr, _tmp1.fabricCanvas = fabricCanvasRef.current, _tmp1))
                        ];
                    case 2:
                        domStr = _state.sent();
                        _state.label = 3;
                    case 3:
                        gradingDom = document.createElement("div");
                        gradingDom.innerHTML = domStr;
                        gradingSVG = gradingDom.querySelector("svg");
                        baseTemplateSvg = "";
                        if (includeGrading) {
                            baseSvgElement = getBaseSvgElement();
                            baseTemplateSvg = getOriginalBaseTemplateSVG(baseSvgElement.innerHTML);
                        }
                        _tmp2 = {};
                        return [
                            2,
                            (_tmp2.gradingSVG = gradingSVG, _tmp2.baseTemplateSvg = baseTemplateSvg, _tmp2)
                        ];
                }
            });
        });
        return _createTemplateSvg.apply(this, arguments);
    }
    function export2dAsSvg(_) {
        return _export2dAsSvg.apply(this, arguments);
    }
    function _export2dAsSvg() {
        _export2dAsSvg = _async_to_generator(function(param) {
            var svgElement, fontList, productData, includeGrading, colorMode, filename, gradingSVG, _tmp, _tmp1;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        svgElement = param.svgElement, fontList = param.fontList, productData = param.productData, includeGrading = param.includeGrading, colorMode = param.colorMode, filename = param.filename;
                        if (!svgElement) return [
                            2
                        ];
                        _tmp = {};
                        return [
                            4,
                            createTemplateSvg((_tmp.productData = productData, _tmp.includeGrading = includeGrading, _tmp.colorMode = colorMode, _tmp.fontList = fontList, _tmp.svgElement = svgElement, _tmp))
                        ];
                    case 1:
                        gradingSVG = _state.sent().gradingSVG;
                        _tmp1 = {};
                        downloadFileToUser((_tmp1.embedData = createSvgEmbedData(gradingSVG.outerHTML), _tmp1.filename = filename, _tmp1));
                        return [
                            2
                        ];
                }
            });
        });
        return _export2dAsSvg.apply(this, arguments);
    }
    function export2DAsPDF(_) {
        return _export2DAsPDF.apply(this, arguments);
    }
    function _export2DAsPDF() {
        _export2DAsPDF = _async_to_generator(function(param) {
            var svgElement, fontList, productData, includeGrading, colorMode, filename, onWarning, extendWidth, ref, gradingSVG, baseTemplateSvg, _tmp, _tmp1;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        svgElement = param.svgElement, fontList = param.fontList, productData = param.productData, includeGrading = param.includeGrading, colorMode = param.colorMode, filename = param.filename, onWarning = param.onWarning;
                        if (!svgElement) return [
                            2
                        ];
                        extendWidth = !productData ? 0 : 650;
                        _tmp = {};
                        return [
                            4,
                            createTemplateSvg((_tmp.productData = productData, _tmp.includeGrading = includeGrading, _tmp.colorMode = colorMode, _tmp.fontList = fontList, _tmp.svgElement = svgElement, _tmp))
                        ];
                    case 1:
                        ref = _state.sent(), gradingSVG = ref.gradingSVG, baseTemplateSvg = ref.baseTemplateSvg;
                        _tmp1 = {};
                        return [
                            4,
                            downloadAsPDF((_tmp1.svgStr = gradingSVG.outerHTML, _tmp1.baseTemplateSvg = baseTemplateSvg, _tmp1.filename = filename, _tmp1.fontList = fontList, _tmp1.extendWidth = extendWidth, _tmp1.includeGrading = includeGrading, _tmp1.onWarning = onWarning, _tmp1))
                        ];
                    case 2:
                        _state.sent();
                        return [
                            2
                        ];
                }
            });
        });
        return _export2DAsPDF.apply(this, arguments);
    }
    function downloadAsPDF(_) {
        return _downloadAsPDF.apply(this, arguments);
    }
    function _downloadAsPDF() {
        _downloadAsPDF = _async_to_generator(function(param) {
            var svgStr, baseTemplateSvg, filename, fontList, _extendWidth, extendWidth, includeGrading, onWarning, fabricCanvas, _$param, _tmp, ref, data, fontsError, _tmp1, error, toast, _tmp2, _tmp3;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        svgStr = param.svgStr, baseTemplateSvg = param.baseTemplateSvg, filename = param.filename, fontList = param.fontList, _extendWidth = param.extendWidth, extendWidth = _extendWidth === void 0 ? 0 : _extendWidth, includeGrading = param.includeGrading, onWarning = param.onWarning;
                        fabricCanvas = fabricCanvasRef.current;
                        _tmp = {};
                        _$param = (_tmp.svg = svgStr, _tmp.baseTemplateSVG = baseTemplateSvg, _tmp.width = getScaledWidth(fabricCanvas) + extendWidth, _tmp.height = getScaledHeight(fabricCanvas), _tmp.fontList = fontList, _tmp.includeGrading = includeGrading, _tmp);
                        _state.label = 1;
                    case 1:
                        _state.trys.push([
                            1,
                            3,
                            ,
                            4
                        ]);
                        return [
                            4,
                            drawPDF(_$param)
                        ];
                    case 2:
                        ref = _state.sent(), data = ref.data, fontsError = ref.fontsError;
                        if (fontsError.length > 0) {
                            onWarning === null || onWarning === void 0 ? void 0 : onWarning(fontsError);
                        }
                        _tmp1 = {};
                        downloadFileToUser((_tmp1.filename = filename, _tmp1.embedData = "data:application/pdf;base64,".concat(data), _tmp1));
                        return [
                            3,
                            4
                        ];
                    case 3:
                        error = _state.sent();
                        _tmp2 = {};
                        toast = createStandaloneToast((_tmp2.theme = dash, _tmp2)).toast;
                        _tmp3 = {};
                        toast((_tmp3.title = error.message, _tmp3.status = "error", _tmp3));
                        return [
                            3,
                            4
                        ];
                    case 4:
                        return [
                            2
                        ];
                }
            });
        });
        return _downloadAsPDF.apply(this, arguments);
    }
    function getSvgElementWithColorZone(_) {
        return _getSvgElementWithColorZone.apply(this, arguments);
    }
    function _getSvgElementWithColorZone() {
        _getSvgElementWithColorZone = _async_to_generator(function(param) {
            var svgElement, colorMode, _extendWidth, extendWidth, colorzoneList, aopList, _tmp;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        svgElement = param.svgElement, colorMode = param.colorMode, _extendWidth = param.extendWidth, extendWidth = _extendWidth === void 0 ? 0 : _extendWidth;
                        colorzoneList = getFillFromColorzone(fabricCanvasRef.current);
                        return [
                            4,
                            getAopFromColorzone(fabricCanvasRef.current)
                        ];
                    case 1:
                        aopList = _state.sent();
                        _tmp = {};
                        // replace svg colorzone with styles
                        return [
                            2,
                            replaceColorzoneToOriginalSVG((_tmp.colorzoneList = colorzoneList, _tmp.aopList = aopList, _tmp.originalSVG = svgElement.innerHTML, _tmp.colorMode = colorMode, _tmp.extendWidth = extendWidth, _tmp.fabricCanvas = fabricCanvasRef.current, _tmp)) || ""
                        ];
                }
            });
        });
        return _getSvgElementWithColorZone.apply(this, arguments);
    }
    function prepareDataRenderer(param) {
        var glbSrc = param.glbSrc, zoneCustomizations = param.zoneCustomizations, all3DElements = param.all3DElements, multiplier = param.multiplier;
        if (!multiplier) multiplier = 1 // multiplier use for make sure design template as base64 not too big or long string, because it might break local storage
        ;
        var urlParams = getURLParams();
        var refPd = urlParams === null || urlParams === void 0 ? void 0 : urlParams.get("pd");
        if (!glbSrc || !refPd) {
            reportError("Sorry, realistic render mode not available for this model.\nPlease upload GLB.", {
                module: "High quality mode"
            });
            return;
        }
        // If multiplier lower than 0.2 then quality will not good enought to bring to realistic render mode
        if (multiplier <= 0.2) {
            // Note: This cannot calculate with dimension. because base64 length depend on pixel color too.
            reportError("This template exceed local storage. Please contact admistrator.", {
                module: "High quality mode"
            });
            return;
        }
        // base64 from 2D design
        var base64 = fabricCanvasRef.current.toDataURL({
            multiplier: multiplier
        });
        var useZoneCustomizations = [];
        zoneCustomizations.forEach(function(zone) {
            useZoneCustomizations.push({
                svgIds: zone.zone.svgIds,
                color: zone.color.hex
            });
        });
        var useAll3DElements = [];
        all3DElements.forEach(function(zone) {
            useAll3DElements.push({
                fullcode: zone.fullCode
            });
        });
        var cleanLocalStorage = function() {
            for(var i = 0, len = localStorage.length; i < len; i++){
                var key = localStorage.key(i);
                if (key && key.indexOf("BJS_") !== -1) localStorage.removeItem(key);
            }
        };
        var bjsLocalStorageCount = function() {
            var count = 0;
            for(var i = 0, len = localStorage.length; i < len; i++){
                var key = localStorage.key(i);
                if (key && key.indexOf("BJS_BASE64_") !== -1) count++;
            }
            return count;
        };
        try {
            // CHECK IF LOCAL STORAGE FOR BJS OVER 5 ITEM, IF YES THEN CLEAN LOCAL STORAGE
            // PREVENT FULL LOCAL STORAGE
            if (bjsLocalStorageCount() >= 1) cleanLocalStorage();
            // SET LOCAL STORAGE
            localStorage.setItem("BJS_BASE64_" + refPd, base64);
            localStorage.setItem("BJS_GLB_SRC_" + refPd, glbSrc) // string
            ;
            localStorage.setItem("BJS_ZONE_CUSTOMIZATION_" + refPd, JSON.stringify(useZoneCustomizations)) // json
            ;
            localStorage.setItem("BJS_ALL3DELEMENTS_" + refPd, JSON.stringify(all3DElements)) // json
            ;
            window.open("rendering?refPd=" + refPd, "_blank" // <- This is what makes it open in a new window.
            );
        } catch (error) {
            // When local storage exceed limit then cleanup and re-call again.
            cleanLocalStorage();
            multiplier = multiplier / 2 // Reduce template size until it usable in local storage.
            ;
            prepareDataRenderer({
                glbSrc: glbSrc,
                zoneCustomizations: zoneCustomizations,
                all3DElements: all3DElements,
                multiplier: multiplier
            }) // call again
            ;
            if ("production" === "development") {
                console.debug("multiplier", multiplier);
                console.debug("localStorage error ... clean & retrying", error);
            }
        }
    }
    return {
        export2D: export2D,
        prepareDataRenderer: prepareDataRenderer
    };
};
