import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
export var placeImage = function(state, param) {
    var placedImage = param.payload;
    var ref;
    var placedImageIndex = state === null || state === void 0 ? void 0 : (ref = state.placedImages) === null || ref === void 0 ? void 0 : ref.findIndex(function(image) {
        return image.id === placedImage.id;
    });
    if (placedImageIndex !== -1) {
        state.placedImages[placedImageIndex] = _object_spread({}, state.placedImages[placedImageIndex], placedImage);
    } else {
        state.placedImages = _to_consumable_array(state.placedImages).concat([
            placedImage
        ]);
    }
};
