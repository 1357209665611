import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import { fixCDN } from "@brikl/studio-utils";
import { OBJECT_VISIBILITY_PROPERTIES } from "./config";
import { convertSvgToObject, fabricRequestRender, loadImageFromURL } from "./util";
export var PATTERN_INITIAL_DATA = {
    scale: null,
    rotate: 0,
    visible: true,
    deleted: false,
    lock: true
};
export var mapImageObjectToFabricPatternData = function(imageObject) {
    return {
        // main
        id: imageObject.data.id,
        // position
        top: imageObject.top,
        left: imageObject.left,
        // transform
        scale: imageObject.scaleX,
        rotate: imageObject.angle,
        // controls
        flipX: imageObject.flipX,
        flipY: imageObject.flipY,
        // lock
        // we only need one property from lock since all of them
        // are the same boolean value
        lock: imageObject.lockMovementX
    };
};
export var addPattern = function() {
    var _ref = _async_to_generator(function(param) {
        var fabricCanvas, patternData, svgDimension, _shouldBringToFront, shouldBringToFront, base64, fabricPattern, _tmp, _rotate, _left, _top, _tmp1, _tmp2;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    fabricCanvas = param.fabricCanvas, patternData = param.patternData, svgDimension = param.svgDimension, _shouldBringToFront = param.shouldBringToFront, shouldBringToFront = _shouldBringToFront === void 0 ? false : _shouldBringToFront;
                    base64 = patternData.base64;
                    if (!base64) {
                        console.error("base64 is empty");
                        return [
                            2
                        ];
                    }
                    if (!svgDimension) {
                        console.log("svgDimension is empty");
                    }
                    if (!(base64.indexOf("image/svg") !== -1 || base64.indexOf(".svg") !== -1)) return [
                        3,
                        2
                    ];
                    return [
                        4,
                        convertSvgToObject(fixCDN(base64))
                    ];
                case 1:
                    // CONVERT SVG TO IMAGE
                    fabricPattern = _state.sent();
                    return [
                        3,
                        4
                    ];
                case 2:
                    return [
                        4,
                        loadImageFromURL(fixCDN(base64))
                    ];
                case 3:
                    fabricPattern = _state.sent();
                    _state.label = 4;
                case 4:
                    _tmp = {};
                    fabricPattern.set("data", _object_spread((_tmp.id = patternData.id, _tmp.type = "Pattern", _tmp), patternData));
                    fabricPattern.set("angle", (_rotate = patternData.rotate) !== null && _rotate !== void 0 ? _rotate : 0);
                    fabricPattern.set("left", (_left = patternData.left) !== null && _left !== void 0 ? _left : 0);
                    fabricPattern.set("top", (_top = patternData.top) !== null && _top !== void 0 ? _top : 0);
                    // in case of scale is null it should scale to fit design template size
                    if (svgDimension && isNil(patternData.scale)) {
                        // Match height of pattern to 2D design template
                        // Use height because some export 2D have included product info which is extend area
                        fabricPattern.scaleToHeight(svgDimension === null || svgDimension === void 0 ? void 0 : svgDimension.height);
                    } else {
                        fabricPattern.set("scaleX", patternData.scale);
                        fabricPattern.set("scaleY", patternData.scale);
                    }
                    fabricPattern.set("centeredScaling", true);
                    fabricPattern.set("centeredRotation", true);
                    fabricPattern.setControlsVisibility(OBJECT_VISIBILITY_PROPERTIES);
                    _tmp1 = {};
                    if (patternData.lock) {
                        fabricPattern.set((_tmp1.lockMovementX = true, _tmp1.lockMovementY = true, _tmp1.lockScalingX = true, _tmp1.lockScalingY = true, _tmp1.lockUniScaling = true, _tmp1.lockRotation = true, _tmp1));
                    }
                    fabricCanvas.add(fabricPattern);
                    if (shouldBringToFront) {
                        fabricCanvas.bringToFront(fabricPattern);
                    }
                    fabricRequestRender(fabricCanvas);
                    _tmp2 = {};
                    fabricCanvas.trigger("object:modified", (_tmp2.target = fabricPattern, _tmp2));
                    return [
                        2,
                        fabricPattern
                    ];
            }
        });
    });
    return function addPattern(_) {
        return _ref.apply(this, arguments);
    };
}();
export var updatePattern = function(param) {
    var fabricCanvas = param.fabricCanvas, imageId = param.imageId, transform = param.transform;
    var imageObject = fabricCanvas.getObjects().find(function(obj) {
        var ref;
        return ((ref = obj.data) === null || ref === void 0 ? void 0 : ref.id) === imageId;
    });
    if (!imageObject) {
        console.warn("Image Object not found", imageId);
        return;
    }
    var setProperty = function(transformProp, imageObjectProp) {
        if (!isNil(transform[transformProp]) && !isEqual(imageObject[imageObjectProp], transform[transformProp])) {
            imageObject.set(imageObjectProp, transform[transformProp]);
        }
    };
    setProperty("rotate", "angle");
    setProperty("scale", "scaleX");
    setProperty("scale", "scaleY");
    fabricRequestRender(fabricCanvas);
    fabricCanvas.trigger("object:modified", {
        target: imageObject
    });
};
export var mirrorItem = function(param) {
    var fabricCanvas = param.fabricCanvas, itemId = param.itemId, mirrorType = param.mirrorType;
    var object = fabricCanvas.getObjects().find(function(obj) {
        var ref;
        return ((ref = obj.data) === null || ref === void 0 ? void 0 : ref.id) === itemId;
    });
    object.set(mirrorType, Boolean(!object[mirrorType]));
    fabricRequestRender(fabricCanvas);
    fabricCanvas.trigger("object:modified", {
        target: object
    });
};
export var toggleVisible = function(param) {
    var fabricCanvas = param.fabricCanvas, itemId = param.itemId;
    var object = fabricCanvas.getObjects().find(function(obj) {
        var ref;
        return ((ref = obj.data) === null || ref === void 0 ? void 0 : ref.id) === itemId;
    });
    object.set("visible", Boolean(!object.visible));
    fabricCanvas.discardActiveObject();
    fabricRequestRender(fabricCanvas);
    fabricCanvas.trigger("object:modified", {
        target: object
    });
};
export var toggleLock = function(param) {
    var fabricCanvas = param.fabricCanvas, itemId = param.itemId;
    var object = fabricCanvas.getObjects().find(function(obj) {
        var ref;
        return ((ref = obj.data) === null || ref === void 0 ? void 0 : ref.id) === itemId;
    });
    object.set({
        lockMovementX: Boolean(!object["lockMovementX"]),
        lockMovementY: Boolean(!object["lockMovementY"]),
        lockScalingX: Boolean(!object["lockScalingX"]),
        lockScalingY: Boolean(!object["lockScalingY"]),
        lockUniScaling: Boolean(!object["lockUniScaling"]),
        lockRotation: Boolean(!object["lockRotation"])
    });
    fabricCanvas.discardActiveObject();
    fabricCanvas.targetControl = null;
    fabricCanvas.targetObject = null;
    fabricCanvas.targetObjectPos = {
        left: 0,
        top: 0
    };
    fabricRequestRender(fabricCanvas);
    fabricCanvas.trigger("object:modified", {
        target: object
    });
};
