import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { call, put, select, takeLatest } from "typed-redux-saga";
import { v4 } from "uuid";
import { selectSelectedColorZone, selectSelectedCustomTemplateColor } from "../selectors";
import { actions } from "../slice";
import { getSelectedColorOnCurrentZone } from "../transforms/get-custom-template-colors";
import { onUpdateColorFill } from "./update-color";
function onResetZoneColor() {
    var selectedColorZone, selectedCustomTemplateColor, selectedColorId, selectedColor, _tmp, newCustomTemplateColor, _tmp1, _tmp2, customTemplateColorWithDefaultColor, _tmp3, _tmp4;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    5,
                    _ts_values(select(selectSelectedColorZone))
                ];
            case 1:
                selectedColorZone = _state.sent();
                // ensure you have selected a color zone
                if (!selectedColorZone) return [
                    2
                ];
                return [
                    5,
                    _ts_values(select(selectSelectedCustomTemplateColor))
                ];
            case 2:
                selectedCustomTemplateColor = _state.sent();
                if (!selectedColorZone.defaultColor) {
                    console.warn("[Color Fill] Default color is missing for this color zone.");
                    return [
                        2
                    ];
                }
                selectedColorId = selectedColorZone.defaultColor.id;
                _tmp = {};
                selectedColor = getSelectedColorOnCurrentZone((_tmp.colorZone = selectedColorZone, _tmp.colorId = selectedColorId, _tmp));
                if (!!selectedCustomTemplateColor) return [
                    3,
                    5
                ];
                _tmp1 = {};
                newCustomTemplateColor = (_tmp1.colorId = selectedCustomTemplateColor.color.id, _tmp1.id = v4(), _tmp1.templateId = selectedCustomTemplateColor.templateId, _tmp1.zoneId = selectedCustomTemplateColor.zone.zoneId, _tmp1);
                return [
                    5,
                    _ts_values(call(onUpdateColorFill, newCustomTemplateColor, selectedColor.hex))
                ];
            case 3:
                _state.sent();
                return [
                    5,
                    _ts_values(put(actions.setTemplateColor(newCustomTemplateColor)))
                ];
            case 4:
                _state.sent();
                _tmp2 = {};
                console.log("[Color Fill] Resetting new color", (_tmp2.selectedColorZone = selectedColorZone, _tmp2));
                return [
                    2
                ];
            case 5:
                _tmp3 = {};
                customTemplateColorWithDefaultColor = (_tmp3.colorId = selectedColorZone.defaultColor.id, _tmp3.id = selectedCustomTemplateColor.id, _tmp3.templateId = selectedCustomTemplateColor.templateId, _tmp3.zoneId = selectedCustomTemplateColor.zone.zoneId, _tmp3);
                return [
                    5,
                    _ts_values(call(onUpdateColorFill, customTemplateColorWithDefaultColor, selectedColor.hex))
                ];
            case 6:
                _state.sent();
                return [
                    5,
                    _ts_values(put(actions.setTemplateColor(customTemplateColorWithDefaultColor)))
                ];
            case 7:
                _state.sent();
                _tmp4 = {};
                console.log("[Color Fill] Resetting custom color", (_tmp4.customTemplateColorWithDefaultColor = customTemplateColorWithDefaultColor, _tmp4));
                return [
                    2
                ];
        }
    });
}
export function watchResetZoneColor() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    takeLatest(actions.resetZoneColor.type, onResetZoneColor)
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
