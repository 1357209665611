import { ProductDesignTemplateType } from "@brikl/codegen";
import { getColorZonesFromTemplate } from "./get-color-zones-from-template";
var sortBySortOrder = function(a, b) {
    return (a === null || a === void 0 ? void 0 : a.sortOrder) - (b === null || b === void 0 ? void 0 : b.sortOrder);
};
var isTemplateContainingZones = function(isDesignRoom) {
    return function(dt) {
        return dt.zones && dt.zones.length > 0 && (isDesignRoom ? true : dt.isEnabled);
    };
};
/** Only filters the templates that has color zones. */ export var getTemplatesWithColorZones = function(productTemplates, isDesignRoom) {
    var ref;
    var filter = isTemplateContainingZones(isDesignRoom);
    return ((ref = productTemplates === null || productTemplates === void 0 ? void 0 : productTemplates.filter(filter)) === null || ref === void 0 ? void 0 : ref.sort(sortBySortOrder)) || [];
};
/** Default text settings. */ var textSettings = {
    colors: [
        {
            hex: "#ffffff",
            id: "white",
            name: "White"
        },
        {
            hex: "#000000",
            id: "black",
            name: "black"
        }, 
    ]
};
/** Populate the template data from backend for use in the studio engine. */ export var transformDesignTemplates = function(context) {
    var transform = function transform(template) {
        var thumbnailSrc = template.previewImageSrc || blankTemplatePreviewSrc;
        var _isEnabled;
        var isEnabled = (_isEnabled = template.isEnabled) !== null && _isEnabled !== void 0 ? _isEnabled : false;
        var templateType = template.type === ProductDesignTemplateType.Colorzone ? "COLORZONE" : "BLANK";
        var colorZones = getColorZonesFromTemplate(template, fabrics, langCode);
        var svgSrc = template.designTemplateFileSrc || template.colorZoneFileSrc;
        var originalSvgSrc = template.originalDesignTemplateFileSrc || svgSrc;
        return {
            id: template.id,
            title: template.no,
            thumbnailSrc: thumbnailSrc,
            svgSrc: svgSrc,
            templateType: templateType,
            colorZones: colorZones,
            textSettings: textSettings,
            originalSvgSrc: originalSvgSrc,
            isEnabled: isEnabled
        };
    };
    var templates = context.productTemplates, fabrics = context.fabrics, langCode = context.langCode, isDesignRoom = context.isDesignRoom, blankTemplatePreviewSrc = context.blankTemplatePreviewSrc;
    return getTemplatesWithColorZones(templates, isDesignRoom).map(transform);
};
