import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
export function uploadPatterns(state, param) {
    var newPatterns = param.payload;
    state.uploadedPatterns = _to_consumable_array(state.uploadedPatterns).concat(_to_consumable_array(newPatterns.map(function(image) {
        return _object_spread_props(_object_spread({}, image), {
            lock: false,
            isUploading: true
        });
    })));
}
export var uploadPatternSuccess = function(state, param) {
    var newPattern = param.payload;
    var isMatched = function(image) {
        return image.id === newPattern.id;
    };
    var NonMatched = function(image) {
        return image.id !== newPattern.id;
    };
    var loaded = function(image) {
        return _object_spread_props(_object_spread({}, image, newPattern), {
            isUploading: false
        });
    };
    var uploadedPattern = state.uploadedPatterns.filter(isMatched).map(loaded);
    state.uploadedPatterns = _to_consumable_array(state.uploadedPatterns.filter(NonMatched)).concat(_to_consumable_array(uploadedPattern));
};
export var uploadPatternFailed = function(state, param) {
    var errorPattern = param.payload;
// TODO handle error message
};
