import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { UploadFileDocument } from "@brikl/codegen";
import { reportError } from "@brikl/shared-utils";
import { fixS3ToCdnUrl } from "@brikl/studio-utils";
import { uploadToS3 } from "~/libs/store/src/util/uploadFile";
import { S3GetFile } from "./image";
var uploadClient = function() {
    var _ref = _async_to_generator(function(folder, file, meta, uploadFile, callback) {
        var _tmp, er;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    _state.trys.push([
                        0,
                        2,
                        ,
                        3
                    ]);
                    _tmp = {};
                    return [
                        4,
                        uploadToS3([
                            file
                        ], [
                            "ownedByOrganisationId"
                        ], (_tmp.ownedByOrganisationId = meta.ownedByOrganisationId, _tmp), folder, uploadFile, true, null, callback)
                    ];
                case 1:
                    _state.sent();
                    return [
                        3,
                        3
                    ];
                case 2:
                    er = _state.sent();
                    throw new Error(er);
                case 3:
                    return [
                        2
                    ];
            }
        });
    });
    return function uploadClient(folder, file, meta, uploadFile, callback) {
        return _ref.apply(this, arguments);
    };
}();
export var uploadImage = function() {
    var _ref = _async_to_generator(function(folder, image, meta, apollo) {
        var file, uploadMutation, uploadResult, ref, ref1, ref2, ref3, fileName, fileUrl, _tmp, _tmp1;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    file = image.file;
                    uploadMutation = function(param) {
                        var variables = param.variables;
                        return apollo.mutate({
                            mutation: UploadFileDocument,
                            variables: variables
                        });
                    };
                    return [
                        4,
                        new Promise(function(resolve, reject) {
                            return uploadClient(folder, file, meta, uploadMutation, resolve).then().catch(function(er) {
                                reportError(er, {
                                    showToast: false,
                                    module: "Studio-Engine-Upload-Image-Helper"
                                });
                                reject("Unable to upload");
                            });
                        })
                    ];
                case 1:
                    uploadResult = _state.sent();
                    _tmp = {};
                    _tmp1 = {};
                    if (uploadResult) {
                        ;
                        ref3 = S3GetFile((ref = uploadResult) === null || ref === void 0 ? void 0 : (ref1 = ref.data) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.uploadFile) === null || ref2 === void 0 ? void 0 : ref2.url), fileName = ref3.fileName, fileUrl = ref3.fileUrl;
                        return [
                            2,
                            Promise.resolve(_object_spread_props(_object_spread(_tmp, image), (_tmp1.fileName = fileName, _tmp1.base64 = fixS3ToCdnUrl(fileUrl, "CDN"), _tmp1.srcUrl = fixS3ToCdnUrl(fileUrl, "CDN"), _tmp1)))
                        ];
                    }
                    return [
                        2,
                        Promise.reject("Unable to upload image")
                    ];
            }
        });
    });
    return function uploadImage(folder, image, meta, apollo) {
        return _ref.apply(this, arguments);
    };
}();
