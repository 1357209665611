import find from "lodash/find";
import { createSelector, createStructuredSelector } from "reselect";
import { selectAllMeta } from "../MetadataEngine/selectors";
import { initialState } from "./constants";
export var selectDomain = function(state) {
    return state.template || initialState;
};
export var selectAllTemplates = createSelector(selectDomain, selectAllMeta, function(state, meta) {
    var allTemplates = state === null || state === void 0 ? void 0 : state.templates;
    // Return all templates for design room
    if (meta === null || meta === void 0 ? void 0 : meta.isDesignRoom) return allTemplates;
    // Only enabled templates for embed
    return allTemplates.filter(function(template) {
        return template === null || template === void 0 ? void 0 : template.isEnabled;
    });
});
export var selectSelectedTemplateId = createSelector(selectDomain, function(state) {
    return (state === null || state === void 0 ? void 0 : state.selectedTemplateId) || null;
});
export var selectSelectedTemplate = createSelector(selectAllTemplates, selectSelectedTemplateId, function(templates, selectedTemplateId) {
    if (selectedTemplateId) return find(templates, {
        id: selectedTemplateId
    });
    return templates[0];
});
export var selectIsTemplateReady = createSelector(selectDomain, function(state) {
    return (state === null || state === void 0 ? void 0 : state.isTemplateReady) || false;
});
export var templateSelector = createStructuredSelector({
    isTemplateReady: selectIsTemplateReady,
    templates: selectAllTemplates,
    selectedTemplate: selectSelectedTemplate
});
