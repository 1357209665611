export var allowedResetFields = [
    "selectedPlaceholderGroup",
    "selectedPlaceholderId", 
];
export var initialState = {
    placeholders: [],
    selectedPlaceholderId: {
        placeholderId: null,
        placeholderSvgId: null
    },
    selectedPlaceholderGroup: null,
    selectedPrintingTechniques: [],
    isReady: false,
    isUploading: false
};
