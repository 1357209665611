import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import debounce from "lodash/debounce";
import { CreatePrintDesignRevisionDocument } from "@brikl/codegen";
import { getFile } from "../../../helpers/image";
import { mapProductDesignPrintInput } from "../transforms/map-product-design-input";
export var createPrintMutation = function(apollo) {
    return function(param) {
        var id = param.id, ownedByOrganisationId = param.ownedByOrganisationId, productDesignId = param.productDesignId, revisionNo = param.revisionNo, input = param.input;
        return apollo.mutate({
            mutation: CreatePrintDesignRevisionDocument,
            variables: {
                id: id,
                ownedByOrganisationId: ownedByOrganisationId,
                productDesignId: productDesignId,
                revisionNo: revisionNo,
                input: input
            }
        });
    };
};
export var createPrint = function(image, meta, apollo) {
    var revisionNo = meta.revisionNo, productDesignId = meta.productDesignId, ownedByOrganisationId = meta.ownedByOrganisationId;
    var id = image.id, fileName = image.fileName, placeholderId = image.placeholderId, printingTechniqueId = image.printingTechniqueId;
    var input = {
        src: fileName,
        name: fileName,
        placeholderId: placeholderId,
        printingTechniqueId: printingTechniqueId
    };
    return createPrintMutation(apollo)({
        id: id,
        ownedByOrganisationId: ownedByOrganisationId,
        productDesignId: productDesignId,
        revisionNo: revisionNo,
        input: input
    });
};
export var addPrint = function(params, print) {
    return function(apollo) {
        return debounce(function() {
            var id = print.id, base64 = print.base64, srcUrl = print.srcUrl;
            if (!base64 || !srcUrl) return;
            var revisionNo = params.revisionNo, productDesignId = params.productDesignId, ownedByOrganisationId = params.ownedByOrganisationId;
            var productDesignInput = mapProductDesignPrintInput(print);
            var input = _object_spread_props(_object_spread({}, productDesignInput), {
                src: getFile(srcUrl || base64).fileUrl
            });
            var inputVariables = {
                id: id,
                ownedByOrganisationId: ownedByOrganisationId,
                productDesignId: productDesignId,
                revisionNo: revisionNo,
                input: input
            };
            return createPrintMutation(apollo)(inputVariables);
        }, 500);
    };
};
