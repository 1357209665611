import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
export var deleteUploadedImage = function(state, param) {
    var image = param.payload;
    state.uploadedImages = state.uploadedImages.filter(function(uploadImage) {
        return uploadImage.id !== image.id;
    });
};
export var deletePlacedImage = function(state, param) {
    var deletedImage = param.payload;
    state.placedImages = state.placedImages.filter(function(placedImage) {
        return placedImage.id !== deletedImage.id;
    });
    state.deletedPlacedImages = _to_consumable_array(state.deletedPlacedImages).concat([
        deletedImage
    ]);
};
