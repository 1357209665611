import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { createSlice } from "../../@redux";
import { initialState } from "./constants";
export var slice = createSlice({
    name: "cart",
    initialState: initialState,
    reducers: {
        resetData: function() {
            return initialState;
        },
        // cart
        loadDesignPrice: function(state, _action) {
            state.cartTotal = _object_spread_props(_object_spread({}, state.cartTotal), {
                value: undefined
            });
        },
        loadDesignPriceNBE: function(state, _action) {
            state.cartTotal = _object_spread_props(_object_spread({}, state.cartTotal), {
                value: undefined
            });
        },
        updateDesignPriceSuccess: function(state, param) {
            var payload = param.payload;
            var ref, ref1, ref2, ref3, ref4, ref5;
            var currency = payload === null || payload === void 0 ? void 0 : (ref = payload.cartTotalPrice) === null || ref === void 0 ? void 0 : ref.currency;
            var totalPrice = payload === null || payload === void 0 ? void 0 : (ref1 = payload.cartTotalPrice) === null || ref1 === void 0 ? void 0 : ref1.value;
            var shippingCost = payload === null || payload === void 0 ? void 0 : (ref2 = payload.cartTotalGroups) === null || ref2 === void 0 ? void 0 : (ref3 = ref2[0]) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.shippingCost) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.cost) === null || ref5 === void 0 ? void 0 : ref5.value;
            var designTotal = {
                currency: currency,
                value: (totalPrice - shippingCost).toFixed(2)
            };
            state.cartTotal = designTotal;
            state.isReady = true;
        },
        updateDesignPrice: function(state, _action) {
            state.cartTotal = _object_spread_props(_object_spread({}, state.cartTotal), {
                value: undefined
            });
        },
        updateDesignPriceSuccessNBE: function(state, param) {
            var payload = param.payload;
            var ref;
            var currency = payload === null || payload === void 0 ? void 0 : payload.currency;
            var ref1;
            var totalPrice = (ref1 = payload === null || payload === void 0 ? void 0 : (ref = payload.cartTotal) === null || ref === void 0 ? void 0 : ref.price) !== null && ref1 !== void 0 ? ref1 : 0;
            var designTotal = {
                currency: currency,
                value: totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.toFixed(2)
            };
            state.cartTotal = designTotal;
            state.isReady = true;
        }
    }
});
export var actions = slice.actions, reducer = slice.reducer, name = slice.name;
