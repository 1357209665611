import { createSelector, createStructuredSelector } from "reselect";
import { initialState } from "./constants";
export var domainSelector = function(state) {
    return state.cart || initialState;
};
export var selectCartTotal = createSelector(domainSelector, function(state) {
    return (state === null || state === void 0 ? void 0 : state.cartTotal) || initialState.cartTotal;
});
export default createStructuredSelector({
    cartTotal: selectCartTotal
});
