export var getFile = function(url) {
    return {
        fileName: url === null || url === void 0 ? void 0 : url.split("/")[(url === null || url === void 0 ? void 0 : url.split("/").length) - 1].split("?")[0],
        fileUrl: convertExtentionToSvg(url === null || url === void 0 ? void 0 : url.split("?")[0])
    };
};
var needLoadingTime = [
    "svg",
    "pdf"
];
export var isNonImageType = function(file) {
    var extensionType = file.name.split(".").pop();
    return needLoadingTime.includes(extensionType);
};
/**
 * Get content type from url extension and replace with new file extension
 * @param fileUrl full path url
 */ export var convertExtentionToSvg = function(fileUrl) {
    if (!fileUrl) return null;
    // File extensions
    var PDF = "pdf";
    var AI = "ai";
    var EPS = "eps";
    // Extract extension from url
    var fileExtension = fileUrl.split(".").pop();
    switch(fileExtension){
        case AI:
        case PDF:
        case EPS:
            return fileUrl.replace(/(\.[^/.]+)+$/, ".svg");
        default:
            return fileUrl;
    }
};
export var S3GetFile = function(url) {
    var urlParams = new URL(url);
    var cleanFullUrl = "".concat(urlParams.origin).concat(urlParams.pathname);
    var imageFileObj = new URL(cleanFullUrl).pathname.split("/");
    var fileName = imageFileObj[imageFileObj.length - 1];
    return {
        fileName: convertExtentionToSvg(fileName),
        fileUrl: convertExtentionToSvg(cleanFullUrl),
        originalFileUrl: url
    };
};
