export var transformPrintImages = function(printDesignRevisions, placeholders) {
    return printDesignRevisions.map(function(param) {
        var id = param.id, placeholderId = param.placeholderId, printingTechniqueId = param.printingTechniqueId, src = param.src, defaultPrintId = param.defaultPrintId, name = param.name;
        var ref;
        return {
            id: id,
            // return 2 keys for studio
            srcUrl: src,
            base64: src,
            fileName: name,
            defaultPrintId: defaultPrintId,
            placeholderId: placeholderId,
            placeholderSvgId: (ref = placeholders === null || placeholders === void 0 ? void 0 : placeholders.find(function(placeholder) {
                return placeholder.id === placeholderId;
            })) === null || ref === void 0 ? void 0 : ref.svgId,
            printingTechniqueId: printingTechniqueId
        };
    });
};
