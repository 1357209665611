import * as constants from "./constants";
import saga from "./saga";
import { slice } from "./slice";
export var TextEngine = {
    constants: constants
};
export var TextEngineConfig = {
    saga: saga,
    reducer: slice.reducer
};
