import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { isBrowser } from "./constants";
export var createLogger = function(namespace) {
    if (isBrowser) {
        var _console;
        if ("production" !== "development") {
            return function() {
                for(var _len = arguments.length, _args = new Array(_len), _key = 0; _key < _len; _key++){
                    _args[_key] = arguments[_key];
                }
                return null;
            };
        }
        return function() {
            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                args[_key] = arguments[_key];
            }
            return (_console = console).log.apply(_console, [
                namespace
            ].concat(_to_consumable_array(args)));
        };
    }
    return function() {
    // noop
    };
};
