/* eslint-disable @typescript-eslint/ban-ts-comment */ import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { all, call, put, select } from "typed-redux-saga";
import { getMutationParams } from "../../../utils/saga-helpers";
import { selectSelectedDesignStep } from "../../DesignStepEngine/selectors";
import { makeSelectPlacedImages } from "../../ImageEngine/selectors";
import { actions as imageEngineActions } from "../../ImageEngine/slice";
import { makeSelectSelectedPlaceholder } from "../../PlaceholderEngine/selectors";
import { selectPlacedTexts } from "../selectors";
import { actions } from "../slice";
import { createTextDesignRevision } from "./text-helpers/actions";
export function onPlacePersonalizationText(action) {
    var ref, apollo, meta, placedText, listOfDeletedItems, selectedPlaceholder, placedTexts, selectedDesignStep, selectedPlaceholderGroup, allPlacedImages, placedGroupPlacedImages, _placeholderGroupId, _placeholderId, _placeholderSvgId, newPlacedText, _tmp, _tmp1, isDeletePlaceTextsInSameGroup, e;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    10,
                    ,
                    11
                ]);
                return [
                    5,
                    _ts_values(getMutationParams())
                ];
            case 1:
                ref = _state.sent(), apollo = ref.apollo, meta = ref.meta;
                if (meta.viewOnly) return [
                    2
                ];
                placedText = action.payload;
                listOfDeletedItems = [];
                return [
                    5,
                    _ts_values(select(makeSelectSelectedPlaceholder))
                ];
            case 2:
                selectedPlaceholder = _state.sent();
                return [
                    5,
                    _ts_values(select(selectPlacedTexts))
                ];
            case 3:
                placedTexts = _state.sent();
                return [
                    5,
                    _ts_values(select(selectSelectedDesignStep))
                ];
            case 4:
                selectedDesignStep = _state.sent();
                selectedPlaceholderGroup = selectedDesignStep.designPlaceholderGroups.find(function(group) {
                    return group.designPlaceholderIds.includes(selectedPlaceholder.id);
                });
                return [
                    5,
                    _ts_values(select(makeSelectPlacedImages))
                ];
            case 5:
                allPlacedImages = _state.sent();
                if (!selectedPlaceholderGroup || !selectedPlaceholder) {
                    new Error("[ImageEngine] onPlacePlaceholderImage => selecedPlaceholderGroup & selectedPlaceholder are empty");
                }
                placedGroupPlacedImages = allPlacedImages.filter(function(image) {
                    return selectedPlaceholderGroup.designPlaceholderIds.includes(image.placeholderId) || image.placeholderGroupId === selectedPlaceholderGroup.id;
                });
                _tmp = {};
                _tmp1 = {};
                newPlacedText = _object_spread_props(_object_spread(_tmp, placedText), (_tmp1.placeholderGroupId = (_placeholderGroupId = placedText.placeholderGroupId) !== null && _placeholderGroupId !== void 0 ? _placeholderGroupId : selectedPlaceholderGroup.id, _tmp1.placeholderId = (_placeholderId = placedText.placeholderId) !== null && _placeholderId !== void 0 ? _placeholderId : selectedPlaceholder === null || selectedPlaceholder === void 0 ? void 0 : selectedPlaceholder.id, _tmp1.placeholderSvgId = (_placeholderSvgId = placedText.placeholderSvgId) !== null && _placeholderSvgId !== void 0 ? _placeholderSvgId : selectedPlaceholder === null || selectedPlaceholder === void 0 ? void 0 : selectedPlaceholder.svgId, _tmp1));
                // Start business logic case for warrix
                placedTexts.forEach(function(placedText) {
                    var ref;
                    var toDelete = false;
                    //if placeholder is taken and maximum is 1 we replace
                    var isExceedMaximumItem = selectedPlaceholder.maximumItems === 1 || selectedPlaceholder.maximumItems === null;
                    if (isExceedMaximumItem && selectedPlaceholder.id === placedText.placeholderId) {
                        toDelete = true;
                    }
                    // in case of in another placeholder to prevent combination logic is "SINGLE" remove image in the same placeholder.
                    var isInAnotherPlaceholder = placedText.placeholderId !== newPlacedText.placeholderId;
                    // if a placeholder group is single we clear all placeholders in the group and add new
                    // delete placeholder image in another placeholder of the same group
                    if (selectedPlaceholderGroup.combinationLogic === "SINGLE" && ((ref = selectedPlaceholderGroup.designPlaceholderIds) === null || ref === void 0 ? void 0 : ref.includes(placedText.placeholderId)) && isInAnotherPlaceholder) {
                        toDelete = true;
                    }
                    if (placedText.id === newPlacedText.id) {
                        toDelete = false;
                    }
                    if (toDelete) {
                        listOfDeletedItems.push(placedText.id);
                    }
                });
                return [
                    5,
                    _ts_values(all(listOfDeletedItems.map(function(id) {
                        return put(actions.deleteText({
                            id: id
                        }));
                    })))
                ];
            case 6:
                _state.sent();
                isDeletePlaceTextsInSameGroup = selectedPlaceholderGroup.combinationLogic === "SINGLE" && placedGroupPlacedImages.length !== 0;
                if (!isDeletePlaceTextsInSameGroup) return [
                    3,
                    8
                ];
                return [
                    5,
                    _ts_values(all(placedGroupPlacedImages.map(function(image) {
                        return put(imageEngineActions.deletePlacedImage({
                            id: image.id
                        }));
                    })))
                ];
            case 7:
                _state.sent();
                _state.label = 8;
            case 8:
                return [
                    5,
                    _ts_values(call(createTextDesignRevision, placedText, meta, apollo))
                ];
            case 9:
                _state.sent();
                console.log("[TextEngine] onPlacePersonalizationText ", placedText);
                return [
                    3,
                    11
                ];
            case 10:
                e = _state.sent();
                console.error("[TextEngine] onPlacePersonalizationText => error ", e);
                return [
                    3,
                    11
                ];
            case 11:
                return [
                    2
                ];
        }
    });
}
