import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { all, put, select, takeLatest } from "typed-redux-saga";
import { getCanvasColorZoneId } from "@brikl/studio";
import { selectSelectedTemplateColorZones } from "../../ColorFillEngine/selectors";
import { actions } from "../slice";
function onSelectSVGElement(action) {
    var svgObjectId, selectedTemplateColorZones, matchSvgColorZone, error;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    4,
                    ,
                    5
                ]);
                svgObjectId = action.payload;
                return [
                    5,
                    _ts_values(select(selectSelectedTemplateColorZones))
                ];
            case 1:
                selectedTemplateColorZones = _state.sent();
                matchSvgColorZone = selectedTemplateColorZones.find(function(colorZone) {
                    return colorZone.zone.svgIds.map(function(svgId) {
                        return getCanvasColorZoneId(svgId);
                    }).includes(svgObjectId);
                });
                if (!matchSvgColorZone) return [
                    3,
                    3
                ];
                return [
                    5,
                    _ts_values(put(actions.selectZone(matchSvgColorZone.zone.zoneId)))
                ];
            case 2:
                _state.sent();
                _state.label = 3;
            case 3:
                return [
                    3,
                    5
                ];
            case 4:
                error = _state.sent();
                console.error("[Scene Engine] onSelectSvgElement error", error);
                return [
                    3,
                    5
                ];
            case 5:
                return [
                    2
                ];
        }
    });
}
function onSelect3DElement() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    console.log("onSelect3DElement")
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
export function watchSelectSVGElement() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    all([
                        takeLatest(actions.selectSVGElement.type, onSelectSVGElement),
                        takeLatest(actions.selectZone3DElement.type, onSelect3DElement)
                    ])
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
