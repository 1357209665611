import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import React from "react";
import { useSelector } from "react-redux";
import { selectAllFabrics, selectPlacedTexts } from "@brikl/studio-engine";
import { updateText } from "../../_fabric/text";
import { useFabricCanvas } from "../../_fabric/useFabricCanvas";
import { useStudio } from "../state/state";
/**
 * This effect is used to sync the state with the canvas, it only need to be
 * added in one place.
 *
 * The reason it was made is to reflect the state changes in the canvas using the state first approach.
 * Only fast feedback operations like moving, scaling, transforming, etc, need to be 3d first.
 */ export var useTextUpdateEffect = function(param) {
    var properties = param.properties;
    var ref = _sliced_to_array(useStudio(), 1), studio = ref[0];
    var placedTexts = useSelector(selectPlacedTexts);
    var fabricCanvas = useFabricCanvas();
    var fabrics = useSelector(selectAllFabrics);
    React.useEffect(function() {
        if (studio.isCanvasReady) {
            placedTexts.forEach(function(canvasText) {
                var transform = properties.reduce(function(acc, prop) {
                    acc[prop] = canvasText[prop];
                    return acc;
                }, {});
                updateText({
                    fabricCanvas: fabricCanvas,
                    transform: transform,
                    textId: canvasText.id,
                    triggerElementModified: false,
                    fabrics: fabrics,
                    text: canvasText
                });
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        placedTexts,
        studio.isCanvasReady
    ]);
};
