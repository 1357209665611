import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { put } from "typed-redux-saga";
import { takeLatest } from "typed-redux-saga";
import { actions } from "../slice";
export function onLoadImages(action) {
    var payload, error;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    2,
                    ,
                    3
                ]);
                payload = action.payload;
                return [
                    5,
                    _ts_values(put(actions.loadImagesSuccess(payload)))
                ];
            case 1:
                _state.sent();
                console.log("[ImageEngine] load images successfully");
                return [
                    3,
                    3
                ];
            case 2:
                error = _state.sent();
                console.error("[ImageEngine] onLoadImages => ", error);
                return [
                    3,
                    3
                ];
            case 3:
                return [
                    2
                ];
        }
    });
}
/**
 * Watch place image event
 */ export function loadImages() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    takeLatest(actions.loadImages.type, onLoadImages)
                ];
            case 1:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
