// import { all } from 'redux-saga/effects'
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
export default function rootSaga() {
    return _ts_generator(this, function(_state) {
        return [
            2
        ];
    });
//
};
