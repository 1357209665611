import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import _ts_values from "@swc/helpers/src/_ts_values.mjs";
import { call } from "typed-redux-saga";
import { ZoneCustomizationType } from "@brikl/codegen";
import { getMutationParams } from "../../../utils/saga-helpers";
import { updateZoneCustomization } from "../../../utils/update-zone-customization";
export function onUpdateColorFill(customTemplateColor, colorHex) {
    var ref, apollo, meta, zoneCustomizationInput, _tmp, error;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    3,
                    ,
                    4
                ]);
                return [
                    5,
                    _ts_values(getMutationParams())
                ];
            case 1:
                ref = _state.sent(), apollo = ref.apollo, meta = ref.meta;
                console.log("[Color Fill] Update custom template color:", customTemplateColor);
                _tmp = {};
                zoneCustomizationInput = (_tmp.type = ZoneCustomizationType.SingleColorFill, _tmp.id = customTemplateColor.id, _tmp.templateId = customTemplateColor.templateId, _tmp.zoneId = customTemplateColor.zoneId, _tmp.colorId = customTemplateColor.colorId, _tmp.colorHex = colorHex, _tmp.alloverprintCustomization = null, _tmp.alloverprintId = null, _tmp.printingTechniqueId = null, _tmp);
                return [
                    5,
                    _ts_values(call(updateZoneCustomization, zoneCustomizationInput, apollo, meta))
                ];
            case 2:
                _state.sent();
                console.log("[Color Fill] Update Customization:", zoneCustomizationInput);
                return [
                    3,
                    4
                ];
            case 3:
                error = _state.sent();
                console.error("[Color Fill] Update Customization error:", error);
                throw error;
            case 4:
                return [
                    2
                ];
        }
    });
}
