import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { getFile } from "../../../../helpers/image";
import { createPatternMutation, deletePatternMutation, updatePatternMutation } from "./mutation";
import { productDesignVariables } from "./transform";
export var createPattern = function(image, meta, apollo) {
    var revisionNo = meta.revisionNo, productDesignId = meta.productDesignId, ownedByOrganisationId = meta.ownedByOrganisationId;
    var id = image.id, defaultImageId = image.defaultImageId, fileName = image.fileName;
    // Mutation input variables
    var logoDesign = {
        src: fileName,
        ownedByOrganisationId: ownedByOrganisationId
    };
    var input = {
        isOverlay: true,
        defaultImageId: defaultImageId,
        logoDesign: logoDesign
    };
    // To create image only without design attributes
    return createPatternMutation(apollo)({
        id: id,
        ownedByOrganisationId: ownedByOrganisationId,
        productDesignId: productDesignId,
        revisionNo: revisionNo,
        input: input
    });
};
export var addPattern = function(image, meta, apollo) {
    var id = image.id;
    // Important data
    var revisionNo = meta.revisionNo, productDesignId = meta.productDesignId, ownedByOrganisationId = meta.ownedByOrganisationId;
    // Prepare parameters for mutation function
    var productDesignInput = productDesignVariables(ownedByOrganisationId, image);
    var input = _object_spread_props(_object_spread({}, productDesignInput), {
        logoDesign: _object_spread_props(_object_spread({
            // Noted: API allow only file name not full path
            // keep src for mutation
            src: getFile((image === null || image === void 0 ? void 0 : image.srcUrl) || (image === null || image === void 0 ? void 0 : image.base64)).fileName
        }, productDesignInput.logoDesign), {
            ownedByOrganisationId: ownedByOrganisationId
        })
    });
    var inputVariables = {
        id: id,
        ownedByOrganisationId: ownedByOrganisationId,
        productDesignId: productDesignId,
        revisionNo: revisionNo,
        input: input
    };
    return createPatternMutation(apollo)(inputVariables);
};
export var updatePattern = function(image, meta, apollo) {
    var id = image.id;
    // Important data
    var revisionNo = meta.revisionNo, productDesignId = meta.productDesignId, ownedByOrganisationId = meta.ownedByOrganisationId;
    // Prepare parameters for mutation function
    var input = productDesignVariables(ownedByOrganisationId, image);
    var inputVariables = {
        id: id,
        ownedByOrganisationId: ownedByOrganisationId,
        productDesignId: productDesignId,
        revisionNo: revisionNo,
        input: _object_spread_props(_object_spread({}, input), {
            logoDesign: _object_spread_props(_object_spread({}, input.logoDesign), {
                // Noted: API allow only file name not full path
                // keep src for mutation
                src: getFile((image === null || image === void 0 ? void 0 : image.srcUrl) || (image === null || image === void 0 ? void 0 : image.base64)).fileName,
                ownedByOrganisationId: undefined
            })
        })
    };
    // Update api
    return updatePatternMutation(apollo)(inputVariables);
};
export var deletePattern = function(image, meta, apollo) {
    if (!image.id) {
        console.error("deletePattern => ", image);
        throw new Error("Pattern id is empty");
    }
    // Product design ID and Revision No
    var productDesignId = meta.productDesignId, revisionNo = meta.revisionNo, ownedByOrganisationId = meta.ownedByOrganisationId;
    // Mutation
    return deletePatternMutation(apollo)({
        productDesignId: productDesignId,
        ownedByOrganisationId: ownedByOrganisationId,
        revisionNo: revisionNo,
        id: image.id
    });
};
