import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import clone from "lodash/clone";
import omit from "lodash/omit";
export var placePattern = function(state, param) {
    var newPattern = param.payload;
    var placeNewPattern = clone(newPattern);
    var isFoundUploadedPattern = function(uploadedPattern) {
        return uploadedPattern.id === newPattern.defaultImageId;
    };
    var isNonMatched = function(placedPattern) {
        return placedPattern.id !== newPattern.id;
    };
    // find existing upload image src by defaultImageId
    var uploadedPattern = state.uploadedPatterns.find(isFoundUploadedPattern);
    if (uploadedPattern) {
        placeNewPattern = omit(newPattern, [
            "base64",
            "srcUrl"
        ]);
        placeNewPattern = _object_spread({}, uploadedPattern, placeNewPattern);
    }
    // update list
    state.placedPatterns = _to_consumable_array(state.placedPatterns.filter(isNonMatched)).concat([
        placeNewPattern, 
    ]);
};
