import { createSelector, createStructuredSelector } from "reselect";
export var layerDomainSelector = function(state) {
    return state.layer;
};
var ref;
export var selectOrderSequences = createSelector(layerDomainSelector, function(state) {
    return (ref = state === null || state === void 0 ? void 0 : state.orderSequences) !== null && ref !== void 0 ? ref : null;
});
export var layerSelector = createStructuredSelector({
    orderSequences: selectOrderSequences
});
