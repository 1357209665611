import useFabricCanvasExport_Internal from "./_fabric/useFabricCanvasExport";
export { removeKeys, StudioGlobal } from "./helper";
export { createLogger } from "./logger";
export { AopFill } from "./studio/components/AopFill/AopFill";
export { ColorFill } from "./studio/components/ColorFill/ColorFill";
export { CustomBackground } from "./studio/components/CustomBackground/CustomBackground";
export { DesignTemplates } from "./studio/components/DesignTemplates/DesignTemplates";
export { Image } from "./studio/components/Image/Image";
export { Pattern } from "./studio/components/Pattern/Pattern";
export { Personalization } from "./studio/components/Personalization/Personalization";
export { Settings } from "./studio/components/Settings/Settings";
export { TextPlacement } from "./studio/components/TextPlacement/TextPlacement";
export { useCapturePreview } from "./studio/hooks/useCapturePreview";
export { StudioActions } from "./studio/state/actions";
export { StudioProvider, useStudio } from "./studio/state/state";
export { DEFAULT_INITIAL_STATE } from "./studio/state/constants";
export { AllowedPlaceholderTypes, TemplateType, FabricObjectType } from "./_fabric/@types";
export { Studio } from "./studio/Studio";
export var AOP_TRANSFORM_INITIAL = {
    scale: 1,
    angle: 0,
    offsetX: 0,
    offsetY: 0,
    repeat: true
};
export { FabricCanvas, initFabricConfig } from "./_fabric/canvas";
export { setSizeTemplateToOriginal } from "./_fabric/export";
export { getCanvasColorZoneId } from "./_fabric/export.utils";
export { addImage, IMAGE_INITIAL_DATA, mirrorItem, toggleLock, toggleVisible, updateImage } from "./_fabric/image";
export { addText, adjustPlaceholder, changeFontFamily, mapTextObjectToFabricTextData, mirrorText, removeTextImage, TEXT_INITIAL_DATA, textToImage, toggleLockText, toggleVisibleText, updateText } from "./_fabric/text";
export { fabricCanvasRef, useFabricCanvas, useFabricCanvasRef } from "./_fabric/useFabricCanvas";
export { useScriptLoader } from "./_fabric/useScriptLoader";
export { removeDuplicateColorZones } from "./_fabric/utils/removeDuplicateColorZones";
export var useFabricCanvasExport = useFabricCanvasExport_Internal;
export { cleanupDesignTemplate } from "./_fabric/utils/cleanupElement";
export { getDefaultColorValuesFromDesignTemplate } from "./_fabric/utils/getDefaultColor";
export { validateDesignTemplate } from "./_fabric/utils/validateDesignTemplate";
/**
 * _three
 */ export { useSwitchNormalMap } from "./_three/hooks";
export { exportGltfFile, MultiViewExports, toggleCapture3D, toggleGifCapture, toggleMultipleCapture, EmbedCapturePosition } from "./_three/reconciler";
export { ThreeRenderer } from "./_three/renderer";
export { use3DLoader } from "./_three/use3DLoader";
export { useEditLight } from "./_three/useEditLight";
export { useThree } from "./_three/useThree";
export { cleanupResources, convertHexStringToHexadecimal, createGridImages, createLight, createMultipleCapture, get3DPrintPatternObject, getMaterial, getMouseXY, getUVCoordinatesFromIntersection, loadBase64Process, prepareApplyNormalMap, setNormalMap, setupHelper, setupLights, setupObjectInCenter, setupObjectScale, trimImage, updateObjectMaterialsWithCanvas } from "./_three/utils";
