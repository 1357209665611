import { getFile } from "@brikl/studio-engine";
import { transformPlacedImages } from "./transform";
var hasDesignAttributes = function(logoDesignRevision) {
    return logoDesignRevision.logoDesign !== null && logoDesignRevision.logoDesign.designAttributes !== null;
};
export var selectAndTransformPlacedImages = function(logoDesigns, placeholders, orderSequences) {
    if (!logoDesigns) return [];
    // Ensure have attributes design configured and not pattern
    logoDesigns = logoDesigns.filter(function(logoDesignRevision) {
        return hasDesignAttributes(logoDesignRevision) && !logoDesignRevision.isOverlay;
    });
    if (!logoDesigns) return [];
    return transformPlacedImages(logoDesigns, placeholders, orderSequences);
};
export var selectAndTransformUploadedImages = function(logoDesigns) {
    if (!logoDesigns) return [];
    // Ensure no attributes design configured and not pattern
    logoDesigns = logoDesigns.filter(function(logoDesignRevision) {
        return !hasDesignAttributes(logoDesignRevision) && !logoDesignRevision.isOverlay;
    });
    return logoDesigns.map(function(param) {
        var id = param.id, logoDesign = param.logoDesign;
        return {
            id: id,
            base64: getFile(logoDesign.src).fileUrl,
            srcUrl: getFile(logoDesign.src).fileUrl
        };
    });
};
